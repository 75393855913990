import { createSelector } from 'reselect'
import { PayloadAction } from '@reduxjs/toolkit'
import { Types } from 'ably'
import React from 'react'

import { RootState } from '../store'
import { MessageCallStart } from './realtime/interfaces/call'
import { TrackDetail } from '../contexts/stream'

export const types = {
  RECEIVE_START: 'call/receive/start',
  RECEIVE_LEAVE: 'call/receive/leave',
  UPDATE_ACTIVE_SPEAKER: 'call/active-speaker',
  UPDATE: 'call/update',
  RESET: 'call/reset',
  CONNECTED: 'call/connected',
  DISCONNECTED: 'call/disconnected',
  LOADED: 'call/loaded',
  SET_CALL_CONFIG: 'call/set-call-config',
  SET_CALL_ROOMS: 'call/set-call-rooms'
}

export interface CallState {
  activeSpeaker: number | null
  activeSpeakerManual: boolean
  activeSpeakerScreen: number | null
  isConnected: boolean
  sessionId: number | boolean
  config: {
    roomFormat: string,
    token: string
  },
  room: string,
  rooms: {[index: number]: number[]}
}

export interface CallPayloadAction extends PayloadAction<Object> {
  payload: {
    activeSpeaker?: number | null
    activeSpeakerManual?: boolean
    isConnected?: boolean
    sessionId?: string
    token?: string | boolean
    room?: string
    rooms?: {[index: number]: number[]}
    userId?: number
    screen?: boolean
    manual?: boolean
  }
}

export const initialState = {
  activeSpeaker: null,
  activeSpeakerScreen: null,
  activeSpeakerManual: false,
  isConnected: false,
  sessionId: false,
  users: [],
  config: {
    token: '',
    roomFormat: ''
  },
  room: '',
  rooms: {}
}

export default (state: CallState = initialState, action: CallPayloadAction) => {
  switch (action.type) {
    case types.SET_CALL_CONFIG:
      return {
        ...state,
        config: action.payload
      }
    case types.SET_CALL_ROOMS:
      return {
        ...state,
        rooms: action.payload
      }
    case types.UPDATE:
      return {
        ...state,
        sessionId: action.payload.sessionId,
        room: action.payload.room
      }
    case types.CONNECTED:
      return {
        ...state,
        isConnected: true
      }
    case types.DISCONNECTED:
      return {
        ...state,
        isConnected: false
      }
    case types.LOADED:
      let rooms = {}
      // todo: update this mapping to handle multiteam
      Object.keys(action.payload).forEach((teamId: string) => {
        rooms = {
          ...rooms,
          // @ts-ignore
          ...(action.payload[teamId]).calls
        }
      })
      return {
        ...state,
        rooms
      }
    case types.RESET:
      return {
        ...state,
        isConnected: false,
        sessionId: false,
        users: [],
        room: ''
      }
    case types.UPDATE_ACTIVE_SPEAKER:
      return {
        ...state,
        activeSpeaker: action.payload.userId,
        activeSpeakerManual: action.payload.manual,
        activeSpeakerScreen: action.payload.screen
          ? action.payload.userId
          : null
      }
    default:
      return state
  }
}

export const actions = {
  receiveStart: (payload: MessageCallStart) => ({
    type: types.RECEIVE_START,
    payload: { ...payload }
  }),
  receiveLeave: (
    userId: number,
    serverChannel: Types.RealtimeChannelCallbacks,
    setTracks?: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>
  ) => ({
    type: types.RECEIVE_LEAVE,
    payload: {
      userId,
      serverChannel,
      setTracks
    }
  }),
  updateActiveSpeaker: (
    userId: number,
    manual: boolean = false,
    screen: boolean = false
  ) => ({
    type: types.UPDATE_ACTIVE_SPEAKER,
    payload: { userId, manual, screen }
  }),
  update: (payload: CallState) => ({
    type: types.UPDATE,
    payload: { ...payload }
  }),
  setCallConfig: (payload: {token: string, roomFormat: string}) => ({ type: types.SET_CALL_CONFIG, payload }),
  setCallRooms: (payload: {[index: number]: number[]}) => ({ type: types.SET_CALL_ROOMS, payload }),
  reset: () => ({ type: types.RESET }),
  connected: () => ({ type: types.CONNECTED }),
  disconnected: () => ({ type: types.DISCONNECTED }),
  loaded: (payload: any) => ({ type: types.LOADED, payload })
}

export const getCall = (state: RootState): CallState => state.call

export const isInCall = createSelector([getCall], (call) => {
  return call.sessionId !== false && call.isConnected
})

export const getActiveSpeaker = createSelector([getCall], (call) => {
  return call.activeSpeaker
})

export const getActiveSpeakerScreen = createSelector([getCall], (call) => {
  return call.activeSpeakerScreen
})

export const getIsActiveSpeakerManual = createSelector([getCall], (call) => {
  return call.activeSpeakerManual
})
