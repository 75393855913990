import axios from 'axios'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { reloadHome } from '../../../utils/routes'

export let requestCount: {[key: string]: number} = {}

export const AUTH_STORAGE = 'auth'

export const getCurrentUser = (): JwtPayload | null => {
  try {
    const jwt = localStorage.getItem(AUTH_STORAGE)
    return jwtDecode<JwtPayload>(String(jwt))
  } catch (ex) {
    return null
  }
}

axios.interceptors.request.use(
  (config) => {
    config.headers = config.headers ?? {}
    config.headers.Accept = 'application/json'
    config.headers['Access-Control-Allow-Origin'] = '*'

    if (config.url) {
      const rawUrl = String(config.url)
      const urls = rawUrl.split('api/v1')
      let key = rawUrl
      if (urls.length > 1) {
        key = urls[1]
      }
      requestCount[key] = requestCount[key] ?
        requestCount[key] + 1 : 1
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.defaults.withCredentials = true

axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  let currentTime = new Date().getTime() / 1000
  const jwt = getCurrentUser()
  if (error.response && error.response.status === 401) {
    // @ts-ignore
    if (jwt && currentTime > jwt.exp) {
      localStorage.removeItem(AUTH_STORAGE)
      reloadHome(false)
    }
  }
  return Promise.reject(error)
})

export const API_URL = process.env.REACT_APP_API_URL
export const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const AUTH_API_ENDPOINT = API_URL + '/auth'
export const BROADCASTING_API_ENDPOINT = BASE_URL + '/broadcasting'
export const PUBLIC_BROADCASTING_API_ENDPOINT = API_URL + '/realtime/channels'
export const ABLY_TOKEN_API_ENDPOINT = API_URL + '/ably'
export const UPLOAD_POLICY_API_ENDPOINT = API_URL + '/upload/policy'
export const USER_API_ENDPOINT = API_URL + '/users'
export const TEAM_API_ENDPOINT = API_URL + '/teams'
export const ROLES_API_ENDPOINT = API_URL + '/roles'
export const SUBSCRIBE_ENDPOINT = API_URL + '/subscriptions'
export const TWILIO_API_ENDPOINT = API_URL + '/twilio'
export const NOTIFICATION_API_ENDPOINT = API_URL + '/notifications'

export { axios }
