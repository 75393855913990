import styled, { css } from 'styled-components'
import { InputFormProps } from '../interfaces/component'
import React from 'react'

export const FormLabel = styled.label`
  color: #000;
  display: block;
  font: 400 14px/18px 'DM Sans';
  letter-spacing: 0.14px;
`
// eslint-disable-next-line space-infix-ops
export const InputForm = styled.input<InputFormProps&React.CSSProperties>`
  background-color: #fff;
  font-size: 18px;
  border: 0 none;
  height: 40px;
  letter-spacing: 0.14px;
  outline: none;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  text-align: left;
  width: 100%;
  -webkit-appearance: none;
  margin-bottom: 13px;

  ${(props) => {
    if (props.appended) {
      return css`
        padding-right: 80px;
      `
    }
    return css``
  }}
`

export const InputFormAppendText = styled.div`
  font-size: 14px;
  height: 42px;
  letter-spacing: 0.18px;
  line-height: 42px;
  position: absolute;
  right: 0;
  bottom: 0;
`
