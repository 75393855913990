import React, { useEffect, useState } from 'react'
import querySting, { ParsedQuery } from 'query-string'
import { Link, RouteComponentProps } from 'react-router-dom'
import { InputForm, LandingPage, Loader, Snackbar } from '../modules'
import { Logo } from '../components/icons'
import { resetPassword } from '../services/api'

const ResetPassword: React.FC<RouteComponentProps> = (props) => {
  const title = 'Reset Your Password'
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [token, setToken] = useState<string>('')
  const [onSave, setOnSave] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<{status: boolean; message: string}>({
    status: false,
    message: ''
  })
  const isValidPassword = () => {
    if (password.length < 6) {
      throw new Error('poorPassword')
    }
    if (password !== confirmPassword) {
      throw new Error('notMatch')
    }
    return true
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOnSave(true)
    try {
      if (!isValidPassword()) {
        throw new Error('Password input error')
      }
    } catch (err) {
      setError({
        status: true,
        message: String(err)
      })
      setOnSave(false)
      return
    }
    resetPassword(token, email, password, confirmPassword).then(() => {
      setOnSave(false)
      setSuccess(true)
      setError({
        status: false,
        message: ''
      })
    }).catch(err => {
      let message = 'Please check your reset password link!'
      if (err.message === 'poorPassword') {
        message = 'The password must be at least 6 characters.'
      }
      if (err.message === 'notMatch') {
        message = 'The password confirmation does not match.'
      }
      if (err.response && err.response.status === 401) {
        message = 'Your reset password link has been expired.'
      }
      setError({
        status: true,
        message: message
      })
      setSuccess(false)
      setOnSave(false)
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const { currentTarget: input } = e
    switch (input.name) {
      case 'password':
        setPassword(input.value)
        break
      case 'confirmPassword':
        setConfirmPassword(input.value)
        break
      default:
        setEmail(input.value)
    }
  }
  useEffect(() => {
    const { search } = props.location || {}
    if (search) {
      const parsed: ParsedQuery = querySting.parse(search)
      setToken(String(parsed.code))
      setEmail(String(parsed.key))
    }
    document.title = title
  }, [])
  return (
    <div className='flash-section'>
      <LandingPage className='container login-page'>
        <Link to='/'>
          <Logo />
        </Link>
        <h2>{title}</h2>
        {success ? (
          <p>
            Password reset successfully, please login <Link to='/'>here</Link>
            .
          </p>
        ) : (
          <>
            <p>Please enter your new password below.</p>
            <div className='form-contain'>
              <form className='forms' onSubmit={(e) => handleSubmit(e)}>
                <div className='form__row'>
                  <InputForm
                    type='password'
                    placeholder='Password'
                    name='password'
                    onChange={(e) => handleInputChange(e)}
                    className='form__text-input'
                    fontSize='18px'
                  />
                </div>
                <div className='form__row'>
                  <InputForm
                    type='password'
                    placeholder='Confirm Password'
                    name='confirmPassword'
                    onChange={(e) => handleInputChange(e)}
                    className='form__text-input'
                    fontSize='18px'
                  />
                </div>
                <div className='form__action form__row'>
                  <button
                    type='submit'
                    name='submit'
                    className='btn btn--action-inline btn--standard p-relative'
                    disabled={onSave}>
                    {!onSave ? 'Reset Password' : <Loader white />}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </LandingPage>
      <Snackbar type='error' show={error.status} message={error.message} zIndex={9999} />
    </div>
  )
}

export default ResetPassword
