import { all, put, takeEvery, delay } from 'redux-saga/effects'
import { types, actions, NotificationsPayloadAction } from '../reducers/notifications'

export function *create(action: NotificationsPayloadAction) {
  const { id, timeout } = action.payload
  if (timeout) {
    yield delay(timeout)
  }
  yield put(actions.destroy(id))
}

export default function *notificationsWatcher() {
  yield all([takeEvery(types.CREATE, create)])
}
