/* eslint-disable indent */
import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as profileAction } from '../../reducers/user'
import { timezones } from '../../utils'

import { Modal, ModalTitle, ModalContainer, Snackbar, ButtonStandard } from '../../modules'
import { ErrorI } from '../../interfaces/error'
import { RootState } from '../../store'
import { SocketContext } from '../../contexts/socket'
import { CHANNEL_CLIENT_TEAM } from '../../constants/socket'

const Profile: React.FC = () => {
  const [errors, setErrors] = useState<ErrorI>({
    message: '',
    status: false
  })
  const openProfileModal = useSelector((state: RootState) => state.user.showProfile)
  const selectedTeam = useSelector((state: RootState) => state.user.selectedTeam)
  const displayName = useSelector((state: RootState) =>
    state.user.teamState[state.user.selectedTeam].displayName)
  const timezone = useSelector((state: RootState) =>
    state.user.teamState[state.user.selectedTeam].timezone ||
    state.user.timezone
  )
  const [userDisplayName, setUserDisplayName] = useState<string>(displayName)
  const [userTimezone, setUserTimezone] = useState<string>(timezone)
  const { clientChannels } = useContext(SocketContext)
  const teamChannel = clientChannels[`${CHANNEL_CLIENT_TEAM}:${selectedTeam}`]
  const dispatch = useDispatch()
  const reset = () => {
    setUserDisplayName(displayName)
    setUserTimezone(timezone)
  }
  const handleCloseModal = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    reset()
    dispatch(profileAction.closeProfile())
  }

  const handleSaveProfile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (!teamChannel) {
      return
    }
    dispatch(profileAction.changeProfile(
      selectedTeam, userDisplayName, userTimezone, teamChannel
    ))
    dispatch(profileAction.closeProfile())
  }

  const handleChangeDisplayName = (e: ChangeEvent<HTMLInputElement>) => {
    const { currentTarget: input } = e
    setUserDisplayName(input.value)
    if (input.value && input.value.trim() !== '') {
      setErrors({
        status: false,
        message: ''
      })
      return
    }
    setErrors({
      status: true,
      message: 'Display name can\'t be empty'
    })
  }

  const handleChangeTimezone = (e: ChangeEvent<HTMLSelectElement>) => {
    const { currentTarget: input } = e
    setUserTimezone(input.value)
  }

  useEffect(() => {
    return () => {
      dispatch(profileAction.closeProfile())
    }
  }, [])
  if (!openProfileModal) {
    return null
  }
  return (
    <>
      <Modal
        style={{
          background: '#fff'
        }}
        show={openProfileModal}
        onClose={handleCloseModal}
        disabledClose={errors.status}>
        <ModalTitle title='Profile' />
        <ModalContainer>
          <form
            action='#'
            method='post'
            className='modal__form'
            onSubmit={handleCloseModal}>
            <div className='modal__av-profile'>
              <div className='modal__form__row'>
                <label className='modal__form__label'>Display Name:</label>
                <input
                  type='text'
                  className='modal__form__text-input'
                  onChange={handleChangeDisplayName}
                  value={userDisplayName || ''}
                />
              </div>
              <div className='modal__form__row'>
                <label className='modal__form__label'>Timezone:</label>
                <div className='skinned-wrapper skinned-wrapper-white'>
                  <div className='skinned-text'>
                    {timezones[userTimezone]}
                  </div>
                  <select
                    name='timezone'
                    className='skinned-select'
                    value={userTimezone || 'UTC'}
                    onChange={handleChangeTimezone}>
                    {Object.keys(timezones).map((tz) => {
                      return (
                        <option
                          key={tz}
                          value={tz}
                        >
                          {timezones[tz]}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className='modal__form__row last'>
                    <ButtonStandard
                      className='btn'
                      onClick={handleSaveProfile}
                    >
                      Save
                    </ButtonStandard>
              </div>
            </div>
          </form>
        </ModalContainer>
      </Modal>
      <Snackbar
        zIndex={40040}
        type='error'
        show={errors.status}
        message={errors.message}
      />
    </>
  )
}

export default Profile
