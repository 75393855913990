/* eslint-disable */
import { timeout } from './functions'

import JitsiStreamBackgroundEffect, { IBackgroundEffectOptions } from './jitsiBlurBackground'
import createTFLiteModule from './tflite/tflite'
import createTFLiteSIMDModule from './tflite/tflite-simd'
const models = {
  modelLandscape: 'models/selfie_segmentation_landscape.tflite'
}
/* eslint-enable lines-around-comment */

let modelBuffer: ArrayBuffer
let tflite: any
let wasmCheck
let isWasmDisabled = false

const segmentationDimensions = {
  modelLandscape: {
    height: 144,
    width: 256
  }
}

export async function createVirtualBackgroundEffect(virtualBackground: IBackgroundEffectOptions['virtualBackground']): Promise<any> {
  if (!MediaStreamTrack.prototype.getSettings && !MediaStreamTrack.prototype.getConstraints) {
    throw new Error('JitsiStreamBackgroundEffect not supported!')
  }

  if (isWasmDisabled) {
    return Promise.reject('wasm-disabled')
  }

  // Checks if WebAssembly feature is supported or enabled by/in the browser.
  // Conditional import of wasm-check package is done to prevent
  // the browser from crashing when the user opens the app.

  if (!tflite) {
    try {
      wasmCheck = require('wasm-check')
      const tfliteTimeout = 10000
      console.log('debug: wasm-check', wasmCheck?.feature.simd, )
      if (wasmCheck?.feature?.simd) {
        console.log('debug: wasm-check 1')
        tflite = await timeout(tfliteTimeout, createTFLiteSIMDModule())
      } else {
        console.log('debug: wasm-check 2')
        tflite = await timeout(tfliteTimeout, createTFLiteModule())
      }
      console.log('debug: wasm-check 3')
    } catch (err: any) {
      if (err?.message === '408') {
        // eslint-disable-next-line
        console.error('Failed to download tflite model!')
      } else {
        isWasmDisabled = true
        // eslint-disable-next-line
        console.error('Looks like WebAssembly is disabled or not supported on this browser', err)
      }

      return Promise.reject('tflite-failed-to-load')
    }
  }
  if (!modelBuffer) {
    const modelResponse = await fetch(models.modelLandscape)
    console.log('modelBuffer', modelResponse)

    if (!modelResponse.ok) {
      throw new Error('Failed to download tflite model!')
    }

    modelBuffer = await modelResponse.arrayBuffer()

    tflite.HEAPU8.set(new Uint8Array(modelBuffer), tflite._getModelBufferMemoryOffset())

    tflite._loadModel(modelBuffer.byteLength)
  }

  const options = {
    ...segmentationDimensions.modelLandscape,
    virtualBackground
  }

  return Promise.resolve(new JitsiStreamBackgroundEffect(tflite, options))
}
