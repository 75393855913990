import { MouseEvent } from 'react'
import Grid from './Grid'
import styled from 'styled-components'

const BtnTransparent = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 10px;
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
`

interface IBackgroundEffectsProps {
  backgrounds: Array<string>
  onChange: (e: MouseEvent<HTMLButtonElement>) => void
}

const BackgroundEffects = (props: IBackgroundEffectsProps) => {
  const { onChange, backgrounds } = props
  return (
    <Grid size={5}>
      <Grid.Col>
        <BtnTransparent
          style={{
            backgroundColor: '#ffffff',
            minHeight: '50px'
          }}
          name='background'
          value='none'
          onClick={onChange}>
          <b>None</b>
        </BtnTransparent>
      </Grid.Col>
      <Grid.Col>
        <BtnTransparent
          style={{
            background: 'linear-gradient(45deg, rgb(50, 100, 255), #ffffff)',
            minHeight: '30px'
          }}
          name='background'
          value='blur'
          onClick={onChange}>
          <b>Blur</b>
        </BtnTransparent>
      </Grid.Col>
      {backgrounds.map((bg, bgI) => {
        return (
          <Grid.Col key={bgI}>
            <BtnTransparent
              name='backgroundSource'
              value={bg}
              onClick={onChange}>
              <Img src={bg} />
            </BtnTransparent>
          </Grid.Col>
        )
      })}
    </Grid>
  )
}

export default BackgroundEffects
