import jitsi from 'lib-jitsi-meet'

export async function getCamera(deviceId: string): Promise<string> {
  if (deviceId === '') {
    return Promise.resolve('')
  }
  await jitsi.init({})
  const devs = await jitsi.mediaDevices.enumerateDevices()
  if (!devs) {
    return Promise.resolve('')
  }
  const selectedCamera = devs
    .filter((dev: any) => dev.kind === 'videoinput')
    .find((dev: any) => dev.deviceId === deviceId)
  if (selectedCamera) {
    return Promise.resolve(selectedCamera.deviceId)
  }
  return Promise.resolve('')
}

export async function getMicrophone(deviceId: string): Promise<string> {
  if (deviceId === '') {
    return Promise.resolve('')
  }
  const devs: MediaDeviceInfo[] = await jitsi.mediaDevices.getDevices()
  const selectedMicrophone = devs
    .filter((dev) => dev.kind === 'audioinput')
    .find((dev) => dev.deviceId === deviceId)
  if (selectedMicrophone) {
    return Promise.resolve(selectedMicrophone.deviceId)
  }
  return Promise.resolve('')
}

export const mountDevices: () => Promise<{
  mics: MediaDeviceInfo[]
  speakers: MediaDeviceInfo[]
  cameras: MediaDeviceInfo[]
}> = async () => {
  try {
    const devices = await new Promise<MediaDeviceInfo[]>((resolve) => {
      jitsi.mediaDevices.enumerateDevices((payload: MediaDeviceInfo[]) => {
        resolve(payload)
      })
    })
    if (devices.length > 0) {
      return remapMediadevices(devices)
    }

    const res = await navigator.mediaDevices.enumerateDevices()
    return remapMediadevices(res)
  } catch (e) {
    // eslint-disable-next-line
    console.log('enumerate device error', e)
    return { mics: [], speakers: [], cameras: [] }
  }
}

export const remapMediadevices = (devices: MediaDeviceInfo[]) => {
  const mics: MediaDeviceInfo[] = []
  const speakers: MediaDeviceInfo[] = []
  const cameras: MediaDeviceInfo[] = []

  devices.forEach((device) => {
    switch (device.kind) {
      case 'audioinput':
        mics.push(device)
        break
      case 'audiooutput':
        speakers.push(device)
        break
      default:
        cameras.push(device)
    }
  })
  return {
    mics,
    speakers,
    cameras
  }
}
