/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import ProtectedPage, { ProtectedComponentProps } from '../hocs/ProtectedPage'
import {
  SectionLoading,
  ManagementPage,
  ManagementPanel,
  ManagementPanelInner,
  ManagementPanelBlock,
  LogItem,
  LogItemDesc,
  LogItemDate, Snackbar
} from '../modules'
import { getTeamChangeLogs } from '../services/api'
import { ChangeLog } from '../interfaces/changeLog'
import AccountSettingTitle from '../components/AccountSettingTitle'

const TeamChangeLogs: React.FC<ProtectedComponentProps> = (props) => {
  const title = 'Team Changes'
  const [loading, setLoading] = useState<boolean>(true)
  const [logs, setLogs] = useState<ChangeLog[]>([])
  const [errors, setErrors] = useState<{status: boolean; message: string;}>({
    status: false,
    message: ''
  })
  const setData = () => {
    getTeamChangeLogs(Number(props.user.selectedTeam)).then((res) => {
      const { data: responseData } = res.data
      setLogs(responseData)
      setLoading(false)
    }).catch(e => {
      // eslint-disable-next-line no-console
      setLoading(false)
      setErrors({
        status: true,
        message: e.message
      })
    })
  }
  const parseTime = (origin: string) => {
    return moment(origin, moment.defaultFormat).format('DD/MM/YYYY')
  }
  useEffect(() => {
    if (errors.status) {
      setTimeout(() =>{
        setErrors({
          status: false,
          message: ''
        })
      }, 3000)
    }
  }, [errors])
  useEffect(() => {
    document.title = 'HQ Remote | ' + title
    if (props.user.selectedTeam) {
      setData()
    }
  }, [])

  return (
    <ManagementPage>
      {loading ? (
        <SectionLoading color={false} />
      ) : (
        <ManagementPanel className='management-panel'>
          <ManagementPanelInner className='management-panel__inner'>
            <ManagementPanelBlock
              className='management-panel__block'
              noMargin>
              <AccountSettingTitle>
                {title}
              </AccountSettingTitle>
              <p style={{
                color: '#666666',
                fontSize: '18px',
                fontWeight: 700,
                margin: 0
              }}>
                This page lists any changes made to your team.
              </p>
            </ManagementPanelBlock>
            <ManagementPanelBlock>
              <div className='log-lists'>
                {logs.map((log) => {
                  return (
                    <LogItem className='log-item' key={log.id}>
                      <LogItemDate className='log-item-time'>
                        {parseTime(log.created_at)}
                      </LogItemDate>
                      <LogItemDesc className='log-item-description'>
                        {log.description}
                      </LogItemDesc>
                    </LogItem>
                  )
                })}
              </div>
            </ManagementPanelBlock>
          </ManagementPanelInner>
        </ManagementPanel>
      )}
      <Snackbar type={'error'} message={errors.message} show={errors.status} zIndex={9999} />
    </ManagementPage>
  )
}

export default ProtectedPage(TeamChangeLogs)
