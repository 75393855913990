import { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface Snackbar {
  show: boolean,
  status: string,
  message: string,
  timeout?: number,
  callback?: () => void
}


export interface NotificationState {
  snackbar: Snackbar,
  audio: string,
  notificationCounts: {[key: number]: number},
}

// Action types
export const types = {
  UPDATE_SNACKBAR: 'notification/update-snackbar',
  UPDATE_AUDIO: 'notification/update-sound',
  INCREMENT_NOTIFICATION_COUNT: 'notification/increment-notification-count',
  SET_NOTIFICATION_COUNT: 'notification/set-notification-count'
}

// Initial state
export const initialState = {
  snackbar: {
    show: false,
    status: '',
    message: ''
  },
  audio: '',
  notificationCounts: {}
}

export interface NotificationPayloadAction extends PayloadAction<Object> {
  payload: {
    snackbar: Snackbar,
    audio: string,
    notificationCounts?: {[key: number]: number},
    // action payload
    teamId: number,
    notificationCount: number,
  }
}

// Action Handler
export default (state: NotificationState = initialState, action: NotificationPayloadAction):
  NotificationState => {
  switch (action.type) {
    case types.UPDATE_SNACKBAR:
      return { ...state, snackbar: action.payload.snackbar }
    case types.UPDATE_AUDIO:
      return { ...state, audio: action.payload.audio }
    case types.INCREMENT_NOTIFICATION_COUNT:
      const { teamId } = action.payload
      let newNotificationCounts = state.notificationCounts
      if (newNotificationCounts[teamId]) {
        // eslint-disable-next-line operator-assignment
        newNotificationCounts[teamId] += 1
      } else {
        newNotificationCounts[teamId] = 1
      }
      return { ...state, notificationCounts: newNotificationCounts }
    case types.SET_NOTIFICATION_COUNT:
      let notificationCounts = state.notificationCounts
      notificationCounts[action.payload.teamId] = action.payload.notificationCount
      return { ...state, notificationCounts: notificationCounts }
    default:
      return state
  }
}

// Actions
export const actions = {
  updateSnackbar: (snackbar: Snackbar) =>
    ({ type: types.UPDATE_SNACKBAR, payload: { snackbar } }),
  updateAudio: (audio: string) =>
    ({ type: types.UPDATE_AUDIO, payload: { audio } }),
  incrementNotificationCount: (teamId: number) =>
    ({ type: types.INCREMENT_NOTIFICATION_COUNT, payload: { teamId } }),
  setNotificationCount: (teamId: number, notificationCount: number) =>
    ({ type: types.SET_NOTIFICATION_COUNT, payload: { teamId, notificationCount } })
}

// Selectors
export const getSnackbar = (state: RootState) => state.notification.snackbar
export const getAudio = (state: RootState) => state.notification.audio
export const getNotificationCount = (state: RootState, teamId: number) =>
  state.notification.notificationCounts[teamId]
export const getNotificationCounts = (state: RootState) =>
  state.notification.notificationCounts
