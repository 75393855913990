/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'

import ProtectedPage from '../../hocs/ProtectedPage'
import {
  SectionLoading,
  ManagementPage,
  ManagementPanel,
  ManagementPanelInner,
  ManagementPanelBlock
} from '../../modules'
import { RootState } from '../../store'
import { getCurrentUser } from '../../reducers/user'
import { Plan } from '../../interfaces/api/paymentApi'
import { getAvailablePlans } from '../../reducers/subscription'
import { getCurrency } from '../../utils/currency'
import AccountSettingTitle from '../../components/AccountSettingTitle'
import AccountSettingText from '../../components/AccountSettingText'

interface IPlan {
  type: string;
  plan: string;
  status: string;
  pricePlan: string;
  expired_date: string;
  currentPeriodEnd: number;
  currentPeriodStart: number;
  subscription: string;
  perUserCost: number;
}

const Main: React.FC = () => {
  const title = 'Billing'
  const [loading, setLoading] = useState<boolean>(true)
  const [plans, setPlans] = useState<IPlan>({
    plan: '',
    status: '',
    pricePlan: 'monthly',
    type: 'free',
    expired_date: '',
    subscription: '',
    perUserCost: 0,
    currentPeriodEnd: 0,
    currentPeriodStart: 0
  })
  // @ts-ignore
  const subscription = useSelector((state: RootState) => state.subscription.plans)
  const availablePlans: Plan[] = useSelector((state: RootState) => getAvailablePlans(state))
  const user = useSelector((state: RootState) => getCurrentUser(state))
  const setPaymentData = () => {
    if (!subscription) {
      return
    }
    const {
      type, pricePlan, endsAt, currentPeriodStart, currentPeriodEnd
    } = subscription
    setLoading(false)
    const availablePlan = availablePlans.find(plan => {
      return plan.period === subscription.type?.split('_')[0] &&
        plan.currency === subscription.type?.split('_')[1]
    })
    setPlans({
      ...plans,
      type,
      pricePlan: pricePlan,
      expired_date: endsAt,
      perUserCost: availablePlan ? availablePlan.price / 100 : 0,
      currentPeriodEnd,
      currentPeriodStart
    })
  }

  const expiredFormat = () => {
    return moment.unix(plans.currentPeriodEnd).format(
      'Do MMMM YYYY'
    )
  }
  useEffect(() => {
    document.title = 'HQ Remote | ' + title
    if (user.selectedTeam) {
      setPaymentData()
    }
  }, [subscription])

  useEffect(() => {
    setPaymentData()
  }, [subscription, availablePlans])
  return (
    <ManagementPage>
      {loading ? (
        <SectionLoading color={false} />
      ) : (
        <ManagementPanel className='management-panel'>
          <ManagementPanelInner className='management-panel__inner'>
            <ManagementPanelBlock
              className='management-panel__block'
              noMargin>
              <AccountSettingTitle>{title} - (Team ID: {user.selectedTeam})</AccountSettingTitle>
              <AccountSettingText>
              You are currently on the{' '}
                { subscription ? (
                  <>
                    <strong>
                      {subscription.status === 'active' ? 'HQ Remote Pro' : 'Free'}
                    </strong>{' '}
                    plan paying <strong>
                      {subscription.type?.split('_')[0]} in
                      {' ' + subscription.type?.split('_')[1]}</strong>.
                    <br/>
                    {subscription.status === 'active' ?
                      // eslint-disable-next-line max-len
                      `Your (team: ${user.selectedTeam}) plan will renew on ${expiredFormat()}.` : ''}
                  </>
                ) : 'Free Plan'}
              </AccountSettingText>
              <AccountSettingText>
                This subscription costs {getCurrency(subscription.type?.split('_')[1])}
                {plans.perUserCost} per user,{' '}
                {plans.subscription} (excluding VAT).
              </AccountSettingText>
            </ManagementPanelBlock>
          </ManagementPanelInner>
        </ManagementPanel>
      )}
    </ManagementPage>
  )
}

export default ProtectedPage(Main)
