import React from 'react'
import { connect } from 'react-redux'

import { actions as uiActions } from '../reducers/ui'

class EventClass {
  constructor() {
    this.functionMap = {}
  }

  addEventListener(event, func) {
    this.functionMap[event] = func
    document.addEventListener(event.split('.')[0], this.functionMap[event])
  }

  removeEventListener(event) {
    document.removeEventListener(event.split('.')[0], this.functionMap[event])
    delete this.functionMap[event]
  }
}

const Event = new EventClass()

class Shortcuts extends React.Component {
  componentDidMount() {
    Event.addEventListener('keydown.sneek', (e) => {
      if (!e.metaKey) {
        return
      }

      if (e.key === 'o') {
        e.preventDefault()
        this.props.toggleOfficeList()
        return
      }

      if (e.key === 's') {
        e.preventDefault()
        this.props.toggleSideBar()
        return
      }
    })
  }

  componentWillUnmount() {
    Event.removeEventListener('keydown.sneek')
  }

  render() {
    return null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleOfficeList: () => dispatch(uiActions.toggleOfficeList()),
    toggleSideBar: () => dispatch(uiActions.toggleSideBar())
  }
}

export default connect(null, mapDispatchToProps)(Shortcuts)
