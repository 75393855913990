import { Dispatch } from '@reduxjs/toolkit'
import { actions as teamRealtimeActions } from '../../reducers/realtime/team'
import { actions as userRealtimeActions } from '../../reducers/realtime/user'
import { Types } from 'ably'
import { REALTIME_MESSAGE } from '../../constants/realtimeMessage'
import {
  CloseDuplicate,
  MessageTeamBusyKnock,
  MessageTeamUpdated,
  MessageTeamUserCallStateChanged,
  MessageTeamUserEnteredOffice,
  MessageTeamUserIdleChanged,
  MessageTeamUserPhotoChanged,
  MessageTeamUserProfileChanged,
  MessageTeamUserStateChanged,
  MessageTeamUserStatusChanged,
  MessageUserCalled,
  UserJoined
} from '../../reducers/realtime/interfaces/team'
import { Office } from '../../interfaces/team'
import React from 'react'
import { TrackDetail } from '../../contexts/stream'
import { CHANNEL_CLIENT_CALL } from '../../constants/socket'

export type MessageHandler = ((
  serverChannel: Types.RealtimeChannelCallbacks,
  clientChannels: {[key: string]: Types.RealtimeChannelCallbacks},
  setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>,
  teamId: number | null,
  message: Types.Message
) => any)
export type PresenceHandler = ((message: Types.PresenceMessage) => any)

export const handlePresenceMessage = (
  teamId: number|null,
  currentUserId: number,
  dispatch: Dispatch,
  serverChannel: Types.RealtimeChannelCallbacks,
  clientChannels: {[key: string]: Types.RealtimeChannelCallbacks}
): PresenceHandler => {
  return (message) => {
    if (message.action === 'enter' || message.action === 'present') {
      if (teamId) {
        return dispatch(
          teamRealtimeActions.receiveUserEnteredTeam({
            teamId,
            userId: Number(message.clientId),
            timestamp: message.timestamp
          })
        )
      }
    }
    if (message.action === 'leave') {
      const userId = Number(message.clientId)
      // note: should not leave own chathead under any circumstances
      // this could cause issue if ably leave comes after enter message
      // can happen when reconnecting
      if (teamId && userId !== currentUserId) {
        return dispatch(
          teamRealtimeActions.receiveUserLeftTeam({
            teamId,
            userId,
            serverChannel: clientChannels[`${CHANNEL_CLIENT_CALL}:${teamId}`]
          })
        )
      }
    }
    return null
  }
}

export const handleTeamMessage = (dispatch: Dispatch): MessageHandler => {
  return (serverChannel, clientChannels, setTracks, teamId, message) => {
    // note: data coming from frontend are stringified, while backend as object/array
    const incoming = typeof message.data === 'string' ? JSON.parse(message.data) : message.data
    switch (incoming.type) {
      case REALTIME_MESSAGE.TEAM.BUSY_KNOCK:
        const busyKnockPayload: MessageTeamBusyKnock = {
          ...incoming.payload,
          teamId: Number(teamId)
        }
        return dispatch(
          teamRealtimeActions.receiveBusyKnock(busyKnockPayload)
        )
      case REALTIME_MESSAGE.TEAM.TEAM_UPDATED:
        const teamUpdatedPayload: MessageTeamUpdated = {
          payload: incoming.payload,
          teamId: Number(teamId)
        }
        return dispatch(
          teamRealtimeActions.receiveTeamUpdated(teamUpdatedPayload)
        )
      case REALTIME_MESSAGE.TEAM.NEW_USER_JOINED:
        const userJoinedPayload: UserJoined = {
          user: incoming.payload.user,
          officeId: incoming.payload.officeId,
          teamId: Number(teamId)
        }
        return dispatch(
          teamRealtimeActions.receiveUserJoined(userJoinedPayload)
        )
      case REALTIME_MESSAGE.TEAM.USER.NOTIFICATION:
        const miscallPayload: MessageUserCalled = {
          ...incoming.payload,
          teamId: Number(teamId)
        }
        return dispatch(teamRealtimeActions.receiveUserCalled(miscallPayload))
      case REALTIME_MESSAGE.TEAM.USER.CHANGE_IDLE:
        const idleChangedPayload: MessageTeamUserIdleChanged = incoming.payload
        return dispatch(
          teamRealtimeActions.receiveUserIdleChanged({
            ...idleChangedPayload,
            teamId: Number(teamId)
          })
        )
      case REALTIME_MESSAGE.TEAM.USER.CHANGE_OFFICE:
        const enteredOfficePayload: MessageTeamUserEnteredOffice = incoming.payload
        return dispatch(
          teamRealtimeActions.receiveUserEnteredOffice(enteredOfficePayload)
        )
      case REALTIME_MESSAGE.TEAM.USER.DUPLICATE_USER:
        const duplicateUserPayload: CloseDuplicate = incoming.payload
        return dispatch(
          teamRealtimeActions.receiveDuplicateUser({
            ...duplicateUserPayload,
            clientChannels
          })
        )
      case REALTIME_MESSAGE.TEAM.USER.CHANGE_STATE:
        const changeStatePayload: MessageTeamUserStateChanged = incoming.payload
        return dispatch(teamRealtimeActions.receiveUserStateChanged({
          ...changeStatePayload,
          teamId: Number(teamId)
        }))
      case REALTIME_MESSAGE.TEAM.USER.CHANGE_PHOTO:
        const changePhotoPayload: MessageTeamUserPhotoChanged = incoming.payload
        return dispatch(
          teamRealtimeActions.receiveUserPhotoUpdated({
            ...changePhotoPayload,
            photo: `${changePhotoPayload.photo}?t=${(new Date()).getTime()}`
          })
        )
      case REALTIME_MESSAGE.TEAM.USER.CHANGE_STATUS:
        const changeStatusMessagePayload: MessageTeamUserStatusChanged = incoming.payload
        return dispatch(
          teamRealtimeActions.receiveUserStatusChanged(changeStatusMessagePayload)
        )
      case REALTIME_MESSAGE.TEAM.USER.CHANGE_PROFILE:
        const changeProfileMessagePayload: MessageTeamUserProfileChanged = incoming.payload
        return dispatch(
          teamRealtimeActions.receiveUserProfileChanged(changeProfileMessagePayload)
        )
      case REALTIME_MESSAGE.TEAM.USER.CHANGE_CALL:
        const changeCallPayload: MessageTeamUserCallStateChanged = {
          userId: incoming.payload.userId,
          callState: {
            callState: incoming.payload.callState,
            callRoom: incoming.payload.callRoom
          }
        }
        return dispatch(
          teamRealtimeActions.receiveUserCallStateChanged(
            changeCallPayload
          )
        )
      case REALTIME_MESSAGE.TEAM.OFFICE.REMOVED_OFFICE:
        const data: {teamId: number, officeId: number} = incoming.data
        return dispatch(
          userRealtimeActions.receiveRemovedOffice(
            data.teamId,
            data.officeId
          )
        )
      case REALTIME_MESSAGE.TEAM.OFFICE.CREATED_OFFICE:
        const officeCreateData: {teamId: number, office: Office} = incoming.data
        return dispatch(
          userRealtimeActions.receiveCreatedOffice(
            officeCreateData.teamId,
            officeCreateData.office
          )
        )
      case REALTIME_MESSAGE.TEAM.OFFICE.UPDATED_OFFICE:
        const officeUpdateData: {teamId: number, office: Office} = incoming.data
        return dispatch(
          userRealtimeActions.receiveUpdatedOffice(
            officeUpdateData.teamId,
            officeUpdateData.office
          )
        )
      default:
        return null
    }
  }
}

export const handleUserMessage = (dispatch: Dispatch): MessageHandler => {
  return (serverChannel, clientChannels, setTracks, teamId, message) => {
    const incoming = message.data
    switch (incoming.type) {
      case REALTIME_MESSAGE.USER.ERROR:
        return dispatch(userRealtimeActions.receiveError(incoming.data))
      case REALTIME_MESSAGE.USER.LOGOUT:
        return dispatch(userRealtimeActions.receiveLogout())
      case REALTIME_MESSAGE.USER.OFFICE_JOIN:
        return dispatch(
          userRealtimeActions.receiveJoinedOffice(
            Number(incoming.data.teamId),
            Number(incoming.data.officeId)
          )
        )
      case REALTIME_MESSAGE.USER.TEAM_JOIN:
        return dispatch(
          userRealtimeActions.receiveJoinedTeam(
            Number(message.clientId), Number(message.data.teamId)
          )
        )
      case REALTIME_MESSAGE.USER.TEAM_REMOVED:
        return dispatch(
          userRealtimeActions.receiveRemovedTeam(
            Number(message.clientId), Number(message.data.teamId)
          )
        )
      default:
        return null
    }
  }
}

export const handleCallMessage = (dispatch: Dispatch): MessageHandler => {
  return (serverChannel, clientChannels, setTracks, teamId, message) => {
    const incoming = typeof message.data === 'string' ? JSON.parse(message.data) : message.data
    switch (incoming.type) {
      case REALTIME_MESSAGE.CALL.START:
        return dispatch(userRealtimeActions.receiveCallStart({
          ...incoming.payload,
          setTracks,
          clientChannels
        }))
      case REALTIME_MESSAGE.CALL.LEAVE:
        return dispatch(userRealtimeActions.receiveCallEnded(
          {
            ...incoming.payload,
            setTracks,
            serverChannel
          }
        ))
      default:
        return null
    }
  }
}
