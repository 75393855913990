/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import ProtectedPage from '../../hocs/ProtectedPage'
import {
  SectionLoading,
  ManagementPage,
  ManagementPanel,
  ManagementPanelInner,
  ManagementPanelBlock,
  LogItem,
  LogItemDate,
  LogItemDesc,
  LogItemAmount
} from '../../modules'
import { getPaymentLogs } from '../../services/api/library/subscribe'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { getCurrentUser } from '../../reducers/user'
import { PaymentLog } from '../../interfaces/paymentLog'
import moment from 'moment'
import AccountSettingTitle from '../../components/AccountSettingTitle'

const labelToCurrencySymbol: {[key: string]: string} = {
  eur: '',
  usd: '$',
  gbp: ''
}

const History: React.FC = () => {
  const title = 'Billing'
  const [loading, setLoading] = useState<boolean>(true)
  const [logs, setLogs] = useState<PaymentLog[]>([])
  const user = useSelector((state: RootState) => getCurrentUser(state))
  const setPaymentData = () => {
    setLoading(false)
  }
  const getDescription = (subName: string) => {
    const splitted = subName.split('_')
    if (splitted.length < 1) {
      return 'unknown payment'
    }
    return `${splitted[0]} subscription`
  }
  const parseTime = (origin: string) => {
    return moment(origin, moment.defaultFormat).format('DD/MM/YYYY')
  }
  useEffect(() => {
    document.title = 'HQ Remote | ' + title
    setPaymentData()
    getPaymentLogs(user.selectedTeam).then(res => {
      const { data } = res
      setLogs(data)
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log('err', user, err)
    })
  }, [])
  return (
    <ManagementPage>
      {loading ? (
        <SectionLoading color={false} />
      ) : (
        <ManagementPanel className='management-panel'>
          <ManagementPanelInner className='management-panel__inner'>
            <ManagementPanelBlock
              className='management-panel__block'
              noMargin>
              <AccountSettingTitle>{title}</AccountSettingTitle>
            </ManagementPanelBlock>
            <ManagementPanelBlock>
              <div className='log-lists'>
                <LogItem className='log-item log-head'>
                  <LogItemDate className='log-item-time'>Date</LogItemDate>
                  <LogItemDesc className='log-item-description billing'>
                    Description
                  </LogItemDesc>
                  <LogItemAmount className='log-item-description'>
                    Amount
                  </LogItemAmount>
                </LogItem>
                {logs.map((log) => {
                  return (
                    <LogItem className='log-item' key={log.id}>
                      <LogItemDate className='log-item-time'>
                        {parseTime(log.created_at)}
                      </LogItemDate>
                      <LogItemDesc className='log-item-description billing'>
                        {getDescription(log.subscription.name)}
                      </LogItemDesc>
                      <LogItemAmount className='log-item-amount'>
                        {
                          labelToCurrencySymbol[log.subscription.name.split('_')[1]]
                        }
                        {log.total / 100}
                      </LogItemAmount>
                    </LogItem>
                  )
                })}
              </div>
            </ManagementPanelBlock>
          </ManagementPanelInner>
        </ManagementPanel>
      )}
    </ManagementPage>
  )
}

export default ProtectedPage(History)
