import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import ProtectedPage, { ProtectedComponentProps } from '../hocs/ProtectedPage'
import {
  getInvitations,
  sendInvitations,
  deleteInvitation
} from '../services/api'
import {
  SectionLoading,
  ManagementPage,
  ManagementPanel,
  ManagementPanelInner,
  Snackbar,
  ButtonStandard,
  ButtonWithoutBorder,
  ManagementPanelBlock,
  Loader,
  IvtItemCol,
  IvtItem
} from '../modules'
import { bulkEmailValidation } from '../utils'
import { SnackbarProps } from '../modules/Snackbar'
import { Invitation } from '../interfaces/invitation'
import AccountSettingTitle from '../components/AccountSettingTitle'

const InvitationPage: React.FC<ProtectedComponentProps> = (props) => {
  const title = 'Invitations'
  const [loading, setLoading] = useState<boolean>(true)
  // eslint-disable-next-line no-undef
  const [ticker, setTicker] = useState<NodeJS.Timeout|null>(null)
  const [onSend, setOnSend] = useState<boolean>(false)
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    message: '', show: false, type: '', zIndex: 9999
  })
  const [invitations, setInvitations] = useState<Invitation[]>([])
  const [invalidations, setInvalidations] = useState<(string|null)[]|null>([])
  const [invitationsInputs, setInvitationsInputs] = useState<string[]>([])
  const updateInvitations = () => {
    getInvitations(Number(props.user.selectedTeam)).then((res) => {
      setLoading(false)
      setInvitations(res.data.map((invitation) => {
        invitation.resend = false
        return invitation
      }))
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log('update-invitations error:', err)
    })
  }

  const populateEmailInput = () => {
    setInvitationsInputs([
      ...invitationsInputs,
      ''
    ])
  }

  const removeEmailInput = (index: number) => {
    const invitationsInputsCopy = [...invitationsInputs]
    invitationsInputsCopy.splice(index, 1)
    setInvitationsInputs(
      invitationsInputsCopy
    )
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { currentTarget: input } = e
    setInvitationsInputs([
    ])
    const invitationsInputsCopy = [...invitationsInputs]
    invitationsInputsCopy[Number(input.name.substring(6))] = input.value.trim()
    setInvitationsInputs(invitationsInputsCopy)
    if (invalidations) {
      const invalidationsCopy = [...invalidations]
      invalidationsCopy[Number(input.name.substring(6))] = null
      setInvalidations(invalidationsCopy)
    }
  }

  const formFieldHasError = (val: string|null) => {
    if (val) {
      return `form__text-input error`
    }
    return `form__text-input`
  }

  const sentInvitations = async () => {
    try {
      const filterEmptyArray = invitationsInputs.filter((val) => {
        const email = val.trim()
        return email !== null && email !== ''
      })
      await sendInvitations(Number(props.user.selectedTeam), {
        emails: filterEmptyArray
      })
      setSnackbar({
        ...snackbar,
        show: true,
        type: '',
        message: 'Invitation(s) sent!'
      })
      setInvitationsInputs([''])
      setOnSend(false)
      updateInvitations()
    } catch (err) {
      throw err
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let getValidations: string[]|null = []
    setOnSend(true)
    getValidations = bulkEmailValidation(invitationsInputs)
    if (getValidations) {
      if (!invitationsInputs[0]) {
        throw new Error('first_email_empty')
      }
      throw new Error('invalid_email')
    }
    sentInvitations().catch(err => {
      let message =
        'Please sorry, unable to submit your request. Please contact our support.'
      if (err.message === 'invalid_email') {
        message = 'Please fill out all fields with valid email format!'
      }
      if (err.message === 'first_email_empty') {
        message = 'First email address is required!'
      }
      setSnackbar({
        ...snackbar,
        show: true,
        type: 'error',
        message: message
      })
      setInvalidations(getValidations)
      setOnSend(false)
    })
  }

  const resendInvitation = async (email: string, index: number) => {
    if (!invitations[index].resend) {
      invitations[index].resend = true
      try {
        await sendInvitations(Number(props.user.selectedTeam), {
          emails: [email]
        })
        setSnackbar({
          ...snackbar,
          show: true,
          type: '',
          message: 'Invitation resent!'
        })
        setInvitations(invitations)
      } catch (error) {
        setSnackbar({
          ...snackbar,
          show: true,
          type: 'error',
          message: 'Failed to resent invitation!'
        })
      }
    }
  }

  useEffect(() => {
    if (props.user.selectedTeam) {
      updateInvitations()
    }
  }, [props.user.selectedTeam])
  useEffect(() => {
    if (snackbar.show) {
      setTimeout(() => {
        setSnackbar({
          ...snackbar,
          show: false
        })
      }, 3000)
    }
  }, [snackbar.show])
  useEffect(() => {
    document.title = 'HQ Remote | Invitations'
    if (props.user.selectedTeam) {
      updateInvitations()
    }
    setTicker(setTimeout(() => {
      // Hide flicker
      setLoading(false)
    }, 500))
    return () => {
      document.title = 'HQ Remote'
      if (ticker) {
        clearInterval(ticker)
      }
    }
  }, [])
  return (
    <ManagementPage>
      {loading ? (
        <SectionLoading color={false} />
      ) : (
        <ManagementPanel className='management-panel'>
          <ManagementPanelInner className='management-panel__inner'>
            <ManagementPanelBlock className='management-panel__block'>
              <AccountSettingTitle>{title}</AccountSettingTitle>
              <p>
                HQ Remote is a lonely place without team mates, enter emails below
                to invite people to Analog Republic.
              </p>

              <form onSubmit={(e) => handleSubmit(e)} className='form manage-team'>
                <div className='form__row form__row--flush'>
                  <label
                    htmlFor='email_address_1'
                    className='form__label form__label--inline'>
                    Invite
                  </label>
                  <ButtonWithoutBorder
                    type='button'
                    className='add-another'
                    float={'right'}
                    onClick={populateEmailInput}
                  >
                    Add another invite
                  </ButtonWithoutBorder>
                </div>
                <div className='email-fields'>
                  {invitationsInputs.map((_, i) => (
                    <div className='form__row' key={i}>
                      <input
                        type='text'
                        className={formFieldHasError(invalidations ? invalidations[i] : null)}
                        name={`email_${i}`}
                        placeholder='Email Address'
                        value={invitationsInputs[i]}
                        onChange={(e) => handleChange(e)}
                      />
                      {i > 0 && (
                        <ButtonWithoutBorder
                          type='button'
                          onClick={() => removeEmailInput(i)}
                          className='form__row__remove'>
                          +
                        </ButtonWithoutBorder>
                      )}
                    </div>
                  ))}
                </div>
                <div className='form__row'>
                  <ButtonStandard
                    type='submit'
                    disabled={invitationsInputs?.length === 0 || onSend}
                    className='btn p-relative'>
                    {!onSend ? 'Send invitations' : <Loader white />}
                  </ButtonStandard>
                </div>
              </form>

              <p>
                You'll be charged a pro-rated amount for each new team member.{' '}
                <a
                  href={process.env.REACT_APP_APP_URL + '/billing'}
                  target='_blank'
                  rel='noopener noreferrer'>
                  Click here
                </a>{' '}
                to view our billing guide for more info.
              </p>
            </ManagementPanelBlock>

            <ManagementPanelBlock className='management-panel__block'>
              <AccountSettingTitle>
                Pending Invitations
              </AccountSettingTitle>
              {invitations.length > 0 ? (
                invitations.map((invitation, index) => (
                  <IvtItem key={index}>
                    <IvtItemCol className='ivt-col-email'>
                      {invitation.email}
                    </IvtItemCol>
                    <IvtItemCol className='ivt-col-action'>
                      <ButtonWithoutBorder
                        type='button'
                        onClick={() =>
                          resendInvitation(invitation.email, index)
                        }
                        disabled={invitation.resend}
                        className='resend'>
                        Resen{invitation.resend ? 't!' : 'd'}
                      </ButtonWithoutBorder>{' '}
                      <ButtonWithoutBorder
                        type='button'
                        onClick={() => {
                          deleteInvitation(
                            Number(props.user.selectedTeam), invitation.id
                          ).then(() => {
                            setSnackbar({
                              ...snackbar,
                              show: true,
                              type: '',
                              message: 'Invitation removed!'
                            })
                            updateInvitations()
                          }).catch(() => {
                            setSnackbar({
                              ...snackbar,
                              show: true,
                              type: 'error',
                              message: 'Failed to remove invitation!'
                            })
                          })
                        }}
                        className='remove'>
                        Delete
                      </ButtonWithoutBorder>
                    </IvtItemCol>
                  </IvtItem>
                ))
              ) : (
                <p>You have no pending invitations.</p>
              )}
            </ManagementPanelBlock>
          </ManagementPanelInner>
        </ManagementPanel>
      )}
      <Snackbar
        show={snackbar.show}
        type={snackbar.type}
        message={snackbar.message}
        zIndex={snackbar.zIndex}
      />
    </ManagementPage>
  )
}

export default ProtectedPage(InvitationPage)
