import { SNAP_EFFECT } from '../../constants/effect'
import { layouts } from '../../constants/call'
import ChatHeadWithoutOthers from '../../assets/img/visibility-hide.svg'
import ChatHead from '../../assets/img/visibility-show.svg'
import FullScreenIcon from '../../assets/img/view-fullscreen.svg'
import { UserAvailabilityState } from '../../constants/user'
import SnapshotNormalIcon from '../../assets/img/snap-normal.svg'
import SnapshotPixelatedLowIcon from '../../assets/img/snap-lowpix.svg'
import SnapshotPixelatedHighIcon from '../../assets/img/snap-highpix.svg'
import SnapshotGifIcon from '../../assets/img/snap-gif.svg'
import SnapshotGrayscaleIcon from '../../assets/img/snap-greyscale.svg'
import SnapshotBWIcon from '../../assets/img/snap-bw.svg'
import SnapshotSepiaIcon from '../../assets/img/snap-sepia.svg'
import StateAvailableIcon from '../../assets/img/state-available.svg'
import StateBusyIcon from '../../assets/img/state-busy.svg'
import StateAwayIcon from '../../assets/img/state-away.svg'
import Timer1Icon from '../../assets/img/timer-1.svg'
import Timer5Icon from '../../assets/img/timer-5.svg'
import TimerMIcon from '../../assets/img/timer-m.svg'
import StateAvailableIconRaw from '../../assets/img/state-available-raw.svg'
import StateBusyIconRaw from '../../assets/img/state-busy-raw.svg'
import StateAwayIconRaw from '../../assets/img/state-away-raw.svg'

export const EFFECT_ICONS = {
  [SNAP_EFFECT.NORMAL]: SnapshotNormalIcon,
  [SNAP_EFFECT.PIXELATE_LOW]: SnapshotPixelatedLowIcon,
  [SNAP_EFFECT.PIXELATE_HIGH]: SnapshotPixelatedHighIcon,
  [SNAP_EFFECT.GIF]: SnapshotGifIcon,
  [SNAP_EFFECT.BLACK_AND_WHITE]: SnapshotBWIcon,
  [SNAP_EFFECT.GRAYSCALE]: SnapshotGrayscaleIcon,
  [SNAP_EFFECT.SEPIA]: SnapshotSepiaIcon
}

export const AVAILABILITY_ICONS: {[key: string]: string} = {
  [UserAvailabilityState.AVAILABLE]: StateAvailableIcon,
  [UserAvailabilityState.BUSY]: StateBusyIcon,
  [UserAvailabilityState.AWAY]: StateAwayIcon
}


export const AVAILABILITY_RAW_ICONS: {[key: string]: string} = {
  [UserAvailabilityState.AVAILABLE]: StateAvailableIconRaw,
  [UserAvailabilityState.BUSY]: StateBusyIconRaw,
  [UserAvailabilityState.AWAY]: StateAwayIconRaw
}

export const LAYOUT_ICONS: {[key: string]: string} = {
  [layouts.CHAT_HEAD]: ChatHead,
  [layouts.CHAT_HEAD_WITHOUT_OTHERS]: ChatHeadWithoutOthers,
  [layouts.FULL_SCREEN]: FullScreenIcon
}

export const INTERVAL_ICONS: {[key: number]: string} = {
  [60]: Timer1Icon,
  [300]: Timer5Icon,
  [0]: TimerMIcon
}
