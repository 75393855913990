import styled, { CSSProperties } from 'styled-components'
import React from 'react'

interface CallBarStyledProps extends CSSProperties {
  connecting: boolean
}

const CallBarStyled = styled.div<CallBarStyledProps>`
  background-color: #${(props) => props.color};
  bottom: 0;
  height: 14.18px;
  left: 0;
  position: relative;
  width: 100%;
  margin: -14.18px auto 0 auto;
  border-radius: 0 0 8px 8px;
  animation: ${(props) => {
    return props.connecting ? 'blink 1s linear infinite;' : 'none;'
  }}
    @keyframes blink {
    0% {
      opacity: 0;
    }
    33% {
      opacity: 0.5;
    }
    66% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  z-index: 5;
`

const CallBar: React.FC<React.PropsWithChildren<any>> = (props) => {
  return (<CallBarStyled {...props}></CallBarStyled>)
}

export default CallBar
