/* eslint-disable no-shadow */
export enum UserAvailabilityState {
  AVAILABLE = 'available',
  BUSY = 'busy',
  AWAY = 'away',
  GHOSTING = 'ghosting'
}

export enum CallInterval {
  MANUAL = 1,
  ONE_MINUTE = 2,
  FIVE_MINUTES = 3
}

/* this has to match id seeded on backend */
export enum SnapshotEffect {
  NORMAL = 1,
  PIXELATE_LOW = 2,
  PIXELATE_HIGH = 3,
  GIF = 4,
  SEPIA = 5,
  GRAYSCALE = 6,
  BLACK_AND_WHITE = 7
}

export enum CallState {
  NOT_IN_CALL = 'not-in-call',
  IN_CALL = 'in-call',
  JOINING_CALL = 'joining',
  LEAVING_CALL = 'leaving'
}

export const IDLE_TRESHOLD = 30

/**
 * User color palette(['mpn65'], 25, 0) by state separated by underscore _.
 * this has to match id seeded on backend.
 */
export enum UserColor {
  WHITE_FFFFFF = 0,
  BLUE_4EA0FF = 1,
  ORANGE_FFAD32 = 2,
  GREEN_6AFFC3 = 3,
  PINK_FD99FF = 4,
  PURPLE_934AEE = 5,
  YELLOW_FCFF62 = 6,
  RED_FF6262 = 7,
  LIGHTBLUE_62ECFF = 8,
  DARKBLUE_3656FF = 9,
  LIGHTGREEN_1EFF1A = 10,
  DARKORANGE_FF9052 = 11,
  DARKGREEN_89BB1F = 12,
}
