import { Role } from '../interfaces/role'
import { User } from '../interfaces/user'

export const canDo = (user: User, level: number): boolean => {
  if (!user) {
    return false
  }
  const role: Role | undefined = user.teamState[user.selectedTeam]?.role
  return role && role.level >= level
}
