import { MouseEvent } from 'react'
import Grid from '../../components/Grid'
import { ButtonStandard } from '../../modules'
import IconPlus from '../../assets/img/icon.plus.svg'
import styled from 'styled-components'

const Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`
const BtnTransparent = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0;
`

interface Props {
  backgrounds: Array<{
    label?: string
    value: string
    thumbnail?: string
  }>
  onChange: (e: MouseEvent<HTMLButtonElement>) => void
}
const VideoBackground = (props: Props) => {
  const { backgrounds, onChange } = props
  return (
    <Grid>
      {backgrounds.map((bg) => {
        if (bg.label === 'Upload') {
          return (
            <Grid.Col key={bg.value}>
              <ButtonStandard
                onClick={onChange}
                value={bg.value}
                type='button'
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: '130px'
                }}>
                <img src={IconPlus} style={{ width: '20px' }} />
              </ButtonStandard>
            </Grid.Col>
          )
        }
        return (
          <Grid.Col key={bg.value}>
            <BtnTransparent
              name='backgroundSource'
              value={bg.value}
              type='button'
              onClick={onChange}>
              <Img src={bg.thumbnail} alt={bg.label} />
            </BtnTransparent>
          </Grid.Col>
        )
      })}
    </Grid>
  )
}

export default VideoBackground
