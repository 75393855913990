import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ProtectedPage from '../../hocs/ProtectedPage'
import {
  ButtonStandard,
  ManagementPage,
  ManagementPanel,
  ManagementPanelBlock,
  ManagementPanelInner,
  RadioButton,
  RadioLabel, SectionLoading,
  Snackbar
} from '../../modules'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { getCurrentUser } from '../../reducers/user'
import { SnackbarProps } from '../../modules/Snackbar'
import { createSubscription } from '../../services/api'
import { Plan } from '../../interfaces/api/paymentApi'
import { getAvailablePlans } from '../../reducers/subscription'
import { getCurrency } from '../../utils/currency'
import AccountSettingTitle from '../../components/AccountSettingTitle'
import AccountSettingText from '../../components/AccountSettingText'
export const InlineHeading = styled.h3`
  display: inline-block;
  margin-right: 10px;
`

const Upgrade: React.FC = () => {
  const title = 'Upgrade to HQ Remote Pro'

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState<boolean>(true)
  const [total, setTotal] = useState<number>(7)
  const [planPeriod, setPlanPeriod] = useState<string>('')
  const [planCurrency, setPlanCurrency] = useState<string>('')
  const [availablePeriods, setAvailablePeriods] = useState<string[]>([])
  const [availableCurrencies, setAvailableCurrencies] = useState<string[]>([])
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    show: false,
    type: '',
    message: '',
    zIndex: 9999
  })

  const user = useSelector((state: RootState) => getCurrentUser(state))
  const availablePlans: Plan[] = useSelector((state: RootState) => getAvailablePlans(state))
  const setTotalValue = () => {
    const plan = availablePlans.find(p => {
      return p.period === planPeriod && p.currency === planCurrency
    })
    if (!plan) {
      setTotal(0)
      return
    }
    setTotal(plan.price / 100)
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // submit (period, currency, teamId)
    createSubscription(user.selectedTeam, {
      currency: planCurrency,
      period: planPeriod
    }).then(() => {
      setSnackbar({
        ...snackbar,
        show: true,
        message: 'Request For Upgrade Sent! Check your email to proceed payment',
        type: 'success'
      })
    }).catch(() => {
      setSnackbar({
        ...snackbar,
        show: true,
        message: 'Error submitting request for upgrade!',
        type: 'error'
      })
    })
  }
  useEffect(() => {
    document.title = 'HQ Remote | Upgrade'
    if (user.selectedTeam) {
      setLoading(false)
    }
  }, [])
  useEffect(() => {
    setTotalValue()
  }, [planCurrency, planPeriod])
  useEffect(() => {
    if (user.selectedTeam) {
      setLoading(false)
    }
  }, [user.selectedTeam])
  useEffect(() => {
    if (snackbar.show) {
      setTimeout(() => {
        setSnackbar({
          ...snackbar,
          message: '',
          type: '',
          show: false
        })
      }, 3000)
    }
  }, [snackbar.show])
  useEffect(() => {
    if (availablePlans && availablePlans.length > 0) {
      let availableP = new Set<string>()
      let availableC = new Set<string>()
      availablePlans.forEach((plan) => {
        availableP.add(plan.period)
        availableC.add(plan.currency)
      })
      setAvailablePeriods([...availableP])
      setAvailableCurrencies([...availableC])
    }
  }, [availablePlans])
  return (
    <ManagementPage>
      {loading && <SectionLoading color={false} />}
      {!loading && (
        <ManagementPanel className='management-panel'>
          <ManagementPanelInner className='management-panel__inner'>
            <ManagementPanelBlock className='management-panel__block'>
              <AccountSettingTitle>{title}</AccountSettingTitle>
              <AccountSettingText>
                You know HQ Remote Pro makes sense and time is money. Just add up
                all those minutes (which turn into hours per month) your whole
                team wastes connecting on Skype or trying to start a Google
                hangout. HQ Remote’s instant video pays for itself. Add your payment
                information below.
              </AccountSettingText>

              <form
                action='#'
                method='post'
                className='form'
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className='form__row'>
                  <label className='form__label'>
                    Would you like a monthly or annual plan? Annual benefits
                    from discounted pricing.
                  </label>
                  <div className='form__options-group'>
                    {availablePeriods.map((p, index) => (
                      <React.Fragment key={`${p}_${index}`}>
                        <RadioButton defaultValue={p} />
                        <RadioLabel
                          className={`btn ${
                            p === planPeriod ? 'checked' : ''
                          }`}
                          onClick={() => setPlanPeriod(p)}
                          inline>
                          {p.charAt(0).toUpperCase() + p.slice(1)}
                        </RadioLabel>
                        &nbsp;
                      </React.Fragment>
                    ))}
                  </div>
                </div>

                <div className='form__row'>
                  <label className='form__label'>
                    Which currency would you like your account in?
                  </label>
                  <div className='form__options-group'>
                    {availableCurrencies.map((c, index) => (
                      <React.Fragment key={`${c}_${index}`}>
                        <RadioButton
                          defaultValue={c}
                          key={`${c}_${index}`}
                        />
                        <RadioLabel
                          className={`btn ${
                            c === planCurrency ? 'checked' : ''
                          }`}
                          onClick={() => setPlanCurrency(c)}
                          inline
                          third>
                          {c.toUpperCase()}
                        </RadioLabel>
                        &nbsp;
                      </React.Fragment>
                    ))}
                  </div>
                  <label className='form__label'>
                    <InlineHeading>
                      {getCurrency(planCurrency)}
                      {total} per user, {planPeriod}
                    </InlineHeading>
                    +VAT if applicable
                  </label>
                </div>
                <div className='form__row'>
                  <ButtonStandard type='submit' className='btn'>
                    Upgrade To Pro
                  </ButtonStandard>
                </div>
              </form>
            </ManagementPanelBlock>
          </ManagementPanelInner>
        </ManagementPanel>
      )}
      <Snackbar
        show={snackbar.show}
        type={snackbar.type}
        message={snackbar.message}
        zIndex={snackbar.zIndex}
      />
    </ManagementPage>
  )
}

export default ProtectedPage(Upgrade)
