import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../modules/Loader'
import { WebcamSelection } from '../../constants/webcam'
import { MediaDevice } from '../../interfaces/zoom/video-types'
import { JmtLocalTrack } from 'lib-jitsi-meet'

interface VideoFieldProps {
  camera: string
  videoTrack: JmtLocalTrack | null
  onChange: React.ChangeEventHandler<HTMLSelectElement | HTMLInputElement>
  devices: MediaDevice[]
  blur: boolean
}

const VideoField: React.FC<VideoFieldProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [currentCamera, setCurrentCamera] = useState<MediaDevice | null>()
  const videoRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    if (!props.videoTrack) {
      setLoading(true)
      return
    }
    props.videoTrack.attach(videoRef.current)
    const deviceId = props.videoTrack.getDeviceId()
    setCurrentCamera(
      props.devices.find((device) => device.deviceId === deviceId)
    )
    setLoading(false)
  }, [props.videoTrack, props.blur, props.camera, props.devices])

  return (
    <div className='hardware-setup-camera modal__form__row'>
      <div className='hardware-setup-label modal__form__label__setting'>
        Camera:
      </div>
      <div className='hardware-setup-selector'>
        <div className='skinned-wrapper skinned-wrapper-white'>
          <div className='skinned-text'>
            {(currentCamera && currentCamera.label) || ''}
          </div>
          <select
            name='camera'
            className='skinned-select'
            onChange={props.onChange}
            value={
              (currentCamera && currentCamera.deviceId) ||
              WebcamSelection.NO_DEVICE
            }>
            {props.devices.map((device) => {
              return (
                <option value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </option>
              )
            })}
            <option value={WebcamSelection.NO_DEVICE}>select device</option>
          </select>
        </div>
      </div>
      <br />
      <div className='hardware-setup-preview'>
        <div
          className='video-container'
          style={{ width: 'auto', height: 'auto' }}>
          {loading && (
            <div className='video-loading'>
              <Loader />
            </div>
          )}
          <video
            ref={videoRef}
            autoPlay
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transform: 'scaleX(-1)'
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default VideoField
