import {
  MessageCallLeave,
  MessageCallStart
} from './interfaces/call'
import { Office } from '../../interfaces/team'

export const types = {
  CALL_START: 'realtime/user/call/start',
  CALL_ENDED: 'realtime/user/call/ended',
  JOINED_OFFICE: 'realtime/user/office/joined',
  REMOVED_OFFICE: 'realtime/user/office/removed',
  CREATED_OFFICE: 'realtime/user/office/created',
  UPDATED_OFFICE: 'realtime/user/office/updated',
  JOINED_TEAM: 'realtime/user/team/joined',
  REMOVED_TEAM: 'realtime/user/team/removed',
  ERROR: 'realtime/user/error',
  LOGOUT: 'realtime/user/logout'
}

export const actions = {
  receiveCallStart: (
    payload: MessageCallStart
  ) => ({ type: types.CALL_START, payload }),
  receiveCallEnded: (
    payload: MessageCallLeave
  ) => ({ type: types.CALL_ENDED, payload }),
  receiveError: (error: any) => ({ type: types.ERROR, payload: { error } }),
  receiveLogout: () => ({ type: types.LOGOUT }),
  receiveJoinedOffice: (teamId: number, officeId: number) => ({
    type: types.JOINED_OFFICE,
    payload: { teamId, officeId }
  }),
  receiveRemovedOffice: (teamId: number, officeId: number) => ({
    type: types.REMOVED_OFFICE,
    payload: { teamId, officeId }
  }),
  receiveCreatedOffice: (teamId: number, office: Office) => ({
    type: types.CREATED_OFFICE,
    payload: { teamId, office }
  }),
  receiveUpdatedOffice: (teamId: number, office: Office) => ({
    type: types.UPDATED_OFFICE,
    payload: { teamId, office }
  }),
  receiveJoinedTeam: (userId: number, teamId: number) => ({
    type: types.JOINED_TEAM,
    payload: { userId, teamId }
  }),
  receiveRemovedTeam: (userId: number, teamId: number) => ({
    type: types.REMOVED_TEAM,
    payload: { userId, teamId }
  })
}
