import { AxiosResponse } from 'axios'
import { axios, TEAM_API_ENDPOINT, API_URL } from './http'
import { User } from '../../../interfaces/user'
import { GeneralResponse } from '../../../interfaces/http'
import { Invitation } from '../../../interfaces/invitation'
import { ChangeLog } from '../../../interfaces/changeLog'

// todo: replace any with me return
export async function fetchTeams(): Promise<any> {
  try {
    return await axios.get(`${TEAM_API_ENDPOINT}/me`)
  } catch (error) {
    throw error
  }
}

// todo: comeback later to layout userData structure
export async function updateTeamToUser(
  teamId: number, userData: any
): Promise<AxiosResponse> {
  try {
    return await axios.put(`${TEAM_API_ENDPOINT}/${teamId}/users`, userData)
  } catch (error) {
    throw error
  }
}

// todo: type this soon
export async function getAllUsers(teamId: number): Promise<AxiosResponse<GeneralResponse<User[]>>> {
  try {
    return await axios.get(`${TEAM_API_ENDPOINT}/${teamId}/users`)
  } catch (error) {
    throw error
  }
}

export async function manageUserTeam(
  teamId: number, userID: number, params: any
): Promise<AxiosResponse> {
  try {
    return await axios.put(
      `${TEAM_API_ENDPOINT}/${teamId}/users/${userID}`,
      params
    )
  } catch (error) {
    throw error
  }
}

export async function updateTeamSetting(
  teamId: number, params: any
): Promise<AxiosResponse> {
  try {
    return await axios.put(`${TEAM_API_ENDPOINT}/${teamId}`, params)
  } catch (error) {
    throw error
  }
}

export async function getInvitations(
  teamId: number
): Promise<AxiosResponse<Invitation[]>> {
  try {
    return await axios.get(`${TEAM_API_ENDPOINT}/${teamId}/invitations`)
  } catch (error) {
    throw error
  }
}

export async function getInvitation(token: string): Promise<AxiosResponse> {
  try {
    return await axios.get(`${API_URL}/invitations/${token}`)
  } catch (error) {
    throw error
  }
}

export async function sendInvitations(teamId: number, params: any): Promise<AxiosResponse> {
  try {
    return await axios.post(
      `${TEAM_API_ENDPOINT}/${teamId}/invitations`,
      params
    )
  } catch (error) {
    throw error
  }
}

export async function deleteInvitation(
  teamId: number, invitationId: number
): Promise<AxiosResponse> {
  try {
    return await axios.delete(
      `${TEAM_API_ENDPOINT}/${teamId}/invitations/${invitationId}`
    )
  } catch (error) {
    throw error
  }
}

export async function getTeamChangeLogs(
  teamId: number
): Promise<AxiosResponse<GeneralResponse<ChangeLog[]>>> {
  try {
    return await axios.get(`${TEAM_API_ENDPOINT}/${teamId}/change-logs`)
  } catch (error) {
    throw error
  }
}

export async function acceptInvitation(params: any): Promise<AxiosResponse> {
  try {
    return await axios.post(`${API_URL}/accept-invite`, params)
  } catch (error) {
    throw error
  }
}
