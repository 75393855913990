import { isElectron } from './env'

export const routes = {
  home: '/',
  forgotPassword: '/forgot-password',
  resendPassword: '/reset-password',
  loginSlack: '/login/slack',
  loginGoogle: '/login/google',
  account: '/account',
  teamManage: '/account/team/manage',
  teamEdit: '/account/team/manage/edit',
  teamChangeLogs: '/account/team/manage/changes',
  invitation: '/account/team/invitations',
  invitationAccept: '/join-a-team/:code',
  billing: '/account/billing',
  billingPaymentMethod: '/account/billing/payment',
  billingHistory: '/account/billing/history',
  billingUpgrade: '/account/billing/upgrade',
  signUp: '/create-a-team',
  activate: '/account/activation',
  paymentSuccess: '/subscription/payment-success',
  pip: '/desktop/pip'
}

export const reloadHome = (duplicate: boolean) => {
  if (isElectron()) {
    // @ts-ignore
    window.ipcRender.reloadHome(duplicate ? process.env.REACT_APP_SITE_URL : null)
  } else if (duplicate) {
    window.location.replace(process.env.REACT_APP_SITE_URL || '/')
  } else {
    window.location.pathname = '/'
  }
}
