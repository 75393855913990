import React, { useContext, useEffect } from 'react'
import ServerSocket from './ServerSocket'

import {
  CHANNEL_CLIENT_CALL,
  CHANNEL_CLIENT_PRESENCE,
  CHANNEL_CLIENT_TEAM,
  CHANNEL_CLIENT_USER,
  CHANNEL_SERVER
} from '../constants/socket'
import ClientSocket from './ClientSocket'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { getTeamsById } from '../reducers/teams'
import { getCurrentUser } from '../reducers/user'
import { Channel } from '../interfaces/channel'
import {
  handleCallMessage,
  handlePresenceMessage,
  handleTeamMessage,
  handleUserMessage
} from '../services/messages/handler'
import { SocketContext } from '../contexts/socket'
import { TrackContext } from '../contexts/stream'

interface SocketsProps {
  setSocketReady: (ready: boolean) => void;
}

const Sockets = (props: SocketsProps) => {
  const currentUser = useSelector((state: RootState) => getCurrentUser(state))
  const teams = useSelector((state: RootState) => getTeamsById(state))
  const { serverChannel, clientChannels } = useContext(SocketContext)
  const [presenceStatus, setPresenceStatus] = React.useState<boolean>(false)
  const [teamStatus, setTeamStatus] = React.useState<boolean>(false)
  const [userStatus, setUserStatus] = React.useState<boolean>(false)
  const [callStatus, setCallStatus] = React.useState<boolean>(false)
  const [serverStatus, setServerStatus] = React.useState<boolean>(false)
  const { setTracks } = useContext(TrackContext)
  const dispatch = useDispatch()

  const channels: Channel[] = [
    {
      name: `${CHANNEL_CLIENT_USER}:${currentUser.id}`,
      presence: false,
      teamId: null,
      present: false,
      messageHandler: handleUserMessage(dispatch),
      ready: setUserStatus
    }
  ]
  Object.values(teams).forEach((team) => {
    const selected = team.id === currentUser.selectedTeam
    const present = selected
    channels.push({
      name: `${CHANNEL_CLIENT_PRESENCE}:${team.id}`,
      presence: true,
      teamId: team.id,
      present: present,
      messageHandler: () => {},
      ready: setPresenceStatus
    })
    channels.push({
      name: `${CHANNEL_CLIENT_TEAM}:${team.id}`,
      presence: false,
      teamId: team.id,
      present: present,
      messageHandler: handleTeamMessage(dispatch),
      ready: setTeamStatus
    })

    if (selected) {
      channels.push({
        name: `${CHANNEL_CLIENT_CALL}:${team.id}`,
        presence: false,
        teamId: team.id,
        present: false,
        messageHandler: handleCallMessage(dispatch),
        ready: setCallStatus
      })
    }
  })

  useEffect(() => {
    props.setSocketReady(presenceStatus && userStatus && callStatus && serverStatus && teamStatus)
  }, [presenceStatus, userStatus, callStatus, serverStatus, teamStatus])

  return (<>
    <ServerSocket
      channelName={`${CHANNEL_SERVER}`}
      ready={setServerStatus}
    />
    {
      (
        serverChannel
      ) && channels.map((channel) => {
        return (
          <ClientSocket
            presence={channel.presence}
            key={`${channel.name}`}
            channelName={`${channel.name}`}
            handler={(message) => {
              channel.messageHandler(serverChannel, clientChannels, setTracks, channel.teamId, message)
            }}
            presenceHandler={
              handlePresenceMessage(
                channel.teamId,
                Number(currentUser.id),
                dispatch,
                serverChannel,
                clientChannels
              )
            }
            ready={channel.ready}
          />
        )
      })
    }
  </>)
}

export default Sockets
