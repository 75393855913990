export const normalize = (data) => {
  return {
    type: data.name,
    quantity: data.quantity,
    stripeId: data.stripe_id,
    pricePlan: data.stripe_plan,
    status: data.stripe_status,
    trialEndAt: data.trial_ends_at,
    endsAt: data.ends_at,
    // todo: ask del/chris if this need to be fetch from backend
    // most likely is yes
    perUserCost: data.stripe_plan === 'monthly' ? 7 : 6,
    currentPeriodEnd: data.current_period_end,
    currentPeriodStart: data.current_period_start
  }
}
