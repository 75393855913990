import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { store } from './store'
import { createBrowserHistory, createHashHistory } from 'history'

import * as serviceWorker from './serviceWorker'
import { isElectron } from './utils/env'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import * as Sentry from '@sentry/react'

const container = document.getElementById('root')
const root = createRoot(container)
export const history = isElectron()
  ? createHashHistory()
  : createBrowserHistory()

localStorage.debug = 'sneek:*'

// 1. Set up the browser history with the updated location
// (minus the # sign)
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1]

if (path) {
  history.replace(path)
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
