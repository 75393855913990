import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { actions as sessionActions, getUploadPolicies } from '../reducers/session'
import { getCurrentUser, types, UserPayloadAction } from '../reducers/user'
import { actions as usersActions, types as usersTypes } from '../reducers/users'
import { actions as realtimeTeamActions } from '../reducers/realtime/team'
import {
  fetchUploadPolicy as fetchUploadPolicyApi,
  updateUser as updateUserApi,
  slackConnectTeam as slackConnectTeamApi,
  uploadPhoto as uploadPhotoApi
} from '../services/api'
import { DateTime } from 'luxon'
import { buildTeamState } from '../utils/state'
import { store } from '../store'
import { CALL_INTERVAL_LABEL_TO_ID } from '../constants/call'
import { snapshotEffectGroups } from '../services/snapshotEffects'
import { User } from '../interfaces/user'
import { UserAvailabilityState } from '../constants/user'
import { UploadPolicy } from '../interfaces/uploadPolicy'
import { deleteOffice } from '../services/api/library/offices'
import { RealtimeAction } from './realtime/interface'
import { Types } from 'ably'
import { sendUserIntervalChanged, sendUserSnapshotEffectChanged } from '../services/socket/socket'
import { IInterval } from '../interfaces/interval'

export function *switchOffice(action: UserPayloadAction) {
  const { teamId, officeId } = action.payload
  if (!teamId || !officeId) {
    return
  }
  yield put(realtimeTeamActions.sendUserEnteredOffice(teamId, officeId))
}

export function *removeTeamOffice(action: UserPayloadAction) {
  const { teamId, officeId } = action.payload
  if (!teamId || !officeId) {
    return
  }
  // const channelName = 'presence:' + teamId
  try {
    yield call(deleteOffice, teamId, officeId)
    // yield call(
    //   publish,
    //   channelName,
    //   REALTIME_MESSAGE.TEAM.OFFICE.REMOVED_OFFICE,
    //   JSON.stringify({
    //     teamId: teamId,
    //     officeId: officeId
    //   }),
    //   () => {}
    // )
  } catch (error) {
    return
  }
}

export function *addTeamOffice(action: UserPayloadAction) {
  const { teamId, office } = action.payload
  if (!teamId || !office) {
    return
  }
  // const channelName = 'presence:' + teamId
  try {
    // yield call(
    //   publish,
    //   channelName,
    //   REALTIME_MESSAGE.TEAM.OFFICE.CREATED_OFFICE,
    //   JSON.stringify({
    //     teamId: teamId,
    //     office: office
    //   }),
    //   () => {}
    // )
  } catch (error) {
    return
  }
}

export function *updateTeamOffice(action: UserPayloadAction) {
  const { teamId, office } = action.payload
  if (!teamId || !office) {
    return
  }
  // const channelName = 'presence:' + teamId
  try {
    // yield call(
    //   publish,
    //   channelName,
    //   REALTIME_MESSAGE.TEAM.OFFICE.UPDATED_OFFICE,
    //   JSON.stringify({
    //     teamId: teamId,
    //     office: office
    //   }),
    //   () => {}
    // )
  } catch (error) {
    return
  }
}

export function *switchTeam(action: UserPayloadAction) {
  const { teamId } = action.payload
  if (!teamId) {
    return
  }
  const user: User = yield select(getCurrentUser)
  try {
    // If we have a previous selected team
    if (user.previouslySelectedTeam) {
      // And we can ghost but were available
      const oldTeamState = user.teamState[user.previouslySelectedTeam]
      if (oldTeamState?.canGhost && oldTeamState.state === UserAvailabilityState.AVAILABLE) {
        // we are now ghosting
        // yield put(actions.changeState(
        //   Number(user.previouslySelectedTeam),
        //   UserAvailabilityState.GHOSTING
        // ))
      }
    }
    // If we can ghost on our new team and are ghosting,
    // then make us available
    const newTeamState = user.teamState[teamId]
    if (newTeamState?.canGhost && newTeamState.state === UserAvailabilityState.GHOSTING) {
      // yield put(actions.changeState(
      //   user.selectedTeam,
      //   UserAvailabilityState.AVAILABLE
      // ))
    }
    yield put(
      realtimeTeamActions.receiveUserEnteredTeam({
        teamId,
        userId: user.id,
        timestamp: (new Date()).getTime() / 1000
      }))
  } catch (error) {
    return
  }
}

export function *changeState(action: RealtimeAction<{
  teamId: number,
  state: UserAvailabilityState,
  serverChannel: Types.RealtimeChannelCallbacks
}>) {
  const { teamId, state, serverChannel } = action.payload
  if (!teamId || !state) {
    return
  }
  yield put(realtimeTeamActions.sendUserStateChanged(teamId, state, serverChannel))
}

export function *changeInterval(action: RealtimeAction<{
  interval: IInterval,
  teamId: number,
  serverChannel: Types.RealtimeChannelCallbacks
}>) {
  const { interval, teamId, serverChannel } = action.payload
  if (!interval || !teamId) {
    return
  }
  const user: User = yield select(getCurrentUser)
  try {
    sendUserIntervalChanged(serverChannel, {
      teamId,
      userId: user.id,
      timerInterval: CALL_INTERVAL_LABEL_TO_ID[interval.name]
    })
  } catch (e) {
    yield put(usersActions.updateSingleFailed(e))
  }
}

export function *changeSnapshotEffect(action: RealtimeAction<{
  snapshotEffect: string,
  teamId: number,
  serverChannel: Types.RealtimeChannelCallbacks
}>) {
  const { snapshotEffect, teamId, serverChannel } = action.payload
  if (!snapshotEffect || !teamId) {
    return
  }
  const user: User = yield select(getCurrentUser)
  try {
    sendUserSnapshotEffectChanged(serverChannel, {
      teamId,
      userId: user.id,
      snapshotEffectId: snapshotEffectGroups[snapshotEffect].id
    })
  } catch (e) {
    yield put(usersActions.updateSingleFailed(e))
  }
}

export function *changeGhosting() {
  const user: User = yield select(getCurrentUser)
  try {
    yield call(updateUserApi, user)
  } catch (e) {
    yield put(usersActions.updateSingleFailed(e))
  }
}

export function *uploadPhoto(action: RealtimeAction<{
  teamId: number,
  imgData: any,
  serverChannel: Types.RealtimeChannelCallbacks
}>) {
  let uploadPolicies: { [key: string]: UploadPolicy } = yield select(getUploadPolicies)

  if (!action.payload.teamId) {
    return
  }
  try {
    if (
      typeof uploadPolicies[action.payload.teamId] !== 'object' ||
      DateTime.local() >
        DateTime.fromMillis(
          uploadPolicies[action.payload.teamId].expiration * 1000
        )
    ) {
      const policy: UploadPolicy = yield call(fetchUploadPolicyApi, action.payload.teamId)
      yield put(
        sessionActions.setUploadPolicy(action.payload.teamId, policy.data)
      )
      uploadPolicies = yield select(getUploadPolicies)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('upload policy fails', e)
    return
  }

  if (typeof uploadPolicies[action.payload.teamId] !== 'object') {
    return
  }

  const policy = uploadPolicies[action.payload.teamId]
  const { imgData } = action.payload
  if (!imgData) {
    return
  }
  try {
    const { data } = yield call(
      uploadPhotoApi,
      policy,
      imgData
    )

    const photoUrl = data.match(/.*<Location>(.*)<\/Location>.*/)[1]
    const urls = photoUrl.split('/')
    const photo = urls[urls.length - 1]
    yield put(realtimeTeamActions.sendUserPhotoUpdated(`https://${process.env.REACT_APP_SNAP_CDN_URL}/${photo}`, action.payload.serverChannel))
  } catch (e) {
    yield put(sessionActions.setUploadPolicy(Number(action.payload.teamId), false))
  }
}

// eslint-disable-next-line
export function *updateSingleUser(action: UserPayloadAction) {
  try {
    yield put(usersActions.updateSingleSuccess())
  } catch (e) {
    yield put(usersActions.updateSingleFailed(e))
  }
}

export function *slackConnectTeam(action: UserPayloadAction) {
  const { teamId } = action.payload
  try {
    yield call(slackConnectTeamApi, Number(teamId))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error', e)
  }
}

export function *changeStatusMessage(action: RealtimeAction<{
  message: string,
  serverChannel: Types.RealtimeChannelCallbacks
}>) {
  const { message, serverChannel } = action.payload
  // @ts-ignore
  store.dispatch(realtimeTeamActions.sendUserStatusChanged(message || '', serverChannel))
}


export function *changeLayout(action: UserPayloadAction) {
  const { layout, teamId } = action.payload
  if (!layout || !teamId) {
    return
  }
  const user: User = yield select(getCurrentUser)
  const state = buildTeamState(
    String(teamId),
    { callLayout: layout }
  )
  try {
    yield call(updateUserApi, { teamState: state, id: user.id })
  } catch (e) {
    yield put(usersActions.updateSingleFailed(e))
  }
}


export default function *userWatcher() {
  yield all([
    takeEvery(types.SWITCH_OFFICE, switchOffice),
    takeEvery(types.REMOVE_TEAM_OFFICE, removeTeamOffice),
    takeEvery(types.ADD_TEAM_OFFICE, addTeamOffice),
    takeEvery(types.UPDATE_TEAM_OFFICE, addTeamOffice),
    takeEvery(types.SWITCH_TEAM, switchTeam),
    takeEvery(types.CHANGE_STATE, changeState),
    takeEvery(types.CHANGE_INTERVAL, changeInterval),
    takeEvery(types.CHANGE_SNAPSHOT_EFFECT, changeSnapshotEffect),
    takeEvery(types.CHANGE_LAYOUT, changeLayout),

    // takeEvery(types.CHANGE_CAN_GHOST, changeGhosting),
    takeEvery(types.CHANGE_GHOSTING, changeGhosting),
    takeEvery(types.UPDATE_PHOTO, uploadPhoto),
    takeEvery(types.CHANGE_STATUS_MESSAGE, changeStatusMessage),
    // only called by current user
    takeEvery(usersTypes.UPDATE_SINGLE, updateSingleUser),
    takeEvery(usersTypes.SLACK_CONNECT_TEAM, slackConnectTeam)
  ])
}
