import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { actions as sessionActions } from '../reducers/session'
import { loginSocial } from '../services/api'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { ProtectedComponentProps } from '../hocs/ProtectedPage'

const SectionPage = styled.section`
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
  font: 400 40px/40px 'DM Sans';
  color: #fff;
`

const SocialAuth: React.FC<
  RouteComponentProps&ProtectedComponentProps
  > = (props) => {
    const dispatch = useDispatch()
    const search = useLocation().search
    const loginWith = (type: string) => {
      if (search) {
        if (type === 'google') {
          dispatch(sessionActions.loginGoogle(search))
        }
        if (type === 'slack') {
          dispatch(sessionActions.loginSlack(search))
        }
        return
      }
      loginSocial(type).then((res) => {
        const { url } = res.data
        window.location = url
        return
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log('login social fail', err)
        return
      })
    }
    useEffect(() => {
      props.history.replace('/')
    }, [props.session])
    useEffect(() => {
      if (localStorage.getItem('token')) {
        props.history.replace('/')
        return
      }
      const { pathname } = props.location
      switch (pathname) {
        case `/login/slack`:
          loginWith('slack')
          break
        case `/login/google`:
          loginWith('google')
          break
        default:
          return
      }
    }, [])
    return (
      <SectionPage>
        <div className='loading__message text-center'>Loading...</div>
      </SectionPage>
    )
  }

export default SocialAuth
