import { SNAP_RESOLUTION } from '../constants/call'
import { GIF_SHOT_INTERVAL } from '../constants/gif'
import ClosePixelation from './helpers/closePixelate.js'
import {
  getWatermarkImageFunction,
  processBlackAndWhite,
  processGrayScale,
  processSepia
} from './helpers/effect'
const GIF = require('gif.js')

export type PixelationParameterOption = {
  pixelation: {
    resolution: number;
  }
}

export type CommonParameterOption = {
  size: {
    width: number;
    height: number;
  };
  publisher: {
    element: HTMLVideoElement;
  };
}

export type GifParameterOption = {
  publisher: {
    element: HTMLVideoElement;
  };
}

export type EffectParameter<T> = {
  imgData: string;
  mimeType?: string;
  options: T;
}

const pixelation = (params: { options?: any; imgData?: any }, next: (arg0: any) => void) => {
  let { imgData, options } = params
  let { resolution } = options.pixelation

  if (!resolution) {
    resolution = 24
  }

  let img = document.createElement('img')

  img.onload = function () {
    // @ts-ignore
    let canvas = new ClosePixelation(img, [{ resolution }])
    params.imgData = canvas
      // @ts-ignore
      .toDataURL('image/png')
      .replace('data:image/png;base64,', '')
      .trim()

    next(params)
  }

  img.setAttribute('src', `data:image/png;base64,${imgData}`)
}

const pixelateHigh = (params: EffectParameter<PixelationParameterOption>, next: any) => {
  params.options.pixelation = {
    resolution: 24
  }

  return pixelation(params, next)
}

const pixelateLow = (params: EffectParameter<PixelationParameterOption>, next: any) => {
  params.options.pixelation = {
    resolution: 10
  }

  return pixelation(params, next)
}

const watermark = (
  params: EffectParameter<CommonParameterOption>, next: (arg0: any) => void
) => {
  let watermarkImgFunction = getWatermarkImageFunction()
  watermarkImgFunction().then(watermarkImg => {
    let { imgData, options } = params
    let { size } = options

    if (size.width < SNAP_RESOLUTION.width || size.height < SNAP_RESOLUTION.height) {
      next(params)
      return
    }

    let img = document.createElement('img')

    img.onload = function () {
      let canvas = document.createElement('canvas')
      canvas.height = size.height
      canvas.width = size.width

      let context = canvas.getContext('2d')
      // @ts-ignore
      context.drawImage(this, 0, 0)
      // @ts-ignore
      context.drawImage(watermarkImg, 20, size.height - 60, 40, 40)

      params.imgData = canvas
        .toDataURL('image/jpeg')
        .replace('data:image/jpeg;base64,', '')
      params.mimeType = 'image/jpeg'

      next(params)
    }

    img.setAttribute('src', `data:image/png;base64,${imgData}`)
  })
}

const resize = (
  params: EffectParameter<CommonParameterOption>, next: (arg0: any) => void
) => {
  const canvasSizes = {
    height: SNAP_RESOLUTION.height,
    width: SNAP_RESOLUTION.width
  }

  let { options } = params
  let { size } = options

  if (
    !size ||
    (size.width <= canvasSizes.width || size.height <= canvasSizes.height)
  ) {
    next(params)
    return
  }

  let img = document.createElement('img')

  img.onload = function () {
    let canvas = document.createElement('canvas')
    canvas.height = canvasSizes.height
    canvas.width = canvasSizes.width

    let ratio = size.width / size.height
    let width = Math.round(canvas.height * ratio)
    let height = Math.round(canvas.height)

    if (canvas.width / canvas.height > ratio) {
      height = Math.round(canvas.width / ratio)
      width = Math.round(canvas.width)
    }

    let top = ((height - canvas.height) / 2) * -1
    let left = ((width - canvas.width) / 2) * -1

    let context = canvas.getContext('2d')
    // @ts-ignore
    context.drawImage(
      // @ts-ignore
      this,
      0,
      0,
      size.width,
      size.height,
      left,
      top,
      width,
      height
    )

    params.imgData = canvas
      .toDataURL('image/png')
      .replace('data:image/png;base64,', '')

    // Update the size of the image
    params.options.size = {
      height: canvas.height,
      width: canvas.width
    }
    next(params)
  }
  setTimeout(() => {
    // note: (todo?) this is a very weird behavior I couldn't figure out why
    // this happen only on safari, if we don't do wait for a second before setting the image
    // we got 0 second image snapped instead of third-second image
    // (start of countdown vs end-of countdown)
    // @ts-ignore
    img.setAttribute('src', params.options.publisher.getImgData())
  }, 1000)
}

const blobToBase64 = (blob: any) => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}


const gif = (params: any, next: (arg0: any) => any) => {
  let interval: any
  let count = 3
  const cWidth = SNAP_RESOLUTION.width
  const cHeight = SNAP_RESOLUTION.height
  const giffy = new GIF({
    width: cWidth,
    height: cHeight,
    workers: 2,
    quality: 30
  })
  giffy.on('finished', (blob: any) => {
    // eslint-disable-next-line
    console.log('gif finished', blob)
    blobToBase64(blob).then((base64Res: any) => {
      params.imgData = base64Res.replace('data:image/gif;base64,', '')
      // release freeworkers after 1500ms since
      // there's currently no listener to set on freeworker value added now atm in the library
      giffy.freeWorkers.forEach((w: any) => {
        if (w !== null) {
          w.terminate()
        }
      })
      next(params)
    })
  })
  interval = setInterval(() => {
    let canvas = document.createElement('canvas')
    canvas.width = cWidth
    canvas.height = cHeight
    let context = canvas.getContext('2d')

    const publisher: HTMLVideoElement = params.options.publisher
    context?.drawImage(
      params.options.publisher,
      (publisher.videoWidth - publisher.videoHeight) / 2, 0, publisher.videoHeight, publisher.videoHeight,
      0, 0, canvas.width, canvas.height
    )
    const image = canvas.toDataURL('image/png')
    // eslint-disable-next-line
    console.log('image', image)

    giffy.addFrame(canvas)
    if (count === 0) {
      clearInterval(interval)
      giffy.render()
    }
    count = count - 1
  }, GIF_SHOT_INTERVAL)
}


const sepia = (params: EffectParameter<CommonParameterOption>, next: (arg0: any) => void) => {
  let img = document.createElement('img')

  img.onload = function () {
    let canvas = document.createElement('canvas')
    canvas.height = SNAP_RESOLUTION.height
    canvas.width = SNAP_RESOLUTION.width

    let context = canvas.getContext('2d')
    // @ts-ignore
    context.drawImage(this, 0, 0)
    processSepia(canvas, context)

    params.imgData = canvas
      .toDataURL('image/png')
      .replace('data:image/png;base64,', '')

    next(params)
  }

  img.setAttribute('src', `data:image/png;base64,${params.imgData}`)
}

const grayscale = (params: EffectParameter<CommonParameterOption>, next: (arg0: any) => void) => {
  let img = document.createElement('img')

  img.onload = function () {
    let canvas = document.createElement('canvas')
    canvas.height = SNAP_RESOLUTION.height
    canvas.width = SNAP_RESOLUTION.width

    let context = canvas.getContext('2d')
    // @ts-ignore
    context.drawImage(this, 0, 0)
    processGrayScale(canvas, context)

    params.imgData = canvas
      .toDataURL('image/png')
      .replace('data:image/png;base64,', '')

    next(params)
  }

  img.setAttribute('src', `data:image/png;base64,${params.imgData}`)
}

const blackandwhite = (
  params: EffectParameter<CommonParameterOption>, next: (arg0: any) => void
) => {
  let img = document.createElement('img')

  img.onload = function () {
    let canvas = document.createElement('canvas')
    canvas.height = SNAP_RESOLUTION.height
    canvas.width = SNAP_RESOLUTION.width

    let context = canvas.getContext('2d')
    // @ts-ignore
    context.drawImage(this, 0, 0)
    processBlackAndWhite(canvas, context)

    params.imgData = canvas
      .toDataURL('image/png')
      .replace('data:image/png;base64,', '')

    next(params)
  }

  img.setAttribute('src', `data:image/png;base64,${params.imgData}`)
}

export type EffectHandler = (
  params: EffectParameter<GifParameterOption & CommonParameterOption & PixelationParameterOption>,
  next: (arg0: any) => void
) => void

export const snapshotEffects: { [key: string]: EffectHandler } = {
  resize,
  pixelateLow,
  pixelateHigh,
  watermark,
  gif,
  sepia,
  grayscale,
  blackandwhite
}

export interface SnapshotEffect {
  id: number,
  name: string,
  effects: string[]
}

export const snapshotEffectGroups: { [key: string]: SnapshotEffect } = {
  'normal': {
    id: 1,
    name: 'Normal',
    effects: ['resize', 'watermark']
  },
  'pixelate-high': {
    id: 2,
    name: 'High Pixelation',
    effects: ['resize', 'pixelateHigh', 'watermark']
  },
  'pixelate-low': {
    id: 3,
    name: 'Low Pixelation',
    effects: ['resize', 'pixelateLow', 'watermark']
  },
  'gif': {
    id: 4,
    name: 'GIF',
    effects: ['gif']
  },
  'sepia': {
    id: 5,
    name: 'Sepia',
    effects: ['resize', 'sepia', 'watermark']
  },
  'grayscale': {
    id: 6,
    name: 'Grayscale',
    effects: ['resize', 'grayscale', 'watermark']
  },
  'black-and-white': {
    id: 7,
    name: 'Black and White',
    effects: ['resize', 'blackandwhite', 'watermark']
  }
}
