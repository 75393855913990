import React, { PropsWithChildren, useEffect } from 'react'
import './_styles/modal.style.css'
import { ModalProps, ModalTitleProps } from '../interfaces/component'

const addBodyClass = (className: string) => document.body.classList.add(className)
const removeBodyClass = (className: string) => document.body.classList.remove(className)

const Modal = (props: (ModalProps & PropsWithChildren<any>)) => {
  useEffect(() => {
    if (props.show) {
      addBodyClass('modal-open')
    } else if (props.show) {
      removeBodyClass('modal-open')
    }
    return () => {
      removeBodyClass('modal-open')
    }
  }, [props.show])
  return (
    <div
      className='modal'
      style={props.style ?? {}}
    >
      <div className='modal__content'>{props.children}</div>
      {!props.disabledClose && (
        <button
          className={props.style && props.style.background === '#000' ? 'modal__close__white' : 'modal__close'} onClick={props.onClose} />
      )}
    </div>
  )
}

export const ModalTitle = (props: ModalTitleProps) => (
  <h1 className='modal__title' style={
    props.style ?? {}
  }>{props.title}</h1>
)
export const ModalContainer = (props: PropsWithChildren<any>) => <div style={{
  color: '#fff'
}}>{props.children}</div>

export default Modal
