import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { JmtLocalTrack } from 'lib-jitsi-meet'

const CanvasStyled = styled.canvas`
  display: block;
`

interface SettingsMicProps {
  mic: string
  onChange: React.ChangeEventHandler<HTMLSelectElement>
  devices: any[]
  audioTrack: JmtLocalTrack | null
}

let micFeedBackInterval: any = ''

const SettingsMic: React.FC<SettingsMicProps> = (props) => {
  const barRef = useRef<HTMLCanvasElement>()
  const setupRef = useRef<HTMLDivElement>()
  const [currentMic, setCurrentMic] = useState<any | null>()
  const [barWidth, setBarWidth] = useState<number>(0)
  const [barHeight, setBarHeight] = useState<number>(0)

  const { onChange, devices, mic, audioTrack } = props

  const updateMicFeedbackStyle = useCallback(() => {
    if (!audioTrack || !barRef.current) {
      return
    }

    const newVolumeIntensity = audioTrack.audioLevel
    const canvasContext = barRef.current.getContext('2d')
    if (!canvasContext) {
      return
    }
    canvasContext.clearRect(0, 0, barWidth, barHeight)

    // check if we're currently clipping
    canvasContext.fillStyle = '#ecfc54'
    // draw a bar based on the current volume
    canvasContext.fillRect(
      0,
      0,
      newVolumeIntensity * 5 * barWidth * 1.7,
      barHeight
    )
  }, [audioTrack, barRef, barHeight, barWidth])

  useEffect(() => {
    if (devices.length <= 0) {
      return
    }
    const selectedMic = devices.find(
      (device) => device.deviceId === audioTrack?.getDeviceId()
    )
    setCurrentMic(selectedMic)
  }, [mic, devices, audioTrack])

  useEffect(() => {
    if (!barHeight || !barWidth || !audioTrack) {
      return () => {}
    }
    micFeedBackInterval = setInterval(updateMicFeedbackStyle, 500)
    return () => {
      if (micFeedBackInterval) {
        clearInterval(micFeedBackInterval)
      }
    }
  }, [barHeight, barWidth, audioTrack])

  useEffect(() => {
    if (!setupRef.current) {
      return
    }
    const { clientWidth: width, clientHeight: height } = setupRef.current
    setBarWidth(width)
    setBarHeight(height)
  }, [setupRef])

  return (
    <div className='hardware-setup-mic modal__form__row'>
      <div className='hardware-setup-label modal__form__label__setting'>
        Mic:
      </div>
      <div className='hardware-setup-selector'>
        <div className='skinned-wrapper skinned-wrapper-white'>
          <div className='skinned-text'>
            {(currentMic && currentMic.label) || ''}
          </div>
          <select
            name='mic'
            className='skinned-select'
            onChange={onChange}
            value={(currentMic && currentMic.deviceId) || ''}>
            {props.devices.map((device) => {
              return (
                <option value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </option>
              )
            })}
            <option value={''}>Select</option>
          </select>
        </div>
      </div>
      <div className='hardware-setup-preview'>
        <div
          className='hardware-setup-activity-bg'
          height='30'
          // @ts-ignore
          ref={setupRef}>
          {/* @ts-ignore */}
          <CanvasStyled width='400' height='30' ref={barRef} />
        </div>
      </div>
      {/*   hardware-setup-selector */}
    </div>
  )
}

export default SettingsMic
