import React, { RefObject, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Countdown from '../components/Countdown'
import SnapshotImage from '../services/snapshotImage'
import SnapshotInterval, { TeamInterval } from '../components/SnapshotInterval'
import {
  actions as countdownActions,
  getStatus as getCountdownStatus
} from '../reducers/countdown'
import { actions as realtimeCallActions } from '../reducers/realtime/call'
import {
  actions as userActions,
  getAllTeamStates,
  getSelectedTeamId
} from '../reducers/user'
import { actions as teamActions } from '../reducers/realtime/team'
import { getCall } from '../reducers/call'
import { getTeamsById } from '../reducers/teams'
import { RootState } from '../store'
import { User } from '../interfaces/user'
import { CALL_STATE, layouts } from '../constants/call'
import { UserAvailabilityState } from '../constants/user'
import { isElectron } from '../utils/env'
import { IDLE_TRESHOLD } from '../constants/user'
import { COUNTDOWN_STATE } from '../constants/countdown'
import { SocketContext } from '../contexts/socket'
import { snapshotEffectGroups } from '../services/snapshotEffects'
import { TrackContext, TrackDetail } from '../contexts/stream'
import { CHANNEL_CLIENT_CALL, CHANNEL_CLIENT_TEAM } from '../constants/socket'
import jitsi, { JmtLocalTrack } from 'lib-jitsi-meet'

interface MyChatHeadProps {
  user: User|null;
  layout: number;
  style: React.CSSProperties;
  tracks: Array<TrackDetail>;
  snapShotRef: RefObject<HTMLVideoElement>
  isOnPip: boolean
}

export default (ChatHead: any) => {
  const MyChatHead: React.FC<MyChatHeadProps> = (props) => {
    const callRef = useRef<string>('')
    const snapState = useRef<string>(COUNTDOWN_STATE.DONE)
    const disableAction = useRef<boolean>(false)
    const snapping = useRef<boolean>(false)
    const [disableActions, setDisableActions] = useState<boolean>(false)
    const [manualSnap, setManualSnap] = useState<boolean>(false)
    const [snapshotTrack, setSnapShotTrack] = useState<JmtLocalTrack|null>(null)
    const teamStates = useSelector((state: RootState) => getAllTeamStates(state))
    const teams = useSelector((state: RootState) => getTeamsById(state))
    const countdownState = useSelector((state: RootState) => getCountdownStatus(state))
    const call = useSelector((state: RootState) => getCall(state))
    const [idleSince, setIdleSince] = useState<number>(0)
    const [idle, setIdle] = useState<boolean>(false)
    const {
      clientChannels
    } = useContext(SocketContext)
    const { setTracks } = useContext(TrackContext)
    const selectedTeamId = useSelector((state: RootState) => getSelectedTeamId(state))
    const callChannel = clientChannels[`${CHANNEL_CLIENT_CALL}:${selectedTeamId}`]
    const teamChannel = clientChannels[`${CHANNEL_CLIENT_TEAM}:${selectedTeamId}`]

    const dispatch = useDispatch()
    const timer = useRef()

    const createLocalTrack = async () => {
      const tracks = await jitsi.createLocalTracks({
        devices: ['video']
        // cameraDeviceId: settings.camera
      })
      tracks[0].attach(props.snapShotRef.current)
      setSnapShotTrack(tracks[0])
    }

    const disposeLocalTrack = () => {
      if (snapshotTrack) {
        snapshotTrack.dispose()
        setSnapShotTrack(null)
      }
    }

    const startCountdown = async () => {
      if (!disableAction.current) {
        setDisableActions(true)
        if (!props.tracks.some(t => t.track && t.track.getType() === 'camera' && !t.track.isMuted())) {
          await createLocalTrack()
        }
        dispatch(countdownActions.start())
      }
    }

    const onCountdownDone = (callback: () => void) => {
      let promises = [Promise.resolve()]
      const selfVideoEl = props.snapShotRef.current as HTMLVideoElement
      const snapshot = new SnapshotImage(selfVideoEl, {
        size: {
          height: selfVideoEl.height,
          width: selfVideoEl.width
        }
      })
      snapshot.setEffects(
        snapshotEffectGroups[teamStates[
          String(props.user?.selectedTeam)
        ].snapshotEffect].effects
      )

      let snapshotPromise = snapshot.process()
        .then((params: any) => {
          if (teamChannel) {
            const { imgData, mimeType } = params
            dispatch(
              userActions.updatePhoto(
                imgData,
                mimeType,
                Number(props.user?.selectedTeam),
                teamChannel
              ))
          } else {
            // eslint-disable-next-line no-console
            console.log('team channel not defined', teamChannel)
          }
          return Promise.resolve()
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log('error updating photo', err)
        })
      promises.push(snapshotPromise)
      Promise.all(promises).then(() => {
        setManualSnap(false)
        snapping.current = false
      }).catch((e) => {
        // eslint-disable-next-line
        console.log('debug: error snap', e)
      }).finally(() => {
        // eslint-disable-next-line
        console.log('debug: dispatching done')
        dispatch(countdownActions.done())
        disposeLocalTrack()
        callback()
      })
    }
    const onInterval = (teamIntervals: TeamInterval[]) => {
      const currentTeamInterval =
      teamIntervals.filter(team => team.teamId === props.user?.selectedTeam)
      // todo: currently only handle active team snap
      if (currentTeamInterval && snapState.current === 'done') {
        if (!snapping.current) {
          snapping.current = true
          startCountdown()
        }
      }
    }
    useEffect(() => {
      disableAction.current = disableActions
    }, [disableActions])
    const handleClick = () => {
      if (
        callChannel !== null &&
        props.user?.teamState[props.user.selectedTeam].callState === CALL_STATE.IN_CALL
      ) {
        dispatch(realtimeCallActions.sendLeave(clientChannels, setTracks))
        return
      }
      if (!disableAction.current && !snapping.current) {
        if (!manualSnap) {
          setManualSnap(true)
        }
        snapping.current = true
        startCountdown()
      }
    }
    const endcallListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (callRef.current && callRef.current !== '') {
          dispatch(realtimeCallActions.sendLeave(clientChannels, setTracks))
        }
      }
    }
    useEffect(() => {
      if (call.isConnected ||
        props.user?.teamState[props.user.selectedTeam].callState === CALL_STATE.IN_CALL) {
        callRef.current = call.room
      }
    }, [call.room, call.isConnected, props.user?.teamState[props.user.selectedTeam].callState])

    useEffect(() => {
      snapState.current = countdownState
      if (countdownState === 'done') {
        setDisableActions(false)
        snapping.current = false
        setManualSnap(false)
      }
    }, [countdownState])

    useEffect(() => {
      setDisableActions(
        props.user?.teamState[props.user?.selectedTeam].state === UserAvailabilityState.AWAY
      )
    }, [props.user?.teamState[props.user?.selectedTeam].state])

    // useEffect(() => {
    //   if (!settings.showSetting) {
    //     if (settings.mirrorVisible === MirrorVisibility.VIDEO) {
    //       dispatch(countdownActions.standby())
    //       createLocalTrack()
    //     } else {
    //       dispatch(countdownActions.done())
    //       disposeLocalTrack()
    //     }
    //     return
    //   }
    // }, [settings.mirrorVisible, settings.camera, settings.showSetting])

    useEffect(() => {
      if (idleSince > IDLE_TRESHOLD && !idle) {
        setIdle(true)
      }
      if (idleSince < IDLE_TRESHOLD && idle) {
        setIdle(false)
      }
    }, [idleSince])

    useEffect(() => {
      if (props.user && props.user.id !== 0 && teamChannel) {
        dispatch(teamActions.sendUserIdleChanged(props.user.id, idle, teamChannel))
      }
    }, [idle, props.user?.id])

    useEffect(() => {
      if (isElectron()) {
        if (timer.current) {
          clearInterval(timer.current)
        }
        if (props.user?.teamState[props.user.selectedTeam].callState !== CALL_STATE.IN_CALL) {
          // @ts-ignore
          timer.current = setInterval(() => {
            // @ts-ignore
            window.ipcRender.getIdleTime().then(res => {
              setIdleSince(res)
            })
          }, 5000)
        } else {
          setIdleSince(0)
        }
      }
    }, [props.user?.teamState[props.user.selectedTeam].callState])

    useEffect(() => {
      window.addEventListener('keyup', endcallListener, true)
      setIdleSince(0)
      return () => {
        if (timer?.current) {
          clearInterval(timer.current)
        }
      }
    }, [])
    if (!props.user) {
      return null
    }

    let chatHeadCall = {
      isConnected: false,
      room: ''
    }

    if (props.user.teamState[selectedTeamId].callState !== CALL_STATE.NOT_IN_CALL) {
      chatHeadCall = {
        isConnected: props.user.teamState[selectedTeamId].callState === CALL_STATE.IN_CALL,
        room: String(props.user.teamState[selectedTeamId].callRoom)
      }
    }

    return (
      <React.Fragment>
        <ChatHead
          tracks={props.tracks}
          myself={true}
          disableActions={disableActions}
          onClick={handleClick}
          id={props.user?.id}
          call={chatHeadCall}
          me={props.user}
          name={teamStates[props.user.selectedTeam].displayName || props.user.name}
          awaySince={teamStates[props.user.selectedTeam].awaySince}
          idleSince={(new Date()).getTime() / 1000 - idleSince}
          isIdle={idleSince > IDLE_TRESHOLD}
          photo={teamStates[props.user.selectedTeam].photo}
          state={teamStates[props.user.selectedTeam].state || UserAvailabilityState.AVAILABLE}
          statusMessage={teamStates[props.user.selectedTeam].statusMessage}
          hideDetails={
            (call.isConnected || props.user.connecting) &&
            props.layout === layouts.FULL_SCREEN
          }
          timezone={teamStates[props.user.selectedTeam].timezone || 'UTC'}
          style={props.style}
          manualSnap={manualSnap}
          showPin={false}
          snapShotRef={props.snapShotRef}
          isOnPip={props.isOnPip}
        >
          <Countdown
            state={countdownState}
            onDone={onCountdownDone}
            manual={manualSnap}
            delay={3500}
            dotsCount={28}
          />
        </ChatHead>

        <SnapshotInterval
          teams={teams || {}}
          teamStates={teamStates}
          onInterval={onInterval}
          selectedTeam={props.user.selectedTeam}
        />
      </React.Fragment>
    )
  }

  MyChatHead.defaultProps = {
    user: null
  }

  return MyChatHead
}
