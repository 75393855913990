// @ts-ignore
import dotProp from 'dot-prop-immutable'
import merge from 'deepmerge'
import { PayloadAction } from '@reduxjs/toolkit'
import { User } from '../interfaces/user'
import { RootState } from '../store'

export const types = {
  LOADED: 'users/loaded',
  FAILED: 'users/failed',
  UPDATE: 'users/update',
  JOINED: 'users/joined',
  UPDATE_SINGLE: 'users/update-single',
  UPDATE_SINGLE_CALL_AUDIO: 'users/update-single-call-audio',
  UPDATE_SINGLE_CALL_VIDEO: 'users/update-single-call-video',
  UPDATE_PHOTO: 'users/update-photo',
  UPDATE_SINGLE_SUCCESS: 'users/update-single-success',
  UPDATE_SINGLE_FAILED: 'users/update-single-failed',
  SLACK_CONNECT_TEAM: 'users/slack/connect-team'
}

export const initialState = {
  hasLoaded: false,
  byId: {},
  allIds: [],
  hasError: false,
  errorMessage: ''
}

export interface UsersState {
  hasLoaded: boolean;
  // todo: comeback later
  byId: {[key: number]: User};
  // todo: comeback later
  allIds: any[];
  hasError: boolean;
  errorMessage: string;
}

export interface UsersPayloadAction extends PayloadAction<Object> {
  payload: {
    userId: number,
    users: User[],
    user: User,
    // todo: comeback
    data: any,
    // stream
    type: string,
    // todo: comeback
    stream: any,
    // todo: this need to be renamed to `speaking`
    value: boolean,
    enabled: boolean,
    video: boolean,
    audio: boolean
  }
}

export default (state: UsersState = initialState, action: UsersPayloadAction) => {
  switch (action.type) {
    case types.LOADED:
      return {
        ...state,
        hasLoaded: true,
        byId: action.payload.users,
        allIds: Object.keys(action.payload.users)
      }

    case types.FAILED:
      return { ...state, hasLoaded: false, byId: {}, allIds: [] }

    case types.UPDATE:
      const updatedState = { ...state }
      action.payload.users.map((data, id) => {
        updatedState.byId[id] = { ...updatedState.byId[id], ...data }
        return id
      })
      return updatedState

    case types.JOINED:
      state.byId[action.payload.user.id] = action.payload.user
      state.allIds.push(action.payload.user.id)
      return state

    case types.UPDATE_SINGLE:
      return dotProp.set(state, `byId.${action.payload.userId}`, (user: User) => {
        return merge(user, action.payload.data)
      })
    case types.UPDATE_SINGLE_CALL_AUDIO:
      return dotProp.set(state, `byId.${action.payload.userId}`, (user: User) => {
        const prevTeamState = user.teamState
        const res = merge(user.teamState[action.payload.data.teamId], action.payload.data.teamState)
        return {
          ...user,
          teamState: {
            ...prevTeamState,
            [action.payload.data.teamId]: res
          }
        }
      })
    case types.UPDATE_SINGLE_CALL_VIDEO:
      return dotProp.set(state, `byId.${action.payload.userId}`, (user: User) => {
        const prevTeamState = user.teamState
        const res = merge(user.teamState[action.payload.data.teamId], action.payload.data.teamState)
        return {
          ...user,
          teamState: {
            ...prevTeamState,
            [action.payload.data.teamId]: res
          }
        }
      })
    case types.UPDATE_PHOTO:
      return dotProp.set(state, `byId.${action.payload.userId}`, (user: User) => {
        const res = merge(user.teamState, action.payload.data.teamState)
        return {
          ...user,
          teamState: res
        }
      })

    case types.UPDATE_SINGLE_SUCCESS:
      return {
        ...state,
        hasError: false,
        errorMessage: ''
      }

    case types.UPDATE_SINGLE_FAILED:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload
      }
    default:
      return state
  }
}

export const actions = {
  loaded: (users: User[]) => ({ type: types.LOADED, payload: { users } }),
  failed: () => ({ type: types.FAILED }),
  update: (users: User[]) => ({ type: types.UPDATE, payload: { users } }),
  join: (user: User) => ({ type: types.JOINED, payload: { user } }),
  updateSingle: (userId: number, data: any) => ({
    type: types.UPDATE_SINGLE,
    payload: { userId, data }
  }),
  updateSingleCallAudio: (userId: number, teamId: number, audio: boolean) => ({
    type: types.UPDATE_SINGLE_CALL_AUDIO,
    payload: { userId, data: { teamId, teamState: { audio } } }
  }),
  updateSingleCallVideo: (userId: number, teamId: number, video: boolean) => ({
    type: types.UPDATE_SINGLE_CALL_VIDEO,
    payload: { userId, data: { teamId, teamState: { video } } }
  }),
  updatePhoto: (userId: number, data: any) => ({
    type: types.UPDATE_PHOTO,
    payload: { userId, data }
  }),
  slackConnectTeam: (teamId: number) => ({
    type: types.SLACK_CONNECT_TEAM,
    payload: { teamId }
  }),
  updateSingleSuccess: () => {
    return { type: types.UPDATE_SINGLE_SUCCESS, payload: {} }
  },
  updateSingleFailed: (err: any) => {
    // eslint-disable-next-line no-console
    console.log('errr', err)
    let errMessage =
      'We\'re sorry, we\'re unable to submit your request. Please contact our administrator.'
    return { type: types.UPDATE_SINGLE_FAILED, payload: errMessage }
  }
}

// Selectors
export const getUsersById = (state: RootState) => state.entities.users.byId
export const getAllUserIds = (state: RootState) => state.entities.users.allIds
export const getSingleUser = (state: RootState, id: number) => state.entities.users.byId[id]
export const hasLoaded = (state: RootState) => state.entities.users.hasLoaded
