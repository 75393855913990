import React from 'react'
import styled from 'styled-components'
import LoaderV2 from './LoaderV2'

const SectionPage = styled.section`
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
  font: 400 40px/40px 'DM Sans';
  color: #fff;

  &.white {
    background-color: #fff;
    color: #1a1a1a;
  }
`

export default ({ color = true }) => {
  return (
    <SectionPage
      className={`loading-screen ${!color ? 'white' : ''}`}>
      <LoaderV2 />
    </SectionPage>
  )
}
