export const getLayoutName = (layout: number): string => {
  switch (layout) {
    case 1:
      return 'Chat head W/ Other'
    case 2:
      return 'Chat head'
    case 3:
      return 'Fullscreen'
    default:
      return 'Chat head'
  }
}
