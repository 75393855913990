import React from 'react'
import styled, { css } from 'styled-components'

interface StyledSnackbarProps {
  zIndex: number;
  type: string;
}

const StyledSnackbar = styled.div < StyledSnackbarProps > `
  z-index: ${(props) => props.zIndex};
  ${(props) => {
    if (props.type === 'error') {
      return css`
        cursor: pointer;
        border-color: #ff3a3a;
        background-color: #ff3a3a;
      `
    } else if (props.type === 'warning') {
      return css`
        cursor: pointer;
        border-color: #e7ff35;
        background-color: #e7ff35;
        color: #222;
      `
    }
    return css`
      cursor: pointer;
      border-color: #46d84a;
      background-color: #46d84a;
    `
  }}
`

export interface SnackbarProps {
  type: string;
  message: string;
  show: boolean;
  zIndex: number;
  callback?: () => void
}


export const Snackbar: React.FC<SnackbarProps> =
  ({ type = '', message = '', show = false, zIndex = 1,
    callback = () => {} }) => {
    if (show) {
      return (
        <StyledSnackbar
          className={`snackbar snackbar-top`}
          type={type}
          zIndex={zIndex}
          onClick={callback}
          dangerouslySetInnerHTML={{ __html: message }} />
      )
    }
    return null
  }
