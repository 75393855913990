import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { Notification } from '../interfaces/notification'
import {
  deleteNotification,
  deleteOldNotifications,
  fetchNotifications,
  markNotificationsAsRead
} from '../services/api/library/notifications'
import NotificationIcon from '../assets/img/notification.svg'
import { showNotificationText } from '../services/helpers/notificationText'
import moment from 'moment'
import { getSelectedTeamId } from '../reducers/user'
import { actions as notificationActions } from '../reducers/notification'
import { ButtonWithoutBorder, Loader } from '../modules'
import XIcon from '../assets/img/x.svg'
import { getSettings } from '../reducers/settings'

interface ContainerProps {
  expanded: boolean;
}

const Container = styled.section < ContainerProps > `
  background: #1a1a1a;
  box-sizing: border-box;
  min-height: 100%;
  overflow-x: auto;
  position: relative;
  transition: width 200ms ease-in-out;
  user-select: none;
  z-index: 40;
  min-width: 60px;
  padding: 28px 0;

  ${(props) =>
    props.expanded &&
          css`
            overflow-y: auto;
            width: 365px;
          `}
`

const IconList = styled.ul < ContainerProps > `
  margin: 0 0 30px;
  padding: 0;
  white-space: nowrap;

  li {
    display: block;
    list-style: none;
    overflow: hidden;
    position: relative;

    ${(props) =>
    !props.expanded &&
            css`
              width: 100%;
            `}
  }
`

const IconButton = styled.button`
  background: none;
  border: 0;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: bold;
  height: 46px;
  margin: 0;
  position: relative;
  text-align: center;
  vertical-align: center;
  transition: background 200ms ease-in-out;

`

const tooltipOptions = {
  position: 'right',
  // this.props.expanded,
  disabled: true,
  delay: 300,
  hideOnClick: false,
  animation: 'fade',
  sticky: true,
  arrow: true
}

export interface NotificationCountProps {
  notificationCount: number;
  style: React.CSSProperties;
}

export const NotificationCount: React.FC<NotificationCountProps> = (props) => {
  const iconSize = '20px'
  return (
    <div style={{
      position: 'absolute',
      backgroundColor: 'red',
      width: iconSize,
      height: iconSize,
      color: 'white',
      display: 'table-cell',
      textAlign: 'center',
      lineHeight: iconSize,
      borderRadius: '50%',
      marginLeft: '5px',
      ...props.style
    }}>
      {props.notificationCount}
    </div>
  )
}

type NotificationCardProps = {
  notification: Notification,
  callback: () => void
}

const NotificationCard: React.FC<NotificationCardProps> = (props) => {
  const cardColor = props.notification.read ? '#222' : '#000'
  const settings = useSelector((state: RootState) => getSettings(state))
  return (
    <li
      key={props.notification.id}
      style={{
        background: cardColor,
        color: '#fff',
        marginTop: '12px',
        marginRight: '12px',
        marginLeft: '12px',
        paddingTop: '16px',
        paddingLeft: '18px',
        paddingRight: '24px'
      }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        {/* eslint-disable-next-line max-len */}
        <p
          style={{ fontSize: '12px', margin: 0, color: '#BEBEBE', fontWeight: 'bold' }}
        >
          {moment(
            props.notification.created_at, moment.defaultFormat).format(`HH:MM:ss - ${settings.dateFormat}`)}
        </p>
        <ButtonWithoutBorder
          type='button'
          style={{
            color: '#fff',
            fontSize: '14px',
            cursor: 'pointer',
            fontWeight: 'bold'
          }}
          onClick={props.callback}>
          <img
            width={12}
            height={12}
            src={XIcon}
          />
        </ButtonWithoutBorder>
      </div>
      <div style={
        {
          whiteSpace: 'normal',
          paddingBottom: '12px'
        }
      }>
        <span
          style={{
            fontWeight: 'bold'
          }}
        >
          {`${props.notification.sender.name} `}
        </span>
        {showNotificationText(props.notification)}
      </div>
    </li>
  )
}

type NotificationBarProps = {
  onBack: () => void
}

const NotificationBar: React.FC<NotificationBarProps> = ({ onBack }) => {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const selectedTeamId = useSelector((state: RootState) => getSelectedTeamId(state))
  const [loading, setLoading] = useState<boolean>(true)
  const dispatch = useDispatch()
  const back = () => {
    // mark all (unread) fetched notifications as read
    const notificationIds = notifications.filter(notification => {
      return !notification.read
    }).map(notification => {
      return notification.id
    })
    markNotificationsAsRead(selectedTeamId, notificationIds).catch(err => {
      // eslint-disable-next-line no-console
      console.log('error marking notification as read', err)
    })
    dispatch(notificationActions.setNotificationCount(selectedTeamId, 0))
    setNotifications([])
    setLoading(false)
  }
  useEffect(() => {
    setLoading(true)
    // fetch data
    fetchNotifications(selectedTeamId, false).then(res => {
      const { data } = res
      setNotifications(data)
      setLoading(false)
    }).catch(() => {
      return dispatch(notificationActions.updateSnackbar({
        show: true,
        status: 'error',
        message: 'Failed to fetch notification, please try again later.',
        timeout: 3000
      }))
    })
  }, [])
  return (
    <Container expanded={true}>
      <IconList expanded={true}>
        <li
          key='notification-button'
        >
          {/* @ts-ignore */}
          <Tooltip style={{
            display: 'flex'
          }} title='Notification' {...tooltipOptions}>
            <div style={{
              display: 'flex',
              flex: '2',
              justifyContent: 'space-between',
              paddingRight: '12px'
            }}>
              <IconButton style={{ textAlign: 'left', flex: '1' }} onClick={() => {
                back()
                onBack()
              }}>
                <img
                  src={XIcon}
                  style={{

                    marginLeft: '12px'
                  }}
                />
              </IconButton>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <img
                  style={{
                    margin: 'auto auto'
                  }}
                  src={NotificationIcon}
                />
                <p
                  style={{
                    flex: '1',
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#fff',
                    margin: 'auto 0 auto 0'
                  }}>
                  Notifications
                </p>
              </div>
              <IconButton style={{ flex: '1' }} onClick={() => {
                deleteOldNotifications(selectedTeamId).then(() => {
                  setNotifications([])
                }).catch(e => {
                  // eslint-disable-next-line no-console
                  console.log('deleteOldNotifications error: ', e)
                })
              }}>
                <p
                  style={{
                    textDecoration: 'underline',
                    margin: 'auto 0 auto 0',
                    fontWeight: '400', textAlign: 'right', fontSize: '16px'
                  }}
                >
                  Clear All
                </p>
              </IconButton>
            </div>
          </Tooltip>
        </li>
        { loading ? (
          <Loader />
        ) : (
          notifications.length > 0 ? notifications.map(notification => {
            return (
              <NotificationCard
                key={notification.id}
                notification={notification}
                callback={() => {
                  deleteNotification(selectedTeamId, notification.id).then(() => {
                    setNotifications(
                      notifications.filter((n) => {
                        return n.id !== notification.id
                      })
                    )
                  }).catch((e) => {
                    // eslint-disable-next-line no-console
                    console.log('delete-notification error: ', e)
                  })
                }}
              />
            )
          }) : (
            <>
              <p style={{
                color: '#fff',
                width: '80%',
                margin: '50% auto',
                whiteSpace: 'normal'
              }}>
                Currently, there is no one trying to reach you
              </p>
            </>
          )
        )
        }
      </IconList>
    </Container>
  )
}

export default NotificationBar
