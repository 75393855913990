import { NotificationType } from '../../constants/notification'
import { Notification } from '../../interfaces/notification'

/**
 * Returns the appropriate notification text based on the type of notification.
 *
 * @param {Notification} notification - The notification object.
 * @return {string} The notification text.
 */
export const showNotificationText = (notification: Notification): string => {
  switch (notification.type) {
    case NotificationType.KNOCK:
      return `Knocked For You!`
    case NotificationType.CALLED:
      return 'Called You!'
    default:
      return ''
  }
}
