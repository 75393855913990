import { call, all, put, takeLatest, select } from 'redux-saga/effects'
import { Types } from 'ably'
import React from 'react'

import { types, actions, getCall, CallState } from '../reducers/call'
import { actions as notificationActions } from '../reducers/notification'
import {
  getCurrentUser,
  getSelectedTeamId,
  actions as userActions
} from '../reducers/user'
import { getSingleUser } from '../reducers/users'
import logger from '../services/logger'
import { joinRoom, leaveRoom } from '../utils/call'
import { User } from '../interfaces/user'
import { CALL_STATE } from '../constants/call'
import { sendUpdateCallState } from '../services/socket/socket'
import { RealtimeAction } from './realtime/interface'
import { sounds } from '../reducers/notifications'
import { teamActions } from '../reducers/realtime'
import { TrackDetail } from '../contexts/stream'
import { CHANNEL_CLIENT_TEAM } from '../constants/socket'
import { Setting } from '../interfaces/setting'
import { actions as uiActions } from '../reducers/ui'
import { getSettings } from '../reducers/settings'
import { actions as callActions } from '../reducers/call'
import { isElectron } from '../utils/env'

const { log, error } = logger('saga:call')

export function *receiveStart(action: any) {
  const teamId: number = yield select(getSelectedTeamId)
  const currentUser: User = yield select(getCurrentUser)
  const callState: CallState = yield select(getCall)
  const settings: Setting = yield select(getSettings)

  const { clientChannels, setTracks, index, calleeId, callerId } = action.payload
  try {
    const roomName = callState.config.roomFormat.replace('*', index)
    log('joining call')
    yield put(notificationActions.updateSnackbar({
      show: false,
      status: 'info',
      message: ''
    }))

    if (settings.enablePip && isElectron()) {
      yield all([
        // put(uiActions.togglePictureInPicture({ isPictureInPicture: true })),
        // put(userActions.changeLayout(currentUser.selectedTeam, layouts.FULL_SCREEN)),
        put(callActions.updateActiveSpeaker(callerId, true))
      ])
    }

    yield call(joinRoom, {
      calleeId,
      token: callState.config.token,
      roomName: roomName
    }, setTracks, clientChannels, {
      teamId,
      userId: currentUser.id
    }, () => {
      log('joined call: setting callState to in-call')
      sendUpdateCallState(clientChannels[`${CHANNEL_CLIENT_TEAM}:${teamId}`], {
        callRoom: roomName,
        userId: currentUser.id,
        teamId,
        callState: CALL_STATE.IN_CALL
      })
    })
  } catch (e) {
    // eslint-disable-next-line
    console.log('error joining call', e)
    if (process.env.REACT_APP_LOCAL) {
      sendUpdateCallState(clientChannels[`${CHANNEL_CLIENT_TEAM}:${teamId}`], {
        callRoom: null,
        userId: currentUser.id,
        teamId,
        callState: CALL_STATE.NOT_IN_CALL
      })
    }
    yield put(notificationActions.updateSnackbar({
      show: true,
      status: 'error',
      message: 'Unable to join call',
      timeout: 3000
    }))
  }
}

export function *receiveLeaveOrFailed(action: RealtimeAction<{
  userId: number;
  serverChannel: Types.RealtimeChannelCallbacks;
  setTracks?: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>
}>) {
  const { userId } = action.payload
  const teamId: number = yield select(getSelectedTeamId)
  const settings: Setting = yield select(getSettings)
  const currentUser: User = yield select(getCurrentUser)
  const them: User = yield select(getSingleUser, Number(userId))
  if (them.teamState[teamId].callState === CALL_STATE.NOT_IN_CALL) {
    return
  }
  try {
    if (settings.enablePip) {
      yield put(uiActions.togglePictureInPicture({ isPictureInPicture: false }))
    }
    if (currentUser.id === userId) {
      yield all([
        put(notificationActions.updateAudio(sounds.CALL_HANGUP)),
        put(actions.reset()),
        put(userActions.toggleCallMute(false)),
        call(leaveRoom, action.payload.setTracks),
        put(teamActions.receiveUserCallStateChanged({
          userId: Number(userId),
          callState: {
            callState: CALL_STATE.NOT_IN_CALL,
            callRoom: null
          }
        }))
      ])
    } else {
      yield put(teamActions.receiveUserCallStateChanged({
        userId: Number(userId),
        callState: {
          callState: CALL_STATE.NOT_IN_CALL,
          callRoom: null
        }
      }))
    }
  } catch (e: any) {
    error(e)
    yield put(notificationActions.updateSnackbar({
      show: true,
      status: 'error',
      message: 'Error occured when leaving call',
      timeout: 3000
    }))
  }
}

export default function *callWatcher() {
  yield all([
    takeLatest(types.RECEIVE_START, receiveStart),
    takeLatest([types.RECEIVE_LEAVE], receiveLeaveOrFailed)
  ])
}
