import React from 'react'
import styled, { css } from 'styled-components'
import { animations } from './Helpers'
import { ContainerStyled } from './ChatHead'
import { Time } from './Time'
import MuteIcon from '../assets/img/icon.mute.raw.svg'
import { AVAILABILITY_RAW_ICONS } from './icons/constants'

export const MetadataContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  overflow: hidden;
  margin-top: -64px;
  height: 64px;
  width: 100%;
  z-index: 5;
`

export const MessageStyled = styled.div `
  opacity: 1;
  z-index: 5;
  position: absolute;
  ${() => css`
    ${ContainerStyled}:hover & {
      animation: 
        ${animations.marquee} 7s 0.1s infinite linear;
    }
  `}
`

export const NameStyled = styled.div <any> `
  opacity: 1;
  z-index: 5;
  white-space: nowrap;
  ${(props) => props.message?.length > 0 ? css`
    opacity: 0;
    ${ContainerStyled}:hover & {
      animation:
        ${animations.slowFadeout} 2s 0.1s normal linear;
    }
  ` : css `
  `}
`

const NameWrapper = styled.div`
  flex: 6; 
  position: relative; 
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  overflow: hidden;
  z-index: 5;
  margin-right: 2%;
  margin-left: 4px;
`

const Name: React.FC<React.PropsWithChildren<any>> = (props) => {
  return (
    <NameWrapper>
      <MessageStyled className="message-label" {...props}>{props.message}</MessageStyled>
      <NameStyled className="name-label" {...props}>{props.children}</NameStyled>
    </NameWrapper>
  )
}

const MetadataContainer: React.FC<React.PropsWithChildren<any>> = (props) => {
  return (
    <MetadataContainerStyled>{props.children}</MetadataContainerStyled>
  )
}

type MetadataProps = {
  state: string;
  statusMessage: string;
  name: string;
  timezone: string;
  muted?: boolean;
  isSmallChathead: boolean;
}

const Metadata: React.FC<MetadataProps> = (props) => {
  return (
    <MetadataContainer>
      <img
        style={{
          visibility: props.isSmallChathead ? 'hidden' : 'visible',
          color: 'white',
          width: '13.89px',
          height: '15px',
          margin: 'auto 4px auto 11.89px',
          zIndex: 5
        }}
        src={AVAILABILITY_RAW_ICONS[props.state]}
      />
      {props.muted === true ? <img style={{
        zIndex: 2,
        width: '13.89px',
        height: '15px',
        margin: 'auto 4px auto 4px'
      }} src={MuteIcon} /> : ''}
      {
        !props.isSmallChathead && (
          <>
            <Name message={props.statusMessage}>
              {props.name}
            </Name>
            <Time timezone={props.timezone} />
          </>
        )
      }
    </MetadataContainer>
  )
}

export default Metadata
