import { axios, AUTH_API_ENDPOINT, BROADCASTING_API_ENDPOINT, BASE_URL, PUBLIC_BROADCASTING_API_ENDPOINT } from './http'
import { AxiosResponse } from 'axios'
import { LoginApiResponse } from '../../../interfaces/api/authApi'
import { MeApiResponse } from '../../../interfaces/api/userApi'

export async function login(
  params: {email: string, password: string, remember_me: boolean}
): Promise<AxiosResponse<MeApiResponse>> {
  try {
    return await axios.post(`${AUTH_API_ENDPOINT}/login`, params, {
      withCredentials: true
    })
  } catch (error) {
    throw error
  }
}

export async function fetchXsrfToken(): Promise<AxiosResponse> {
  return await axios.get(`${BASE_URL}/sanctum/csrf-cookie`)
}

export async function broadcastingAuth(queryString: string): Promise<AxiosResponse> {
  try {
    return await axios.get(`${BROADCASTING_API_ENDPOINT}/auth?${queryString}`)
  } catch (error) {
    throw error
  }
}

export async function publicBroadcastingAuth(queryString: string): Promise<AxiosResponse> {
  try {
    return await axios.get(`${PUBLIC_BROADCASTING_API_ENDPOINT}/auth?${queryString}`)
  } catch (error) {
    throw error
  }
}


export async function loginSocial(type: string): Promise<AxiosResponse> {
  try {
    return await axios.get(`${AUTH_API_ENDPOINT}/login/${type}`)
  } catch (error) {
    throw error
  }
}

export async function callbackSocial(
  type: string, queryString: string
): Promise<AxiosResponse<LoginApiResponse>> {
  try {
    return await axios.get(
      `${AUTH_API_ENDPOINT}/${type}/callback${queryString}`
    )
  } catch (error) {
    throw error
  }
}

export async function callbackAddSlack(
  queryString: string
): Promise<AxiosResponse<LoginApiResponse>> {
  try {
    return await axios.get(
      `${AUTH_API_ENDPOINT}/app/slack/callback${queryString}`
    )
  } catch (error) {
    throw error
  }
}

export async function logout(): Promise<AxiosResponse> {
  try {
    return await axios.post(`${AUTH_API_ENDPOINT}/logout`)
  } catch (error) {
    throw error
  }
}

export async function forgotPassword(email: string): Promise<AxiosResponse> {
  try {
    return await axios.post(`${AUTH_API_ENDPOINT}/forgot`, {
      email
    })
  } catch (error) {
    throw error
  }
}

export async function resetPassword(
  token: string, email: string, password: string, confirm: string
): Promise<boolean> {
  try {
    await axios.post(`${AUTH_API_ENDPOINT}/reset`, {
      token,
      email,
      password,
      // eslint-disable-next-line camelcase
      password_confirmation: confirm
    })
    return true
  } catch (error) {
    throw error
  }
}

export async function register(
  params: {name: string, displayName: string, passwordConfirmation: string}
): Promise<AxiosResponse> {
  try {
    return await axios.post(`${AUTH_API_ENDPOINT}/register`, {
      // eslint-disable-next-line camelcase
      display_name: params.displayName,
      name: params.name,
      // eslint-disable-next-line camelcase
      password_confirmation: params.passwordConfirmation
    })
  } catch (error) {
    throw error
  }
}

export async function activate(token: string): Promise<AxiosResponse> {
  try {
    return await axios.get(`${AUTH_API_ENDPOINT}/register/activate/${token}`)
  } catch (error) {
    throw error
  }
}
