import React, { useEffect, useState } from 'react'
import ProtectedPage, { ProtectedComponentProps } from '../hocs/ProtectedPage'
import { getRoles, getAllUsers, manageUserTeam } from '../services/api'
import {
  SectionLoading,
  ManagementPage,
  ManagementPanel,
  ManagementPanelInner,
  ManagementPanelRow,
  ManagementPanelColName,
  ManagementPanelColForms,
  ManagementBtnActivated,
  ManagementBtnRole,
  Snackbar,
  ButtonStandard
} from '../modules'
import { canDo } from '../utils/permission'
import { User } from '../interfaces/user'
import { SnackbarProps } from '../modules/Snackbar'
import { Role } from '../interfaces/role'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { getCurrentUser } from '../reducers/user'
import { PERMISSION } from '../constants/permission'
import AccountSettingTitle from '../components/AccountSettingTitle'

interface RemappedRole {
  [key: string]: Role;
}

interface RolesProps {
  roles: RemappedRole|null;
  rkey: number;
  handleClick: (
    (e: React.MouseEvent<HTMLButtonElement>) => (
      (roleId: number, userId: number) => void)
    );
  teamRole: number;
  userID: number;
  disabled: boolean;
}

const Roles = ({
  roles,
  rkey,
  handleClick,
  teamRole,
  userID,
  disabled = false
}: RolesProps) => {
  return roles && Object.keys(roles).map((role) => {
    return (
      <ManagementBtnRole
        selected={teamRole === roles[role].id}
        type='button'
        key={`${rkey}_${roles[role].initials}`}
        onClick={(e) => {
          return teamRole === roles[role].id
            ? () => {
            }
            : handleClick(e)(roles[role].id, userID)
        }}
        disabled={disabled}
        title={roles[role].name}>
        {roles[role].initials.toUpperCase()}
      </ManagementBtnRole>
    )
  })
}

const ManageTeam: React.FC<ProtectedComponentProps> = (props) => {
  const title = 'Manage Team'
  const [roles, setRoles] = useState<RemappedRole | null>(null)
  const [users, setUsers] = useState<User[]>([])
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    show: false,
    type: '',
    message: '',
    zIndex: 9999
  })
  const currentUser = useSelector((state: RootState) => getCurrentUser(state))
  const [onSaving, setOnSaving] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  // eslint-disable-next-line no-undef
  const [ticker, setTicker] = useState<NodeJS.Timeout | null>(null)

  // const handleUpdateGhosting = async (ghosting: boolean, userID: number) => {
  //   const userIndex = users.findIndex((user) => user.id === userID)
  //   const oldRoleID = users[userIndex].team_state.role_id

  //   try {
  //     // eslint-disable-next-line camelcase
  //     users[userIndex].team_state.can_ghost = ghosting
  //     setUsers(users)
  //     setOnSaving(true)
  //     manageUserTeam(Number(props.user.selectedTeam), userID, {
  //       // eslint-disable-next-line camelcase
  //       can_ghost: ghosting
  //     }).then(() => {
  //       setSnackbar({
  //         ...snackbar,
  //         show: true,
  //         type: '',
  //         message: 'Ghosting ability updated'
  //       })
  //     }).catch((error) => {
  //       throw error
  //     })
  //   } catch (error) {
  //     let message = 'Unable to update.'
  //     // eslint-disable-next-line camelcase
  //     users[userIndex].team_state.role_id = oldRoleID
  //     setSnackbar({
  //       ...snackbar,
  //       show: true,
  //       type: 'error',
  //       message
  //     })
  //   }
  // }
  const handleUpdateRole =
    (e: React.MouseEvent<HTMLButtonElement>) => async (roleID: number, userID: number) => {
      e.preventDefault()
      const userIndex = users.findIndex((user) => user.id === userID)
      const oldRoleID = users[userIndex].team_state.role_id

      try {
        // eslint-disable-next-line camelcase
        users[userIndex].team_state.role_id = roleID
        setUsers(users)
        setOnSaving(true)
        manageUserTeam(Number(props.user.selectedTeam), userID, {
          // eslint-disable-next-line camelcase
          role_id: roleID
        }).then(() => {
          setSnackbar({
            ...snackbar,
            show: true,
            type: '',
            message: 'Role has changed'
          })
        })
      } catch (error) {
        let message = 'Unable to update.'
        // eslint-disable-next-line camelcase
        users[userIndex].team_state.role_id = oldRoleID
        setSnackbar({
          ...snackbar,
          show: true,
          type: 'error',
          message
        })
      }
    }

  const handleUpdateActive =
    (e: React.MouseEvent<HTMLButtonElement>) => async (current: string, userID: number) => {
      e.preventDefault()
      const active = current === 'disable' ? 'enable' : 'disable'
      const userIndex = users.findIndex((user) => user.id === userID)
      const oldActive = users[userIndex].team_state.active

      try {
        users[userIndex].team_state.active = active

        setUsers(users)
        setOnSaving(true)
        manageUserTeam(Number(props.user.selectedTeam), userID, {
          active
        }).then(() => {
          setSnackbar({
            ...snackbar,
            show: true,
            type: '',
            message: `User has been ${active}`
          })
        })
      } catch (error) {
        let message = 'Unable to update.'
        users[userIndex].team_state.active = oldActive
        setSnackbar({
          ...snackbar,
          show: true,
          type: 'error',
          message
        })
      }
    }

  useEffect(() => {
    if (snackbar.show) {
      setTicker(setTimeout(() => {
        setOnSaving(false)
        setSnackbar({
          ...snackbar,
          show: false,
          type: '',
          message: ''
        })
      }, 3000))
    }
  }, [snackbar])
  useEffect(() => {
    if (props.user.selectedTeam) {
      getAllUsers(props.user.selectedTeam).then((res) => {
        const { data: usersData } = res.data
        setLoading(false)
        setUsers(usersData)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log('get-all-users error:', err)
      })
    }
  }, [props.user.selectedTeam])
  useEffect(() => {
    getRoles().then(({ data }) => {
      const { data: rolesData } = data
      const reMap = rolesData.reduce((a, b) => {
        return { ...a, [b.id]: b }
      }, {})
      setRoles(reMap)
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log('get-roles error', error)
    })
    document.title = 'HQ Remote | Manage Teams'

    if (props.user.selectedTeam) {
      getAllUsers(props.user.selectedTeam).then(res => {
        const { data: usersData } = res.data
        setLoading(false)
        setUsers(usersData)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log('get-all-users error:', err)
      })
    }
    return () => {
      if (ticker) {
        clearInterval(ticker)
      }
      document.title = 'HQ Remote'
    }
  }, [])
  return (
    <ManagementPage>
      {loading ? (
        <SectionLoading color={false} />
      ) : (
        <ManagementPanel className='management-panel'>
          <ManagementPanelInner className='management-panel__inner'>
            <div className='management-panel__block'>
              <AccountSettingTitle>
                {title}
              </AccountSettingTitle>
              {users &&
                users.map((user) => {
                  // @ts-ignore
                  return (
                    <React.Fragment key={user.id}>
                      <ManagementPanelRow className='users-list'>
                        <ManagementPanelColName>
                          {user.name}
                        </ManagementPanelColName>
                        <ManagementPanelColForms>
                          {props.user.id === user.id ? (
                            <p style={{
                              fontWeight: 500,
                              color: '#9D9D9D'
                            }}>{'(You) '}</p>
                          ) : (
                            <>
                              <ManagementBtnActivated
                                onClick={(e) =>
                                  handleUpdateActive(e)(
                                    user.team_state.active,
                                    user.id
                                  )
                                }
                                disabled={
                                  onSaving || !canDo(currentUser, PERMISSION.MANAGE_TEAM)
                                }>
                                {user.team_state.active === 'disable'
                                  ? 'enable'
                                  : 'disable'}
                              </ManagementBtnActivated>{' '}
                              {/* @ts-ignore */}
                              <Roles
                                userID={user.id}
                                roles={roles}
                                rkey={user.id}
                                teamRole={user.team_state.role_id}
                                handleClick={handleUpdateRole}
                                disabled={onSaving || !canDo(currentUser, PERMISSION.MANAGE_TEAM)}
                              />{' '}
                            </>
                          )}
                          {/* <label htmlFor={`can-ghost-${user.id}`}>
                            Ghosting
                          </label>
                          <input
                            id={`can-ghost-${user.id}`}
                            type='checkbox'
                            disabled={
                              onSaving || !canDo(currentUser, PERMISSION.MANAGE_TEAM)
                            }
                            checked={user.team_state.can_ghost === 1 ||
                              user.team_state.can_ghost === true}
                            onChange={(e) => {
                              e.preventDefault()
                              handleUpdateGhosting(e.target.checked, user.id)
                            }}
                          /> */}
                        </ManagementPanelColForms>
                      </ManagementPanelRow>
                    </React.Fragment>
                  )
                })}
            </div>
            {/* {
              canDo(currentUser, PERMISSION.MANAGE_TEAM) && (
                <div
                  className='form__action form__row'
                  style={{
                    margin: '30px 0 10px'
                  }}>
                  <FormLabel>Team Slack ID</FormLabel>
                  <InputForm
                    type={'text'}
                    placeholder={'YOUR TEAM SLACK ID'}
                  />
                </div>
              )
            } */}
            {
              canDo(currentUser, PERMISSION.INVITE_MEMBER) && (
                <div
                  className='form__action form__row'
                  style={{
                    margin: '30px 0 10px'
                  }}>
                  <ButtonStandard>
                    Invite Team Members
                  </ButtonStandard>
                </div>
              )
            }
          </ManagementPanelInner>
        </ManagementPanel>
      )}
      <Snackbar
        zIndex={9999}
        show={snackbar.show}
        type={snackbar.type}
        message={snackbar.message}
      />
    </ManagementPage>
  )
}

export default ProtectedPage(ManageTeam)
