import { axios, SUBSCRIBE_ENDPOINT } from './http'
import { AxiosResponse } from 'axios'
import { PaymentLog } from '../../../interfaces/paymentLog'
import { PlansApiResponse } from '../../../interfaces/api/paymentApi'

export async function subscription(teamId: number): Promise<AxiosResponse> {
  try {
    return await axios.get(`${SUBSCRIBE_ENDPOINT}/${teamId}`)
  } catch (error) {
    throw error
  }
}

export async function getPlans(): Promise<AxiosResponse<PlansApiResponse>> {
  try {
    return await axios.get(`${SUBSCRIBE_ENDPOINT}/plans`)
  } catch (error) {
    throw error
  }
}

export async function createSubscription(
  teamId: number, data: any
): Promise<AxiosResponse> {
  try {
    return await axios.post(`${SUBSCRIBE_ENDPOINT}/${teamId}`, data)
  } catch (error) {
    throw error
  }
}

export async function updatePricePlan(
  teamId: number, params: any
): Promise<AxiosResponse> {
  try {
    return await axios.put(`${SUBSCRIBE_ENDPOINT}/${teamId}`, params)
  } catch (error) {
    throw error
  }
}

export async function getPaymentLogs(
  teamId: number
): Promise<AxiosResponse<PaymentLog[]>> {
  try {
    return await axios.get(`${SUBSCRIBE_ENDPOINT}/${teamId}/payment-logs`)
  } catch (error) {
    throw error
  }
}

export async function getStripeCustomerPortalUrl(
  teamId: number
): Promise<AxiosResponse<{url: string}>> {
  try {
    return await axios.get(`${SUBSCRIBE_ENDPOINT}/${teamId}/portal`)
  } catch (error) {
    throw error
  }
}
