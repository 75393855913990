export const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone

export const timezones: {[key: string]: string} = {
  'Pacific/Midway': 'GMT-11 Pacific/Midway',
  'Pacific/Niue': 'GMT-11 Pacific/Niue',
  'Pacific/Pago_Pago': 'GMT-11 Pacific/Pago_Pago',
  'Pacific/Honolulu': 'GMT-10 Pacific/Honolulu',
  'Pacific/Johnston': 'GMT-10 Pacific/Johnston',
  'Pacific/Rarotonga': 'GMT-10 Pacific/Rarotonga',
  'Pacific/Tahiti': 'GMT-10 Pacific/Tahiti',
  'Pacific/Marquesas': 'GMT-9.30 Pacific/Marquesas',
  'America/Adak': 'GMT-9 America/Adak',
  'Pacific/Gambier': 'GMT-9 Pacific/Gambier',
  'America/Anchorage': 'GMT-8 America/Anchorage',
  'America/Juneau': 'GMT-8 America/Juneau',
  'America/Metlakatla': 'GMT-8 America/Metlakatla',
  'America/Nome': 'GMT-8 America/Nome',
  'America/Sitka': 'GMT-8 America/Sitka',
  'America/Yakutat': 'GMT-8 America/Yakutat',
  'Pacific/Pitcairn': 'GMT-8 Pacific/Pitcairn',
  'America/Creston': 'GMT-7 America/Creston',
  'America/Dawson': 'GMT-7 America/Dawson',
  'America/Dawson_Creek': 'GMT-7 America/Dawson_Creek',
  'America/Fort_Nelson': 'GMT-7 America/Fort_Nelson',
  'America/Hermosillo': 'GMT-7 America/Hermosillo',
  'America/Los_Angeles': 'GMT-7 America/Los_Angeles',
  'America/Phoenix': 'GMT-7 America/Phoenix',
  'America/Tijuana': 'GMT-7 America/Tijuana',
  'America/Vancouver': 'GMT-7 America/Vancouver',
  'America/Whitehorse': 'GMT-7 America/Whitehorse',
  'America/Belize': 'GMT-6 America/Belize',
  'America/Boise': 'GMT-6 America/Boise',
  'America/Cambridge_Bay': 'GMT-6 America/Cambridge_Bay',
  'America/Chihuahua': 'GMT-6 America/Chihuahua',
  'America/Costa_Rica': 'GMT-6 America/Costa_Rica',
  'America/Denver': 'GMT-6 America/Denver',
  'America/Edmonton': 'GMT-6 America/Edmonton',
  'America/El_Salvador': 'GMT-6 America/El_Salvador',
  'America/Guatemala': 'GMT-6 America/Guatemala',
  'America/Inuvik': 'GMT-6 America/Inuvik',
  'America/Managua': 'GMT-6 America/Managua',
  'America/Mazatlan': 'GMT-6 America/Mazatlan',
  'America/Ojinaga': 'GMT-6 America/Ojinaga',
  'America/Regina': 'GMT-6 America/Regina',
  'America/Swift_Current': 'GMT-6 America/Swift_Current',
  'America/Tegucigalpa': 'GMT-6 America/Tegucigalpa',
  'America/Yellowknife': 'GMT-6 America/Yellowknife',
  'Pacific/Galapagos': 'GMT-6 Pacific/Galapagos',
  'America/Atikokan': 'GMT-5 America/Atikokan',
  'America/Bahia_Banderas': 'GMT-5 America/Bahia_Banderas',
  'America/Bogota': 'GMT-5 America/Bogota',
  'America/Cancun': 'GMT-5 America/Cancun',
  'America/Cayman': 'GMT-5 America/Cayman',
  'America/Chicago': 'GMT-5 America/Chicago',
  'America/Eirunepe': 'GMT-5 America/Eirunepe',
  'America/Guayaquil': 'GMT-5 America/Guayaquil',
  'America/Indiana/Knox': 'GMT-5 America/Indiana/Knox',
  'America/Indiana/Tell_City': 'GMT-5 America/Indiana/tell_City',
  'America/Jamaica': 'GMT-5 America/Jamaica',
  'America/Lima': 'GMT-5 America/Lima',
  'America/Matamoros': 'GMT-5 America/Matamoros',
  'America/Menominee': 'GMT-5 America/Menominee',
  'America/Merida': 'GMT-5 America/Merida',
  'America/Mexico_City': 'GMT-5 America/Mexico_City',
  'America/Monterrey': 'GMT-5 America/Monterrey',
  'America/North_Dakota/Beulah': 'GMT-5 America/North_dakota/beulah',
  'America/North_Dakota/Center': 'GMT-5 America/North_dakota/center',
  'America/North_Dakota/New_Salem': 'GMT-5 America/North_dakota/new_salem',
  'America/Panama': 'GMT-5 America/Panama',
  'America/Port-au-Prince': 'GMT-5 America/Port-au-Prince',
  'America/Rainy_River': 'GMT-5 America/Rainy_River',
  'America/Rankin_Inlet': 'GMT-5 America/Rankin_Inlet',
  'America/Resolute': 'GMT-5 America/Resolute',
  'America/Rio_Branco': 'GMT-5 America/Rio_Branco',
  'America/Winnipeg': 'GMT-5 America/Winnipeg',
  'Pacific/Easter': 'GMT-5 Pacific/Easter',
  'America/Anguilla': 'GMT-4 America/Anguilla',
  'America/Antigua': 'GMT-4 America/Antigua',
  'America/Aruba': 'GMT-4 America/Aruba',
  'America/Asuncion': 'GMT-4 America/Asuncion',
  'America/Barbados': 'GMT-4 America/Barbados',
  'America/Blanc-Sablon': 'GMT-4 America/Blanc-Sablon',
  'America/Boa_Vista': 'GMT-4 America/Boa_Vista',
  'America/Campo_Grande': 'GMT-4 America/Campo_Grande',
  'America/Caracas': 'GMT-4 America/Caracas',
  'America/Cuiaba': 'GMT-4 America/Cuiaba',
  'America/Curacao': 'GMT-4 America/Curacao',
  'America/Detroit': 'GMT-4 America/Detroit',
  'America/Dominica': 'GMT-4 America/Dominica',
  'America/Grand_Turk': 'GMT-4 America/Grand_Turk',
  'America/Grenada': 'GMT-4 America/Grenada',
  'America/Guadeloupe': 'GMT-4 America/Guadeloupe',
  'America/Guyana': 'GMT-4 America/Guyana',
  'America/Havana': 'GMT-4 America/Havana',
  'America/Indiana/Indianapolis': 'GMT-4 America/Indiana/indianapolIs',
  'America/Indiana/Marengo': 'GMT-4 America/Indiana/Marengo',
  'America/Indiana/Petersburg': 'GMT-4 America/Indiana/petersburg',
  'America/Indiana/Vevay': 'GMT-4 America/Indiana/Vevay',
  'America/Indiana/Vincennes': 'GMT-4 America/Indiana/vincennes',
  'America/Indiana/Winamac': 'GMT-4 America/Indiana/Winamac',
  'America/Iqaluit': 'GMT-4 America/Iqaluit',
  'America/Kentucky/Louisville': 'GMT-4 America/Kentucky/louisville',
  'America/Kentucky/Monticello': 'GMT-4 America/Kentucky/monticello',
  'America/Kralendijk': 'GMT-4 America/Kralendijk',
  'America/La_Paz': 'GMT-4 America/La_Paz',
  'America/Lower_Princes': 'GMT-4 America/Lower_Princes',
  'America/Manaus': 'GMT-4 America/Manaus',
  'America/Marigot': 'GMT-4 America/Marigot',
  'America/Martinique': 'GMT-4 America/Martinique',
  'America/Montserrat': 'GMT-4 America/Montserrat',
  'America/Nassau': 'GMT-4 America/Nassau',
  'America/New_York': 'GMT-4 America/New_York',
  'America/Nipigon': 'GMT-4 America/Nipigon',
  'America/Pangnirtung': 'GMT-4 America/Pangnirtung',
  'America/Port_of_Spain': 'GMT-4 America/Port_of_Spain',
  'America/Porto_Velho': 'GMT-4 America/Porto_Velho',
  'America/Puerto_Rico': 'GMT-4 America/Puerto_Rico',
  'America/Santo_Domingo': 'GMT-4 America/Santo_Domingo',
  'America/St_Barthelemy': 'GMT-4 America/St_Barthelemy',
  'America/St_Kitts': 'GMT-4 America/St_Kitts',
  'America/St_Lucia': 'GMT-4 America/St_Lucia',
  'America/St_Thomas': 'GMT-4 America/St_Thomas',
  'America/St_Vincent': 'GMT-4 America/St_Vincent',
  'America/Thunder_Bay': 'GMT-4 America/Thunder_Bay',
  'America/Toronto': 'GMT-4 America/Toronto',
  'America/Tortola': 'GMT-4 America/Tortola',
  'America/Araguaina': 'GMT-3 America/Araguaina',
  'America/Argentina/Buenos_Aires': 'GMT-3 America/Argentina/buenos_aires',
  'America/Argentina/Catamarca': 'GMT-3 America/Argentina/catamarca',
  'America/Argentina/Cordoba': 'GMT-3 America/Argentina/cordoba',
  'America/Argentina/Jujuy': 'GMT-3 America/Argentina/jujuy',
  'America/Argentina/La_Rioja': 'GMT-3 America/Argentina/la_rioja',
  'America/Argentina/Mendoza': 'GMT-3 America/Argentina/mendoza',
  'America/Argentina/Rio_Gallegos': 'GMT-3 America/Argentina/rio_GaLlegos',
  'America/Argentina/Salta': 'GMT-3 America/Argentina/salta',
  'America/Argentina/San_Juan': 'GMT-3 America/Argentina/san_juan',
  'America/Argentina/San_Luis': 'GMT-3 America/Argentina/san_luis',
  'America/Argentina/Tucuman': 'GMT-3 America/Argentina/tucuman',
  'America/Argentina/Ushuaia': 'GMT-3 America/Argentina/ushuaia',
  'America/Bahia': 'GMT-3 America/Bahia',
  'America/Belem': 'GMT-3 America/Belem',
  'America/Cayenne': 'GMT-3 America/Cayenne',
  'America/Fortaleza': 'GMT-3 America/Fortaleza',
  'America/Glace_Bay': 'GMT-3 America/Glace_Bay',
  'America/Goose_Bay': 'GMT-3 America/Goose_Bay',
  'America/Halifax': 'GMT-3 America/Halifax',
  'America/Maceio': 'GMT-3 America/Maceio',
  'America/Moncton': 'GMT-3 America/Moncton',
  'America/Montevideo': 'GMT-3 America/Montevideo',
  'America/Paramaribo': 'GMT-3 America/Paramaribo',
  'America/Recife': 'GMT-3 America/Recife',
  'America/Santarem': 'GMT-3 America/Santarem',
  'America/Santiago': 'GMT-3 America/Santiago',
  'America/Sao_Paulo': 'GMT-3 America/Sao_Paulo',
  'America/Thule': 'GMT-3 America/Thule',
  'Antarctica/Palmer': 'GMT-3 Antarctica/Palmer',
  'Antarctica/Rothera': 'GMT-3 Antarctica/Rothera',
  'Atlantic/Bermuda': 'GMT-3 Atlantic/Bermuda',
  'Atlantic/Stanley': 'GMT-3 Atlantic/Stanley',
  'America/St_Johns': 'GMT-2.30 America/St_Johns',
  'America/Godthab': 'GMT-2 America/Godthab',
  'America/Miquelon': 'GMT-2 America/Miquelon',
  'America/Noronha': 'GMT-2 America/Noronha',
  'Atlantic/South_Georgia': 'GMT-2 Atlantic/South_Georgia',
  'Atlantic/Cape_Verde': 'GMT-1 Atlantic/Cape_Verde',
  'Africa/Abidjan': 'GMT Africa/Abidjan',
  'Africa/Accra': 'GMT Africa/Accra',
  'Africa/Bamako': 'GMT Africa/Bamako',
  'Africa/Banjul': 'GMT Africa/Banjul',
  'Africa/Bissau': 'GMT Africa/Bissau',
  'Africa/Conakry': 'GMT Africa/Conakry',
  'Africa/Dakar': 'GMT Africa/Dakar',
  'Africa/Freetown': 'GMT Africa/Freetown',
  'Africa/Lome': 'GMT Africa/Lome',
  'Africa/Monrovia': 'GMT Africa/Monrovia',
  'Africa/Nouakchott': 'GMT Africa/Nouakchott',
  'Africa/Ouagadougou': 'GMT Africa/Ouagadougou',
  'Africa/Sao_Tome': 'GMT Africa/Sao_Tome',
  'America/Danmarkshavn': 'GMT America/Danmarkshavn',
  'America/Scoresbysund': 'GMT America/Scoresbysund',
  'Atlantic/Azores': 'GMT Atlantic/Azores',
  'Atlantic/Reykjavik': 'GMT Atlantic/Reykjavik',
  'Atlantic/St_Helena': 'GMT Atlantic/St_Helena',
  'UTC': 'GMT UTC',
  'Africa/Algiers': 'GMT+1 Africa/Algiers',
  'Africa/Bangui': 'GMT+1 Africa/Bangui',
  'Africa/Brazzaville': 'GMT+1 Africa/Brazzaville',
  'Africa/Casablanca': 'GMT+1 Africa/Casablanca',
  'Africa/Douala': 'GMT+1 Africa/Douala',
  'Africa/El_Aaiun': 'GMT+1 Africa/El_Aaiun',
  'Africa/Kinshasa': 'GMT+1 Africa/Kinshasa',
  'Africa/Lagos': 'GMT+1 Africa/Lagos',
  'Africa/Libreville': 'GMT+1 Africa/Libreville',
  'Africa/Luanda': 'GMT+1 Africa/Luanda',
  'Africa/Malabo': 'GMT+1 Africa/Malabo',
  'Africa/Ndjamena': 'GMT+1 Africa/Ndjamena',
  'Africa/Niamey': 'GMT+1 Africa/Niamey',
  'Africa/Porto-Novo': 'GMT+1 Africa/Porto-Novo',
  'Africa/Tunis': 'GMT+1 Africa/Tunis',
  'Atlantic/Canary': 'GMT+1 Atlantic/Canary',
  'Atlantic/Faroe': 'GMT+1 Atlantic/Faroe',
  'Atlantic/Madeira': 'GMT+1 Atlantic/Madeira',
  'Europe/Dublin': 'GMT+1 Europe/Dublin',
  'Europe/Guernsey': 'GMT+1 Europe/Guernsey',
  'Europe/Isle_of_Man': 'GMT+1 Europe/Isle_of_Man',
  'Europe/Jersey': 'GMT+1 Europe/Jersey',
  'Europe/Lisbon': 'GMT+1 Europe/Lisbon',
  'Europe/London': 'GMT+1 Europe/London',
  'Africa/Blantyre': 'GMT+2 Africa/Blantyre',
  'Africa/Bujumbura': 'GMT+2 Africa/Bujumbura',
  'Africa/Cairo': 'GMT+2 Africa/Cairo',
  'Africa/Ceuta': 'GMT+2 Africa/Ceuta',
  'Africa/Gaborone': 'GMT+2 Africa/Gaborone',
  'Africa/Harare': 'GMT+2 Africa/Harare',
  'Africa/Johannesburg': 'GMT+2 Africa/Johannesburg',
  'Africa/Kigali': 'GMT+2 Africa/Kigali',
  'Africa/Lubumbashi': 'GMT+2 Africa/Lubumbashi',
  'Africa/Lusaka': 'GMT+2 Africa/Lusaka',
  'Africa/Maputo': 'GMT+2 Africa/Maputo',
  'Africa/Maseru': 'GMT+2 Africa/Maseru',
  'Africa/Mbabane': 'GMT+2 Africa/Mbabane',
  'Africa/Tripoli': 'GMT+2 Africa/Tripoli',
  'Africa/Windhoek': 'GMT+2 Africa/Windhoek',
  'Antarctica/Troll': 'GMT+2 Antarctica/Troll',
  'Arctic/Longyearbyen': 'GMT+2 Arctic/Longyearbyen',
  'Europe/Amsterdam': 'GMT+2 Europe/Amsterdam',
  'Europe/Andorra': 'GMT+2 Europe/Andorra',
  'Europe/Belgrade': 'GMT+2 Europe/Belgrade',
  'Europe/Berlin': 'GMT+2 Europe/Berlin',
  'Europe/Bratislava': 'GMT+2 Europe/Bratislava',
  'Europe/Brussels': 'GMT+2 Europe/Brussels',
  'Europe/Budapest': 'GMT+2 Europe/Budapest',
  'Europe/Busingen': 'GMT+2 Europe/Busingen',
  'Europe/Copenhagen': 'GMT+2 Europe/Copenhagen',
  'Europe/Gibraltar': 'GMT+2 Europe/Gibraltar',
  'Europe/Kaliningrad': 'GMT+2 Europe/Kaliningrad',
  'Europe/Ljubljana': 'GMT+2 Europe/Ljubljana',
  'Europe/Luxembourg': 'GMT+2 Europe/Luxembourg',
  'Europe/Madrid': 'GMT+2 Europe/Madrid',
  'Europe/Malta': 'GMT+2 Europe/Malta',
  'Europe/Monaco': 'GMT+2 Europe/Monaco',
  'Europe/Oslo': 'GMT+2 Europe/Oslo',
  'Europe/Paris': 'GMT+2 Europe/Paris',
  'Europe/Podgorica': 'GMT+2 Europe/Podgorica',
  'Europe/Prague': 'GMT+2 Europe/Prague',
  'Europe/Rome': 'GMT+2 Europe/Rome',
  'Europe/San_Marino': 'GMT+2 Europe/San_Marino',
  'Europe/Sarajevo': 'GMT+2 Europe/Sarajevo',
  'Europe/Skopje': 'GMT+2 Europe/Skopje',
  'Europe/Stockholm': 'GMT+2 Europe/Stockholm',
  'Europe/Tirane': 'GMT+2 Europe/Tirane',
  'Europe/Vaduz': 'GMT+2 Europe/Vaduz',
  'Europe/Vatican': 'GMT+2 Europe/Vatican',
  'Europe/Vienna': 'GMT+2 Europe/Vienna',
  'Europe/Warsaw': 'GMT+2 Europe/Warsaw',
  'Europe/Zagreb': 'GMT+2 Europe/Zagreb',
  'Europe/Zurich': 'GMT+2 Europe/Zurich',
  'Africa/Addis_Ababa': 'GMT+3 Africa/Addis_Ababa',
  'Africa/Asmara': 'GMT+3 Africa/Asmara',
  'Africa/Dar_es_Salaam': 'GMT+3 Africa/Dar_es_Salaam',
  'Africa/Djibouti': 'GMT+3 Africa/Djibouti',
  'Africa/Juba': 'GMT+3 Africa/Juba',
  'Africa/Kampala': 'GMT+3 Africa/Kampala',
  'Africa/Khartoum': 'GMT+3 Africa/Khartoum',
  'Africa/Mogadishu': 'GMT+3 Africa/Mogadishu',
  'Africa/Nairobi': 'GMT+3 Africa/Nairobi',
  'Antarctica/Syowa': 'GMT+3 Antarctica/Syowa',
  'Asia/Aden': 'GMT+3 Asia/Aden',
  'Asia/Amman': 'GMT+3 Asia/Amman',
  'Asia/Baghdad': 'GMT+3 Asia/Baghdad',
  'Asia/Bahrain': 'GMT+3 Asia/Bahrain',
  'Asia/Beirut': 'GMT+3 Asia/Beirut',
  'Asia/Damascus': 'GMT+3 Asia/Damascus',
  'Asia/Gaza': 'GMT+3 Asia/Gaza',
  'Asia/Hebron': 'GMT+3 Asia/Hebron',
  'Asia/Jerusalem': 'GMT+3 Asia/Jerusalem',
  'Asia/Kuwait': 'GMT+3 Asia/Kuwait',
  'Asia/Nicosia': 'GMT+3 Asia/Nicosia',
  'Asia/Qatar': 'GMT+3 Asia/Qatar',
  'Asia/Riyadh': 'GMT+3 Asia/Riyadh',
  'Europe/Athens': 'GMT+3 Europe/Athens',
  'Europe/Bucharest': 'GMT+3 Europe/Bucharest',
  'Europe/Chisinau': 'GMT+3 Europe/Chisinau',
  'Europe/Helsinki': 'GMT+3 Europe/Helsinki',
  'Europe/Istanbul': 'GMT+3 Europe/Istanbul',
  'Europe/Kiev': 'GMT+3 Europe/Kiev',
  'Europe/Kirov': 'GMT+3 Europe/Kirov',
  'Europe/Mariehamn': 'GMT+3 Europe/Mariehamn',
  'Europe/Minsk': 'GMT+3 Europe/Minsk',
  'Europe/Moscow': 'GMT+3 Europe/Moscow',
  'Europe/Riga': 'GMT+3 Europe/Riga',
  'Europe/Simferopol': 'GMT+3 Europe/Simferopol',
  'Europe/Sofia': 'GMT+3 Europe/Sofia',
  'Europe/Tallinn': 'GMT+3 Europe/Tallinn',
  'Europe/Uzhgorod': 'GMT+3 Europe/Uzhgorod',
  'Europe/Vilnius': 'GMT+3 Europe/Vilnius',
  'Europe/Volgograd': 'GMT+3 Europe/Volgograd',
  'Europe/Zaporozhye': 'GMT+3 Europe/Zaporozhye',
  'Indian/Antananarivo': 'GMT+3 Indian/Antananarivo',
  'Indian/Comoro': 'GMT+3 Indian/Comoro',
  'Indian/Mayotte': 'GMT+3 Indian/Mayotte',
  'Asia/Baku': 'GMT+4 Asia/Baku',
  'Asia/Dubai': 'GMT+4 Asia/Dubai',
  'Asia/Muscat': 'GMT+4 Asia/Muscat',
  'Asia/Tbilisi': 'GMT+4 Asia/Tbilisi',
  'Asia/Yerevan': 'GMT+4 Asia/Yerevan',
  'Europe/Astrakhan': 'GMT+4 Europe/Astrakhan',
  'Europe/Samara': 'GMT+4 Europe/Samara',
  'Europe/Ulyanovsk': 'GMT+4 Europe/Ulyanovsk',
  'Indian/Mahe': 'GMT+4 Indian/Mahe',
  'Indian/Mauritius': 'GMT+4 Indian/Mauritius',
  'Indian/Reunion': 'GMT+4 Indian/Reunion',
  'Asia/Kabul': 'GMT+4.30 Asia/Kabul',
  'Asia/Tehran': 'GMT+4.30 Asia/Tehran',
  'Antarctica/Mawson': 'GMT+5 Antarctica/Mawson',
  'Asia/Aqtau': 'GMT+5 Asia/Aqtau',
  'Asia/Aqtobe': 'GMT+5 Asia/Aqtobe',
  'Asia/Ashgabat': 'GMT+5 Asia/Ashgabat',
  'Asia/Dushanbe': 'GMT+5 Asia/Dushanbe',
  'Asia/Karachi': 'GMT+5 Asia/Karachi',
  'Asia/Oral': 'GMT+5 Asia/Oral',
  'Asia/Samarkand': 'GMT+5 Asia/Samarkand',
  'Asia/Tashkent': 'GMT+5 Asia/Tashkent',
  'Asia/Yekaterinburg': 'GMT+5 Asia/Yekaterinburg',
  'Indian/Kerguelen': 'GMT+5 Indian/Kerguelen',
  'Indian/Maldives': 'GMT+5 Indian/Maldives',
  'Asia/Colombo': 'GMT+5.30 Asia/Colombo',
  'Asia/Kolkata': 'GMT+5.30 Asia/Kolkata',
  'Asia/Kathmandu': 'GMT+5.45 Asia/Kathmandu',
  'Antarctica/Vostok': 'GMT+6 Antarctica/Vostok',
  'Asia/Almaty': 'GMT+6 Asia/Almaty',
  'Asia/Bishkek': 'GMT+6 Asia/Bishkek',
  'Asia/Dhaka': 'GMT+6 Asia/Dhaka',
  'Asia/Omsk': 'GMT+6 Asia/Omsk',
  'Asia/Qyzylorda': 'GMT+6 Asia/Qyzylorda',
  'Asia/Thimphu': 'GMT+6 Asia/Thimphu',
  'Asia/Urumqi': 'GMT+6 Asia/Urumqi',
  'Indian/Chagos': 'GMT+6 Indian/Chagos',
  'Asia/Rangoon': 'GMT+6.30 Asia/Rangoon',
  'Indian/Cocos': 'GMT+6.30 Indian/Cocos',
  'Antarctica/Davis': 'GMT+7 Antarctica/Davis',
  'Asia/Bangkok': 'GMT+7 Asia/Bangkok',
  'Asia/Barnaul': 'GMT+7 Asia/Barnaul',
  'Asia/Ho_Chi_Minh': 'GMT+7 Asia/Ho_Chi_Minh',
  'Asia/Jakarta': 'GMT+7 Asia/Jakarta',
  'Asia/Krasnoyarsk': 'GMT+7 Asia/Krasnoyarsk',
  'Asia/Novokuznetsk': 'GMT+7 Asia/Novokuznetsk',
  'Asia/Novosibirsk': 'GMT+7 Asia/Novosibirsk',
  'Asia/Phnom_Penh': 'GMT+7 Asia/Phnom_Penh',
  'Asia/Pontianak': 'GMT+7 Asia/Pontianak',
  'Asia/Tomsk': 'GMT+7 Asia/Tomsk',
  'Asia/Vientiane': 'GMT+7 Asia/Vientiane',
  'Indian/Christmas': 'GMT+7 Indian/Christmas',
  'Antarctica/Casey': 'GMT+8 Antarctica/Casey',
  'Asia/Brunei': 'GMT+8 Asia/Brunei',
  'Asia/Hong_Kong': 'GMT+8 Asia/Hong_Kong',
  'Asia/Hovd': 'GMT+8 Asia/Hovd',
  'Asia/Irkutsk': 'GMT+8 Asia/Irkutsk',
  'Asia/Kuala_Lumpur': 'GMT+8 Asia/Kuala_Lumpur',
  'Asia/Kuching': 'GMT+8 Asia/Kuching',
  'Asia/Macau': 'GMT+8 Asia/Macau',
  'Asia/Makassar': 'GMT+8 Asia/Makassar',
  'Asia/Manila': 'GMT+8 Asia/Manila',
  'Asia/Shanghai': 'GMT+8 Asia/Shanghai',
  'Asia/Singapore': 'GMT+8 Asia/Singapore',
  'Asia/Taipei': 'GMT+8 Asia/Taipei',
  'Australia/Perth': 'GMT+8 Australia/Perth',
  'Asia/Pyongyang': 'GMT+8.30 Asia/Pyongyang',
  'Australia/Eucla': 'GMT+8.45 Australia/Eucla',
  'Asia/Chita': 'GMT+9 Asia/Chita',
  'Asia/Choibalsan': 'GMT+9 Asia/Choibalsan',
  'Asia/Dili': 'GMT+9 Asia/Dili',
  'Asia/Jayapura': 'GMT+9 Asia/Jayapura',
  'Asia/Khandyga': 'GMT+9 Asia/Khandyga',
  'Asia/Seoul': 'GMT+9 Asia/Seoul',
  'Asia/Tokyo': 'GMT+9 Asia/Tokyo',
  'Asia/Ulaanbaatar': 'GMT+9 Asia/Ulaanbaatar',
  'Asia/Yakutsk': 'GMT+9 Asia/Yakutsk',
  'Pacific/Palau': 'GMT+9 Pacific/Palau',
  'Australia/Adelaide': 'GMT+9.30 Australia/Adelaide',
  'Australia/Broken_Hill': 'GMT+9.30 Australia/Broken_hill',
  'Australia/Darwin': 'GMT+9.30 Australia/Darwin',
  'Antarctica/DumontDUrville': 'GMT+10 Antarctica/Dumontdurville',
  'Asia/Ust-Nera': 'GMT+10 Asia/Ust-Nera',
  'Asia/Vladivostok': 'GMT+10 Asia/Vladivostok',
  'Australia/Brisbane': 'GMT+10 Australia/Brisbane',
  'Australia/Currie': 'GMT+10 Australia/Currie',
  'Australia/Hobart': 'GMT+10 Australia/Hobart',
  'Australia/Lindeman': 'GMT+10 Australia/Lindeman',
  'Australia/Melbourne': 'GMT+10 Australia/Melbourne',
  'Australia/Sydney': 'GMT+10 Australia/Sydney',
  'Pacific/Chuuk': 'GMT+10 Pacific/Chuuk',
  'Pacific/Guam': 'GMT+10 Pacific/Guam',
  'Pacific/Port_Moresby': 'GMT+10 Pacific/Port_Moresby',
  'Pacific/Saipan': 'GMT+10 Pacific/Saipan',
  'Australia/Lord_Howe': 'GMT+10.30 Australia/Lord_Howe',
  'Antarctica/Macquarie': 'GMT+11 Antarctica/Macquarie',
  'Asia/Magadan': 'GMT+11 Asia/Magadan',
  'Asia/Sakhalin': 'GMT+11 Asia/Sakhalin',
  'Asia/Srednekolymsk': 'GMT+11 Asia/Srednekolymsk',
  'Pacific/Bougainville': 'GMT+11 Pacific/Bougainville',
  'Pacific/Efate': 'GMT+11 Pacific/Efate',
  'Pacific/Guadalcanal': 'GMT+11 Pacific/Guadalcanal',
  'Pacific/Kosrae': 'GMT+11 Pacific/Kosrae',
  'Pacific/Norfolk': 'GMT+11 Pacific/Norfolk',
  'Pacific/Noumea': 'GMT+11 Pacific/Noumea',
  'Pacific/Pohnpei': 'GMT+11 Pacific/Pohnpei',
  'Antarctica/McMurdo': 'GMT+12 Antarctica/McMurdo',
  'Asia/Anadyr': 'GMT+12 Asia/Anadyr',
  'Asia/Kamchatka': 'GMT+12 Asia/Kamchatka',
  'Pacific/Auckland': 'GMT+12 Pacific/Auckland',
  'Pacific/Fiji': 'GMT+12 Pacific/Fiji',
  'Pacific/Funafuti': 'GMT+12 Pacific/Funafuti',
  'Pacific/Kwajalein': 'GMT+12 Pacific/Kwajalein',
  'Pacific/Majuro': 'GMT+12 Pacific/Majuro',
  'Pacific/Nauru': 'GMT+12 Pacific/Nauru',
  'Pacific/Tarawa': 'GMT+12 Pacific/Tarawa',
  'Pacific/Wake': 'GMT+12 Pacific/Wake',
  'Pacific/Wallis': 'GMT+12 Pacific/Wallis',
  'Pacific/Chatham': 'GMT+12.45 Pacific/Chatham',
  'Pacific/Apia': 'GMT+13 Pacific/Apia',
  'Pacific/Enderbury': 'GMT+13 Pacific/Enderbury',
  'Pacific/Fakaofo': 'GMT+13 Pacific/Fakaofo',
  'Pacific/Tongatapu': 'GMT+13 Pacific/Tongatapu',
  'Pacific/Kiritimati': 'GMT+14 Pacific/Kiritimati'
}
