import styled, { css } from 'styled-components'
import { Label } from './Helpers'
import React from 'react'

interface StatusOverlayStyledProps {
  isAway: boolean;
  isIdle: boolean;
}

/* eslint-disable indent */
const StatusOverlayStyled = styled.div < StatusOverlayStyledProps > `
  background: ${(props) => {
  if (props.isAway) {
    return css`rgba(0,0,0,0.8)`
  }

  if (props.isIdle) {
    return css`rgba(0,0,0,0.4)`
  }
  return css`transparent`
}};

  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: background 200ms ease-in-out;
  width: 100%;
  background-size: cover;
  }

  ${Label} {
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`

const StatusOverlay: React.FC<React.PropsWithChildren<any>> = (props) => {
  return (
    <StatusOverlayStyled {...props}>
      {props.children}
    </StatusOverlayStyled>
  )
}

export default StatusOverlay
