import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { actions as sessionActions } from '../reducers/session'
import { actions as uiActions, getAccountSideBarExpanded } from '../reducers/ui'
import { NavLink as RLink } from 'react-router-dom'
import { routes } from '../utils'
import { RootState } from '../store'
import { actions as subscriptionActions } from '../reducers/subscription'
import { getCurrentUser } from '../reducers/user'
import { getTeamsById } from '../reducers/teams'
import { canDo } from '../utils/permission'
import { PERMISSION } from '../constants/permission'

interface ContainerProps {
  expanded: boolean;
}

const Container = styled.section < ContainerProps > `
  background: #3264FF;
  box-sizing: border-box;
  min-height: 100%;
  overflow: hidden;
  overflow-x: auto;
  position: relative;
  transition: width 200ms ease-in-out;
  user-select: none;
  z-index: 40;
  width: 60px;
  padding: 40px 10px;

  ${(props) =>
    props.expanded &&
    css`
      overflow-y: auto;
      width: 389px;
      padding: 40px;
    `}
`

const LinksList = styled.ul < ContainerProps > `
  margin: 0 0 40px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: left;
  transition: opacity 200ms ease-in-out;
  white-space: nowrap;
  width: 0;
  padding-bottom: 30px;
  ${(props) =>
    props.expanded &&
    css`
      opacity: 1;
      width: auto;
    `}

  li {
    display: block;
    list-style: none;
  }
`

const Link = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 24px;
  height: 36px;
  letter-spacing: 0.16px;
  line-height: 36px;
  text-align: left;
  text-decoration: none;
  transition: background 200ms ease-in-out;
  width: 100%;
  padding: 1px 7px 2px;
  font-weight: 400;

  &:hover,
  &:active {
    font-weight: 700;
  }
`

const StyledRlink = styled(RLink)`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 24px;
  height: 36px;
  letter-spacing: 0.16px;
  line-height: 36px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  padding: 1px 7px 2px;
  font-weight: 400;
  &:visited {
    font-weight: 700;
    color: #fff;
  }

  &:hover,
  &.active {
    color: #fff;
    font-weight: 700;
  }
`

interface SubMenuProps {
  active: boolean;
}

const SubMenu = styled.ul < SubMenuProps > `
  display: none;
  padding: 0 0 0 16px;
  ${(props) =>
    props.active &&
    css`
      display: block;
    `}
`

const AccountSidebar = () => {
  const [windowWidth, setWindowWidth] = useState<number>(0)
  const [isManageTeam, setIsManageTeam] = useState<boolean>(false)
  const [isBilling, setIsBilling] = useState<boolean>(false)
  const expanded = useSelector((state: RootState) => getAccountSideBarExpanded(state))

  const currentUser = useSelector((state: RootState) => getCurrentUser(state))
  const teamsById = useSelector((state: RootState) => getTeamsById(state))
  const dispatch = useDispatch()
  const checkIsManageTeam = () => {
    if (window.location.pathname.includes(routes.teamManage)) {
      setIsManageTeam(true)
    }
    if (windowWidth <= 1023) {
      dispatch(uiActions.collapseAccountSideBar())
    } else {
      dispatch(uiActions.expandAccountSideBar())
    }
  }

  // const handleTeamChange = (event: ChangeEvent<HTMLSelectElement>) => {
  //   const { currentTarget: input } = event
  //   if (input.value) {
  //     dispatch(userActions.switchTeam(Number(input.value)))
  //   }
  // }

  const checkIsSubscription = () => {
    if (
      window.location.pathname.includes(routes.billing) &&
      !window.location.pathname.includes(routes.billingUpgrade)
    ) {
      setIsBilling(true)
    }
  }
  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  // const onManageClick = () => {
  //   if (currentUser && subscription.status === 'active') {
  //     getStripeCustomerPortalUrl(currentUser.selectedTeam).then(res => {
  //       const { url } = res.data
  //       if (url) {
  //         window.open(url)
  //       }
  //     }).catch(err => {
  //       // eslint-disable-next-line no-console
  //       console.log('error getting stripe portal url', err)
  //     })
  //   }
  // }

  useEffect(() => {
    setTimeout(() => {
      try {
        window.dispatchEvent(new Event('resize'))
      } catch (e) {
        // todo: notify error
      }
    }, 200)
  }, [])
  useEffect(() => {
    if (currentUser && currentUser.selectedTeam) {
      dispatch(subscriptionActions.init())
    }
  }, [currentUser])
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    checkIsManageTeam()
    checkIsSubscription()
    return (() => {
      window.removeEventListener('resize', handleResize)
    })
  }, [windowWidth])
  return (
    <Container expanded={expanded}>
      <LinksList expanded={expanded}>
        { currentUser && (
          <li>
            {currentUser.selectedTeam && (
              <div style={{
                color: '#fff',
                fontFamily: 'DM Sans',
                fontWeight: 700,
                fontSize: 28
              }}>
                {teamsById[currentUser.selectedTeam]?.name}
              </div>
            )}
            {/* <div className='skinned-wrapper' style={{
              color: '#fff'
            }}> */}
            {/* <select
                name='team'
                onChange={handleTeamChange}
                value={currentUser.selectedTeam || ''}>
                {
                  Object.keys(currentUser.teamState).map((key) => {
                    return (
                      <option value={key} key={key}>
                        {key} | {teamsById[Number(key)]?.name}
                      </option>
                    )
                  })}
                <option value={''}>
                  Select Team
                </option>
              </select>
            </div> */}

          </li>
        )}
      </LinksList>
      <LinksList expanded={expanded} style={{
        marginTop: '60px'
      }}>
        <li>
          <StyledRlink exact to={routes.home}>
            Launch App
          </StyledRlink>
        </li>
      </LinksList>
      <LinksList expanded={expanded}>
        <li>
          <StyledRlink exact to={routes.account}>
            Account
          </StyledRlink>
        </li>
        {
          canDo(currentUser, PERMISSION.MANAGE_TEAM) && (
            <li>
              <StyledRlink to={routes.teamManage}>Manage Team</StyledRlink>
              <SubMenu className='sub-menu' active={isManageTeam}>

                <li>
                  <StyledRlink exact to={routes.teamEdit}>
                    Settings
                  </StyledRlink>
                </li>
                <li>
                  <StyledRlink exact to={routes.teamChangeLogs}>
                    Changes log
                  </StyledRlink>
                </li>
              </SubMenu>
            </li>
          )
        }
        {
          canDo(currentUser, PERMISSION.MANAGE_BILLING) && (
            <>
              <li>
                <StyledRlink
                  to={routes.billing}
                  className={!isBilling ? 'upgrade' : ''}>
                  Subscription
                </StyledRlink>
                <SubMenu className='sub-menu' active={isBilling}>
                  <li>
                    <StyledRlink exact to={routes.billingUpgrade}>
                      Payment Method
                    </StyledRlink>
                  </li>
                  <li>
                    <StyledRlink exact to={routes.billingHistory}>
                      History
                    </StyledRlink>
                  </li>
                </SubMenu>
              </li>
            </>
          )
        }
        {
          canDo(currentUser, PERMISSION.INVITE_MEMBER) && (
            <li>
              <StyledRlink exact to={routes.invitation}>
                Invitations
              </StyledRlink>
            </li>
          )
        }
      </LinksList>
      <LinksList expanded={expanded}>
        <li>
          <Link
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_APP_URL}/support`,
                '_blank'
              )
            }>
            Support
          </Link>
        </li>
        <li>
          <Link
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_APP_URL}/getting-started`,
                '_blank'
              )
            }>
            Getting Started
          </Link>
        </li>
        <li>
          <Link
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_APP_URL}/terms-of-service`,
                '_blank'
              )
            }>
            Terms of Service
          </Link>
        </li>
        <li>
          <Link
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_APP_URL}/privacy-policy`,
                '_blank'
              )
            }>
            Privacy Policy
          </Link>
        </li>
      </LinksList>
      <LinksList expanded={expanded}>
        <li>
          <Link onClick={() => {
            dispatch(sessionActions.logout())
          }}>Logout</Link>
        </li>
      </LinksList>
    </Container>
  )
}

export default AccountSidebar
