import { createGlobalStyle } from 'styled-components'

import './App.css'

import { fontFamily } from './components/Helpers'

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'DM Sans';
  }

  @font-face {
    font-family: 'DM Sans Bold';
  }
  @font-face {
    font-family: 'Gotham';
    src: url(./assets/fonts/gotham-medium.otf);
  }

  body {
    font-family: ${fontFamily.normal};
  }

  input,
  button,
  select,
  textarea {
    font-family: ${fontFamily.normal};
  }
`
