import * as call from './call'
import * as team from './team'
import * as user from './user'

export const callActions = call.actions
export const callTypes = call.types
export const teamActions = team.actions
export const teamTypes = team.types
export const userActions = user.actions
export const userTypes = user.types
