import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import styled, { StyleSheetManager } from 'styled-components'
import { GlobalStyles } from '../styles'
import MoveIcon from '../assets/img/icon.move.svg'

const DragBarStyled = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: move;
  background-color: rgb(51, 100, 252);
  border-radius: 0 0 8px 0;
  z-index: 999;
  app-region: drag;
  --webkit-app-region: drag;
`

const DragBar = () => {
  return (
    <DragBarStyled>
      <img
        src={MoveIcon}
        alt='Move'
        style={{ width: '20px', padding: '6px' }}
      />
    </DragBarStyled>
  )
}

const PictureInPicture = (
  props: PropsWithChildren<{ onClose(value: boolean): void }>
) => {
  const [container] = useState<HTMLElement>(document.createElement('div'))
  container.setAttribute('id', 'pip-container')
  const win = window.open('', 'PIP')
  win?.document.body.style.setProperty('margin', '0')
  win?.document.body.style.setProperty('font-family', 'gotham')
  const onBeforeUnloaded = useCallback(
    (e: BeforeUnloadEvent) => {
      props.onClose(false)
      win?.window.close()
      e.preventDefault()
    },
    [win]
  )

  const onResize = useCallback(async (e: UIEvent) => {
    e.preventDefault()
    let windowState: Object = await window.ipcRender.invoke(
      'get-window-state',
      'pip'
    )
    const { outerHeight, outerWidth, screenX, screenY } = win
      ? win
      : { outerHeight: 0, outerWidth: 0, screenX: 0, screenY: 0 }
    windowState = {
      ...windowState,
      height: outerHeight,
      width: outerWidth,
      x: screenX,
      y: screenY
    }
    window.ipcRender.invoke('set-window-state', {
      windowName: 'pip',
      windowState
    })
  }, [])

  const onResetWindowState = async (payload: any) => {
    win?.resizeTo(payload.width, payload.height)
    win?.moveTo(payload.x, payload.y)
  }

  useEffect(() => {
    if (container && win) {
      win.document.body.appendChild(container)
      win.addEventListener('beforeunload', onBeforeUnloaded)
      win.addEventListener('resize', onResize)
      return () => {
        win.removeEventListener('beforeunload', onBeforeUnloaded)
        win.removeEventListener('resize', onResize)
      }
    }
    return () => {}
  }, [container, win, onBeforeUnloaded, onResize])

  useEffect(() => {
    window.ipcRender.on('reset-window-state', onResetWindowState)
    return () => {
      window.ipcRender.off('reset-window-state', onResetWindowState)
    }
  }, [onResetWindowState])

  useEffect(
    () => () => {
      const { outerHeight, outerWidth, screenX, screenY } = win
        ? win
        : { outerHeight: 0, outerWidth: 0, screenX: 0, screenY: 0 }
      const windowState = {
        height: outerHeight,
        width: outerWidth,
        x: screenX,
        y: screenY
      }
      window.ipcRender
        .invoke('set-window-state', {
          windowName: 'pip',
          windowState
        })
        .then(() => {
          win?.window.close()
        })
    },
    []
  )

  return createPortal(
    <StyleSheetManager target={win?.document.head}>
      <>
        <GlobalStyles />
        <DragBar />
        <div style={{ position: 'relative' }}>{props.children}</div>
      </>
    </StyleSheetManager>,
    container
  )
}

export default PictureInPicture
