import { axios, AUTH_STORAGE } from './api/library/http'

export function removeAuthData(): void {
  localStorage.removeItem(AUTH_STORAGE)
  axios.defaults.headers.common = {}
}

export function setAuthData(authData: any): void {
  // eslint-disable-next-line no-console
  console.log('auth', authData)
  window.localStorage.setItem('auth', JSON.stringify(authData))
}
