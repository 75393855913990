import { sepiaB, sepiaG, sepiaR, WATERMARK_IMG_DATA } from './constant'

export function processBlackAndWhite(
  canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D | null
) {
  // @ts-ignore
  let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
  for (let i = 0, n = imgData.data.length; i < n; i = i + 4) {
    const pixel = Math.min(
      imgData.data[i],
      imgData.data[i + 1],
      imgData.data[i + 2]
    )
    imgData.data[i] = pixel
    imgData.data[i + 1] = pixel
    imgData.data[i + 2] = pixel
  }
  // @ts-ignore
  ctx.putImageData(imgData, 0, 0)
}

export function processGrayScale(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D | null) {
  // @ts-ignore
  let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
  for (let i = 0, n = imgData.data.length; i < n; i = i + 4) {
    const pixel = imgData.data[i]
    imgData.data[i] = pixel
    imgData.data[i + 1] = pixel
    imgData.data[i + 2] = pixel
  }
  // @ts-ignore
  ctx.putImageData(imgData, 0, 0)
}

export function processSepia(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D | null) {
  // @ts-ignore
  let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
  for (let i = 0, n = imageData.data.length; i < n; i = i + 4) {
    imageData.data[i] = sepiaR[imageData.data[i]]
    imageData.data[i + 1] = sepiaG[imageData.data[i + 1]]
    imageData.data[i + 2] = sepiaB[imageData.data[i + 2]]
  }
  // @ts-ignore
  ctx.putImageData(imageData, 0, 0)
}

export const getWatermarkImageFunction = () => {
  let element: HTMLImageElement|boolean = false

  return () => {
    return new Promise((resolve, reject) => {
      try {
        if (!element) {
          let img:HTMLImageElement = document.createElement('img')

          img.onload = function () {
            element = img
            resolve(element)
          }

          img.setAttribute('src', WATERMARK_IMG_DATA)
          return
        }
      } catch (e) {
        reject(e)
      }

      resolve(element)
    })
  }
}
