export default class Middleware {
  constructor() {}

  use(fn: any) {
    this.go = ((stack) => (...args) => stack(...args.slice(0, -1), () => {
      let next = args[args.length - 1]
      fn.apply(this, [
        ...args.slice(0, -1),
        next.bind.apply(next, [
          null,
          ...args.slice(0, -1)
        ])
      ])
    }))(this.go)
  }

  go(...args: any) {
    let next = args[args.length - 1]
    next.apply(this, args.slice(0, -1))
  }
}

