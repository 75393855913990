import { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface ChatheadNotificationDetail {
  show: boolean,
  status: string,
  message: string,
  fromUserId: number,
  toUserId: number,
  timeout?: number,
  callback?: () => void
}


export interface ChatheadNotificationState {
  detail: ChatheadNotificationDetail,
}

// Action types
export const types = {
  UPDATE_CHATHEAD_NOTIFICATION: 'notification/update-chathead-notification'
}

// Initial state
export const initialState = {
  detail: {
    show: false,
    status: '',
    message: '',
    fromUserId: 0,
    toUserId: 0
  }
}

export interface ChatheadNotificationPayloadAction extends PayloadAction<Object> {
  payload: {
    detail: ChatheadNotificationDetail,
    teamId: number
  }
}

// Action Handler
export default (
  state: ChatheadNotificationState = initialState, action: ChatheadNotificationPayloadAction):
  ChatheadNotificationState => {
  switch (action.type) {
    case types.UPDATE_CHATHEAD_NOTIFICATION:
      return { ...state, detail: action.payload.detail }
    default:
      return state
  }
}

// Actions
export const actions = {
  updateChatheadNotification: (detail: ChatheadNotificationDetail) =>
    ({ type: types.UPDATE_CHATHEAD_NOTIFICATION, payload: { detail } })
}

// Selectors
export const getChatheadNotification = (state: RootState) => state.chatheadNotification.detail
