import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import { getCurrentUser } from '../reducers/user'
import { types, actions, SubscriptionPayloadAction } from '../reducers/subscription'
import { subscription, updatePricePlan } from '../services/api'
import { normalize } from '../schema/subscription'
import logger from '../services/logger'
import { User } from '../interfaces/user'
import { getPlans } from '../services/api/library/subscribe'

const { error } = logger('saga:subscription')

export function *fetchInitSubscription() {
  try {
    const user: User = yield select(getCurrentUser)
    const { data } = yield call(subscription, user.selectedTeam)
    const { data: planData } = yield call(getPlans)
    const normalized = normalize(data)
    yield put(actions.loaded({
      plans: normalized,
      availablePlans: planData
    }))
  } catch (err) {
    // eslint-disable-next-line no-catch-shadow, no-console
    console.log('fetch-init-subscription error: ', err)
  }
}

export function *updatingPricePlan(action: SubscriptionPayloadAction) {
  const { teamId, currency, period } = action.payload
  try {
    const user: User = yield select(getCurrentUser)

    if (user.selectedTeam !== teamId) {
      throw new Error('Current team is invalid!')
    }

    yield call(updatePricePlan, user.selectedTeam, {
      currency,
      period
    })
    yield all([
      put(actions.updatedPricePlan({
        currency,
        period
      })),
      put(
        actions.actionNotification({
          type: 'success',
          message: 'Your price plan has been changed!'
        })
      )
    ])
  } catch (err) {
    yield all([
      put(
        actions.actionNotification({
          type: 'error',
          message: 'error updating price plan'
        })
      )
    ])
    error(err)
  }
}

export default function *subscriptionWatcher() {
  yield all([
    takeLatest(types.INIT_REQUEST, fetchInitSubscription),
    takeEvery(types.UPDATE_PRICE_PLAN, updatingPricePlan)
  ])
}
