/* eslint-disable max-len */
import {
  all,
  put,
  select,
  takeLatest,
  throttle
} from 'redux-saga/effects'
import { actions as callActions } from '../../reducers/call'

import { getSelectedTeamId, getCurrentUser, actions as userActions } from '../../reducers/user'
import { actions as usersActions, getSingleUser } from '../../reducers/users'
import {
  actions as teamsActions,
  getSingleTeam,
  getSingleOffice
} from '../../reducers/teams'
import {
  actions as notificationsActions,
  sounds as notificationSounds,
  bannerTypes as notificationBannerTypes,
  displayTypes as notificationDisplay, sounds
} from '../../reducers/notifications'

import {
  actions as notificationActions
} from '../../reducers/notification'
import {
  actions as chatheadNotificationActions
} from '../../reducers/chatheadNotification'
import { types } from '../../reducers/realtime/team'
import { buildTeamState } from '../../utils/state'
import { UserAvailabilityState } from '../../constants/user'
import { RealtimeAction } from './interface'
import { User, UserCallState } from '../../interfaces/user'
import { Office, Team } from '../../interfaces/team'
import { CALL_STATE } from '../../constants/call'
import { store } from '../../store'
import { getSettings } from '../../reducers/settings'
import { Types } from 'ably'
import {
  sendTeamBusyKnock,
  sendUserPhotoChanged,
  sendUserStatusMessageChanged,
  sendUserStateChanged as socketSendUserStateChanged,
  sendUserIdleChanged as socketSendUserIdleChanged,
  sendUserProfileChanged as socketSendUserProfileChanged
} from '../../services/socket/socket'
import { mapLocalCalls } from '../../utils/call'
import { teamActions } from '../../reducers/realtime'
import { actions as teamLocalActions } from '../../reducers/teams'
import { MessageTeamUpdated } from '../../reducers/realtime/interfaces/team'
import { reloadHome } from '../../utils/routes'
import { CHANNEL_CLIENT_PRESENCE } from '../../constants/socket'

export var BUSY_KNOCK_TIMEOUT = 5000 // eslint-disable-line no-var

export function *sendUserIdleChanged(action: RealtimeAction<{
  idle: boolean;
  serverChannel: Types.RealtimeChannelCallbacks;
}>) {
  const { idle, serverChannel } = action.payload

  const teamId: number = yield select(getSelectedTeamId)
  const currentUser: User = yield select(getCurrentUser)
  socketSendUserIdleChanged(serverChannel, {
    teamId,
    userId: currentUser.id,
    idle
  })
}

export function *receiveUserIdleChanged(action: RealtimeAction<{
  userId: number,
  idle: boolean,
  timestamp: number,
  teamId: number
}>) {
  const { userId, idle, timestamp, teamId } = action.payload
  const user: User = yield select(getSingleUser, userId)
  if (!user) {
    return
  }

  const updatedState: {[key: string]: any} = {
    teamState: {}
  }

  updatedState.teamState[teamId] = {
    idle,
    idleSince: idle ? timestamp / 1000 : null
  }
  yield put(
    usersActions.updateSingle(userId, updatedState)
  )
}

export function *sendUserStateChanged(action: RealtimeAction<{
  state: UserAvailabilityState,
  teamId: number,
  serverChannel: Types.RealtimeChannelCallbacks
}>) {
  const { teamId, state, serverChannel } = action.payload

  const currentUser: User = yield select(getCurrentUser)
  socketSendUserStateChanged(
    serverChannel,
    {
      teamId,
      userId: currentUser.id,
      state
    }
  )
}

export function *receiveUserStateChanged(action: RealtimeAction<{
  userId: number,
  teamId: number,
  state: UserAvailabilityState,
  timestamp: number
}>) {
  const { userId, teamId, state, timestamp } = action.payload

  const user: User = yield select(getSingleUser, userId)
  if (!user) {
    return
  }

  const updatedState: {[key: string]: any} = {
    teamState: {}
  }

  updatedState.teamState[teamId] = {
    awaySince: null,
    state: state
  }

  if (state === UserAvailabilityState.AWAY) {
    updatedState.teamState[teamId].awaySince = timestamp / 1000
  }

  yield put(usersActions.updateSingle(userId, updatedState))
}

export function *sendUserPhotoUpdated(action: RealtimeAction<{
  photo: string,
  serverChannel: Types.RealtimeChannelCallbacks
}>) {
  const { photo, serverChannel } = action.payload

  const currentUser: User = yield select(getCurrentUser)
  const teamId: number = yield select(getSelectedTeamId)
  sendUserPhotoChanged(
    serverChannel,
    {
      teamId,
      userId: currentUser.id,
      photo: `${photo}`
    }
  )
}

export function *sendBusyKnock(action: RealtimeAction<{
  userId: number;
  serverChannel: Types.RealtimeChannelCallbacks;
}>) {
  const { userId, serverChannel } = action.payload

  const currentUser: User = yield select(getCurrentUser)
  const teamId: number = yield select(getSelectedTeamId)

  const toUser: User = yield select(getSingleUser, userId)
  if (!toUser) {
    return
  }

  try {
    sendTeamBusyKnock(serverChannel, {
      teamId,
      senderId: currentUser.id,
      recipientId: toUser.id
    })
    yield all([
      put(
        notificationActions.updateSnackbar(
          {
            show: true,
            message: `Knocking for ${toUser.name}`,
            status: 'warning',
            timeout: 3000
          })),
      put(
        notificationActions.updateAudio(sounds.KNOCKING)
      )
    ])
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error sending busy knock', error)
  }
}

export function *receiveBusyKnock(action: RealtimeAction<{
  recipientId: number,
  senderId: number,
  teamId: number
}>) {
  const { recipientId, senderId, teamId } = action.payload
  const fromUser: User = yield select(getSingleUser, senderId)
  if (!fromUser) {
    return
  }

  const currentUser: User = yield select(getCurrentUser)
  if (currentUser.id !== recipientId ||
    [UserAvailabilityState.BUSY, UserAvailabilityState.GHOSTING]
      .indexOf(currentUser.teamState[teamId].state) < 0
  ) {
    return
  }
  let message = 'Knocking for you...'
  // todo:andy-shi get rid of this log after notification bubble is implemented
  const options = {
    display: notificationDisplay.BOTH,
    type: notificationBannerTypes.ALERT,
    sound: notificationSounds.KNOCKING,
    timeout: BUSY_KNOCK_TIMEOUT
  }
  let notificationCallback = () => {
    // @ts-ignore
    store.dispatch(notificationActions.updateSnackbar({
      show: false,
      status: '',
      message: '',
      timeout: 0
    }))
  }
  if (teamId !== currentUser.selectedTeam) {
    notificationCallback = () => {
      store.dispatch(userActions.switchTeam(teamId))
    }
    message = message + ' (click here to switch to the team)'
  }

  yield all([
    put(notificationActions.incrementNotificationCount(teamId)),
    teamId !== currentUser.selectedTeam ?
      put(
        notificationActions.updateSnackbar(
          {
            show: true,
            message: fromUser.name + ' is trying to get a hold of you',
            status: 'warning',
            timeout: teamId !== currentUser.selectedTeam ? 5000 : 3000,
            callback: notificationCallback
          })) : put(chatheadNotificationActions.updateChatheadNotification({
        show: true,
        message,
        status: 'warning',
        timeout: teamId !== currentUser.selectedTeam ? 5000 : 3000,
        toUserId: currentUser.id,
        fromUserId: fromUser.id,
        callback: notificationCallback
      })),
    put(
      notificationActions.updateAudio(sounds.KNOCKING)
    ),
    put(notificationsActions.create(message, options))
  ])
}

export function *usersUpdated(action: RealtimeAction<{
  users: User[]
}>) {
  const { users } = action.payload

  yield put(usersActions.update(users))
}

export function *userJoined(action: RealtimeAction<{
  teamId: number,
  officeId: number,
  user: User
}>) {
  const { teamId, officeId, user } = action.payload
  yield put(usersActions.join(user))
  yield put(teamsActions.userJoined({
    teamId, officeId, userId: user.id
  }))
}

export function *teamUpdated(action: RealtimeAction<{
  data: MessageTeamUpdated
}>) {
  const { data } = action.payload
  // eslint-disable-next-line
  console.log('team-updated data', data)
  yield put(teamLocalActions.updateSingle(data.teamId, {
    permissions: data.payload
  }))
}

export function *notifyDuplicateSession(action: RealtimeAction<{
  userId: number,
  clientChannels: {[key: string]: Types.RealtimeChannelCallbacks},
}>) {
  const { userId, clientChannels } = action.payload
  const currentUser: User = yield select(getCurrentUser)
  const teamId: Number = yield select(getSelectedTeamId)
  if (userId === currentUser.id) {
    // eslint-disable-next-line
    console.log('clientchannels', clientChannels[`${CHANNEL_CLIENT_PRESENCE}:${teamId}`].presence)
    clientChannels[`${CHANNEL_CLIENT_PRESENCE}:${teamId}`].presence.leave()
    clientChannels[`${CHANNEL_CLIENT_PRESENCE}:${teamId}`].unsubscribe()
    yield put(
      notificationActions.updateSnackbar({
        show: true,
        message: `This HQ Remote session has ended as you logged in at another location.  To restart this session, click here.`,
        status: 'warning',
        callback: () => {
          reloadHome(false)
        }
      }))
  }
}

export function *receiveUserEnteredTeam(action: RealtimeAction<{
  userId: number,
  teamId: number,
  timestamp: number
}>) {
  const { userId, teamId, timestamp } = action.payload
  const { users, onlineUsers } = yield select(getSingleTeam, teamId)
  const { notificationSounds: notificationSoundOn } = yield select(getSettings)
  const currentUser: User = yield select(getCurrentUser)
  if (users.includes(userId) && !onlineUsers.includes(userId)) {
    yield put(teamsActions.userEntered(teamId, userId))
    if (currentUser.id !== userId) {
      if (notificationSoundOn) {
        yield put(notificationActions.updateAudio(sounds.MEMBER_SIGN_ON))
      }
      yield put(usersActions.updateSingle(
        userId,
        { id: userId, onlineSince: timestamp }))
      return
    }

    const teamState = buildTeamState(
      String(teamId),
      { callRoom: null, callState: CALL_STATE.NOT_IN_CALL }
    )
    yield put(userActions.updateCallState({
      teamId,
      teamState,
      callRoom: null,
      callState: CALL_STATE.NOT_IN_CALL
    }))
  }
}


export function *loadUserEnteredTeam(action: RealtimeAction<{
  userId: number,
  teamId: number,
  timestamp: number
}>) {
  const { userId, teamId, timestamp } = action.payload
  const { users, onlineUsers } = yield select(getSingleTeam, teamId)
  const currentUser: User = yield select(getCurrentUser)
  if (users.includes(userId) && !onlineUsers.includes(userId)) {
    const teamState = buildTeamState(
      String(teamId),
      { callRoom: null, callState: CALL_STATE.NOT_IN_CALL }
    )
    yield all([
      put(teamsActions.userEntered(teamId, userId)),
      put(notificationActions.updateAudio(sounds.MEMBER_SIGN_ON))
    ])
    if (currentUser.id === userId) {
      yield put(usersActions.updateSingle(
        userId,
        { id: userId, onlineSince: timestamp, teamState }))
    }
  }
}

export function *receiveUserLeftTeam(action: RealtimeAction<{
  userId: number,
  teamId: number,
  serverChannel: Types.RealtimeChannelCallbacks,
}>) {
  const { userId, teamId, serverChannel } = action.payload
  const { users, onlineUsers } = yield select(getSingleTeam, teamId)
  const user: User = yield select(getSingleUser, userId)

  const userTeamState = user.teamState[teamId]
  if (userTeamState.callState === CALL_STATE.IN_CALL) {
    yield put(callActions.receiveLeave(userId, serverChannel))
    yield put(teamActions.receiveUserCallStateChanged({
      userId,
      callState: {
        callState: CALL_STATE.NOT_IN_CALL,
        callRoom: null
      }
    }))
  }
  if (users.includes(userId) && onlineUsers.includes(userId)) {
    yield all([
      put(teamsActions.userLeft(teamId, userId))
    ])
  }
}

export function *sendUserEnteredOffice(action: RealtimeAction<{
  teamId: number,
  officeId: number
}>) {
  const { teamId, officeId } = action.payload
  const currentUser: User = yield select(getCurrentUser)
  // const channelName = 'presence:' + teamId

  let teamState = buildTeamState(String(teamId), { selectedOfficeId: officeId })
  yield put(usersActions.updateSingle(currentUser.id, { teamState }))
  // yield call(
  //   publish,
  //   channelName,
  //   REALTIME_MESSAGE.TEAM.USER.CHANGE_OFFICE,
  //   JSON.stringify({
  //     userId: currentUser.id,
  //     teamId,
  //     officeId
  //   }),
  //   () => {}
  // )
}

export function *receiveUserEnteredOffice(action: RealtimeAction<{
  userId: number,
  teamId: number,
  officeId: number
}>) {
  const { userId, teamId, officeId } = action.payload
  const currentUser: User = yield select(getCurrentUser)
  if (String(userId) === String(currentUser.id)) {
    return
  }

  const office: Office = yield select(getSingleOffice, teamId, officeId)
  if (!office) {
    return
  }

  yield put(teamsActions.userSwitchoffice(userId, teamId, officeId))
}

export function *sendUserProfileChanged(action: RealtimeAction<{
  timezone: string,
  displayName: string,
  serverChannel: Types.RealtimeChannelCallbacks
}>) {
  const { timezone, displayName, serverChannel } = action.payload
  const currentUser: User = yield select(getCurrentUser)
  const teamId = currentUser.selectedTeam
  socketSendUserProfileChanged(serverChannel, {
    userId: currentUser.id,
    teamId,
    timezone,
    displayName
  })
}

export function *receiveUserProfileChanged(action: RealtimeAction<{
  userId: number,
  timezone: string,
  displayName: string
}>) {
  const { userId, timezone, displayName } = action.payload

  const teamId: number = yield select(getSelectedTeamId)
  const user: User = yield select(getSingleUser, userId)
  if (!user) {
    return
  }
  const state = buildTeamState(String(teamId), { timezone, displayName })
  yield put(usersActions.updateSingle(userId, { teamState: { ...state } }))
}

export function *sendUserStatusChanged(action: RealtimeAction<{
  message: string,
  serverChannel: Types.RealtimeChannelCallbacks
}>) {
  const { message, serverChannel } = action.payload

  const currentUser: User = yield select(getCurrentUser)
  const teamId = currentUser.selectedTeam
  sendUserStatusMessageChanged(serverChannel, {
    teamId,
    userId: currentUser.id,
    statusMessage: message
  })
}

export function *receiveUserStatusChanged(action: RealtimeAction<{
  userId: number,
  statusMessage: string
}>) {
  const { userId, statusMessage } = action.payload

  const teamId: number = yield select(getSelectedTeamId)
  const user: User = yield select(getSingleUser, userId)
  if (!user) {
    return
  }
  const state = buildTeamState(String(teamId), { statusMessage })
  yield put(usersActions.updateSingle(userId, { teamState: { ...state } }))
}

export function *sendUserCallStateChanged(action: RealtimeAction<{
  callState: UserCallState
}>) {
  const { callState } = action.payload

  const currentUser: User = yield select(getCurrentUser)
  const teamId = currentUser.selectedTeam
  const channelName = 'presence:' + teamId
  // eslint-disable-next-line no-console
  console.log('call-state', callState, channelName)

  // yield call(
  //   publish,
  //   channelName,
  //   REALTIME_MESSAGE.TEAM.USER.CHANGE_CALL,
  //   JSON.stringify({
  //     userId: currentUser.id,
  //     callState
  //   }),
  //   () => {}
  // )
}

export function *receiveUserCallStateChanged(action: RealtimeAction<{
  userId: number,
  callState: UserCallState
}>) {
  const { userId, callState } = action.payload
  const currentUser: User = yield select(getCurrentUser)
  const teamId = currentUser.selectedTeam
  const user: User = yield select(getSingleUser, userId)
  if (!user) {
    return
  }
  const state = buildTeamState(String(teamId), { ...callState })
  const currentTeamState: Team = yield select(getSingleTeam, teamId)
  let newCalls = currentTeamState.calls
  if (
    callState.callState === CALL_STATE.IN_CALL ||
    callState.callState === CALL_STATE.NOT_IN_CALL
  ) {
    newCalls = mapLocalCalls(userId, callState, newCalls)
  }
  yield put(teamsActions.updateLocalTeamSetting(teamId,
    { calls: newCalls }
  ))
  const isMe = currentUser.id === userId
  if (
    (isMe && callState.callState !== currentUser.callState) ||
    currentUser.id !== userId
  ) {
    yield put(usersActions.updateSingle(userId,
      { teamState: { ...state } }))
  }
  if (
    !isMe && callState.callState === CALL_STATE.IN_CALL &&
    callState.callRoom === currentUser.teamState[teamId].callRoom
  ) {
    yield put(notificationActions.updateAudio(sounds.CALL_INCOMING))
  }
  if (isMe) {
    yield put(userActions.updateCallState({
      teamId: teamId,
      ...callState
    }))
  }
}

export function *receiveUserPhotoUpdated(action: RealtimeAction<{
  userId: number,
  photo: string
}>) {
  const { userId, photo } = action.payload

  const teamId: number = yield select(getSelectedTeamId)
  const user: User = yield select(getSingleUser, userId)
  if (!user) {
    return
  }
  const state = buildTeamState(String(teamId), { photo })
  yield put(usersActions.updatePhoto(userId, { teamState: { ...state } }))
}

export function *receiveUserCalled(action: RealtimeAction<{
  recipientId: number,
  senderId: number,
  teamId: number
}>) {
  const { senderId, recipientId, teamId } = action.payload
  const fromUser: User = yield select(getSingleUser, senderId)
  if (!fromUser) {
    return
  }

  const currentUser: User = yield select(getCurrentUser)
  if (recipientId !== currentUser.id) {
    return
  }

  yield put(notificationActions.incrementNotificationCount(teamId))
}

export default function *realtimeTeamWatcher() {
  yield all([
    takeLatest(types.SEND_USER_IDLE_CHANGED, sendUserIdleChanged),
    takeLatest(types.SEND_USER_STATUS_CHANGED, sendUserStatusChanged),
    takeLatest(types.SEND_USER_PROFILE_CHANGED, sendUserProfileChanged),
    takeLatest(types.RECEIVE_USER_IDLE_CHANGED, receiveUserIdleChanged),
    takeLatest(types.SEND_USER_STATE_CHANGED, sendUserStateChanged),
    takeLatest(types.SEND_USER_CALL_STATE_CHANGED, sendUserCallStateChanged),
    takeLatest(types.RECEIVE_USER_STATE_CHANGED, receiveUserStateChanged),
    takeLatest(types.RECEIVE_USER_STATUS_CHANGED, receiveUserStatusChanged),
    takeLatest(types.RECEIVE_USER_PROFILE_CHANGED, receiveUserProfileChanged),
    takeLatest(types.RECEIVE_USER_CALL_STATE_CHANGED, receiveUserCallStateChanged),
    takeLatest(types.RECEIVE_USER_PHOTO_UPDATED, receiveUserPhotoUpdated),
    takeLatest(types.SEND_USER_PHOTO_UPDATED, sendUserPhotoUpdated),
    throttle(BUSY_KNOCK_TIMEOUT, types.SEND_BUSY_KNOCK, sendBusyKnock),
    throttle(BUSY_KNOCK_TIMEOUT, types.RECEIVE_BUSY_KNOCK, receiveBusyKnock),
    takeLatest(types.USERS_UPDATED, usersUpdated),
    takeLatest(types.USER_JOINED, userJoined),
    takeLatest(types.TEAM_UPDATED, teamUpdated),
    takeLatest(types.RECEIVE_USER_DUPLICATE, notifyDuplicateSession),
    takeLatest(types.RECEIVE_USER_ENTERED_TEAM, receiveUserEnteredTeam),
    takeLatest(types.LOAD_USER_ENTERED_TEAM, loadUserEnteredTeam),
    takeLatest(types.RECEIVE_USER_LEFT_TEAM, receiveUserLeftTeam),
    takeLatest(types.SEND_USER_ENTERED_OFFICE, sendUserEnteredOffice),
    takeLatest(types.RECEIVE_USER_ENTERED_OFFICE, receiveUserEnteredOffice),
    throttle(BUSY_KNOCK_TIMEOUT, types.RECEIVE_USER_CALLED, receiveUserCalled)
  ])
}
