// @ts-ignore
import debug from 'debug'

export const logger = debug('hqremote')

// eslint-disable no-console
export default (namespace: string) => {
  const rootDebugger = logger.extend(namespace)

  const info = rootDebugger.extend('info')
  // eslint-disable-next-line no-console
  info.log = console.info.bind(console)

  const log = rootDebugger.extend('log')
  // eslint-disable-next-line no-console
  log.log = console.log.bind(console)

  const error = rootDebugger.extend('error')
  // eslint-disable-next-line no-console
  error.log = console.error.bind(console)

  return {
    info,
    log,
    error
  }
}
