import React from 'react'
import LoadingLeft from '../assets/img/loading_left.svg'
import LoadingRight from '../assets/img/loading_right.svg'
import styled from 'styled-components'
import { animations } from '../components/Helpers'


const LeftImage = styled.img`
  animation: ${animations.bounce} 2000ms 100ms infinite;
`

const RightImage = styled.img`
  animation: ${animations.bounceReverse} 2000ms 100ms infinite;
`

const LoaderV2 = () => {
  return (<div>
    <LeftImage
      src={LoadingLeft}
    />
    <RightImage
      src={LoadingRight}
    />
  </div>)
}

export default LoaderV2
