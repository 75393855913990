import React from 'react'

const AccountSettingTitle: React.FC<React.PropsWithChildren<any>> = (props) => {
  return (<p
    style={{
      color: '#666666',
      fontSize: '48px',
      fontWeight: 500
    }}
  >
    {props.children}
  </p>)
}

export default AccountSettingTitle
