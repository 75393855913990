import { SETTINGS } from '../constants/localStorage'
import { RootState } from '../store'
import { MirrorVisibility, Setting } from '../interfaces/setting'
import { PayloadAction } from '@reduxjs/toolkit'
import { isElectron } from '../utils/env'
import {
  TVirtualBackground,
  VIRTUAL_BACKGROUND_TYPE
} from '../services/video/constants'

export const types = {
  OPEN: 'setting/open',
  CLOSE: 'setting/close',
  TOGGLE: 'setting/toggle',
  CHANGE_INPUT: 'setting/changed',
  UPDATE_SETTING: 'setting/update',
  SET_ELECTRON_SETTINGS: 'setting/set-electron-settings',
  UPDATE_SETTING_STATE: 'setting/update-setting-state',
  CHANGE_BACKGROUND_EFFECT: 'setting/change-background-effect'
}

export const setSettingStorage = (state: Setting): void => {
  // Use manual override rather than delete function
  const getState: Setting = {
    showSetting: false,
    mirrorVisible: state.mirrorVisible,
    notificationSounds: state.notificationSounds,
    camera: state.camera,
    mic: state.mic,
    background: state.background,
    backgroundSource: state.backgroundSource,
    backgroundSources: state.backgroundSources,
    noiseReduction: state.noiseReduction,
    enablePip: state.enablePip,
    dateFormat: state.dateFormat
  }
  if (isElectron()) {
    // @ts-ignore
    window.ipcRender.setSettings(getState)
    return
  }
  localStorage.setItem(SETTINGS, JSON.stringify(getState))
}

export const getStorageSettings = (): Setting => {
  if (localStorage.getItem(SETTINGS)) {
    return JSON.parse(String(localStorage.getItem(SETTINGS)))
  }
  return {
    showSetting: false,
    mirrorVisible: MirrorVisibility.PHOTO,
    notificationSounds: true,
    camera: '',
    mic: '',
    background: VIRTUAL_BACKGROUND_TYPE.NONE,
    noiseReduction: true,
    enablePip: true,
    dateFormat: 'DD/MM/YYYY'
  }
}
export const initialState = {
  ...getStorageSettings()
}

export interface SettingPayloadAction extends PayloadAction<Object> {
  payload: {
    name: string
    value: string | Array<string>
    settings?: any
  }
}

export default (state = initialState, action: SettingPayloadAction) => {
  switch (action.type) {
    case types.OPEN:
      return {
        ...state,
        showSetting: true
      }
    case types.CLOSE:
      return {
        ...state,
        showSetting: false
      }
    case types.TOGGLE:
      return {
        ...state,
        showSetting: !state.showSetting
      }
    case types.CHANGE_INPUT: {
      // eslint-disable-next-line
      console.log('change input', action.payload)
      const response = {
        ...state,
        [action.payload.name]:
          ['notificationSounds', 'noiseReduction', 'enablePip'].indexOf(
            action.payload.name
          ) > -1
            ? action.payload.value === 'true'
            : action.payload.value
      }
      return response
    }
    case types.UPDATE_SETTING_STATE: {
      const response = {
        ...state,
        [action.payload.name]:
          ['notificationSounds', 'noiseReduction', 'enablePip'].indexOf(
            action.payload.name
          ) > -1
            ? action.payload.value === 'true'
            : action.payload.value
      }
      // eslint-disable-next-line no-console
      console.log('update setting state', response)
      setSettingStorage(response)
      return response
    }
    case types.UPDATE_SETTING: {
      setSettingStorage(state)
      return state
    }
    case types.SET_ELECTRON_SETTINGS: {
      if (!action.payload.settings) {
        return {
          ...state,
          mirrorVisible: MirrorVisibility.PHOTO,
          notificationSounds: true,
          camera: '',
          mic: '',
          background: VIRTUAL_BACKGROUND_TYPE.NONE,
          noiseReduction: true,
          enablePip: true
        }
      }
      return {
        ...state,
        ...action.payload.settings
      }
    }
    case types.CHANGE_BACKGROUND_EFFECT:
      const { name, value } = action.payload
      return {
        ...state,
        background: name,
        backgroundSource: value
      }
    default:
      return state
  }
}

export const actions = {
  open: () => ({ type: types.OPEN, payload: {} }),
  close: () => ({ type: types.CLOSE, payload: {} }),
  // toggle: () => ({ type: types.TOGGLE, payload: {} }),
  onChangeSetting: (input: {
    name: string
    value: string | Array<string>
  }) => ({
    type: types.CHANGE_INPUT,
    payload: {
      name: input.name,
      value: input.value
    }
  }),
  updateSettingState: (input: {
    name: string
    value: string | Array<string>
  }) => ({
    type: types.UPDATE_SETTING_STATE,
    payload: {
      name: input.name,
      value: input.value
    }
  }),
  updateSetting: () => ({
    type: types.UPDATE_SETTING,
    payload: {}
  }),
  setElectronSettings: (settings: any) => ({
    type: types.SET_ELECTRON_SETTINGS,
    payload: { settings }
  }),
  changeBackgroundEffect: (payload: {
    name: TVirtualBackground
    value: string
  }) => ({
    type: types.CHANGE_BACKGROUND_EFFECT,
    payload
  })
}

export const getSettings = (state: RootState) => state.settings
