// @ts-ignore
import dotProp from 'dot-prop-immutable'
import { PayloadAction } from '@reduxjs/toolkit'
import NotificationLogo from '../assets/img/notification-logo.png'
import { isElectron } from '../utils/env'
const DEFAULT_TIMEOUT = 2000

export const displayTypes = {
  APP: 'app',
  DESKTOP: 'desktop',
  BOTH: 'both'
}

export const bannerTypes = {
  ALERT: 'alert',
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error'
}

export const sounds = {
  DEFAULT: '',
  KNOCKING: 'sounds/knock.mp3',
  SNAP: 'sounds/snap.mp3',
  CALL_INCOMING: 'sounds/incoming_call.mp3',
  CALL_HANGUP: 'sounds/hang_up.mp3',
  MEMBER_SIGN_ON: 'sounds/member_sign_on.mp3'
}

export const defaultOptions = {
  display: displayTypes.APP,
  type: bannerTypes.INFO,
  sound: sounds.DEFAULT,
  timeout: DEFAULT_TIMEOUT
}

export const types = {
  CREATE: 'notifications/create',
  DESTROY: 'notifications/destroy'
}

export const initialState = {}

export interface NotificationsPayloadAction extends PayloadAction<Object> {
  payload: {
    message: string;
    timeout?: number;
    id: number;
  }
}

export default (state = initialState, action: NotificationsPayloadAction) => {
  switch (action.type) {
    case types.CREATE:
      return dotProp.set(state, `${action.payload.id}`, action.payload)

    case types.DESTROY:
      return dotProp.delete(state, `${action.payload.id}`)

    default:
      return state
  }
}

export const actions = {
  create: (message: string, options = {}) => {
    const Notification = window.Notification
    if (Notification) {
      try {
        if (isElectron()) {
          // @ts-ignore
          window.ipcRender.showNotification({
            title: 'HQ Remote',
            message: message,
            icon: NotificationLogo
          })
        } else {
          Notification.requestPermission(function (permission) {
            if (permission === 'granted' && !document.hasFocus()) {
              const notification = new Notification('HQ Remote', {
                icon: NotificationLogo,
                body: message,
                silent: true
              })
              notification.onclick = () => {
                notification.close()
                window.focus()
              }
              notification.onclose = () => {
                clearTimeout(3000)
              }
            }
          })
        }
      } catch (e) {
        throw e
      }
    }

    return {
      type: types.CREATE,
      payload: { message, ...defaultOptions, ...options }
    }
  },
  destroy: (id: number) => {
    return {
      type: types.DESTROY,
      payload: { id }
    }
  }
}
