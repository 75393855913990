import React, { useEffect, useState } from 'react'
import {
  FullHeightContainer,
  LandingBranding,
  PaginationContainer,
  PaginationItem,
  SignUpContainer,
  SignUpInner,
  Snackbar
} from '../modules'
import { Email, Invited, Name, Password, Review } from '../components/forms'
import { Logo } from '../components/icons'
import { getInvitation, acceptInvitation } from '../services/api'
import { timezone as defaultTimezone } from '../utils'
import { AcceptInvitationFormData } from '../components/forms/interface'
import { DefaultError, ErrorI } from '../interfaces/error'
import { RouteComponentProps } from 'react-router-dom'

const AcceptInvitation: React.FC<RouteComponentProps> = (props) => {
  const title = 'Join a team!'
  const [step, setStep] = useState<number>(1)
  const [data, setData] = useState<AcceptInvitationFormData>({
    token: '',
    email: '',
    teamName: '',
    teamUrl: '',
    firstName: '',
    lastName: '',
    password: '',
    timezone: defaultTimezone,
    tnc: false
  })
  const [errors, setErrors] = useState<ErrorI>(DefaultError)
  const [onSend, setOnSend] = useState<boolean>(false)
  const setCode = (code: string) => {
    getInvitation(code).then((response) => {
      if (response.data.teamName && response.data.email && response.data.token) {
        const dataCopy = { ...data }
        dataCopy.email = response.data.email
        dataCopy.token = response.data.token
        dataCopy.teamName = response.data.teamName
        setData(dataCopy)
      } else {
        props.history.push('/not-found')
      }
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log('get-invitation error:', err)
    })
  }
  const setError = (msg: string, error = true) => {
    setErrors({
      status: error,
      message: msg
    })
    setOnSend(false)
  }
  const updateForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dataCopy = { ...data }
    // @ts-ignore
    dataCopy[e.target.name] = e.target.value
    setData(dataCopy)
    if (!e.target.value) {
      setError('Field cannot be empty!')
    } else if (
      e.target.name === 'email' &&
      !e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      setError('Not a valid email format.')
    } else if (
      e.target.name === 'teamUrl' &&
      e.target.value.match(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)
    ) {
      setError('URL must not contain space or unique characters.')
    } else {
      setError('', false)
    }
  }

  const submitForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setOnSend(true)

    if (!data.tnc) {
      setError('You must accept the terms of service.')
      return
    }
    const additionalParams = {
      name: `${data.firstName}${data.lastName ?? ` ${data.lastName}`}`,
      // eslint-disable-next-line camelcase
      display_name: `${data.firstName}${data.lastName ?? ` ${data.lastName}`}`,
      // eslint-disable-next-line camelcase
      password_confirmation: data.password
    }
    acceptInvitation({
      ...data,
      ...additionalParams
    }).then(() => {
      setError('', false)
      props.history.push('/')
    }).catch(() => {
      let errMessage =
        'We\'re sorry, we\'re unable to submit your request. Please contact our administrator.'
      setError(errMessage)
    })
  }
  useEffect(() => {
    const {
      match: { params }
    } = props
    document.title = title
    // @ts-ignore
    setCode(params.code)
  }, [])

  return (
    <FullHeightContainer>
      <SignUpContainer>
        <SignUpInner>
          <LandingBranding>
            <Logo />
          </LandingBranding>
          <Invited
            data={data.teamName}
            currentStep={step}
            next={() => setStep(2)}
            formStep={1}
          />
          <Email
            currentStep={step}
            change={(e) => updateForm(e)}
            next={() => setStep(3)}
            data={data.email}
            formStep={2}
          />
          <Name
            currentStep={step}
            change={(e) => updateForm(e)}
            next={() => setStep(4)}
            data={{
              firstName: data.firstName,
              lastName: data.lastName
            }}
            formStep={3}
          />
          <Password
            currentStep={step}
            change={(e) => updateForm(e)}
            next={() => setStep(5)}
            data={data.password}
            formStep={4}
          />
          <Review
            currentStep={step}
            change={(e) => updateForm(e)}
            data={data}
            formStep={5}
            loading={onSend}
            submit={(e) => submitForm(e)}
          />
        </SignUpInner>
        {step < 5 && (
          <PaginationContainer>
            {[1, 2, 3, 4].map((i) => {
              return (
                <PaginationItem
                  key={i}
                  current={step === i}
                  disabled={step < i}
                  onClick={() => setStep(i)}>
                  {i}
                </PaginationItem>
              )
            })}
          </PaginationContainer>
        )}
      </SignUpContainer>
      <Snackbar type='error' show={errors.status} message={errors.message} zIndex={9999} />
    </FullHeightContainer>
  )
}

export default AcceptInvitation
