import styled, { css } from 'styled-components'
import { GeneralComponentProps, PanelProps } from '../interfaces/component'

export const ManagementPage = styled.section`
  background: #ffffff;
  height: 100%;
  position: relative;
  padding: 0;
  width: -webkit-calc(100% - 60px);
  width: calc(100% - 60px);

  @media (min-width: 1024px) {
    width: -webkit-calc(100% - 300px);
    width: calc(100% - 300px);
  }
`
export const ManagementPanel = styled.div`
  background: #ffffff;
  height: 100%;
  overflow-y: auto;
  padding: 53px 30px 0;
  position: absolute;
  right: 0;
  -webkit-transition: width 300ms ease, right 300ms ease;
  transition: width 300ms ease, right 300ms ease;
  width: 100%;

  @media (min-width: 400px) {
    padding: 53px 40px 0;
  }
  @media (min-width: 1024px) {
    padding: 22px 40px 22px 150px;
  }

  h2 {
    font-size: 22px;
    letter-spacing: 0.22px;
    line-height: 38px;
  }
`

export const ManagementPanelInner = styled.div`
  .management-panel__inner {
    width: 490px;
  }
`

export const ManagementPanelRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #cecece;
  height: 54px;
  align-items: center;
`

export const ManagementPanelColName = styled.div`
  color: #808080;
  font-weight: 500;
  font-size: 20px;
  flex: 0 0 100%;
  max-width: 100%;
  @media screen and (min-width: 520px) {
    flex: 0 0 30%;
    max-width: 30%;
  }
`

export const ManagementPanelColForms = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  @media screen and (min-width: 520px) {
    flex: 0 0 70%;
    max-width: 70%;
  }
  text-align: right;
`
export const ManagementBtnActivated = styled.button`
  color: #808080;
  font-weight: 700;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
  letter-spacing: 0.14px;
  position: relative;
  text-decoration: none;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  text-transform: capitalize;
  padding: 0 10px 0 0;
  text-decoration: underline;
  border: none;
  background-color: transparent;
`
export const ManagementBtnRole = styled.button < GeneralComponentProps > `
  border: 2px solid #9D9D9D;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: #ccc;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 28px;
  margin-left: 8px;
  padding-left: 1px;
  text-align: center;
  -webkit-transition: border 200ms ease, color 200ms ease;
  transition: border 200ms ease, color 200ms ease;
  width: 30px;
  font-size: 1em;
  padding: 0;
  font-family: 'DM Sans';
  opacity: 1;
  background-color: transparent;

  ${(props) => {
    if (props.selected) {
      return css`
        color: #666666;
        border-color: #666666;
      `
    }
    return css``
  }}

  &:hover {
    border-color: #505050;
    color: #505050;
  }

  &.selected {
    border: 2px solid #000;
    color: #000;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.25;

    &:hover {
      color: #ccc;
      border-color: #ccc;
    }
  }
`

export const ManagementPanelBlock = styled.div < PanelProps > `
  display: block;

  &:not(:last-child) {
    ${(props) => {
    if (!props.noMargin) {
      return css`
          margin-bottom: 70px;
        `
    }
    return css``
  }}
  }

  p {
    line-height: 1.5;

    &.spaced {
      margin-bottom: 16px;
    }

    &.form__secondary-text {
      color: #646464;
      margin-left: 17px;
    }
  }

  .form__label {
    display: block;
    letter-spacing: 0.14px;

    &.error {
      color: red;
    }
  }

  .form__options-group {
    padding: 16px 0 4px;
  }
`

export const ManagementPanelBlockRow = styled.div`
  border-bottom: 1px solid #000;
  line-height: 20px;
  padding: 17px 0;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
`

export const FormRowWithBorder = styled.div`
  border-bottom: 1px solid #cecece;
`

export const LogItem = styled.div`
  display: flex;
  font-size: 18px;
  color: #9D9D9D;
  height: 100%;
  align-items: start;
  flex-wrap: wrap;
  border-bottom: 1px solid #000;
  line-height: 20px;

  &.log-head {
    display: none;

    @media screen and (min-width: 520px) {
      display: flex;
    }
  }
`

export const LogItemDate = styled.div`
  flex: 0 0 100%;
  min-width: 100%;
  padding: 17px 0 0;
  font-weight: 500;
  color: #808080;
  @media screen and (min-width: 520px) {
    flex: 0 0 126px;
    min-width: 126px;
    padding-bottom: 17px;
  }
`

export const LogItemAmount = styled.div`
  flex: 0 0 100%;
  min-width: 100%;
  padding: 0 0 17px;

  @media screen and (min-width: 520px) {
    flex: 0 0 90px;
    min-width: 90px;
    text-align: right;
    padding: 17px 0;
  }
`

export const LogItemDesc = styled.div`
  flex: 0 0 100%;
  min-width: 100%;
  padding: 8px 0;

  @media screen and (min-width: 520px) {
    flex: 0 0 calc(100% - 126px);
    min-width: calc(100% - 126px);
    padding: 17px 0;

    &.billing {
      flex: 0 0 calc(100% - 216px);
      min-width: calc(100% - 216px);
      padding: 17px 0 0;
    }
  }
`

export const GreyText = styled.small`
  color: #646464;
`

export const TextWithBorder = styled.a`
  display: inline-block;
  position: relative;
  text-decoration: none !important;
  &:after {
    background-color: #cecece;
    content: '';
    display: block;
    height: 1px;
    margin-top: 0;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    width: 100%;
  }

  &:hover {
    &:after {
      background-color: #e7ff35;
    }
  }
`

export const IvtItem = styled.div`
  display: flex;
  height: 100%;
  align-items: start;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #000;
  line-height: 1;
  padding: 17px 0;

  @media screen and (min-width: 520px) {
    justify-content: space-between;
  }

  .resend,
  .remove {
    text-align: right;
    color: #959595;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.14px;
    text-decoration: underline;

    &:hover {
      color: #000;
    }
  }
`
export const IvtItemCol = styled.div`
  flex: 0 0 100%;
  min-width: 100%;
  padding: 17px 0 0;

  @media screen and (min-width: 520px) {
    min-width: unset;
    flex: 0 0 auto;
  }
`
