// import { BACKGROUND_EFFECT } from '../constants/effect'
import { TVirtualBackground } from '../services/video/constants'

// eslint-disable-next-line no-shadow
export enum MirrorVisibility {
  VIDEO = 'video',
  PHOTO = 'photo'
}

export interface Device {
  id: string
  label: string
}

export interface Setting {
  showSetting: boolean
  mirrorVisible: MirrorVisibility
  notificationSounds: boolean
  camera: string
  mic: string
  background: TVirtualBackground
  backgroundSource?: string
  backgroundSources?: Array<string>
  noiseReduction: boolean
  enablePip: boolean
  dateFormat: string
}
