import { Types } from 'ably'
import React from 'react'
import { TrackDetail } from '../../contexts/stream'

export const types = {
  SEND_START: 'realtime/call/send/start',
  SEND_LEAVE: 'realtime/call/send/leave',
  CHANGE_VIDEO_SOURCE: 'realtime/call/change-video-source',
  CHANGE_AUDIO_SOURCE: 'realtime/call/change-audio-source'
}

export const actions = {
  sendStart: (
    clientChannels: {[key: string]: Types.RealtimeChannelCallbacks},
    userId: number,
    callIndex: number
  ) => ({
    type: types.SEND_START,
    payload: { clientChannels, userId, callIndex }
  }),
  sendLeave: (
    clientChannels: {[key: string]: Types.RealtimeChannelCallbacks},
    setTracks: React.Dispatch<
      React.SetStateAction<{ [key: string]: TrackDetail[] } | null>
    >
  ) => {
    return {
      type: types.SEND_LEAVE,
      payload: { clientChannels, setTracks }
    }
  },
  changeVideoSource: (id: string) => ({
    type: types.CHANGE_VIDEO_SOURCE,
    payload: { id }
  }),
  changeAudioSource: (id: string) => ({
    type: types.CHANGE_AUDIO_SOURCE,
    payload: { id }
  })
}
