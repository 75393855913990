// Action types
import { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { COUNTDOWN_STATE } from '../constants/countdown'

export const types = {
  COUNTDOWN_START: 'countdown/start',
  COUNTDOWN_READY: 'countdown/ready',
  COUNTDOWN_DONE: 'countdown/done',
  COUNTDOWN_STANDBY: 'countdown/standby'
}

// The initial state
export const initialState = {
  status: COUNTDOWN_STATE.DONE
}

export interface CountdownState {
  status: string;
}

// Reducer
export default (state: CountdownState = initialState, action: PayloadAction) => {
  switch (action.type) {
    case types.COUNTDOWN_START:
      return {
        ...state,
        status: COUNTDOWN_STATE.STARTED
      }
    case types.COUNTDOWN_STANDBY:
      return {
        ...state,
        status: COUNTDOWN_STATE.STANDBY
      }
    case types.COUNTDOWN_READY:
      return {
        ...state,
        status: COUNTDOWN_STATE.READY
      }
    case types.COUNTDOWN_DONE:
      return {
        ...state,
        status: COUNTDOWN_STATE.DONE
      }
    default:
      return state
  }
}

// Actions
export const actions = {
  start: () => ({ type: types.COUNTDOWN_START }),
  ready: () => ({ type: types.COUNTDOWN_READY }),
  done: () => ({ type: types.COUNTDOWN_DONE }),
  standby: () => ({ type: types.COUNTDOWN_STANDBY })
}

// Selectors
export const getStatus = (state: RootState) => state.countdown.status
