export const emailValidation = (email: string): boolean => {
  // eslint-disable-next-line max-len
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const bulkEmailValidation = (inputs: string[] = []): string[]|null => {
  let res: string[] = []
  inputs.forEach((input, i) => {
    // the first email is required
    if (i === 0 && !emailValidation(input)) {
      res[i] = 'invalid'
    }

    if (i > 0 && input !== '' && !emailValidation(input)) {
      res[i] = 'invalid'
    }
  })
  return res.length > 0 ? res : null
}
