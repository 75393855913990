import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Transition from 'react-transition-group/Transition'
import styled, { css } from 'styled-components'

import { getSelectedTeamOffices } from '../reducers/shared'
import { actions as uiActions, getOfficeListOpen } from '../reducers/ui'
import {
  actions as userActions,
  getSelectedTeamId,
  getSelectedOfficeId, getCurrentUser
} from '../reducers/user'
import { actions as teamActions } from '../reducers/teams'
import { RootState } from '../store'
import { ModalContainer, ModalTitle } from '../modules'
import Modal from '../modules/Modal'
import { createOffice, updateOffice } from '../services/api/library/offices'
import { User } from '../interfaces/user'
import { getAllUsers } from '../services/api'
import { Office } from '../interfaces/team'

interface OfficeListProps {
  state: string;
}

const OfficeList = styled.section < OfficeListProps > `
  background: black;
  box-sizing: border-box;
  height: 100%;
  left: 80px;
  opacity: 0;
  overflow: hidden;
  margin: 0 0 0 -20px;
  padding: 0;
  position: absolute;
  transition: opacity 300ms ease-in-out, margin 300ms ease-in-out;
  z-index: 50;
  width: 0;

  ${(props) => {
    if (
      props.state === 'entering' ||
      props.state === 'entered'
    ) {
      return css`
        opacity: 1;
        margin: 0;
        padding: 20px;
        width: auto;
      `
    }

    if (props.state === 'exiting') {
      return css`
        padding: 20px;
        width: auto;
      `
    }
    return null
  }}
`

interface OfficeLinkProps {
  selected?: boolean;
}

const OfficeLink = styled.button < OfficeLinkProps > `
  background: none;
  border: none;
  color: white;
  display: block;
  margin: 0;
  padding: 0;

  ${(props) =>
    props.selected &&
    css`
      font-weight: bold;
    `}
`
const initializeEmptyOffice = (): Office => {
  return {
    onlineUsers: [],
    id: 0,
    // eslint-disable-next-line camelcase
    team_id: 0,
    name: '',
    initials: '',
    default: false,
    users: []
  }
}

const OfficeSwitcher = () => {
  const showing = useSelector((state: RootState) => getOfficeListOpen(state))
  const offices = useSelector((state: RootState) => getSelectedTeamOffices(state))
  const [showCreateOfficeModal, setShowCreateOfficeModal] = useState<boolean>(false)
  const [office, setOffice] = useState<Office>(initializeEmptyOffice())
  const [teamMembers, setTeamMembers] = useState<User[]>([])
  const selectedOfficeId = useSelector((state: RootState) => getSelectedOfficeId(state))
  const selectedTeamId = useSelector((state: RootState) => getSelectedTeamId(state))
  const user = useSelector((state: RootState) => getCurrentUser(state))
  const dispatch = useDispatch()
  const reset = () => {
    setOffice(initializeEmptyOffice())
  }
  const createOfficeHandler = () => {
    // fetch team members
    if (!showCreateOfficeModal) {
      getAllUsers(selectedTeamId).then(res => {
        const { data } = res.data
        setTeamMembers(data)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log('get-all-users error:', err)
      })
    }
    reset()
    setShowCreateOfficeModal(!showCreateOfficeModal)
  }
  const editOfficeHandler = (selectedOffice: Office) => {
    // fetch team members
    if (!showCreateOfficeModal) {
      getAllUsers(selectedTeamId).then(res => {
        const { data } = res.data
        setTeamMembers(data)
        setOffice(selectedOffice)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log('get-all-users error:', err)
      })
    }
    reset()
    setShowCreateOfficeModal(!showCreateOfficeModal)
  }
  const createOfficeSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (office) {
      createOffice(selectedTeamId, office).then(res => {
        dispatch(userActions.addTeamOffice(selectedTeamId, { ...office, id: res.data.id }))
        setShowCreateOfficeModal(!showCreateOfficeModal)
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.log('create-office error: ', error)
      })
    }
  }
  const updateOfficeSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (office) {
      updateOffice(
        selectedTeamId,
        office
      ).then(() => {
        dispatch(userActions.updateTeamOffice(selectedTeamId, office))
        setShowCreateOfficeModal(false)
        reset()
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log('error updating office', err)
      })
    }
  }
  useEffect(() => {
    if (selectedOfficeId) {
      dispatch(userActions.switchOffice(selectedTeamId, selectedOfficeId))
      dispatch(teamActions.userSwitchoffice(
        user.id,
        selectedTeamId,
        selectedOfficeId
      ))
    }
  }, [])
  return (
    <>
      <Modal
        show={showCreateOfficeModal}
        onClose={() => setShowCreateOfficeModal(false)}>
        <ModalTitle title={office.id === 0 ? 'Create New Office' : 'Update Office'} />
        <ModalContainer>
          <form
            action='#'
            method='post'
            className='modal__form'
            onSubmit={office.id === 0 ? createOfficeSubmitHandler : updateOfficeSubmitHandler}
          >
            <div className='modal__av-profile'>
              <div className='modal__form__row'>
                <label className='modal__form__label'>Office Name:</label>
                <input
                  name='name'
                  type='text'
                  value={office?.name}
                  onChange={(e) => {
                    setOffice({ ...office, name: e.target.value })
                  }}
                />
              </div>
            </div>
            <div className='modal__av-profile'>
              <div className='modal__form__row'>
                <label className='modal__form__label'>Initials:</label>
                <input
                  name='initials'
                  type='text'
                  value={office?.initials}
                  onChange={(e) => {
                    setOffice({ ...office, initials: e.target.value })
                  }}
                />
              </div>
            </div>
            <div className='modal__av-profile'>
              <div className='modal__form__row last'>
                <label className='modal__form__label'>Default:</label>
                <input
                  name='default'
                  type='checkbox'
                  checked={office?.default}
                  onChange={(e) => {
                    setOffice({ ...office, default: e.target.checked })
                  }}
                />
              </div>
            </div>
            <div className='modal__av-profile'>
              <div className='modal__form__row last'>
                <label className='modal__form__label'>Members:</label>
                { teamMembers.map(member => {
                  return (
                    <div
                      key={member.id}
                    >
                      <label className='modal__form__label'>{member.email}</label>
                      <input
                        name='default'
                        type='checkbox'
                        checked={office ? office.users.indexOf(member.id) > -1 : false}
                        onChange={(e) => {
                          let members = office ? office.users : []
                          if (e.target.checked) {
                            setOffice({ ...office, users: [...members, member.id] })
                          } else {
                            setOffice({
                              ...office, users: members.filter(om => om !== member.id)
                            })
                          }
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='modal__av-profile'>
              <div className='modal__form__row'>
                <input name='submit' type='submit' value='Submit'/>
              </div>
            </div>
          </form>
        </ModalContainer>
      </Modal>
      <Transition in={showing} timeout={300}>
        {(state) => {
          return (
            <OfficeList state={state}>
              {Object.values(offices).map((off) => {
                if (off.users.indexOf(Number(user.id)) < 0) {
                  return <div key={off.id} />
                }
                return (
                  <div key={off.id} style={{
                    border: '1px',
                    borderRadius: '10px',
                    backgroundColor: '#afafaf',
                    marginTop: '10px',
                    padding: '5px'
                  }}>
                    <OfficeLink
                      onClick={() => {
                        dispatch(userActions.switchOffice(selectedTeamId, off.id))
                        dispatch(teamActions.userSwitchoffice(
                          user.id,
                          selectedTeamId,
                          off.id
                        ))
                        dispatch(uiActions.hideOfficeList())
                      }}
                      selected={off.id === selectedOfficeId}
                      key={off.id}>
                      {off.name}
                    </OfficeLink>
                    <button
                      style={{
                        backgroundColor: '#544f4f',
                        border: '0px',
                        borderRadius: '2px',
                        marginRight: '2px',
                        color: '#ffffff'
                      }}
                      onClick={() => {
                        editOfficeHandler(off)
                      }}
                    >
                      manage
                    </button>
                    <button
                      style={{
                        backgroundColor: '#544f4f',
                        border: '0px',
                        borderRadius: '2px',
                        color: '#ffffff'
                      }}
                      onClick={() => {
                        // eslint-disable-next-line no-alert
                        if (confirm(`Are you sure you want to delete ${off.name}?`)) {
                          dispatch(userActions.removeTeamOffice(selectedTeamId, off.id))
                        }
                      }}
                    >
                      X
                    </button>
                  </div>
                )
              })}
              {/* eslint-disable-next-line no-alert */}
              <OfficeLink
                style={{ marginTop: '10px' }}
                onClick={() => createOfficeHandler()}
                key={'add'}
              >
                + Add Office
              </OfficeLink>
            </OfficeList>
          )
        }}
      </Transition>
    </>
  )
}

export default OfficeSwitcher
