import { axios, ROLES_API_ENDPOINT } from './http'
import { AxiosResponse } from 'axios'
import { GeneralResponse } from '../../../interfaces/http'
import { Role } from '../../../interfaces/role'

export async function getRoles(): Promise<AxiosResponse<GeneralResponse<Role[]>>> {
  try {
    return await axios.get(ROLES_API_ENDPOINT)
  } catch (error) {
    throw error
  }
}
