import {
  CommonParameterOption,
  EffectHandler,
  GifParameterOption,
  PixelationParameterOption,
  snapshotEffects
} from './snapshotEffects'
import Middleware from './helpers/middleware'
import { SNAP_RESOLUTION } from '../constants/call'

export default class SnapshotImage {
  publisher: HTMLVideoElement
  effects: string[] = []
  middleware: Middleware
  options: GifParameterOption | CommonParameterOption | PixelationParameterOption | {}
  imgData: string

  constructor(publisher: HTMLVideoElement, options = {}) {
    this.publisher = publisher

    this.middleware = new Middleware()

    this.options = options
    let canvas = document.createElement('canvas')

    canvas.width = SNAP_RESOLUTION.height
    canvas.height = SNAP_RESOLUTION.height
    let ctx = canvas.getContext('2d')

    ctx?.drawImage(this.publisher,
      (publisher.videoWidth - publisher.videoHeight) / 2, 0, publisher.videoHeight, publisher.videoHeight,
      0, 0, canvas.width, canvas.height)
    this.imgData = canvas.toDataURL('image/png')
  }

  setEffects(effects: string[] = []) {
    this.effects = effects

    this.effects.forEach((effect) => {
      const effectFn: EffectHandler = snapshotEffects[effect]

      if (effectFn) {
        this.middleware.use((params: any, next: any) => {
          params.count = params.count + 1
          effectFn(params, next)
        })
      }
    })
  }

  process() {
    return new Promise((resolve, reject) => {
      try {
        if (this.imgData === null) {
          reject('snapshotImage.imgData is null')
        }
        const params = {
          mimeType: 'image/png',
          imgData: this.imgData.replace('data:image/png;base64,', ''),
          options: {
            publisher: this.publisher,
            ...this.options
          },
          count: 0
        }
        this.middleware.go(params, (output: any) => {
          resolve(output)
        })
      } catch (e) {
        reject(e)
      }
    })
  }
}
