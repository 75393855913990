import { all, call, takeEvery, select, put } from 'redux-saga/effects'
import { types, getCurrentUser } from '../reducers/user'
import { updateTeamToUser as updateTeamToUserApi } from '../services/api'
import { User } from '../interfaces/user'
import {
  actions as notificationActions
} from '../reducers/notification'
import { actions as realtimeTeamActions } from '../reducers/realtime/team'

export function *changeProfile(action: any) {
  try {
    const user: User = yield select(getCurrentUser)
    const { serverChannel, displayName, timezone } = action.payload
    yield all([
      call(updateTeamToUserApi, user.selectedTeam, {
        // eslint-disable-next-line camelcase
        display_name: displayName || '',
        timezone
      }),
      put(realtimeTeamActions.sendUserProfileChanged(timezone, displayName || '', serverChannel))
    ])
    yield put(notificationActions.updateSnackbar({
      message: 'Profile Updated',
      show: true,
      timeout: 3000,
      status: 'success'
    }))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('change-profile error', error)
    yield put(notificationActions.updateSnackbar({
      message: 'Profile Update Failed',
      show: true,
      timeout: 3000,
      status: 'error'
    }))
  }
}

export default function *profileWatcher() {
  yield all([
    takeEvery(types.CHANGE_PROFILE, changeProfile)
  ])
}
