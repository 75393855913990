/* eslint-disable generator-star-spacing */
import { all, put, takeLatest } from 'redux-saga/effects'
import {
  types,
  actions as settingActions
} from '../reducers/settings'
import { actions as notificationActions } from '../reducers/notification'

export function* updateSetting() {
  yield all([
    put(notificationActions.updateSnackbar({
      show: true,
      status: 'success',
      message: 'Settings Updated',
      timeout: 3000
    })),
    put(settingActions.close())
  ])
}

export default function* settingsWatcher() {
  yield all([
    takeLatest(types.UPDATE_SETTING, updateSetting)
  ])
}
