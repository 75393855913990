import { User, UserCallState } from '../../interfaces/user'
import {
  CloseDuplicate,
  MessageTeamBusyKnock,
  MessageTeamUpdated,
  MessageTeamUserCallStateChanged,
  MessageTeamUserEnteredOffice,
  MessageTeamUserEnteredTeam,
  MessageTeamUserIdleChanged,
  MessageTeamUserLeaveTeam,
  MessageTeamUserPhotoChanged,
  MessageTeamUserProfileChanged,
  MessageTeamUserStateChanged,
  MessageTeamUserStatusChanged,
  MessageUserCalled,
  UserJoined
} from './interfaces/team'
import { Types } from 'ably'

export const types = {
  RECEIVE_BUSY_KNOCK: 'realtime/team/receive/busy-knock',
  RECEIVE_USER_ENTERED_OFFICE: 'realtime/team/receive/user/office/entered',
  RECEIVE_USER_ENTERED_TEAM: 'realtime/team/receive/user/entered',
  LOAD_USER_ENTERED_TEAM: 'realtime/team/load/user/entered',
  RECEIVE_USER_IDLE_CHANGED: 'realtime/team/receive/user/idle/changed',
  RECEIVE_USER_LEFT_TEAM: 'realtime/team/receive/user/left',
  RECEIVE_USER_PHOTO_UPDATED: 'realtime/team/receive/user/photo/updated',
  RECEIVE_USER_STATE_CHANGED: 'realtime/team/receive/user/state/changed',
  RECEIVE_USER_STATUS_CHANGED: 'realtime/team/receive/user/status/changed',
  RECEIVE_USER_PROFILE_CHANGED: 'realtime/team/receive/user/profile/changed',
  RECEIVE_USER_CALL_STATE_CHANGED: 'realtime/team/receive/user/call-state/changed',
  SEND_BUSY_KNOCK: 'realtime/team/send/busy-knock',
  SEND_USER_ENTERED_OFFICE: 'realtime/team/send/user/office/entered',
  SEND_USER_IDLE_CHANGED: 'realtime/team/send/user/idle/changed',
  SEND_USER_PHOTO_UPDATED: 'realtime/team/send/user/photo/updated',
  SEND_USER_STATE_CHANGED: 'realtime/team/send/user/state/changed',
  SEND_USER_CALL_STATE_CHANGED: 'realtime/team/send/user/call-state/changed',
  SEND_USER_STATUS_CHANGED: 'realtime/team/send/user/status/changed',
  SEND_USER_PROFILE_CHANGED: 'realtime/team/send/user/profile/changed',
  USERS_UPDATED: 'realtime/team/users/updated',
  USER_JOINED: 'realtime/team/users/joined',
  TEAM_UPDATED: 'realtime/team/updated',
  RECEIVE_USER_DUPLICATE: 'realtime/team/receive/user/duplicate',
  RECEIVE_USER_CALLED: 'realtime/team/receive/user/called'
}

export const actions = {
  // receivers
  receiveBusyKnock: (
    payload: MessageTeamBusyKnock
  ) => ({
    type: types.RECEIVE_BUSY_KNOCK,
    payload
  }),
  receiveUserStateChanged: (
    payload: MessageTeamUserStateChanged
  ) => ({
    type: types.RECEIVE_USER_STATE_CHANGED,
    payload
  }),
  receiveUserStatusChanged: (
    payload: MessageTeamUserStatusChanged
  ) => ({
    type: types.RECEIVE_USER_STATUS_CHANGED,
    payload
  }),
  receiveUserProfileChanged: (
    payload: MessageTeamUserProfileChanged
  ) => ({
    type: types.RECEIVE_USER_PROFILE_CHANGED,
    payload
  }),
  receiveUserCallStateChanged: (
    payload: MessageTeamUserCallStateChanged
  ) => ({
    type: types.RECEIVE_USER_CALL_STATE_CHANGED,
    payload
  }),
  receiveUserPhotoUpdated: (
    payload: MessageTeamUserPhotoChanged
  ) => ({
    type: types.RECEIVE_USER_PHOTO_UPDATED,
    payload
  }),
  receiveUserEnteredOffice: (
    payload: MessageTeamUserEnteredOffice
  ) => ({
    type: types.RECEIVE_USER_ENTERED_OFFICE,
    payload
  }),
  receiveDuplicateUser: (
    payload: CloseDuplicate
  ) => ({
    type: types.RECEIVE_USER_DUPLICATE,
    payload
  }),
  receiveUserEnteredTeam: (payload: MessageTeamUserEnteredTeam) => ({
    type: types.RECEIVE_USER_ENTERED_TEAM,
    payload
  }),
  loadUserEnteredTeam: (payload: MessageTeamUserEnteredTeam) => ({
    type: types.LOAD_USER_ENTERED_TEAM,
    payload
  }),
  receiveUserIdleChanged: (
    payload: MessageTeamUserIdleChanged
  ) => ({
    type: types.RECEIVE_USER_IDLE_CHANGED,
    payload
  }),
  receiveUserLeftTeam: (payload: MessageTeamUserLeaveTeam) => ({
    type: types.RECEIVE_USER_LEFT_TEAM,
    payload
  }),
  receiveUsersUpdated: (users: User[]) => ({ type: types.USERS_UPDATED, payload: { users } }),
  receiveUserJoined: ({
    teamId, officeId, user
  }: UserJoined) => ({ type: types.USER_JOINED, payload: { teamId, officeId, user } }),
  receiveTeamUpdated: (data: MessageTeamUpdated) => ({ type: types.TEAM_UPDATED, payload: { data } }),
  receiveUserCalled: (
    payload: MessageUserCalled
  ) => ({
    type: types.RECEIVE_USER_CALLED,
    payload
  }),
  // senders
  sendBusyKnock: (
    userId: number,
    serverChannel: Types.RealtimeChannelCallbacks
  ) => ({ type: types.SEND_BUSY_KNOCK, payload: { userId, serverChannel } }),
  sendUserEnteredOffice: (teamId: number, officeId: number) => ({
    type: types.SEND_USER_ENTERED_OFFICE,
    payload: { teamId, officeId }
  }),
  sendUserIdleChanged: (
    userId: number,
    idle: boolean,
    serverChannel: Types.RealtimeChannelCallbacks
  ) => ({
    type: types.SEND_USER_IDLE_CHANGED,
    payload: { userId, idle, serverChannel }
  }),
  sendUserPhotoUpdated: (photo: string, serverChannel: Types.RealtimeChannelCallbacks) => ({
    type: types.SEND_USER_PHOTO_UPDATED,
    payload: { photo, serverChannel }
  }),
  sendUserStateChanged: (
    teamId: number,
    state: string,
    serverChannel: Types.RealtimeChannelCallbacks
  ) => ({
    type: types.SEND_USER_STATE_CHANGED,
    payload: { teamId, state, serverChannel }
  }),
  sendUserCallStateChanged: (callState: UserCallState) => ({
    type: types.SEND_USER_CALL_STATE_CHANGED,
    payload: { callState }
  }),
  sendUserStatusChanged: (message: string, serverChannel: Types.RealtimeChannelCallbacks) => ({
    type: types.SEND_USER_STATUS_CHANGED,
    payload: {
      message,
      serverChannel
    }
  }),
  sendUserProfileChanged: (
    timezone: string,
    displayName: string,
    serverChannel: Types.RealtimeChannelCallbacks
  ) => ({
    type: types.SEND_USER_PROFILE_CHANGED,
    payload: {
      timezone,
      displayName,
      serverChannel
    }
  })
}
