export const SNAP_EFFECT = {
  NORMAL: 'normal',
  PIXELATE_LOW: 'pixelate-low',
  PIXELATE_HIGH: 'pixelate-high',
  GIF: 'gif',
  SEPIA: 'sepia',
  GRAYSCALE: 'grayscale',
  BLACK_AND_WHITE: 'black-and-white'
}

export const BACKGROUND_EFFECT = {
  NORMAL: 'normal',
  BLUR: 'blur',
  IMAGE: 'image'
}
