/**
 * The standard time when auto-disappearing notifications should disappear.
 */
export const NOTIFICATION_TIMEOUT = {
  SHORT: 2500,
  MEDIUM: 5000,
  LONG: 10000,
  STICKY: false
}

/**
 * Notification timeout type.
 */
export const NOTIFICATION_TIMEOUT_TYPE = {
  SHORT: 'short',
  MEDIUM: 'medium',
  LONG: 'long',
  STICKY: 'sticky'
}

export const VIRTUAL_BACKGROUND_TYPE = {
  IMAGE: 'image',
  BLUR: 'blur',
  NONE: 'none'
} as const

export const VirtualBackgrounds = (Object.values(VIRTUAL_BACKGROUND_TYPE))
export type TVirtualBackground = typeof VirtualBackgrounds[number]

export const BLUR_VALUE = {
  LOW: 4,
  MID: 8,
  HIGH: 12
}
