import { PayloadAction } from '@reduxjs/toolkit'
// @ts-ignore
import dotProp from 'dot-prop-immutable'
import { RootState } from '../store'
import { UploadPolicy } from '../interfaces/uploadPolicy'

export const types = {
  LOGIN_REQUEST: 'session/login/request',
  LOGIN_REQUEST_GOOGLE: 'session/login/request/google',
  LOGIN_REQUEST_SLACK: 'session/login/request/slack',
  ADD_APP_SLACK: 'session/app/slack',
  LOGIN_SUCCESS: 'session/login/success',
  LOGIN_FAILURE: 'session/login/failure',
  LOGOUT_REQUEST: 'session/logout/request',
  LOGOUT_SUCCESS: 'session/logout/success',
  INITIAL_DATA_REQUEST: 'session/data/request',
  INITIAL_DATA_SUCCESS: 'session/data/success',
  INITIAL_DATA_FAILURE: 'session/data/failure',
  SET_UPLOAD_POLICY: 'session/upload-policies/set'
}

export interface SessionState {
  authToken: boolean|string;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  hasLoaded: boolean;
  hasError: boolean;
  realtimeState: boolean;
  errorMessage: string;
  uploadPolicies: any;
}
export const initialState = {
  authToken: false,
  isAuthenticating: false,
  isAuthenticated: false,
  isLoading: true,
  hasLoaded: false,
  hasError: false,
  realtimeState: false,
  errorMessage: '',
  uploadPolicies: {}
}

export interface SessionPayloadAction extends PayloadAction<Object> {
  payload: {
    authToken?: string;
    teamId?: number;
    policy?: any;
  }
}

export default (state: SessionState = initialState, action: SessionPayloadAction) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        isLoading: false,
        hasError: false,
        errorMessage: ''
      }

    case types.LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: true,
        hasError: false,
        errorMessage: ''
      }

    case types.LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        hasError: true,
        errorMessage: action.payload
      }

    case types.LOGOUT_SUCCESS:
      return { ...state, isAuthenticated: false, authToken: false }

    case types.INITIAL_DATA_REQUEST:
      return { ...state, isLoading: true, hasLoaded: false }

    case types.INITIAL_DATA_SUCCESS:
      return { ...state, isLoading: false, hasLoaded: true }

    case types.INITIAL_DATA_FAILURE:
      return { ...state, isLoading: false, hasLoaded: false, hasError: true }

    case types.SET_UPLOAD_POLICY:
      return dotProp.set(
        state,
        `uploadPolicies.${action.payload.teamId}`,
        action.payload.policy
      )

    default:
      return state
  }
}

export const actions = {
  // eslint-disable-next-line
  login: (email: string, password: string, remember_me: boolean) => ({
    type: types.LOGIN_REQUEST,
    // eslint-disable-next-line
    payload: { email, password, remember_me }
  }),
  loginGoogle: (queryString: string) => ({
    type: types.LOGIN_REQUEST_GOOGLE,
    payload: { queryString }
  }),
  loginSlack: (queryString: string) => ({
    type: types.LOGIN_REQUEST_SLACK,
    payload: { queryString }
  }),
  addAppSlack: (queryString: string) => ({
    type: types.ADD_APP_SLACK,
    payload: { queryString }
  }),
  loginFailure: (err: any) => {
    const { response } = err
    let message = err.message
    if (response && response.status === 401) {
      const { data } = response || {}
      message = data.message
    }
    return { type: types.LOGIN_FAILURE, payload: message }
  },
  loginSuccess: () => ({
    type: types.LOGIN_SUCCESS
  }),
  logout: () => ({ type: types.LOGOUT_REQUEST }),
  logoutSuccess: () => ({ type: types.LOGOUT_SUCCESS }),
  initialData: () => ({ type: types.INITIAL_DATA_REQUEST }),
  initialDataSuccess: () => ({ type: types.INITIAL_DATA_SUCCESS, payload: {} }),
  initialDataFailure: () => ({ type: types.INITIAL_DATA_FAILURE, payload: {} }),
  setUploadPolicy: (teamId: number, policy: any) => ({
    type: types.SET_UPLOAD_POLICY,
    payload: { teamId, policy }
  })
}

// Selectors
export const isReady = (state: RootState) => {
  return (
    state.session.isAuthenticated &&
    state.session.hasLoaded
  )
}

export const getUploadPolicies = (state: RootState): UploadPolicy => state.session.uploadPolicies
export const getHasError = (state: RootState) => state.session.hasError
export const getErrorMessage = (state: RootState) => state.session.errorMessage
