import { AxiosResponse } from 'axios'
import { axios, TEAM_API_ENDPOINT } from './http'
import { Office } from '../../../interfaces/team'

export async function createOffice(
  teamId: number, office: Office
): Promise<AxiosResponse> {
  try {
    return await axios.post(
      `${TEAM_API_ENDPOINT}/${teamId}/offices`,
      {
        name: office.name,
        initials: office.initials,
        default: office.default,
        users: office.users
      }
    )
  } catch (error) {
    throw error
  }
}

export async function deleteOffice(
  teamId: number, officeId: number
): Promise<AxiosResponse> {
  try {
    return await axios.delete(
      `${TEAM_API_ENDPOINT}/${teamId}/offices/${officeId}`
    )
  } catch (error) {
    throw error
  }
}

export async function updateOffice(
  teamId: number,
  office: Office
): Promise<AxiosResponse> {
  try {
    return await axios.put(
      `${TEAM_API_ENDPOINT}/${teamId}/offices/${office.id}`,
      {
        name: office.name,
        initials: office.initials,
        default: office.default,
        users: office.users
      }
    )
  } catch (error) {
    throw error
  }
}
