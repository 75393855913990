import React, { useContext, useEffect } from 'react'
import { initializeSocket } from '../services/socket/socket'
import { SocketContext } from '../contexts/socket'

interface ServerSocketProps {
  channelName: string;
  ready: (ready:boolean) => void;
}

const ServerSocket = (props: ServerSocketProps) => {
  const { setServerChannel } = useContext(SocketContext)
  useEffect(() => {
    if (setServerChannel) {
      initializeSocket(
        props.channelName,
        setServerChannel
      ).then(() => {
        props.ready(true)
      })
    }
  }, [setServerChannel])

  useEffect(() => {
    return () => {
      props.ready(false)
    }
  }, [])

  return <></>
}

export default ServerSocket
