import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  FullHeightContainer,
  HeaderContainer,
  Header,
  HeaderNav,
  LandingBranding,
  SectionBranded,
  TransparentButton
} from '../modules'
import { Logo } from '../components/icons'
import { useHistory } from 'react-router-dom'

const Link = styled.button`
  background: none;
  border: none;
  color: #282828;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding: 0 4px;
  position: relative;
  text-decoration: none;
  transition: background 200ms ease-in-out;

  &:hover,
  &:active {
    color: #000;
  }

  &:after {
    background: #000;
    bottom: 6px;
    content: '';
    height: 1px;
    left: 0;
    opacity: 0;
    position: absolute;
    -webkit-transform: translateY(4px);
    -ms-transform: translateY(4px);
    transform: translateY(4px);
    transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
    transition: height 0.3s, opacity 0.3s, transform 0.3s;
    transition: height 0.3s, opacity 0.3s, transform 0.3s,
      -webkit-transform 0.3s;
    width: 100%;
  }
`

const PaymentSuccess: React.FC = () => {
  const title = 'HQ Remote - Payment Succeess'
  const history = useHistory()
  useEffect(() => {
    document.title = title
  }, [])
  return (
    <FullHeightContainer>
      <HeaderContainer>
        <Header>
          <LandingBranding>
            <Logo />
          </LandingBranding>
          <HeaderNav>
            <nav>
              <Link
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_APP_URL}/testimonials`,
                    '_blank'
                  )
                }>
                Testimonials
              </Link>
              <Link
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_APP_URL}/pricing`,
                    '_blank'
                  )
                }>
                Pricing
              </Link>
              <Link onClick={() => history.push('create-a-team')}>
                Create a team
              </Link>
            </nav>
            <TransparentButton
              className='sign-in-btn'
              onClick={() => history.push('/')}>
              Sign In
            </TransparentButton>
          </HeaderNav>
        </Header>
      </HeaderContainer>
      <SectionBranded>
        <h1>Payment Success</h1>
        <p>
          Thank You!
        </p>
      </SectionBranded>
    </FullHeightContainer>
  )
}

export default PaymentSuccess
