const packageJson = require('../../../package.json')
import { compare } from 'compare-versions'

export const needUpdate = (latestVersionObj: any): boolean => {
  const data = latestVersionObj.data.split('\n')
  const versions = data[0].split('version: ')
  const latest = versions[1]
  const currentVersion = packageJson.version
  return compare(latest, currentVersion, '>')
}
