import {
  createSelector
} from 'reselect'
import {
  getTeamsById
} from './teams'
import {
  getCurrentUser,
  getSelectedTeamId,
  getSelectedOfficeId, getShowOffline
} from './user'
import {
  getUsersById
} from './users'
import { User } from '../interfaces/user'

export const getSelectedTeam = createSelector(
  [getTeamsById, getSelectedTeamId],
  (teams, selectedTeamId) => teams[selectedTeamId]
)

export const getSelectedTeamOffices = createSelector(
  [getSelectedTeam],
  (team) => team.offices
)

export const getSelectedTeamPermissions = createSelector(
  [getSelectedTeam],
  (team) => team.permissions
)

export const getSelectedTeamOnlineUsers = createSelector(
  [getSelectedTeam],
  (team) => team.onlineUsers
)

export const getVisibleTeamUserIds = createSelector(
  [getSelectedTeam, getSelectedOfficeId, getShowOffline],
  (team, selectedOfficeId, showOffline) => {
    if (!team || !team.offices[selectedOfficeId]) {
      return []
    }
    if (showOffline) {
      return [
        ...team.onlineUsers,
        ...team.offices[selectedOfficeId].users.filter(u => !team.onlineUsers.includes(u))
      ]
    }
    return [
      team.onlineUsers,
      team.offices[selectedOfficeId].onlineUsers
    ].reduce((a, b) => a.filter(c => b.includes(c)))
  }
)

export const getVisibleUserIds = createSelector(
  [getVisibleTeamUserIds],
  (visibleTeamUsersIds) => {
    return visibleTeamUsersIds
  }
)

export const getVisibleUsers = createSelector(
  [getCurrentUser, getVisibleUserIds, getUsersById],
  (user, visibleUsers, usersById) => {
    return visibleUsers.map((userId) => {
      if (user.id === userId) {
        return user
      }
      return {
        ...usersById[userId]
      }
    })
  }
)

export const getSortedVisibleUsers = createSelector(
  [getCurrentUser, getVisibleUsers],
  (user, visibleUsers) => {
    return visibleUsers.sort((a, b) => {
      // Sort the current user first
      if (a.id === user.id) {
        return -1
      }
      if (b.id === user.id) {
        return 1
      }

      return a.onlineSince - b.onlineSince
    })
  }
)

export interface UserChatHeadProps {
  user: User;
  me: User;
  offline: boolean;
}

export const getUserChatHeads = createSelector(
  [getSelectedTeam, getSelectedOfficeId, getSortedVisibleUsers, getCurrentUser],
  (selectedTeam, selectedOfficeId, visibleUsers, currentUser): UserChatHeadProps[] => {
    let chatHeads: UserChatHeadProps[] = []
    visibleUsers.forEach((user: any) => {
      const userOnline = (
        selectedTeam.onlineUsers.includes(user.id) &&
        selectedTeam.offices[selectedOfficeId].onlineUsers.includes(user.id)
      )
      chatHeads.push({
        user,
        me: currentUser,
        offline: !userOnline
      })
      return
    })

    return chatHeads
  }
)
