import { axios, NOTIFICATION_API_ENDPOINT } from './http'
import { AxiosResponse } from 'axios'
import { Notification } from '../../../interfaces/notification'

export async function fetchNotifications(
  teamId: number,
  read: boolean
): Promise<AxiosResponse<Notification[]>> {
  return axios.get(`${NOTIFICATION_API_ENDPOINT}?read=${read ? 1 : 0}&team_id=${teamId}`)
}

export async function deleteNotification(
  teamId: number,
  notificationId: number
): Promise<AxiosResponse> {
  return axios.delete(`${NOTIFICATION_API_ENDPOINT}/${teamId}/${notificationId}`)
}

export async function deleteOldNotifications(
  teamId: number
): Promise<AxiosResponse> {
  return axios.delete(`${NOTIFICATION_API_ENDPOINT}/${teamId}`)
}

export async function markNotificationsAsRead(
  teamId: number,
  notificationIds: number[]
): Promise<AxiosResponse> {
  return axios.put(`${NOTIFICATION_API_ENDPOINT}/read`, {
    // eslint-disable-next-line camelcase
    team_id: teamId,
    // eslint-disable-next-line camelcase
    notification_ids: notificationIds
  })
}

