import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OfficeSwitcher, SideBar, TeamSwitcher, LoginForm } from '../components'
import { ChatHeads, Settings, Profile } from '../containers'
import { SectionLoading } from '../modules'
import { actions as sessionActions, isReady } from '../reducers/session'
import Shortcuts from '../services/shortcuts'
import { GlobalStyles } from '../styles'
import { RootState } from '../store'
import { RouteComponentProps } from 'react-router-dom'
import {
  actions as userActions,
  getCurrentUser,
  getSelectedOfficeId
} from '../reducers/user'
import Sockets from '../components/Sockets'
import { Types } from 'ably'
import { SocketContext } from '../contexts/socket'
import { getTeamsById } from '../reducers/teams'
import { Snackbar } from '../reducers/notification'
import { reloadHome } from '../utils/routes'
import { actions as notificationActions } from '../reducers/notification'
import { getLatestVersion } from '../services/api/library/app'
import { needUpdate } from '../services/helpers/app'
import { isElectron } from '../utils/env'
import { TrackContext, TrackDetail } from '../contexts/stream'
import Jitsi from 'lib-jitsi-meet'
import { actions as uiActions, getIsPictureInPicture } from '../reducers/ui'
import PictureInPicture from '../components/PictureInPicture'
import { layouts } from '../constants/call'
import { MirrorVisibility, Setting } from '../interfaces/setting'
import { getSettings } from '../reducers/settings'
import { User } from '../interfaces/user'
import { CallState } from '../constants/user'
import { createTracks } from '../utils/call'
import { DevicesContext, DevicesContextPropsMap } from '../contexts/devices'
import { mountDevices, remapMediadevices } from '../services/devices'

const Home: FC<RouteComponentProps> = () => {
  const [teamLoaded, setTeamLoaded] = useState(false)
  const [socketReady, setSocketReady] = useState(false)
  const [clientChannels, setClientChannels] = useState<{
    [key: string]: Types.RealtimeChannelCallbacks
  }>({})
  const [serverChannel, setServerChannel] =
    useState<Types.RealtimeChannelCallbacks | null>(null)
  const [tracks, setTracks] = useState<{ [key: string]: TrackDetail[] } | null>(
    null
  )
  const [devices, setDevices] = useState<DevicesContextPropsMap>({
    mics: [],
    speakers: [],
    cameras: []
  })

  const session = useSelector((state: RootState) => state.session)
  const ready = useSelector((state: RootState) => isReady(state))
  const user: User = useSelector((state: RootState) => getCurrentUser(state))
  const selectedOfficeId = useSelector((state: RootState) =>
    getSelectedOfficeId(state)
  )
  const teamsById = useSelector((state: RootState) => getTeamsById(state))
  const isPictureInPicture = useSelector((state: RootState) =>
    getIsPictureInPicture(state)
  )
  const prevIsPictureInPicture = useRef<boolean>()
  const settings: Setting = useSelector((state: RootState) =>
    getSettings(state)
  )
  const timer = useRef()
  const dispatch = useDispatch()

  const isUserLoaded = () => {
    return user && user.id !== 0
  }

  const onClosePictureInPicture = () => {
    dispatch(uiActions.togglePictureInPicture({ isPictureInPicture: false }))
    dispatch(userActions.changeLayout(user.selectedTeam, layouts.CHAT_HEAD))
  }

  const showPip = () => {
    if (tracks && isPictureInPicture !== prevIsPictureInPicture.current) {
      Object.values(tracks).forEach((ts) => {
        ts.forEach((t) => t.track?.detach())
      })
    }

    if (isPictureInPicture && isElectron()) {
      return (
        <PictureInPicture onClose={onClosePictureInPicture}>
          <ChatHeads isOnPip />
        </PictureInPicture>
      )
    }
    return null
  }

  const onFocus = useCallback(
    (e: FocusEvent) => {
      e.preventDefault()
      if (e.type === 'focus') {
        dispatch(
          uiActions.togglePictureInPicture({ isPictureInPicture: false })
        )
        dispatch(userActions.changeLayout(user.selectedTeam, layouts.CHAT_HEAD))
        return
      }
      if (e.type === 'blur') {
        dispatch(uiActions.togglePictureInPicture({ isPictureInPicture: true }))
        dispatch(
          userActions.changeLayout(user.selectedTeam, layouts.FULL_SCREEN)
        )
        return
      }
    },
    [user]
  )

  const onMediaDevicesChanged = useCallback(
    (payload: MediaDeviceInfo[]) => {
      // eslint-disable-next-line no-console
      console.log(payload)
      const res = remapMediadevices(payload)
      setDevices(res)
    },
    [devices]
  )

  useEffect(() => {
    if (
      isElectron() &&
      settings.enablePip &&
      user.callState === CallState.IN_CALL
    ) {
      window.addEventListener('focus', onFocus)
      window.addEventListener('blur', onFocus)
      return () => {
        window.removeEventListener('focus', onFocus)
        window.removeEventListener('blur', onFocus)
      }
    }
    return () => {}
  }, [onFocus, window, settings, user])

  useEffect(() => {
    Jitsi.init({
      enableAnalyticsLogging: true
    })
    Jitsi.mediaDevices.addEventListener(
      'mediaDevices.devicechange',
      onMediaDevicesChanged
    )
    return () => {
      Jitsi.mediaDevices.removeEventListener(
        'mediaDevices.devicechange',
        onMediaDevicesChanged
      )
    }
  }, [onMediaDevicesChanged])

  useEffect(() => {
    dispatch(sessionActions.loginSuccess())
    document.title = 'HQ Remote'
    mountDevices()
      .then(res => setDevices(res))
    if (user) {
      return () => {}
    }
    return () => {
      // eslint-disable-next-line
      console.log('home.unmount, unregistering all channels')
      Object.keys(clientChannels).forEach((channelName: string) => {
        const channel = clientChannels[channelName]
        if (channel) {
          if (channel.presence) {
            channel.presence.leave()
          }
          channel.unsubscribe()
        }
      })
    }
  }, [])

  useEffect(() => {
    if (user && user.id !== 0) {
      let currentUserOnline = teamsById[user.selectedTeam]?.onlineUsers.find(
        (u) => u === user.id
      )
      if (currentUserOnline) {
        setTeamLoaded(true)
      }
    }
  }, [user, teamsById, selectedOfficeId])

  useEffect(() => {
    window.scrollTo(0, 0)
    const notifyUpdateAvailable = () => {
      // check for update periodic
      const payload: Snackbar = {
        show: true,
        status: 'warning',
        message: 'An update is available, click here to reload',
        callback: () => {
          reloadHome(false)
        }
      }
      dispatch(notificationActions.updateSnackbar(payload))
    }
    if (!process.env.REACT_APP_DESKTOP_URL || isElectron()) {
      return () => {}
    }
    if (timer?.current) {
      clearInterval(timer.current)
    }
    // @ts-ignore
    timer.current = setInterval(() => {
      getLatestVersion()
        .then((result) => {
          if (needUpdate(result)) {
            notifyUpdateAvailable()
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.log('error getting version', e)
        })
    }, 60000)
    return () => {
      clearInterval(timer.current)
    }
  }, [ready])

  useEffect(() => {
    prevIsPictureInPicture.current = isPictureInPicture
  }, [isPictureInPicture])

  useEffect(() => {
    if (!user.id) {
      return
    }

    if (user.callState === CallState.NOT_IN_CALL) {
      if (settings.mirrorVisible === MirrorVisibility.VIDEO) {
        if (tracks && tracks[user.id]) {
          tracks[user.id].forEach(
            (pt) => pt.track && !pt.track.disposed && pt.track.dispose()
          )
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          setTracks((_) => {
            return {}
          })
        }
        createTracks(['video'], settings).then((locals) => {
          setTracks((prev) => {
            return {
              ...prev,
              [user.id]: [{ track: locals[0], muted: false }]
            }
          })
        })
        return
      }
      setTracks((prev) => {
        const prevTracks = prev ? prev[user.id] ?? [] : []
        prevTracks.find(
          (pt) => pt.track && !pt.track.disposed && pt.track.dispose()
        )
        return {}
      })
    }
  }, [
    settings.mirrorVisible,
    user.callState,
    settings.camera,
    settings.background,
    settings.backgroundSource
  ])

  if (session.isLoading) {
    return <SectionLoading />
  }

  return (
    <>
      {!session.isAuthenticated ? (
        <section className='flash-section'>
          <LoginForm />
        </section>
      ) : (
        <DevicesContext.Provider
          value={{
            devices,
            setDevices
          }}>
          <TrackContext.Provider
            value={{
              tracks: tracks,
              setTracks: setTracks
            }}>
            <SocketContext.Provider
              value={{
                serverChannel,
                clientChannels,
                setServerChannel,
                // @ts-ignore
                setClientChannels
              }}>
              {ready && isUserLoaded() ? (
                <>
                  <Sockets setSocketReady={setSocketReady} />
                  {socketReady && teamLoaded ? (
                    <>
                      <div className={`App ${(ready && 'show') || ''}`}>
                        <GlobalStyles />
                        <>
                          <>
                            <Shortcuts />
                            {process.env.REACT_APP_ACTIVATE_MULTIPLE_TEAMS ===
                              'true' && <TeamSwitcher />}
                            {process.env.REACT_APP_ACTIVATE_MULTIPLE_TEAMS ===
                              'true' &&
                              process.env
                                .REACT_APP_ACTIVATE_MULTIPLE_OFFICES ===
                                'true' && <OfficeSwitcher />}
                            <SideBar />
                            <ChatHeads isOnPip={false} />
                            {showPip()}
                          </>
                        </>
                      </div>
                      <Settings />
                      <Profile />
                    </>
                  ) : (
                    <SectionLoading />
                  )}
                </>
              ) : (
                <SectionLoading />
              )}
            </SocketContext.Provider>
          </TrackContext.Provider>
        </DevicesContext.Provider>
      )}
    </>
  )
}

export default Home
