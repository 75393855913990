import { Team } from '../interfaces/team'
import { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { Subscription } from '../interfaces/subscription'
import { Plan } from '../interfaces/api/paymentApi'

export const types = {
  INIT_REQUEST: 'teams/subscription/init',
  LOADED: 'teams/subscription/loaded',
  FAILED: 'teams/subscription/failed',
  UPDATE_PRICE_PLAN: 'teams/subscription/update-price-plan',
  UPDATED_PRICE_PLAN: 'teams/subscription/updated-price-plan',
  UPDATE_CARD: 'teams/subscription/update-card',
  NOTIFICATION: 'teams/subscription/notification'
}

export const initialState = {
  availablePlans: [],
  plans: null,
  hasLoaded: false,
  byId: { },
  allIds: [],
  errors: null,
  notification: null,
  processing: false
}

export interface SubscriptionState {
  availablePlans: Plan[];
  plans: any|null;
  hasLoaded: boolean;
  byId: { [key: number]: Team };
  allIds: any[];
  errors: Object | null;
  notification: Object | null;
  processing: boolean;
}

export interface SubscriptionPayloadAction extends PayloadAction<Object> {
  payload: {
    plans: Subscription|null;
    availablePlans: Plan[];
    teamId: number;
    prevPlan: number;
    planId: number;
    period: string;
    currency: string;
    notification: Object | null;
    processing: boolean;
  }
}
export default (state: SubscriptionState = initialState, action: SubscriptionPayloadAction) => {
  switch (action.type) {
    case types.LOADED:
      return {
        ...state,
        plans: {
          ...action.payload.plans
        },
        availablePlans: [
          ...action.payload.availablePlans
        ],
        processing: false,
        notification: null
      }
    case types.UPDATE_PRICE_PLAN:
      return {
        ...state,
        plans: {
          ...state.plans,
          pricePlan: action.payload.planId
        },
        processing: true,
        notification: null
      }
    case types.UPDATED_PRICE_PLAN:
      const { period, currency } = action.payload
      return {
        ...state,
        plans: {
          ...state.plans,
          period,
          currency
        },
        processing: false
      }
    case types.NOTIFICATION:
      return {
        ...state,
        processing: false,
        notification: {
          ...action.payload
        }
      }
    default:
      return state
  }
}

export const actions = {
  init: () => ({ type: types.INIT_REQUEST, payload: {} }),
  loaded: (data: any) => ({ type: types.LOADED, payload: data }),
  updatePricePlan: (teamId: number, currency: string, period: string) => ({
    type: types.UPDATE_PRICE_PLAN,
    payload: {
      teamId,
      currency,
      period
    }
  }),
  updatedPricePlan: (data: any) => ({
    type: types.UPDATED_PRICE_PLAN,
    payload: data
  }),
  actionNotification: (data: any) => ({
    type: types.NOTIFICATION,
    payload: data
  })
}

export const getSubscriptionPlan = (state: RootState) => state.subscription.plans
export const getAvailablePlans = (state: RootState) => state.subscription.availablePlans
export const getSubscriptionNotification = (state: RootState) => state.subscription.notification
