// @ts-ignore
import dotProp from 'dot-prop-immutable'
import { createSelector } from 'reselect'
import { timezone } from '../utils'
import { PayloadAction } from '@reduxjs/toolkit'
import { CallInterval, CallState, SnapshotEffect, UserAvailabilityState } from '../constants/user'
import { TeamState } from '../interfaces/user'
import { RootState } from '../store'
import { Office } from '../interfaces/team'
import { Types } from 'ably'
import { IInterval } from '../interfaces/interval'

// Action types
export const types = {
  CHANGE_LAYOUT: 'user/change-layout',
  TOGGLE_SHOW_OFFLINE: 'user/toggle-show-offline',
  TOGGLE_CALL_VIDEO: 'user/toggle-call-video',
  TOGGLE_CALL_MUTE: 'user/toggle-call-mute',
  TOGGLE_CALL_BLUR: 'user/toggle-call-blur',
  CHANGE_INTERVAL: 'user/change-interval',
  CHANGE_SNAPSHOT_EFFECT: 'user/change-snapshot-effect',
  CHANGE_STATE: 'user/change-state',
  CHANGE_STATUS_MESSAGE: 'user/change-status-message',
  CHANGE_GHOSTING: 'user/change-ghosting',
  CHANGE_CAN_GHOST: 'user/change-can-ghost',
  CHANGE_PROFILE: 'user/change-profile',
  SWITCH_TEAM: 'user/switch-team',
  USER_LOADED: 'user/loaded',
  USER_DATA_RESET: 'user/data-reset',
  USER_FAILED: 'user/failed',
  USER_ERROR: 'user/error',
  TAKE_PHOTO: 'user/photo/take',
  UPDATE_PHOTO: 'user/photo/update',
  OPEN_PROFILE: 'profile/open',
  CLOSE_PROFILE: 'profile/close',
  // office specific
  SWITCH_OFFICE: 'user/switch-office',
  SET_SELECTED_OFFICES: 'user/set-selected-offices',
  REMOVE_OFFICE: 'user/remove-office',
  REMOVE_TEAM_OFFICE: 'user/remove-team-office',
  ADD_TEAM_OFFICE: 'user/add-team-office',
  UPDATE_TEAM_OFFICE: 'user/update-team-office',
  // call specific
  UPDATE_CALL_STATE: 'user/team-state/update',
  UPDATE_CALL_VIDEO_STATE: 'user/team-state/update-video-state',
  UPDATE_CALL_AUDIO_STATE: 'user/team-state/update-audio-state'
}

export interface UserState {
  id: number;
  showProfile: boolean;
  hasLoaded: boolean;
  selectedTeam: number|null;
  selectedOffices: {[key: string]: number}|null;
  error: boolean;
  teamState: {[key: string]: TeamState};
  timezone: string;
  state: UserAvailabilityState;
  showOffline: boolean;
  callVideo: boolean;
  mute: boolean;
  blur: boolean;
}

// Initial state
export const initialState = {
  id: 0,
  showProfile: false,
  hasLoaded: false,
  selectedTeam: null,
  selectedOffices: {},
  error: false,
  teamState: {},
  timezone,
  state: UserAvailabilityState.AVAILABLE,
  showOffline: false,
  callVideo: true,
  mute: false,
  blur: false,
  video: false,
  audio: false
}

export interface UserPayloadAction extends PayloadAction<Object> {
  payload: {
    layout?: number,
    teamId?: number,
    officeId?: number,
    selectedOffices?: string[],
    message?: string,
    snapshotEffect?: SnapshotEffect,
    displayName?: string,
    timezone?: string,
    error?: string,
    interval?: CallInterval,
    ghosting?: boolean,
    canGhost?: boolean,
    mimeType?: string,
    imgData?: string,
    callRoom?: string|null,
    callState?: CallState,
    type?: string|null,
    state?: UserAvailabilityState,
    data?: any,
    userId?: number,
    office?: Office,
    showOffline?: boolean,
    callVideo: boolean,
    mute: boolean,
    blur: boolean,
    audio: boolean,
    video: boolean
  }
}

// Action Handler
export default (state: UserState = initialState, action: UserPayloadAction) => {
  switch (action.type) {
    case types.CHANGE_LAYOUT:
      return dotProp.set(
        state,
        `teamState.${action.payload.teamId}`,
        (teamState: TeamState) => {
          if (action.payload.layout) {
            teamState.layout = action.payload.layout
          }
          return teamState
        })
    case types.TOGGLE_SHOW_OFFLINE:
      return { ...state, showOffline: !state.showOffline }
    case types.TOGGLE_CALL_VIDEO:
      return { ...state, callVideo: action.payload.callVideo }
    case types.TOGGLE_CALL_MUTE:
      return { ...state, mute: action.payload.mute }
    case types.TOGGLE_CALL_BLUR:
      return { ...state, blur: action.payload.blur }
    case types.SWITCH_TEAM:
      if (state.selectedTeam === action.payload.teamId) {
        return state
      }
      const previouslySelectedTeam = state.selectedTeam

      return {
        ...state,
        selectedTeam: action.payload.teamId,
        previouslySelectedTeam: previouslySelectedTeam
      }

    case types.SWITCH_OFFICE:
      if (
        state.selectedOffices &&
        state.selectedOffices[String(action.payload.teamId)] === action.payload.officeId
      ) {
        return state
      }

      return dotProp.set(
        state,
        `selectedOffices.${action.payload.teamId}`,
        action.payload.officeId
      )

    case types.SET_SELECTED_OFFICES:
      return { ...state, selectedOffices: action.payload.selectedOffices }

    case types.REMOVE_OFFICE:
      return dotProp.delete(state, `selectedOffices.${action.payload.teamId}`)

    case types.USER_LOADED:
      return { ...state, hasLoaded: true, ...action.payload }

    case types.USER_DATA_RESET:
      return { ...initialState, hasLoaded: false }

    case types.USER_FAILED:
      return { ...state, hasLoaded: false }

    case types.USER_ERROR:
      return { ...state, hasLoaded: false, error: action.payload.error }
    case types.CHANGE_STATE:
      return dotProp.set(
        state,
        `teamState.${action.payload.teamId}`,
        (teamState: TeamState) => {
          if (action.payload.state) {
            teamState.state = action.payload.state
          }
          teamState.awaySince = null
          if (teamState.state === 'away') {
            teamState.awaySince = Math.floor(Number(new Date()) / 1000)
          }
          return teamState
        }
      )

    case types.CHANGE_STATUS_MESSAGE:
      return dotProp.set(
        state,
        `teamState.${action.payload.teamId}.statusMessage`,
        action.payload.message
      )

    case types.CHANGE_SNAPSHOT_EFFECT:
      return dotProp.set(
        state,
        `teamState.${action.payload.teamId}.snapshotEffect`,
        action.payload.snapshotEffect
      )

    case types.CHANGE_INTERVAL:
      return dotProp.set(
        state,
        `teamState.${action.payload.teamId}.interval`,
        action.payload.interval
      )

    case types.CHANGE_CAN_GHOST:
      return dotProp.set(
        state,
        `teamState.${action.payload.teamId}.canGhost`,
        action.payload.canGhost
      )

    case types.CHANGE_PROFILE:
      if (!action.payload.teamId) {
        return dotProp.set(
          state
        )
      }
      return dotProp.set(
        state,
        `teamState.${action.payload.teamId}`,
        {
          ...state.teamState[action.payload.teamId],
          displayName: action.payload.displayName,
          timezone: action.payload.timezone
        }
      )

    case types.UPDATE_PHOTO:
      return dotProp.set(
        state,
        `teamState.${action.payload.teamId}.photo`,
        `data:${action.payload.mimeType};base64,${action.payload.imgData}`
      )

    case types.UPDATE_CALL_STATE:
      if (!action.payload.teamId) {
        return dotProp.set(
          state
        )
      }
      return {
        ...dotProp.set(
          state,
          `teamState.${action.payload.teamId}`,
          {
            ...state.teamState[action.payload.teamId],
            callState: action.payload.callState,
            callRoom: action.payload.callRoom
          }
        ),
        callState: action.payload.callState
      }
    case types.UPDATE_CALL_VIDEO_STATE:
      if (!action.payload.teamId) {
        return dotProp.set(
          state
        )
      }
      return {
        ...dotProp.set(
          state,
          `teamState.${action.payload.teamId}`,
          {
            ...state.teamState[action.payload.teamId],
            video: action.payload.video
          }
        )
      }

    case types.UPDATE_CALL_AUDIO_STATE:
      if (!action.payload.teamId) {
        return dotProp.set(
          state
        )
      }
      return {
        ...dotProp.set(
          state,
          `teamState.${action.payload.teamId}`,
          {
            ...state.teamState[action.payload.teamId],
            video: action.payload.audio
          }
        )
      }

    case types.OPEN_PROFILE:
      return {
        ...state,
        showProfile: true
      }

    case types.CLOSE_PROFILE:
      dotProp.set(
        state,
        `teamState.${action.payload.teamId}.displayName`,
        action.payload.displayName
      )
      return {
        ...state,
        showProfile: false
      }

    default:
      return state
  }
}

// Actions
export const actions = {
  switchTeam: (teamId: number) => ({ type: types.SWITCH_TEAM, payload: { teamId } }),
  switchOffice: (teamId: number, officeId: number) => ({
    type: types.SWITCH_OFFICE,
    payload: { teamId, officeId }
  }),
  setSelectedOffices: (selectedOffices: string[]) => ({
    type: types.SET_SELECTED_OFFICES,
    payload: { selectedOffices }
  }),
  addTeamOffice: (teamId: number, office: Office) => ({
    type: types.ADD_TEAM_OFFICE,
    payload: { teamId, office }
  }),
  updateTeamOffice: (teamId: number, office: Office) => ({
    type: types.UPDATE_TEAM_OFFICE,
    payload: { teamId, office }
  }),
  removeTeamOffice: (teamId: number, officeId: number) => ({
    type: types.REMOVE_TEAM_OFFICE,
    payload: { teamId, officeId }
  }),
  removeOffice: (teamId: number) => ({
    type: types.REMOVE_OFFICE,
    payload: { teamId }
  }),
  loaded: (data: any) => ({ type: types.USER_LOADED, payload: data }),
  resetUserData: () => ({ type: types.USER_DATA_RESET, payload: {} }),
  failed: () => ({ type: types.USER_FAILED }),
  error: (error: any) => ({ type: types.USER_ERROR, payload: { error } }),
  changeLayout: (teamId: number, layout: number) => ({
    type: types.CHANGE_LAYOUT,
    payload: { teamId, layout }
  }),
  toggleCallVideo: (callVideo: boolean) => ({
    type: types.TOGGLE_CALL_VIDEO,
    payload: { callVideo }
  }),
  toggleCallMute: (mute: boolean) => ({
    type: types.TOGGLE_CALL_MUTE,
    payload: { mute }
  }),
  toggleCallBlur: (blur: boolean) => ({
    type: types.TOGGLE_CALL_BLUR,
    payload: { blur }
  }),
  toggleShowOffline: () => ({
    type: types.TOGGLE_SHOW_OFFLINE
  }),
  changeInterval: (
    teamId: number,
    interval: IInterval,
    serverChannel: Types.RealtimeChannelCallbacks
  ) => ({
    type: types.CHANGE_INTERVAL,
    payload: { interval, teamId, serverChannel }
  }),
  changeSnapshotEffect: (
    teamId: number,
    snapshotEffect: string,
    serverChannel: Types.RealtimeChannelCallbacks
  ) => ({
    type: types.CHANGE_SNAPSHOT_EFFECT,
    payload: { snapshotEffect, teamId, serverChannel }
  }),
  changeStatusMessage: (
    teamId: number,
    message: string,
    serverChannel: Types.RealtimeChannelCallbacks
  ) => ({
    type: types.CHANGE_STATUS_MESSAGE,
    payload: { message, teamId, serverChannel }
  }),
  changeState: (
    teamId: number,
    state: UserAvailabilityState,
    serverChannel: Types.RealtimeChannelCallbacks
  ) => ({
    type: types.CHANGE_STATE,
    payload: { state, teamId, serverChannel }
  }),
  changeCanGhost: (teamId: number, canGhost: boolean) => ({
    type: types.CHANGE_CAN_GHOST,
    payload: { canGhost, teamId }
  }),
  changeGhosting: (teamId: number, ghosting: boolean) => ({
    type: types.CHANGE_GHOSTING,
    payload: { ghosting, teamId }
  }),
  changeProfile: (
    teamId: number,
    displayName: string,
    selectedTimezone: string,
    serverChannel: Types.RealtimeChannelCallbacks
  ) => ({
    type: types.CHANGE_PROFILE,
    payload: { teamId, displayName, timezone: selectedTimezone, serverChannel }
  }),
  updatePhoto: (
    imgData: any,
    mimeType: string,
    teamId: number,
    serverChannel: Types.RealtimeChannelCallbacks
  ) => ({
    type: types.UPDATE_PHOTO,
    payload: { imgData, mimeType, teamId, serverChannel }
  }),
  openProfile: () => ({ type: types.OPEN_PROFILE, payload: {} }),
  closeProfile: () => ({ type: types.CLOSE_PROFILE, payload: {} }),
  updateCallState: (payload: any) => ({
    type: types.UPDATE_CALL_STATE,
    payload: { ...payload }
  }),
  updateCallVideoState: (payload: any) => ({
    type: types.UPDATE_CALL_VIDEO_STATE,
    payload: { ...payload }
  }),
  updateCallAudioState: (payload: any) => ({
    type: types.UPDATE_CALL_AUDIO_STATE,
    payload: { ...payload }
  })
}

// Selectors
export const getSelectedTeamId = (state: RootState) => state.user.selectedTeam
export const getSelectedOfficeId = (state: RootState) =>
  state.user.selectedOffices[state.user.selectedTeam]
export const getCurrentUser = (state: RootState) => state.user
export const getAllTeamStates = (state: RootState) => state.user.teamState
export const getTeamState = createSelector(
  [getAllTeamStates, getSelectedTeamId],
  (teamStates, selectedTeam) => teamStates[selectedTeam]
)

export const getCurrentUserLayout = createSelector(
  [getCurrentUser, getSelectedTeamId],
  (user, selectedTeam) => user.teamState[selectedTeam].layout
)

export const getShowOffline = (state: RootState) => state.user.showOffline
export const getCallVideo = (state: RootState) => state.user.callVideo
export const getCallMute = (state: RootState) => state.user.mute
export const getCallBlur = (state: RootState) => state.user.blur

export const getNextUserState = (currentState: UserAvailabilityState) => {
  if (currentState === UserAvailabilityState.AVAILABLE) {
    return UserAvailabilityState.BUSY
  }
  if (currentState === UserAvailabilityState.BUSY) {
    return UserAvailabilityState.AWAY
  }
  return UserAvailabilityState.AVAILABLE
}

export const getNextUserCallLayout = (currentLayout: number) => {
  const nextLayout = currentLayout + 1
  if (nextLayout > 3) {
    return 1
  }
  return nextLayout
}
