import React, {
  ChangeEvent,
  MouseEvent,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { JmtLocalTrack, JtmTDevice } from 'lib-jitsi-meet'
import { capitalize } from 'lodash'
import { actions as settingAction, getSettings } from '../../reducers/settings'
import Modal, { ModalTitle, ModalContainer } from '../../modules/Modal'
import SettingsVideo from './SettingsVideo'
import { RootState } from '../../store'
import { MirrorVisibility, Setting } from '../../interfaces/setting'
import SettingsMic from './SettingsMic'
import { ButtonStandard } from '../../modules'
import { isElectron } from '../../utils/env'
import { VirtualBackgrounds } from '../../services/video/constants'
import fallback from '../../assets/img/background.png'
import { getIsPictureInPicture } from '../../reducers/ui'
import { createTracks } from '../../utils/call'
import VideoBackground from './VideoBackground'
import { DevicesContext } from '../../contexts/devices'
import { TrackContext } from '../../contexts/stream'
import { User } from '../../interfaces/user'
import { getCurrentUser } from '../../reducers/user'
import { CallState } from '../../constants/user'

const Settings: React.FC = () => {
  const { devices } = useContext(DevicesContext)
  const { tracks } = useContext(TrackContext)

  const inputFileRef = useRef<HTMLInputElement>(null)
  const [locals, setLocals] = useState<Array<JmtLocalTrack>>([])

  const isPictureInPicture = useSelector((state: RootState) =>
    getIsPictureInPicture(state)
  )
  const settings: Setting = useSelector((state: RootState) =>
    getSettings(state)
  )
  const user: User = useSelector((state: RootState) => getCurrentUser(state))

  const dispatch = useDispatch()

  const onClose = (e: MouseEvent) => {
    e.preventDefault()
    dispatch(settingAction.close())
  }

  const onChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    dispatch(settingAction.onChangeSetting(e.currentTarget))
  }

  const onBackgroundImageChange = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const value = parseInt(e.currentTarget.value, 8)
    if (value === -2) {
      inputFileRef.current?.click()
      return
    }

    let imageUrl: string
    if (value === -1) {
      imageUrl = fallback
    } else {
      imageUrl = settings.backgroundSources?.[value] ?? ''
    }

    dispatch(
      settingAction.onChangeSetting({
        name: 'backgroundSource',
        value: imageUrl
      })
    )
  }

  const onUpdateBackgroundImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const fileReader: FileReader = new FileReader()
      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        const result = event.target?.result
        if (result) {
          const backgrounds = settings.backgroundSources
            ? [...settings.backgroundSources, result.toString()]
            : [result.toString()]

          dispatch(
            settingAction.updateSettingState({
              name: 'backgroundSources',
              value: backgrounds
            })
          )
        }
      }
      fileReader.readAsDataURL(e.currentTarget.files[0])
    }
  }

  const handleSaveSetting = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(settingAction.updateSetting())
  }

  const getTrack = (device: JtmTDevice) => {
    if (locals.length > 0) {
      return locals.find((l) => l.getType() === device)
    }
    const track = (
      (tracks &&
        tracks[user.id]?.find((t) => t.track?.getType() === device)?.track) ||
      // eslint-disable-next-line no-undefined
      undefined
    )
    return track && track as unknown as JmtLocalTrack
  }

  const disposeLocals = async () => {
    return new Promise((resolve) => {
      locals.forEach(local => local.dispose())
      resolve(true)
    })
  }

  useEffect(() => {
    if (!settings.showSetting) {
      if (locals.length > 0) {
        disposeLocals()
          .then(() => setLocals([]))
      }
      return
    }

    if (user.callState === CallState.IN_CALL) {
      if (locals.length > 0) {
        disposeLocals()
          .then(() => setLocals([]))
      }
      return
    }

    createTracks(['audio', 'video'], settings).then((resp) => setLocals(resp))
  }, [settings.showSetting, user.callState])

  useEffect(() => {
    if (!settings.showSetting || user.callState === CallState.IN_CALL) {
      // Should handled in Sidebar
      return
    }

    createTracks(['video'], settings).then((resp) => {
      locals.find((t) => t.getType() === 'video')?.dispose()
      setLocals((prev) => [
        // eslint-disable-next-line max-nested-callbacks
        ...prev.filter((t) => t.getType() !== 'video'),
        ...resp
      ])
    })
  }, [settings.camera, settings.background, settings.backgroundSource])

  useEffect(() => {
    if (!settings.showSetting || user.callState === CallState.IN_CALL) {
      // should handled in Sidebar
      return
    }

    createTracks(['audio'], settings).then((resp) => {
      locals.find((t) => t.getType() === 'audio')?.dispose()
      setLocals((prev) => [
        // eslint-disable-next-line max-nested-callbacks
        ...prev.filter((t) => t.getType() !== 'audio'),
        ...resp
      ])
    })
  }, [settings.mic])

  useEffect(() => {
    if (!settings.showSetting) {
      return
    }
    if (locals.length === 0) {
      return
    }

    const audioTrack = locals.find(
      (t) => (t.getType() as JtmTDevice) === 'audio'
    )
    if (
      !devices.mics.some(
        (device) => device.deviceId === audioTrack?.getDeviceId()
      )
    ) {
      dispatch(
        settingAction.updateSettingState({
          name: 'mic',
          value:
            devices.mics.find((m) =>
              m.label.toLocaleLowerCase().includes('default')
            )?.deviceId ?? devices.mics[0].deviceId
        })
      )
    }

    const videoTrack = locals.find(
      (t) => (t.getType() as JtmTDevice) === 'video'
    )
    if (
      !devices.cameras.some(
        (device) => device.deviceId === videoTrack?.getDeviceId()
      )
    ) {
      dispatch(
        settingAction.updateSettingState({
          name: 'mic',
          value:
            devices.cameras.find((m) =>
              m.label.toLocaleLowerCase().includes('default')
            )?.deviceId ?? devices.cameras[0].deviceId
        })
      )
    }
  }, [devices, locals, settings.showSetting])

  if (!settings.showSetting) {
    return null
  }

  const {
    mirrorVisible,
    notificationSounds,
    background,
    // backgroundSource,
    backgroundSources,
    noiseReduction,
    enablePip,
    camera,
    mic,
    dateFormat
  } = settings

  const backgrounds: Array<{
    label?: string
    value: string
    thumbnail?: string
  }> = backgroundSources
    ? backgroundSources.map((bg, bgI) => {
      return {
        value: bgI.toString(),
        thumbnail: bg
      }
    })
    : []

  backgrounds.push(
    ...[
      { value: '-1', thumbnail: fallback },
      { label: 'Upload', value: '-2' }
    ]
  )

  return (
    <Modal
      show={settings.showSetting}
      onClose={onClose}
      style={{
        background: '#fff'
      }}>
      <ModalTitle title='Settings' />
      <ModalContainer>
        <form action='#' method='post' className='modal__form'>
          <div className='modal__av-settings'>
            <div className='hardware-setup'>
              <SettingsVideo
                videoTrack={getTrack('video') ?? null}
                camera={camera}
                devices={devices.cameras}
                onChange={onChange}
                blur={!!background}
              />
              {devices.mics && (
                <SettingsMic
                  mic={mic}
                  devices={devices.mics}
                  onChange={onChange}
                  audioTrack={getTrack('audio') ?? null}
                />
              )}
            </div>
            <div className='modal__form__row'>
              <label className='modal__form__label__setting'>
                Make my chat head:
              </label>
              <div className='skinned-wrapper skinned-wrapper-white'>
                <div className='skinned-text'>{capitalize(mirrorVisible)}</div>
                <select
                  name='mirrorVisible'
                  className='skinned-select'
                  value={mirrorVisible}
                  onChange={onChange}>
                  <option value={MirrorVisibility.VIDEO}>Video</option>
                  <option value={MirrorVisibility.PHOTO}>Photo</option>
                </select>
              </div>
            </div>
            <div className='modal__form__row'>
              <label className='modal__form__label__setting'>
                Notification sounds:
              </label>
              <div className='skinned-wrapper skinned-wrapper-white'>
                <div className='skinned-text'>
                  {notificationSounds ? 'On' : 'Off'}
                </div>
                <select
                  name='notificationSounds'
                  className='skinned-select'
                  onChange={onChange}
                  value={notificationSounds ? 'true' : 'false'}>
                  <option value='true'>On</option>
                  <option value='false'>Off</option>
                </select>
              </div>
            </div>

            <div className='modal__form__row'>
              <label className='modal__form__label__setting'>
                Video background:
              </label>
              <div className='skinned-wrapper skinned-wrapper-white'>
                <div className='skinned-text'>{capitalize(background)}</div>
                <select
                  name='background'
                  className='skinned-select'
                  onChange={onChange}
                  value={background}>
                  {VirtualBackgrounds.map((v, i) => (
                    <option value={v} key={i}>
                      {capitalize(v)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {settings.background === 'image' && (
              <>
                <div
                  className='modal__form__row'
                  style={{ overflow: 'visible' }}>
                  <label className='modal__form__label__setting'>
                    Background Image:
                  </label>
                  <VideoBackground
                    backgrounds={backgrounds}
                    onChange={onBackgroundImageChange}
                  />
                  <input
                    style={{ display: 'none' }}
                    ref={inputFileRef}
                    name='uploadImage'
                    type='file'
                    onChange={onUpdateBackgroundImage}
                  />
                </div>
              </>
            )}
            <div className='modal__form__row'>
              <label className='modal__form__label__setting'>
                Reduce background noise:
              </label>
              <div className='skinned-wrapper skinned-wrapper-white'>
                <div className='skinned-text'>
                  {noiseReduction ? 'On' : 'Off'}
                </div>
                <select
                  name='noiseReduction'
                  className='skinned-select'
                  onChange={onChange}
                  value={noiseReduction ? 'true' : 'false'}>
                  <option value='true'>On</option>
                  <option value='false'>Off</option>
                </select>
              </div>
            </div>
            <div className='modal__form__row'>
              <label className='modal__form__label__setting'>
                Date Format:
              </label>
              <div className='skinned-wrapper skinned-wrapper-white'>
                <div className='skinned-text'>{dateFormat}</div>
                <select
                  name='dateFormat'
                  className='skinned-select'
                  value={dateFormat}
                  onChange={onChange}>
                  <option value={`DD/MM/YYYY`}>DD/MM/YYYY</option>
                  <option value={`MM/DD/YYYY`}>MM/DD/YYYY</option>
                </select>
              </div>
            </div>
            {isElectron() && !isPictureInPicture && (
              <div className='modal__form__row'>
                <label className='modal__form__label__setting'>
                  Enable PIP
                </label>
                <div className='skinned-wrapper skinned-wrapper-white'>
                  <div className='skinned-text'>{enablePip ? 'On' : 'Off'}</div>
                  <select
                    name='enablePip'
                    className='skinned-select'
                    onChange={onChange}
                    value={enablePip ? 'true' : 'false'}>
                    <option value='true'>On</option>
                    <option value='false'>Off</option>
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className='modal__form__row last'>
            <ButtonStandard className='btn' onClick={handleSaveSetting}>
              Save
            </ButtonStandard>
          </div>
        </form>
      </ModalContainer>
    </Modal>
  )
}

export default Settings
