import { schema } from 'normalizr'

const mergedKeys = [
  'awaySince',
  'callRoom',
  'callState',
  'displayName',
  'idleSince',
  'office',
  'photo',
  'state',
  'statusMessage',
  'timezone',
  'canGhost',
  'ghosting',
  'role'
]

const user = new schema.Entity(
  'users',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
        ...entityB,
        teamState: { ...entityA.teamState, ...entityB.teamState }
      }
    },
    processStrategy: (u, t) => {
      u.teamState = {}
      u.teamState[t.id] = {}
      mergedKeys.forEach((key) => {
        u.teamState[t.id][key] = u[key]
        delete u[key]
      })
      return u
    }
  }
)

export const office = new schema.Entity('offices')

export const team = [
  new schema.Entity('teams', {
    users: [user]
    // offices: [ office ]
  })
]
