import { all, takeEvery, select, put } from 'redux-saga/effects'
import React from 'react'
import { getCurrentUser } from '../../reducers/user'
import { actions as userActions } from '../../reducers/user'
import { types } from '../../reducers/realtime/call'
import {
  actions as callActions,
  getActiveSpeaker
} from '../../reducers/call'
import { User } from '../../interfaces/user'
import { actions as userRealtimeActions } from '../../reducers/realtime/user'
import { sendEndCall, sendStartCall } from '../../services/socket/socket'
import { Types } from 'ably'
import { TrackDetail } from '../../contexts/stream'
import { CHANNEL_CLIENT_CALL, CHANNEL_CLIENT_TEAM } from '../../constants/socket'
import { Setting } from '../../interfaces/setting'
import { actions as uiActions } from '../../reducers/ui'
import { getSettings } from '../../reducers/settings'
import { actions as notificationActions } from '../../reducers/notification'


export function *sendStart(action: any) {
  const { clientChannels, userId, callIndex } = action.payload
  const me: User = yield select(getCurrentUser)
  const callChannel = clientChannels[`${CHANNEL_CLIENT_CALL}:${me.selectedTeam}`]
  if (!callChannel) {
    // alert user for  subscription error
    yield put(notificationActions.updateSnackbar({
      show: true,
      status: 'warning',
      message: `access denied on call channel, please check your subscription`,
      timeout: 5000
    }))
    return
  }
  sendStartCall(
    callChannel,
    {
      teamId: me.selectedTeam,
      officeId: Number(me.selectedOffices[me.selectedTeam]),
      callerId: me.id,
      calleeId: userId,
      index: callIndex
    }
  )
}

export function *sendLeave(action: {
  payload: {
    clientChannels: {[key: string]: Types.RealtimeChannelCallbacks},
    setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>
  }
}) {
  const { clientChannels, setTracks } = action.payload
  const activeSpeaker: String = yield select(getActiveSpeaker)
  const me: User = yield select(getCurrentUser)
  const settings: Setting = yield select(getSettings)

  sendEndCall(clientChannels[`${CHANNEL_CLIENT_CALL}:${me.selectedTeam}`], {
    teamId: me.selectedTeam || 0,
    userId: me.id || 0
  })
  yield put(userRealtimeActions.receiveCallEnded({
    userId: me.id,
    serverChannel: clientChannels[`${CHANNEL_CLIENT_TEAM}:${me.selectedTeam}`],
    setTracks
  }))
  yield all([
    put(callActions.updateActiveSpeaker(Number(activeSpeaker), false)),
    put(userActions.toggleCallVideo(true))
  ])
  if (settings.enablePip) {
    yield put(uiActions.togglePictureInPicture({ isPictureInPicture: false }))
  }
}

export function *triggerToggleMute(action: any) {
  const { mute } = action.payload
  yield all([
    put(userActions.toggleCallMute(mute))
  ])
}

export function *triggerToggleCallBlur(action: any) {
  const { blur } = action.payload
  // eslint-disable-next-line no-console
  console.log('blur', blur)
  // yield call(toggleVideoBlur, blur)
}

export function *changeVideoSource(action: any) {
  const { id } = action.payload
  // eslint-disable-next-line no-console
  console.log('id', id)
  // yield all([
  //   call(changeVideoSrc, id)
  // ])
}

export function *changeAudioSource(action: any) {
  const { id } = action.payload
  // eslint-disable-next-line no-console
  console.log('id', id)
  // yield all([
  //   call(changeAudioSrc, id)
  // ])
}

export default function *realtimeCallWatcher() {
  yield all([
    takeEvery(types.SEND_START, sendStart),
    // @ts-ignore
    takeEvery(types.SEND_LEAVE, sendLeave),
    takeEvery(types.CHANGE_VIDEO_SOURCE, changeVideoSource),
    takeEvery(types.CHANGE_AUDIO_SOURCE, changeAudioSource)
  ])
}
