export function timeout(milliseconds: number, promise: Promise<any>): Promise<Object> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('408'))

      return
    }, milliseconds)

    promise.then(resolve, reject)
  })
}
