import React, { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  actions as callActions,
  getActiveSpeaker,
  getActiveSpeakerScreen,
  getCall,
  getIsActiveSpeakerManual
} from '../reducers/call'
import { actions as realtimeCallActions } from '../reducers/realtime/call'
import { getSnackbar, actions as notificationActions } from '../reducers/notification'
import { actions as realtimeTeamActions } from '../reducers/realtime/team'
import { actions as userActions, getSelectedTeamId } from '../reducers/user'
import { TeamState, User } from '../interfaces/user'
import { RootState } from '../store'
import { UserAvailabilityState } from '../constants/user'
import { CALL_STATE, layouts } from '../constants/call'
import { isTeamSubscriptionActive } from '../reducers/teams'
import { SocketContext } from '../contexts/socket'
import { CHANNEL_CLIENT_TEAM } from '../constants/socket'
import { TrackDetail } from '../contexts/stream'
import { getNextCallRoomIndex } from '../components/Helpers'

interface OtherChatHeadProps {
  user: User|null;
  me: User|null;
  offline: boolean;
  layout: number;
  style: React.CSSProperties;
  showPin: boolean;
  tracks: Array<TrackDetail>
  isOnPip: boolean
}

export default (ChatHead: any) => {
  const OtherChatHead: React.FC<OtherChatHeadProps> = (props) => {
    const selectedTeamId = useSelector((state: RootState) => getSelectedTeamId(state))
    const activeSpeaker = useSelector((state: RootState) => getActiveSpeaker(state))
    const activeSpeakerScreen = useSelector((state: RootState) => getActiveSpeakerScreen(state))
    const isActiveSpeakerManual = useSelector((state: RootState) => getIsActiveSpeakerManual(state))
    const notificationBar = useSelector((state: RootState) => getSnackbar(state))
    const call = useSelector((state: RootState) => getCall(state))
    const isSubscribed = useSelector(
      (state: RootState) => isTeamSubscriptionActive(
        state, props.me ? props.me.selectedTeam : 0))
    const {
      clientChannels
    } = useContext(SocketContext)
    const dispatch = useDispatch()
    const teamState: TeamState | undefined = props.user?.teamState[selectedTeamId]

    const handlePin = (
      event: any,
      userId: number
    ) => {
      event.preventDefault()
      // unpin user manually to fullscreen view
      if (isActiveSpeakerManual) {
        return dispatch(callActions.updateActiveSpeaker(userId, false))
      }
      // pin user manually to fullscreen view
      return dispatch(callActions.updateActiveSpeaker(userId, true))
    }
    const handleClick = useCallback((
      event: any,
      userId: number,
      isInCall: boolean,
      connecting: boolean,
      state: string,
      isIdle: boolean,
      self: User,
      username: string = ''
      // eslint-disable-next-line max-params
    ) => {
      event.preventDefault()
      if (state === UserAvailabilityState.AWAY) {
        return dispatch(notificationActions.updateSnackbar({
          show: true,
          status: 'warning',
          message: 'You cannot call someone while they are away.',
          timeout: 3000
        }))
      }

      if (
        isIdle &&
        !notificationBar.show &&
        notificationBar.message.indexOf('inactive') === -1
      ) {
        return dispatch(notificationActions.updateSnackbar({
          show: true,
          status: 'warning',
          message: `${username} is inactive. Click again to call them anyway`,
          timeout: 3000
        }))
      }

      if (self.teamState[self.selectedTeam].state === UserAvailabilityState.AWAY) {
        return dispatch(notificationActions.updateSnackbar({
          show: true,
          status: 'warning',
          message: 'You cannot make a call while you\'re away.',
          timeout: 3000
        }))
      }

      if (!isSubscribed) {
        return dispatch(notificationActions.updateSnackbar({
          show: true,
          status: 'warning',
          message: 'You cannot make a call on free plan.',
          timeout: 3000
        }))
      }

      if (self.callState === CALL_STATE.IN_CALL && isInCall) {
        // unpin user manually to fullscreen view
        if (
          props.layout === layouts.FULL_SCREEN &&
          Number(activeSpeaker) === userId &&
          activeSpeakerScreen === null
        ) {
          // minimize
          dispatch(userActions.changeLayout(self.selectedTeam, layouts.CHAT_HEAD))
          return dispatch(callActions.updateActiveSpeaker(userId, false))
        }
        if (props.layout !== layouts.FULL_SCREEN) {
          dispatch(userActions.changeLayout(self.selectedTeam, layouts.FULL_SCREEN))
        }
        // pin user manually to fullscreen view
        return dispatch(callActions.updateActiveSpeaker(userId, true))
      }

      const teamChannel = clientChannels[`${CHANNEL_CLIENT_TEAM}:${selectedTeamId}`]
      if (!teamChannel) {
        return null
      }

      if (state === UserAvailabilityState.BUSY || state === UserAvailabilityState.GHOSTING) {
        return dispatch(realtimeTeamActions.sendBusyKnock(userId, teamChannel))
      }

      let callIndex = getNextCallRoomIndex(Object.keys(call.rooms))

      if (
        [CALL_STATE.IN_CALL, CALL_STATE.JOINING_CALL].includes(self.callState)
      ) {
        dispatch(
          notificationActions.updateSnackbar({
            show: true,
            status: 'info',
            message: `inviting ${username}...`,
            timeout: 3000
          })
        )
        Object.keys(call.rooms).forEach((k: string) => {
          if (call.rooms[Number(k)].indexOf(self.id) >= 0) {
            callIndex = Number(k)
          }
        })
      } else {
        if (
          [CALL_STATE.IN_CALL, CALL_STATE.JOINING_CALL].includes(
            teamState?.callState ?? CALL_STATE.NOT_IN_CALL
          )
        ) {
          dispatch(
            notificationActions.updateSnackbar({
              show: true,
              status: 'info',
              message: `joining call...`,
              timeout: 3000
            })
          )
        } else {
          dispatch(
            notificationActions.updateSnackbar({
              show: true,
              status: 'info',
              message: `starting call...`,
              callback: () => {
                dispatch(
                  notificationActions.updateSnackbar({
                    show: false,
                    status: 'info',
                    message: ''
                  })
                )
              }
            })
          )
        }

        // joining
        Object.keys(call.rooms).forEach((k: string) => {
          if (call.rooms[Number(k)].indexOf(userId) >= 0) {
            callIndex = Number(k)
          }
        })
      }
      return dispatch(realtimeCallActions.sendStart(
        clientChannels,
        userId,
        callIndex
      ))
    }, [call])


    if (!props.user) {
      return null
    }

    let chatHeadCall = {
      isConnected: false,
      room: ''
    }

    if (props.user.teamState[selectedTeamId].callState !== CALL_STATE.NOT_IN_CALL) {
      chatHeadCall = {
        isConnected: props.user.teamState[selectedTeamId].callState === CALL_STATE.IN_CALL,
        room: String(props.user.teamState[selectedTeamId].callRoom)
      }
    }
    return (
      <ChatHead
        tracks={props.tracks}
        focused={true}
        myself={false}
        onClick={handleClick}
        id={props.user.id}
        call={chatHeadCall}
        me={props.me}
        user={props.user}
        name={props.user.teamState[selectedTeamId].displayName || props.user.name}
        awaySince={props.user.teamState[selectedTeamId].awaySince}
        idleSince={props.user.teamState[selectedTeamId].idleSince}
        isIdle={props.user.teamState[selectedTeamId].idleSince !== null}
        photo={props.user.teamState[selectedTeamId].photo}
        state={(
          props.offline ? UserAvailabilityState.AWAY : (
            props.user.teamState[selectedTeamId].state ?? UserAvailabilityState.AVAILABLE
          )
        )}
        offline={props.offline}
        statusMessage={props.user.teamState[selectedTeamId].statusMessage}
        hideDetails={
          props.layout === layouts.FULL_SCREEN
        }
        layout={props.layout}
        timezone={props.user.teamState[selectedTeamId].timezone || 'UTC'}
        style={props.style}
        handlePin={handlePin}
        pin={
          isActiveSpeakerManual &&
          props.user.id === activeSpeaker &&
          activeSpeakerScreen === null
        }
        showPin={props.showPin}
        isOnPip={props.isOnPip}
      />
    )
  }

  OtherChatHead.defaultProps = {
    user: null,
    layout: layouts.CHAT_HEAD,
    showPin: false
  }

  return OtherChatHead
}
