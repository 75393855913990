import { all } from 'redux-saga/effects'

import realtimeCallWatcher from './realtime/call'
import realtimeTeamWatcher from './realtime/team'
import realtimeUserWatcher from './realtime/user'
import sessionWatcher from './session'
import userWatcher from './user'
import countdownWatcher from './countdown'
import teamsWatcher from './teams'
import notificationsWatcher from './notifications'
import callWatcher from './call'
import settingsWatcher from './settings'
import profileWatcher from './profile'
import subscriptionWatcher from './subscription'

export default function *rootSaga() {
  yield all([
    realtimeCallWatcher(),
    realtimeTeamWatcher(),
    realtimeUserWatcher(),
    sessionWatcher(),
    countdownWatcher(),
    userWatcher(),
    teamsWatcher(),
    notificationsWatcher(),
    callWatcher(),
    settingsWatcher(),
    profileWatcher(),
    subscriptionWatcher()
  ])
}
