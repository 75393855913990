import React from 'react'
import {
  BlackButton,
  InputForm,
  InputFormAppendText,
  SignUpFormContainer,
  SignUpForm,
  SignUpFormGroup,
  SignUpFormRow
} from '../../modules'
import { FormFieldProps } from './interface'

interface FormTeamUrlProps extends FormFieldProps {
  data: string;
}

const TeamUrl: React.FC<FormTeamUrlProps> = (props) => {
  const dataMatch = () => {
    return props.data.match(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)
  }
  if (props.currentStep !== props.formStep) {
    return null
  }

  return (
    <SignUpFormContainer>
      <SignUpForm>
        <h1>Get your URL</h1>
        <p>
          This is the address that you and your team will use to sign in to
          HQ Remote.
        </p>
        <SignUpFormGroup>
          <SignUpFormRow>
            <InputForm
              type='text'
              placeholder='Team Name'
              name='teamUrl'
              appended={true}
              onChange={props.change}
              value={props.data}
            />
            <InputFormAppendText>.hqremote.io</InputFormAppendText>
          </SignUpFormRow>
          <SignUpFormRow>
            <BlackButton
              type='button'
              onClick={props.next}
              disabled={
                props.data === '' ||
                typeof props.data === 'undefined' ||
                dataMatch() ? true : false
              }>
              Next
            </BlackButton>
          </SignUpFormRow>
        </SignUpFormGroup>
      </SignUpForm>
    </SignUpFormContainer>
  )
}

export default TeamUrl
