import styled, { css } from 'styled-components'
import { GeneralComponentProps } from '../interfaces/component'

export const SignUpContainer = styled.div`
  min-height: 100%;
  position: relative;
  width: 100%;
`

export const SignUpInner = styled.div`
  margin: 50px auto 0;
  max-width: 1220px;
  position: relative;
`

export const SignUpFormContainer = styled.div`
  margin-top: 100px;
`

export const SignUpForm = styled.div`
  margin: 30px auto;
  max-width: 470px;
  transition: all 0.25s ease;
  margin-bottom: 0;
  padding: 20px 20px 130px;
  width: 100%;

  h1 {
    font-size: 34px;
    margin: 0 0 30px;
    font-weight: 400;
  }
`

export const SignUpFormGroup = styled.div`
  max-width: initial;
`

export const SignUpFormRow = styled.div`
  margin: 0 0 20px;
  min-height: 37px;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: table;
  }
`

export const SignUpFormRowHalf = styled.div`
  margin-bottom: 21px;

  @media only screen and (min-width: 500px) {
    display: inline-block;
    margin-bottom: 0;
    width: calc(50% - 12px) !important;

    &:first-of-type {
      margin-right: 24px;
    }
  }
`

export const PaginationContainer = styled.nav`
  bottom: 0;
  display: block;
  left: 0;
  padding: 20px 0;
  position: absolute;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 768px) {
    bottom: 40px;
    left: 50%;
    margin: 0;
    padding: 0;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: auto;
  }

  @media only screen and (min-height: 769px) {
    bottom: 70px;
  }
`

export const PaginationItem = styled.button < GeneralComponentProps > `
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 29px;
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  width: 29px;

  ${(props) => {
    let style = css``

    if (props.current) {
      style.concat(css`
          background: #282828;
          border: 2px solid #282828;
          color: white;
      `)
    }

    if (props.disabled) {
      style.concat(css`
          cursor: default;
          color: #000;
      `)
    }

    return style
  }}
`
