import { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export const types = {
  SHOW_OFFICE_LIST: 'ui/office-list/show',
  HIDE_OFFICE_LIST: 'ui/office-list/hide',
  TOGGLE_OFFICE_LIST: 'ui/office-list/toggle',
  EXPAND_SIDE_BAR: 'ui/side-bar/expand',
  COLLAPSE_SIDE_BAR: 'ui/site-bar/collapse',
  TOGGLE_SIDE_BAR: 'ui/site-bar/toggle',
  TOGGLE_NOTIFICATION_BAR: 'ui/notification-bar/toggle',
  EXPAND_ACCOUNT_SIDE_BAR: 'ui/account-side-bar/expand',
  COLLAPSE_ACCOUNT_SIDE_BAR: 'ui/account-site-bar/collapse',
  TOGGLE_ACCOUNT_SIDE_BAR: 'ui/account-site-bar/toggle',
  TOGGLE_PICTURE_IN_PICTURE: 'ui/picture-in-picture/toggle'
}

export const initialState: UiState = {
  officeListOpen: false,
  sideBarExpanded: false,
  notificationBarExpanded: false,
  accountSideBarExpanded: true,
  isPictureInPicture: false
}

export interface UiState {
  officeListOpen: boolean;
  sideBarExpanded: boolean;
  notificationBarExpanded: boolean;
  accountSideBarExpanded: boolean;
  isPictureInPicture: boolean
}

export default (state: UiState = initialState, action: PayloadAction<{isPictureInPicture: boolean}>) => {
  switch (action.type) {
    case types.SHOW_OFFICE_LIST:
      return {
        ...state,
        officeListOpen: true
      }

    case types.HIDE_OFFICE_LIST:
      return {
        ...state,
        officeListOpen: false
      }

    case types.TOGGLE_OFFICE_LIST:
      return {
        ...state,
        officeListOpen: !state.officeListOpen
      }

    case types.EXPAND_SIDE_BAR:
      return {
        ...state,
        sideBarExpanded: true
      }

    case types.COLLAPSE_SIDE_BAR:
      return {
        ...state,
        sideBarExpanded: false
      }

    case types.TOGGLE_SIDE_BAR:
      return {
        ...state,
        sideBarExpanded: !state.sideBarExpanded
      }
    case types.TOGGLE_NOTIFICATION_BAR:
      return {
        ...state,
        notificationBarExpanded: !state.notificationBarExpanded
      }
    case types.EXPAND_ACCOUNT_SIDE_BAR:
      return {
        ...state,
        accountSideBarExpanded: true
      }

    case types.COLLAPSE_ACCOUNT_SIDE_BAR:
      return {
        ...state,
        accountSideBarExpanded: false
      }

    case types.TOGGLE_ACCOUNT_SIDE_BAR:
      return {
        ...state,
        accountSideBarExpanded: !state.sideBarExpanded
      }
    case types.TOGGLE_PICTURE_IN_PICTURE:
      return {
        ...state,
        isPictureInPicture: action.payload.isPictureInPicture
      }
    default:
      return state
  }
}

export const actions = {
  showOfficeList: () => ({ type: types.SHOW_OFFICE_LIST }),
  hideOfficeList: () => ({ type: types.HIDE_OFFICE_LIST }),
  toggleOfficeList: () => ({ type: types.TOGGLE_OFFICE_LIST }),
  expandSideBar: () => ({ type: types.EXPAND_SIDE_BAR }),
  collapseSideBar: () => ({ type: types.COLLAPSE_SIDE_BAR }),
  toggleSideBar: () => ({ type: types.TOGGLE_SIDE_BAR }),
  toggleNotificationBar: () => ({ type: types.TOGGLE_NOTIFICATION_BAR }),
  expandAccountSideBar: () => ({ type: types.EXPAND_ACCOUNT_SIDE_BAR }),
  collapseAccountSideBar: () => ({ type: types.COLLAPSE_ACCOUNT_SIDE_BAR }),
  toggleAccountSideBar: () => ({ type: types.TOGGLE_ACCOUNT_SIDE_BAR }),
  togglePictureInPicture: (payload: { isPictureInPicture: boolean }) => ({ type: types.TOGGLE_PICTURE_IN_PICTURE, payload })
}

export const getOfficeListOpen = (state: RootState) => state.ui.officeListOpen
export const getAccountSideBarExpanded = (state: RootState) => state.ui.accountSideBarExpanded
export const getNotificationBarExpanded = (state: RootState) => state.ui.notificationBarExpanded
export const getIsPictureInPicture = (state: RootState) => state.ui.isPictureInPicture
