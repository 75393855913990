import React, { Dispatch, SetStateAction } from 'react'
import { UserCallState } from '../interfaces/user'
import { Types } from 'ably'
import { select } from 'redux-saga/effects'
import { getSettings } from '../reducers/settings'
import { MirrorVisibility, Setting } from '../interfaces/setting'
import { createVirtualBackgroundEffect } from '../services/video'
import { BLUR_VALUE, TVirtualBackground } from '../services/video/constants'
import { TrackDetail } from '../contexts/stream'
import { NoiseSuppressionEffect } from '../services/audio/NoiseSuppressionEffect'
import jitsi, { JitsiConnection, JmtLocalTrack, JtmRemoteTrack, JtmTDevice, JtmTrack } from 'lib-jitsi-meet'
import { store } from '../store'
import { actions as realtimeCallActions } from '../reducers/realtime/call'
import { actions as callActions } from '../reducers/call'
import DefaultBackground from '../assets/img/background.png'
import { isElectron } from './env'
import { mountDevices } from '../services/devices'

const REGION_SHARD_MAPPING: any = {
  default: 'default',
  frankfurt: 'eu-central-1',
  london: 'eu-west-2'
}
let localTracks: Array<JmtLocalTrack> = []
let isJoined = false
let room: any = null
let currentUserId: number
// map of [jitsi-user-id]: backend-user-id
let participantIdMap: {[key: string]: string} = {}
let connection: JitsiConnection

// return selected mic device id if available, fallback to available default if not
export const getDevices = async (camera: string, mic: string) => {
  let availableMic = mic
  let availableCamera = camera
  const { mics, cameras } = await mountDevices()
  if (!mics.find(m => m.deviceId === mic)) {
    // find one with label `default`
    const availableMicObj = mics.find(m => m.label === 'default')
    // fallback to first mic in the list if default not found
    availableMic = availableMicObj ? availableMicObj.deviceId : mics[0].deviceId
  }
  if (!cameras.find(c => c.deviceId === camera)) {
    const availableCamObj = cameras.find(c => c.label === 'default')
    availableCamera = availableCamObj ? availableCamObj.deviceId : cameras[0].deviceId
  }
  return {
    mic: availableMic,
    camera: availableCamera
  }
}

export const createTracks = async (
  constrains: Array<JtmTDevice>,
  settings: Setting
) => {
  const effects = constrains.map(constrain => {
    if (constrain === 'audio') {
      return settings.noiseReduction
        ? new NoiseSuppressionEffect({
          krisp: {
            enabled: false
          }
        })
        : Promise.resolve()
    }

    if (constrain === 'video') {
      const effectOptions = {
        // eslint-disable-next-line no-undefined
        backgroundType: settings.background !== 'none' ? settings.background : undefined,
        blurValue: settings.background === 'blur' ? BLUR_VALUE.HIGH : 0,
        virtualSource:
          settings.background === 'image'
            ? settings.backgroundSource || DefaultBackground
            : // eslint-disable-next-line no-undefined
            undefined
      }
      return settings.background !== 'none'
        ? createVirtualBackgroundEffect(effectOptions).catch((e) => {
          // eslint-disable-next-line
          console.log('debug: fail createVirtualBackgroundEffect', e)
          return Promise.resolve()
        })
        : Promise.resolve()
    }
    return null
  })
  const applied: Array<any> = (await Promise.all(effects)).filter(e => !!e)
  const { camera, mic } = await getDevices(settings.camera, settings.mic)
  const tracks = await jitsi.createLocalTracks({
    devices: constrains,
    cameraDeviceId: camera,
    micDeviceId: mic,
    effects: applied
  })
  return tracks
}

export const leaveRoom = function *(setTracks?: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>) {
  if (!room) {
    return
  }
  room.off(
    jitsi.events.conference.P2P_STATUS,
    (conf: any, p2p: any) => {
      // eslint-disable-next-line
      console.log('debug-track: p2p status change', conf, p2p)
      // toggleVideo(true)
    })
  room.off(jitsi.events.conference.TRACK_ADDED, onRemoteTrack)
  room.off(jitsi.events.conference.TRACK_REMOVED, onRemoteTrackRemoved)
  room.off(jitsi.events.conference.CONFERENCE_JOINED, onConferenceJoined)
  room.off(jitsi.events.conference.USER_JOINED, () => {})
  room.off(jitsi.events.conference.USER_LEFT, onUserLeft)
  room.off(jitsi.events.conference.TRACK_MUTE_CHANGED, onTrackMute)
  room.off(jitsi.events.conference.LOBBY_USER_JOINED, onLobbyUserJoined)
  room.off(jitsi.events.conference.LOBBY_USER_UPDATED, onLobbyUserUpdated)
  room.off(jitsi.events.conference.LOBBY_USER_LEFT, onLobbyUserLeft)
  room.leave().then(() => {
    participantIdMap = {}
    // remoteTracks = {}
    disconnect(setTracks)
    room = null
    isJoined = false
  }).catch((e: any) => {
    // eslint-disable-next-line
    console.log('error leaving room', e)
  })
}

export const joinRoom = function *(
  roomData: any,
  setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>,
  clientChannels: {[key: string]: Types.RealtimeChannelCallbacks},
  caller: {
    userId: number,
    teamId: number
  },
  // eslint-disable-next-line
  onSuccessCallback: () => void
) {
  if (isElectron()) {
    window.ipcRender?.playbackPause()
  }
  const { token, calleeId, roomName } = roomData
  const stage = ''
  currentUserId = calleeId
  const tenant = process.env.REACT_APP_JAAS_TENANT
  const hasRegion = false
  const settings: Setting = yield select(getSettings)
  const connectJitsi = (count: number) => {
    if (count === 3) {
      // eslint-disable-next-line
      console.log('error connecting call: too many attempt')
      return
    }
    connection = new jitsi.JitsiConnection(tenant, token, {
      hosts: {
        domain: `${stage}8x8.vc`,
        muc: `conference.${tenant}.${stage}8x8.vc`,
        focus: `focus.${stage}8x8.vc`
      },
      constraints: {
        video: {
          height: {
            ideal: 720,
            max: 720,
            min: 360
          },
          width: {
            ideal: 720,
            max: 720,
            min: 360
          }
        }
      },
      // Enable Callstats (note, none of this is secret, despite its name)
      callStatsID: '706724306',
      callStatsSecret: 'f+TKWryzPOyX:dNR8PMw42WJwM3YM1XkJUjPOLY0M40wz+0D4mZud8mQ=',
      serviceUrl: `wss://8x8.vc/${tenant}/xmpp-websocket?room=${roomName}`,
      websocketKeepAliveUrl: `https://8x8.vc/${tenant}/_unlock?room=${roomName}`,
      channelLastN: 25,
      confID: `https://${stage}8x8.vc/${tenant}/${roomName}`,
      siteID: tenant,
      applicationName: 'HQ Remote',
      // Misc
      deploymentInfo: hasRegion ? { userRegion: REGION_SHARD_MAPPING.london } : {},
      // Logging
      logging: {
        // Default log level
        'defaultLogLevel': 'trace',
        // The following are too verbose in their logging with the default level
        'modules/RTC/TraceablePeerConnection.js': 'info',
        'modules/statistics/CallStats.js': 'info',
        'modules/xmpp/strophe.util.js': 'log'
      },
      // End marker, disregard
      __end: true
    })
    connection.addEventListener(jitsi.events.connection.CONNECTION_ESTABLISHED, () => {
      onSuccessCallback()
      onConnectionSuccess(roomName, setTracks, clientChannels, () => {
        connectJitsi(count + 1)
      })
    })
    connection.addEventListener(jitsi.events.connection.CONNECTION_FAILED, onConnectionFailed)
    connection.addEventListener(jitsi.events.connection.CONNECTION_DISCONNECTED, disconnect)
    connection.connect()
  }

  if (settings.mirrorVisible === MirrorVisibility.VIDEO) {
    setTracks(prev => {
      const prevTracks = prev ? prev[currentUserId] ?? [] : []
      prevTracks.forEach(pt => pt.track && !pt.track.disposed && pt.track.dispose())
      return {
        ...prev,
        [currentUserId]: []
      }
    })
  }
  yield createTracks(['audio', 'video'], settings)
    .then((tracks: Array<JmtLocalTrack>) => {
      onLocalTracks(tracks, setTracks)
      connectJitsi(0)
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.log('error creating local track', error)
    })
}

export const mapLocalCalls = function (
  userId: number,
  callState: UserCallState,
  newCalls: {[key: string]: number[]}
): {[key: string]: number[]} {
  if (!callState.callRoom) {
    Object.keys(newCalls).forEach(key => {
      const index: number = newCalls[key].indexOf(userId)
      if (index > -1) {
        newCalls[key].splice(index, 1)
      }
      if (newCalls[key].length <= 0) {
        delete newCalls[key]
      }
    })
  } else if (newCalls[callState.callRoom]) {
    newCalls[callState.callRoom].push(userId)
  } else {
    newCalls[callState.callRoom] = [userId]
  }
  return newCalls
}

// listeners
function onLocalTracks(tracks: Array<JmtLocalTrack>, setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>) {
  tracks.forEach(track => {
    track.addEventListener(jitsi.events.track.TRACK_MUTE_CHANGED, async () => {
      setTracks(prevUserTracks => {
        const prevTrack: TrackDetail[] = prevUserTracks ? prevUserTracks[currentUserId] ?? [] : []
        return {
          ...prevUserTracks,
          // eslint-disable-next-line max-nested-callbacks
          [currentUserId]: prevTrack.map(t => t.track && t.track.getVideoType() === track.getVideoType() ? { ...t, muted: true } : t)
        }
      })
    })
    track.addEventListener(jitsi.events.track.LOCAL_TRACK_STOPPED, async () => {
      setTracks(prevUserTracks => {
        const prevTrack: TrackDetail[] = prevUserTracks ? prevUserTracks[currentUserId] ?? [] : []
        return {
          ...prevUserTracks,
          // eslint-disable-next-line max-nested-callbacks
          [currentUserId]: prevTrack.map(t => t.track && t.track.getVideoType() === track.getVideoType() ? { ...t, muted: true } : t)
        }
      })
    })
    setTracks(prevUserTracks => {
      const prevTrack: TrackDetail[] = prevUserTracks ? prevUserTracks[currentUserId] ?? [] : []
      prevTrack.push({ track: track as unknown as JtmRemoteTrack, muted: false })
      return {
        ... (prevUserTracks ?? {}),
        [currentUserId]: prevTrack
      }
    })
  })
  localTracks = tracks
}

export const switchCamera = async (settings: Setting, setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>) => {
  const prevVideoTrack = localTracks.find(tr => tr.getType() === 'video' && tr.getVideoType() === 'camera')

  const newTracks = await createTracks(['video'], settings)
  localTracks = localTracks.filter(tr => tr.getType() !== 'video')
  localTracks.push(newTracks[0])
  if (isJoined) {
    await room.removeTrack(prevVideoTrack)
    await room.addTrack(newTracks[0])
  }
  prevVideoTrack?.dispose()

  setTracks(prevUserTracks => {
    const prevTracks = (prevUserTracks ? prevUserTracks[currentUserId] ?? [] : []).filter(t => t.track && t.track.getVideoType() !== 'camera')
    return {
      ...prevUserTracks,
      [currentUserId]: [...prevTracks, { track: newTracks[0], muted: false }]
    }
  })
}

export const switchMic = async (settings: Setting, setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>) => {
  const prevAudioTrack = localTracks.find(tr => tr.getType() === 'audio')

  const newTracks = await createTracks(['audio'], settings)
  localTracks = localTracks.filter(tr => tr.getType() !== 'audio')
  localTracks.push(newTracks[0])
  if (isJoined) {
    await room.removeTrack(prevAudioTrack)
    await room.addTrack(newTracks[0])
  }
  prevAudioTrack?.dispose()

  setTracks(prevUserTracks => {
    const prevTracks = (prevUserTracks ? prevUserTracks[currentUserId] ?? [] : []).filter(t => t.track && t.track.getType() !== 'audio')
    return {
      ...prevUserTracks,
      [currentUserId]: [...prevTracks, { track: newTracks[0], muted: false }]
    }
  })
}

export const changeEffect = async (
  backgroundType: TVirtualBackground,
  backgroundSource: string | undefined,
  setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>
) => {
  const prevVideoTrack = localTracks.find(tr => tr.getType() === 'video' && tr.getVideoType() === 'camera')
  await createVirtualBackgroundEffect({
    backgroundType,
    blurValue: backgroundType === 'blur' ? BLUR_VALUE.HIGH : 0,
    virtualSource: backgroundType === 'image'
      ? backgroundSource || DefaultBackground
      // eslint-disable-next-line no-undefined
      : undefined
  })
    .then(effect => prevVideoTrack?.setEffect(effect))
  setTracks(prevUserTracks => {
    const prevTracks = (prevUserTracks ? prevUserTracks[currentUserId] ?? [] : []).filter(t => t.track && t.track.getType() !== 'video')
    return {
      ...prevUserTracks,
      [currentUserId]: [...prevTracks, { track: prevVideoTrack, muted: false }]
    }
  })
}

const onConnectionFailed = (e: any) => {
  // eslint-disable-next-line no-console
  console.error('Connection Failed!', e)
}

const disconnect = async (setTracks?: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>) => {
  connection.removeEventListener(
    jitsi.events.connection.CONNECTION_ESTABLISHED,
    onConnectionSuccess)
  connection.removeEventListener(
    jitsi.events.connection.CONNECTION_FAILED,
    onConnectionFailed)
  connection.removeEventListener(
    jitsi.events.connection.CONNECTION_DISCONNECTED,
    disconnect)
  setTracks?.(() => {
    // eslint-disable-next-line no-console
    console.log('debug:disconnect emptying tracks')
    return null
  })
  for (let i = 0; i < localTracks.length; i++) {
    localTracks[i].dispose()
  }
  localTracks = []

  try {
    return await connection.disconnect()
  } catch (e) {
    // eslint-disable-next-line
    console.log('error disconnecting', e)
  }
  return true
}

export const toggleVideo = async (video: boolean): Promise<any> => {
  if (!room) {
    return Promise.resolve
  }
  const roomTracks = room.getLocalTracks()
  const videoTrack = roomTracks.find((track: any) => track.getType() === 'video' && track.getVideoType() === 'camera')
  if (!videoTrack.isMuted() && !video) {
    return videoTrack.mute()
  }
  if (!videoTrack.isMuted() && video) {
    // wants to toggle on, but video already playing
    return Promise.resolve
  }
  return videoTrack.unmute()
}


export const toggleAudio = async (mute: boolean): Promise<any> => {
  if (!room) {
    return Promise.resolve
  }
  const roomTracks = room.getLocalTracks()
  const audioTrack = roomTracks.find((track: any) => track.getType() === 'audio')
  if (!audioTrack.isMuted() && mute) {
    return audioTrack.mute()
  }
  if (!audioTrack.isMuted() && !mute) {
    return Promise.resolve
  }
  return audioTrack.unmute()
}

const onConnectionSuccess = (
  roomName: string,
  setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>,
  clientChannels: {[key: string]: Types.RealtimeChannelCallbacks},
  retry: () => void
) => {
  try {
    room = connection.initJitsiConference(roomName, {
      p2p: {
        enabled: false
      }
    })
    room.on(
      jitsi.events.conference.P2P_STATUS,
      (conf: any, p2p: any) => {
        // eslint-disable-next-line
        console.log('debug-track: p2p status change', conf, p2p)
        // toggleVideo(true)
      })
    room.on(jitsi.events.conference.TRACK_ADDED, (track: any) => {
      onRemoteTrack(track, setTracks)
    })
    room.on(jitsi.events.conference.TRACK_REMOVED, (track: any) => {
      onRemoteTrackRemoved(track, setTracks)
    })
    room.on(jitsi.events.conference.CONFERENCE_JOINED, onConferenceJoined)
    room.on(jitsi.events.conference.USER_JOINED, (id: any, payload: any) => {
      // eslint-disable-next-line
      const user = payload._identity.user
      participantIdMap[id] = String(Number(user.id))
      setTracks((prevTrack) => {
        return {
          ...prevTrack,
          [user.id]: []
        }
      })
    })
    room.on(jitsi.events.conference.USER_LEFT, (id: any) => {
      onUserLeft(id, setTracks, clientChannels)
    })
    room.on(jitsi.events.conference.TRACK_MUTE_CHANGED, (track: any) => {
      onTrackMute(track, setTracks)
    })

    room.on(jitsi.events.conference.LOBBY_USER_JOINED, onLobbyUserJoined)

    room.on(jitsi.events.conference.LOBBY_USER_UPDATED, onLobbyUserUpdated)

    room.on(jitsi.events.conference.LOBBY_USER_LEFT, onLobbyUserLeft)
    // room.on(jitsi.events.conference.TRACK_AUDIO_LEVEL_CHANGED, (participantId: string) => {
    //   // eslint-disable-next-line
    //   console.log('audiolevel changed', participantId)
    //   const userId = participantIdMap[participantId]
    //   if (Number(userId) !== currentUserId) {
    //     store.dispatch(callActions.updateActiveSpeaker(Number(userId), false, false))
    //   }
    // })
    room.on(jitsi.events.conference.DOMINANT_SPEAKER_CHANGED, (participantId: string) => {
      const userId = participantIdMap[participantId]
      if (userId) {
        const state = store.getState()
        // @ts-ignore
        // eslint-disable-next-line
        if (!state.call.activeSpeakerManual) {
          store.dispatch(callActions.updateActiveSpeaker(Number(userId), false, false))
        }
      }
    })

    let promises: any = []
    const conferenceTracks = room.getLocalTracks()
    localTracks.forEach((lt: any) => {
      if (conferenceTracks.indexOf(lt) === -1) {
        promises.push(room.addTrack(lt).catch((e: any) => {
          // eslint-disable-next-line
          console.log('debug-stuck: error adding track: ', e)
        }))
      }
    })
    Promise.all(promises).then(() => {
      room.join()
      // Send at most 720p
      room.setSenderVideoConstraint(720)
      room.setReceiverVideoConstraint(360)
    }).catch((e: any) => {
      // eslint-disable-next-line
      console.log('debug-stuck: fail joining room', e)
    })
  } catch (e: any) {
    if (e.message === 'A conference with the same name has already been created!') {
      // colliding while join/creating room - retry
      // eslint-disable-next-line
      console.warn('warn: collided while creating/joining room - retry: ')
      connection.removeEventListener(
        jitsi.events.connection.CONNECTION_ESTABLISHED,
        onConnectionSuccess)
      connection.removeEventListener(
        jitsi.events.connection.CONNECTION_FAILED,
        onConnectionFailed)
      connection.removeEventListener(
        jitsi.events.connection.CONNECTION_DISCONNECTED,
        () => disconnect(setTracks))
      connection.disconnect().then(() => {
        retry()
      })
      return
    }
    // eslint-disable-next-line
    console.log('debug-stuck: error on connection success callback', e)
    disconnect()
  }
}

const onLobbyUserJoined = (id: string, name: string) => {
  // eslint-disable-next-line
  console.log('debug-stuck: LOBBY_USER_JOINED', id, name)
}
const onLobbyUserUpdated = (id: string, participant: any) => {
  // eslint-disable-next-line
  console.log('debug-stuck: LOBBY_USER_UPDATED', id, participant)
}

const onLobbyUserLeft = (id: string) => {
  // eslint-disable-next-line
  console.log('debug-stuck: LOBBY_USER_LEFT', id)
}

function onTrackMute(track: JtmRemoteTrack, setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>) {
  const userId = participantIdMap[track.getParticipantId()]
  setTracks((prevTracks) => {
    const prevUserTracks = prevTracks ? prevTracks[userId] ?? [] : []
    if (!prevUserTracks.find(pt => pt.track && pt.track.ssrc === track.ssrc)) {
      return prevTracks
    }
    return {
      ...prevTracks,
      [userId]: [
        ...prevUserTracks.filter((prevTrack) => prevTrack.track && prevTrack.track.getVideoType() !== track.getVideoType()),
        {
          track: track,
          muted: track.muted
        }
      ]
    }
  })
}

function onRemoteTrackRemoved(track: JtmRemoteTrack, setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>) {
  if (track.isLocal()) {
    return
  }
  const userId = participantIdMap[track.getParticipantId()]
  setTracks((prevTracks) => {
    const prevUserTracks = prevTracks ? prevTracks[userId] ?? [] : []
    return {
      ...prevTracks,
      [userId]: prevUserTracks.filter((prevTrack) => prevTrack.track && prevTrack.track.getType() !== track.getType() && prevTrack.track.getVideoType() !== track.getVideoType())
    }
  })
}

function onRemoteTrack(track: JtmRemoteTrack, setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>) {
  if (track.isLocal()) {
    return
  }
  const userId = participantIdMap[track.getParticipantId()]
  setTracks((prevTracks) => {
    const prevUserTracks: TrackDetail[] = prevTracks ? prevTracks[userId] ?? [] : []
    return {
      ...prevTracks,
      [userId]: [
        ...prevUserTracks.filter(tr => tr.track && tr.track.getVideoType() !== track.getVideoType()),
        {
          track: track,
          muted: track.muted
        }
      ]
    }
  })
}

const onConferenceJoined = () => {
  isJoined = true
}

const onUserLeft = (
  id: any,
  setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>,
  clientChannels: {[key: string]: Types.RealtimeChannelCallbacks}
) => {
  const userId = participantIdMap[id]
  if (Object.keys(participantIdMap).length === 1) {
    // @ts-ignore
    store.dispatch(realtimeCallActions.sendLeave(
      clientChannels,
      setTracks
    ))
  }
  setTracks((prevTracks) => {
    return {
      ...prevTracks,
      [userId]: []
    }
  })
}

export const startScreenShare = async (setTracks: Dispatch<SetStateAction<{[key: string]: TrackDetail[]}|null>>): Promise<number> => {
  try {
    const tracks = await jitsi.createLocalTracks({
      devices: ['desktop']
    })

    const screenAudio = tracks.find(tr => tr.getType() === 'audio')
    if (screenAudio) {
      screenAudio.dispose()
    }

    const screenTrack = tracks.find(tr => tr.getType() === 'video')
    if (!screenTrack) {
      return 0
    }

    screenTrack?.addEventListener(jitsi.events.track.TRACK_MUTE_CHANGED, async () => {
      setTracks(prevUserTracks => {
        const prevTrack: TrackDetail[] = prevUserTracks ? prevUserTracks[currentUserId] ?? [] : []
        return {
          ...prevUserTracks,
          [currentUserId]: prevTrack.map(t => t.track && t.track.getVideoType() === 'desktop' ? { ...t, muted: true } : t)
        }
      })
    })
    screenTrack?.addEventListener(jitsi.events.track.LOCAL_TRACK_STOPPED, async () => {
      setTracks(prevUserTracks => {
        const prevTrack: TrackDetail[] = prevUserTracks ? prevUserTracks[currentUserId] ?? [] : []
        return {
          ...prevUserTracks,
          [currentUserId]: prevTrack.map(t => t.track && t.track.getVideoType() === 'desktop' ? { ...t, muted: true } : t)
        }
      })
      await screenTrack.mute()
      await room.removeTrack(screenTrack)
      await screenTrack.dispose()
    })

    await room.addTrack(screenTrack)
    localTracks = [...localTracks, screenTrack]
    setTracks(prevUserTracks => {
      const prevTrack: TrackDetail[] = prevUserTracks ? prevUserTracks[currentUserId] : []
      const curr = prevUserTracks ? prevUserTracks : { currentUserId: [] }
      curr[currentUserId] = prevTrack
        ? [
          ...prevTrack.filter(t => t.track && t.track.getVideoType() !== 'desktop'),
          { track: screenTrack as unknown as JtmRemoteTrack,
            muted: false
          }
        ]
        : [ { track: screenTrack as unknown as JtmRemoteTrack, muted: false }]
      return curr
    })
    return currentUserId
  } catch (err) {
    return 0
  }
}

export const startScreenShareWithSourceId = async (
  sourceId: string
): Promise<{ userId: number, screenTrack: JmtLocalTrack | null }> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        // @ts-ignore
        mandatory: {
          chromeMediaSource: 'desktop',
          chromeMediaSourceId: sourceId,
          minWidth: 1280,
          maxWidth: 99999,
          minHeight: 720,
          maxHeight: 99999
        }
      }
    })

    const tracks = jitsi.createLocalTracksFromMediaStreams([{
      stream,
      sourceType: 'video',
      mediaType: 'video',
      videoType: 'desktop'
    }])

    if (tracks) {
      const screenTrack = tracks.find(tr => tr.getType() === 'video')
      if (!screenTrack) {
        return { userId: 0, screenTrack: null }
      }

      await room.addTrack(screenTrack)
      localTracks = [...localTracks, screenTrack]
      return { userId: currentUserId, screenTrack }
    }
    return { userId: 0, screenTrack: null }
  } catch (e) {
    return { userId: 0, screenTrack: null }
  }
}

export const stopScreenShare = async (setTracks: Dispatch<SetStateAction<{[key: string]: TrackDetail[]}|null>>): Promise<void> => {
  const screenTrack = room.getLocalTracks('video').find((t: JmtLocalTrack) => t.getVideoType() !== 'camera' && t.getType() === 'video') as JtmTrack
  await room.removeTrack(screenTrack)
  await screenTrack.dispose()
  setTracks(prevUserTracks => {
    const prevTrack: TrackDetail[] = prevUserTracks ? prevUserTracks[currentUserId] ?? [] : []
    return {
      ...prevUserTracks,
      [currentUserId]: prevTrack.map(t => t.track && t.track.getVideoType() === 'desktop' ? { ...t, muted: true } : t)
    }
  })
}
