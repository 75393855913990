import React, { useEffect, useRef, useState } from 'react'
import { Team } from '../interfaces/team'
import { TeamState } from '../interfaces/user'
import { UserAvailabilityState } from '../constants/user'
import { IInterval } from '../interfaces/interval'

export interface TeamInterval {
  interval: IInterval;
  teamId: number;
}

interface SnapshotIntervalProps {
  selectedTeam: number;
  teams: {[key: string]: Team};
  teamStates: { [key: number]: TeamState };
  onInterval: (intervals: TeamInterval[]) => void;
}

const SnapshotInterval: React.FC<SnapshotIntervalProps> = (props) => {
  // states
  const [intervalLooped, setIntervalLooped] = useState<number>(0)
  const [teamIntervals, setTeamIntervals] = useState<TeamInterval[]>([])
  const timer = useRef()
  // states
  // functions
  const snapshot = (intervals: TeamInterval[]) => {
    if (props.onInterval) {
      props.onInterval(intervals)
    }
  }
  const startIntervals = () => {
    Object.values(props.teams).forEach((team) => {
      const teamState = props.teamStates[team.id]
      if (
        props.selectedTeam === team.id && (
          teamState.state !== UserAvailabilityState.AWAY || teamState.canGhost
        )
      ) {
        // We don't need to bind an interval if we're on manual
        setTeamIntervals(teamState.interval.second === 0 ? [] : [
          ...teamIntervals.filter(interval => interval.teamId !== team.id),
          {
            interval: teamState.interval,
            teamId: team.id
          }
        ])
      } else {
        setTeamIntervals([])
      }
    })
  }
  useEffect(() => {
    if (teamIntervals.length === 0) {
      // all manual
      if (timer?.current) {
        clearInterval(timer.current)
      }
      return
    }
    const has1Minutes = teamIntervals.filter((teamInterval) => {
      return teamInterval.interval.second === 60
    }).length > 0
    if (timer?.current) {
      clearInterval(timer.current)
    }
    // @ts-ignore
    timer.current = setInterval(() => {
      let include5Minutes = false
      if (has1Minutes) {
        setIntervalLooped(intervalLooped + 1)
        if (intervalLooped === 5) {
          // Do 5 minutes ones as well
          include5Minutes = true
          setIntervalLooped(0)
        }
      }
      const intervalsToRun = teamIntervals.filter((teamInterval) => {
        return !(!include5Minutes && teamInterval.interval.second === 5 * 60)
      })
      snapshot(intervalsToRun)
    }, has1Minutes ? 60 * 1000 : 5 * 60 * 1000)
  }, [teamIntervals])

  useEffect(() => {
    startIntervals()
  }, [props.teamStates])

  useEffect(() => {
    return () => {
      if (timer?.current) {
        clearInterval(timer.current)
      }
    }
  }, [])
  return (<></>)
}

export default SnapshotInterval
