import { all, call, put } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { actions as teamsActions } from '../reducers/teams'
import { actions as usersActions } from '../reducers/users'
import { fetchTeams } from '../services/api'

import * as schema from '../schema'
import { AxiosResponse } from 'axios'
import { Team } from '../interfaces/team'
import { User } from '../interfaces/user'
import { actions as callActions } from '../reducers/call'


export function *fetchInitialData() {
  // Call the API to fetch the teams.
  const teamsResponse: AxiosResponse<Team[]> = yield call(fetchTeams)
  // Normalize our data into a schema
  const normalized = normalize<
      any,
      {teams: Team[], users: User[]}
    >(teamsResponse.data, [schema.team])
  yield all([
    // Give our teams reducer the teams
    put(teamsActions.loaded(normalized.entities.teams)),
    // Give our users reducer the users
    put(usersActions.loaded(normalized.entities.users)),
    // Give call reducer the calls
    put(callActions.loaded(normalized.entities.teams))
  ])
}
