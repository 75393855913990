import rootSaga from './sagas'
import rootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)

// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof store.getState>
