import { all, put, select, takeEvery, call } from 'redux-saga/effects'
import { getSelectedTeam } from '../reducers/shared'
import { types, actions as teamActions, TeamsPayloadAction } from '../reducers/teams'
import { getCurrentUser } from '../reducers/user'
import { updateTeamSetting as updateTeamSettingApi } from '../services/api'
import logger from '../services/logger'
import { actions as usersActions } from '../reducers/users'
import { Team } from '../interfaces/team'
import { User } from '../interfaces/user'
import { CALL_STATE } from '../constants/call'
import { IInterval } from '../interfaces/interval'

const { error } = logger('saga:team_update')

export function *updateSingle(action: TeamsPayloadAction) {
  const { teamId } = action.payload

  const user: User = yield select(getCurrentUser)
  if (user.selectedTeam !== teamId) {
    return
  }

  const team: Team = yield select(getSelectedTeam)
  const userTeamState = user.teamState[team.id]

  const selectedInterval = team.permissions.intervals.find((interval: IInterval) => {
    return interval.id === Number(userTeamState.interval)
  })
  if (!selectedInterval) {
    return
  }
}

export function *updateTeamSetting(action: TeamsPayloadAction) {
  const { teamId, data } = action.payload

  const user: User = yield select(getCurrentUser)
  if (user.selectedTeam !== teamId) {
    return
  }
  try {
    yield call(updateTeamSettingApi, teamId, {
      ...data
    })
    yield put(
      teamActions.onUpdateError({
        error: false,
        message: 'Update successfully.'
      })
    )
  } catch (err) {
    error('updateTeamSetting:error', err)
    yield put(
      // @ts-ignore
      teamActions.onUpdateError({ error: true, message: err.message, ...err })
    )
    error(err)
  }
}

export function *resetCurrentTeamSettings(action: TeamsPayloadAction) {
  const { teamId } = action.payload

  const user: User = yield select(getCurrentUser)
  if (user.selectedTeam !== teamId) {
    return
  }
  yield put(
    usersActions.updateSingle(
      user.id,
      { userId: user.id, roomState: CALL_STATE.NOT_IN_CALL, roomName: null }
    )
  )
}

export default function *teamsWatcher() {
  yield all([
    takeEvery(types.UPDATE_SINGLE, updateSingle),
    takeEvery(types.UPDATE_TEAM_SETTING, updateTeamSetting)
  ])
}
