import styled,
{ css, CSSObject, FlattenSimpleInterpolation, keyframes } from 'styled-components'
import { snapshotEffectGroups } from '../services/snapshotEffects'
import { UserColor } from '../constants/user'
import { IInterval } from '../interfaces/interval'

// Helper components
export const Label = styled.span`
  letter-spacing: 0.5px;
  font-family: ${(props) => props.theme.fontFamily.normal};
  font-size: 14px;
  line-height: 16px;
`

export const fontFamily = {
  normal: `'DM Sans'`
}

// Helper functions
export const breakpoints: { [key: string]: number } = {
  mobile: 576,
  tablet: 768,
  desktop: 992
}

export const mediaMin = Object.keys(breakpoints).reduce(
  // eslint-disable-next-line no-undef
  (acc: { [key: string]: ((args: TemplateStringsArray) => FlattenSimpleInterpolation) }, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${breakpoints[label] / 16}em) {
        ${css(...args)};
      }
    `
    return acc
  }, {})

export const mediaMax = Object.keys(breakpoints).reduce(
  (acc: { [key: string]: ((args: CSSObject) => FlattenSimpleInterpolation) }, label) => {
    acc[label] = (...args) => css`
      @media (max-width: ${breakpoints[label] / 16}em) {
        ${css(...args)};
      }
    `
    return acc
  }, {})

// eslint-disable-next-line no-undef
export const inputPlaceholder = (args: TemplateStringsArray) => {
  const argsCss = css(args)
  return css`
    &::-webkit-input-placeholder {
      ${argsCss};
    }

    &:-moz-placeholder {
      ${argsCss};
    }

    &:-ms-input-placeholder {
      ${argsCss};
    }
  `
}

// Themes
export const darkTheme = {
  backgroundColor: '#444',
  foregroundColor: '#fff',
  fontFamily: fontFamily
}

// Animations

export const animations = {
  marquee: keyframes`
    0% {
      opacity: 0;
      transform: translate(0, 0);
    },
    30% {
      opacity: 1;
      transform: translate(0, 0);
    }
    40% {
      opacity: 1;
      transform: translate(0, 0);
    }
    100% {
      opacity: 1;
      transform: translate(-100%, 0);
    }
  `,
  bounce: keyframes`
    0% {
      transform: translate(-10%, 0);
    }
    50% {
      transform: translate(120%, 0);
    }
    100% {
      transform: translate(-10%, 0);
    }
  `,
  bounceReverse: keyframes`
    0% {
      transform: translate(10%, 0);
    }
    50% {
      transform: translate(-120%, 0);
    }
    100% {
      transform: translate(10%, 0);
    }
  `,
  fadeOut: keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  `,
  slowFadeout: keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  `,
  fadeIn: keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `,
  flashCountdown: keyframes`
    0% {
      background-color: rgba(#000, 0.7);
    }
    50% {
      background-color: red;
    }
    100% {
      background-color: rgba(#000, 0.7);
    }
  `,
  flashOpacity: keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `,
  backgroundFlash: keyframes`
    0% {
      background-color: #ffffff;
    }
    100% {
      background-color: transparent;
    }
  `
}

export const getUserStateNameFromState = (state: string): string => {
  switch (state) {
    case 'busy':
    case 'ghosting':
      return 'Busy'

    case 'away':
      return 'Away'

    default:
      return 'Available'
  }
}

export const getUserIntervalNameFromInterval = (interval: IInterval) => {
  if (interval.second === 0) {
    return interval.name.charAt(0).toUpperCase() + interval.name.slice(1)
  }
  const minutes = interval.second / 60
  return `Every ${minutes} minute${minutes > 1 ? 's' : ''}`
}

export const getUserIntervalCodeFromInterval = (interval: IInterval) => {
  if (interval.name.indexOf('_') > -1) {
    return Number(interval.name.split('_')[0])
  }
  return interval.name.charAt(0).toUpperCase()
}

export const getCallRoomIndex = (callRoom: string, next: boolean): number => {
  if (callRoom === '') {
    return 0
  }
  const chunks = callRoom.split('_')
  return Number(chunks[chunks.length - 1]) + (next ? 1 : 0)
}

export const getNextCallRoomIndex = (rooms: string[]): number => {
  rooms.sort((a, b) => Number(a) - Number(b))
  if (rooms.length > 0) {
    let index = 1
    for (index; index < rooms.length; index++) {
      if (Number(rooms[index - 1]) !== index + 1) {
        return index + 1
      }
    }
    return index + 1
  }
  return 1
}


export const getNextSnapshotEffect = (effect: string) => {
  const effectGroupIds = Object.keys(snapshotEffectGroups)
  const currentGroup = effectGroupIds.indexOf(effect)

  const nextEffectGroup = currentGroup + 1
  if (nextEffectGroup >= effectGroupIds.length) {
    return effectGroupIds[0]
  }

  return effectGroupIds[nextEffectGroup]
}

export const getSnapshotEffectName = (effect: string) => {
  return snapshotEffectGroups[effect].name
}

export const calculateElementFit = (
  containerWidth: number,
  containerHeight: number,
  number: number
) => {
  const area = containerHeight * containerWidth
  const elementArea = area / number

  // Calculate side length if there is no "spill":
  let sideLength = Math.sqrt(elementArea)

  // We now need to fit the squares. Let's reduce the square size
  // so an integer number fits the width.
  let numX = Math.ceil(containerWidth / sideLength)
  sideLength = containerWidth / numX

  while (numX <= number) {
    // With a bit of luck, we are done.
    if (Math.floor(containerHeight / sideLength) * numX >= number) {
      // They all fit! We are done!
      return sideLength
    }
    // They don't fit. Make room for one more square i each row.
    numX = numX + 1
    sideLength = containerWidth / numX
  }

  // Still doesn't fit? The window must be very wide
  // and low.
  sideLength = containerHeight
  return sideLength
}

export const debounce = (fn: () => void, time: number) => {
  // eslint-disable-next-line no-undef
  let timeout: NodeJS.Timeout

  return () => {
    const functionCall = () => fn.apply(this)
    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}


/**
 * Finds the index of a color that is not used in the given rooms.
 *
 * @param {Object.<string, number[]>} rooms - The object representing the rooms and their colors.
 * @return {number} - The index of a color that is not used in the rooms.
 */
export const findColorIndex = (rooms: {[k: string]: number[]} = {}): number => {
  if (!rooms) {
    return 1
  }

  const used = Object.keys(rooms).map((room) => {
    const chunks = room.split('_')
    return chunks[chunks.length - 1]
  }).sort()

  for (const color in Object.keys(UserColor)) {
    if (!used.includes(color) && color !== '0') {
      return parseInt(color, 10)
    }
  }
  return 1
}
