import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { getTimeFormatted } from '../utils'
import { Label } from './Helpers'

export const StyledTime = styled.p`
  fontSize: 36px;
  fontWeight: 700;
  margin: 0 0 0;
`

export const StyledTimeSubtitle = styled.p`
  fontSize: 24px;
  fontWeight: 700;
`

const TimeStyled = styled(Label)`
  flex: 1;
  z-index: 5;
  fontSize: 14px;
  fontFamily: 'DM Sans';
  fontWeight: 700;
  margin-top: auto;
  margin-bottom: auto;
  min-width: fit-content;
  margin-right: 16.91px;
`

type TimeProps = {
    timezone: string;
}

export const Time: React.FC<TimeProps> = (props) => {
  let ticker = useRef(null)
  const [time, setTime] = useState<string>()
  const updateTime = (timezone: string): void => {
    setTime(getTimeFormatted(timezone))
  }
  useEffect(() => {
    setTime(getTimeFormatted(props.timezone))
    // @ts-ignore
    ticker.current = setInterval(() => {
      updateTime(props.timezone)
    }, 10000)
    return () => {
      if (ticker.current) {
        clearInterval(ticker.current)
        ticker.current = null
      }
    }
  }, [props.timezone])
  return <TimeStyled>{time}</TimeStyled>
}
