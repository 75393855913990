import { axios } from './http'
import { AxiosResponse } from 'axios'

export async function getLatestVersion(): Promise<AxiosResponse<any>> {
  try {
    return await axios.get(`${process.env.REACT_APP_DESKTOP_URL}/latest-mac.yml`, {
      responseType: 'text',
      withCredentials: false,
      headers: {
        'Accept': '*/*',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    })
  } catch (error) {
    throw error
  }
}
