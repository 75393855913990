export const CALL_INTERVAL = {
  MANUAL: 1,
  ONE_MINUTE: 2,
  FIVE_MINUTES: 3
}

export const CALL_INTERVAL_LABEL_TO_ID: {[key: string]: number} = {
  'manual': CALL_INTERVAL.MANUAL,
  '1_minute': CALL_INTERVAL.ONE_MINUTE,
  '5_minutes': CALL_INTERVAL.FIVE_MINUTES
}

export const CALL_STATE = {
  IN_CALL: 'in-call',
  NOT_IN_CALL: 'not-in-call',
  JOINING_CALL: 'joining',
  LEAVING_CALL: 'leaving'
}

export const layouts = {
  CHAT_HEAD_WITHOUT_OTHERS: 1,
  CHAT_HEAD: 2,
  FULL_SCREEN: 3
}

export const CALL_RESOLUTION = {
  video720P: {
    width: 1280,
    height: 720
  },

  video480P: {
    width: 854,
    height: 480
  },
  video360P: {
    width: 640,
    height: 360
  }
}

export const SNAP_RESOLUTION = {
  width: 480,
  height: 480
}

