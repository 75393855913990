import { JtmRemoteTrack } from 'lib-jitsi-meet'
import React from 'react'

export interface TrackDetail {
  muted: boolean;
  track: JtmRemoteTrack | null;
}

interface TrackContextProps {
  tracks: {[key: string]: TrackDetail[]}|null;
  setTracks: React.Dispatch<React.SetStateAction<{[key: string]: TrackDetail[]}|null>>;
}

export const TrackContext = React.createContext<TrackContextProps>(
  {
    tracks: null,
    setTracks: () => {}
  }
)
