import { combineReducers } from 'redux'
import call from './call'
import countdown from './countdown'
import notifications from './notifications'
import notification from './notification'
import chatheadNotification from './chatheadNotification'
import session from './session'
import teams from './teams'
import ui from './ui'
import user from './user'
import users from './users'
import settings from './settings'
import subscription from './subscription'

const entities = combineReducers({
  // offices,
  teams,
  users
})

export default combineReducers({
  call,
  countdown,
  entities,
  notifications,
  notification,
  chatheadNotification,
  session,
  ui,
  user,
  settings,
  subscription
})
