import { axios, AUTH_API_ENDPOINT, USER_API_ENDPOINT } from './http'
import { AxiosResponse } from 'axios'
import { MeApiResponse } from '../../../interfaces/api/userApi'
import { User } from '../../../interfaces/user'
import { CALL_STATE } from '../../../constants/call'
import { buildTeamState } from '../../../utils/state'

import {
  updateUser as updateUserApi
} from '../../../services/api'

export async function fetchUser(): Promise<AxiosResponse<MeApiResponse>> {
  try {
    return await axios.post(`${AUTH_API_ENDPOINT}/me`)
  } catch (error) {
    throw error
  }
}

export async function resetCallState(me: User): Promise<AxiosResponse> {
  const state = buildTeamState(
    String(me.selectedTeam),
    { callState: CALL_STATE.NOT_IN_CALL, callRoom: null }
  )
  return await updateUserApi({
    id: me.id,
    teamState: {
      ...state
    }
  })
}

const camelToSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

const skippedUserKey = ['idle']

// todo: layout exact data structure
const transformUserBackendData = (data: any): any => {
  let teams = {}
  let parent = {}
  Object.keys(data).forEach((key) => {
    if (key !== 'teamState') {
      // @ts-ignore
      parent[camelToSnakeCase(key)] = data[key]
    }
  })
  if (data.teamState) {
    Object.keys(data.teamState).forEach((teamId) => {
      // @ts-ignore
      teams[teamId] = {}
      if (data.teamState[teamId]) {
        Object.keys(data.teamState[teamId]).forEach(key => {
          if (!skippedUserKey.includes(key)) {
            // @ts-ignore
            teams[teamId][camelToSnakeCase(key)] = data.teamState[teamId][key]
          }
        })
      }
    })
  }
  let backendData = {
    ...parent
  }
  if (teams) {
    // @ts-ignore
    backendData.teams = teams
  }
  return backendData
}

export async function updateUser(user: any): Promise<AxiosResponse> {
  const backendData = transformUserBackendData(user)
  try {
    return await axios.put(`${USER_API_ENDPOINT}/${user.id}`, backendData)
  } catch (error) {
    throw error
  }
}

export async function slackConnectTeam(teamId: number): Promise<AxiosResponse> {
  try {
    // eslint-disable-next-line camelcase
    return await axios.post(`${AUTH_API_ENDPOINT}/app/slack`, { team_id: teamId })
  } catch (error) {
    throw error
  }
}
