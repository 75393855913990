import React from 'react'
import {
  BlackButton,
  FormLabel,
  Loader,
  InputForm,
  InputFormAppendText,
  SignUpFormContainer,
  SignUpForm,
  SignUpFormGroup,
  SignUpFormRow,
  SignUpFormRowHalf
} from '../../modules'
import { FormFieldProps, SignUpFormData } from './interface'

interface FormReviewProps extends FormFieldProps {
  submit: React.MouseEventHandler<HTMLButtonElement>;
  loading: boolean;
  data: SignUpFormData;
}

const Review: React.FC<FormReviewProps> = (props) => {
  if (props.currentStep !== props.formStep) {
    return null
  }
  const {
    teamName,
    teamUrl,
    email,
    password,
    firstName,
    lastName
  } = props.data
  return (
    <SignUpFormContainer>
      <SignUpForm>
        <h1>Everything looks good?</h1>
        <SignUpFormGroup>
          {teamUrl && (
            <>
              <SignUpFormRow>
                <FormLabel>Team Name</FormLabel>
                <InputForm
                  type='text'
                  name='teamName'
                  onChange={props.change}
                  value={teamName}
                />
              </SignUpFormRow>
              <SignUpFormRow>
                <FormLabel>Team URL</FormLabel>
                <InputForm
                  type='text'
                  name='teamUrl'
                  appended={true}
                  onChange={props.change}
                  value={teamUrl}
                />
                <InputFormAppendText>.hqremote.io</InputFormAppendText>
              </SignUpFormRow>
            </>
          )}
          <SignUpFormRow>
            {teamUrl && <FormLabel>Email</FormLabel>}
            <InputForm
              type='email'
              placeholder='Email address'
              name='email'
              onChange={props.change}
              value={email}
            />
          </SignUpFormRow>
          <SignUpFormRow>
            <SignUpFormRowHalf>
              {teamUrl && <FormLabel>First Name</FormLabel>}
              <InputForm
                type='text'
                placeholder='First Name'
                name='first_name'
                onChange={props.change}
                value={firstName}
              />
            </SignUpFormRowHalf>
            <SignUpFormRowHalf>
              {teamUrl && <FormLabel>Last Name</FormLabel>}
              <InputForm
                type='text'
                placeholder='Last Name'
                name='last_name'
                onChange={props.change}
                value={lastName}
              />
            </SignUpFormRowHalf>
          </SignUpFormRow>
          <SignUpFormRow>
            {teamUrl && <FormLabel>Password</FormLabel>}
            <InputForm
              type='password'
              placeholder='Password'
              name='password'
              onChange={props.change}
              value={password}
            />
          </SignUpFormRow>
          <SignUpFormRow>
            <FormLabel
              htmlFor='tnc'
              className='form__label form__label--left'>
              <input
                type='checkbox'
                name='tnc'
                value={1}
                onChange={props.change}
              />
              I agree to the{' '}
              <a
                href={process.env.REACT_APP_APP_URL + '/terms-of-service'}
                target='_blank'
                rel='noopener noreferrer'>
                Terms of Service
              </a>
              .
            </FormLabel>
          </SignUpFormRow>
          <SignUpFormRow>
            <BlackButton
              type='button'
              size={'large'}
              onClick={props.submit}>
              {props.loading ? (
                <Loader />
              ) : teamUrl ? (
                'Create My HQ Remote Team'
              ) : (
                `Join ${teamName}`
              )}
            </BlackButton>
          </SignUpFormRow>
        </SignUpFormGroup>
      </SignUpForm>
    </SignUpFormContainer>
  )
}

export default Review
