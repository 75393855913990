
export function getBaseUrl(w: typeof window = window) {
  const doc = w.document
  const base = doc.querySelector('base')

  if (base?.href) {
    return base.href
  }

  const { protocol, host } = w.location

  return `${protocol}//${host}`
}
