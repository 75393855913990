import React, { useEffect, useRef, useState } from 'react'
import { UserAvailabilityState } from '../constants/user'
import StatusOverlay from './StatusOverlay'

import * as timeago from 'timeago.js'
import { StyledTime, StyledTimeSubtitle } from './Time'
import { capitalize } from '../services/helpers/string'

type StatusProps = {
    state: string;
    isIdle: boolean;
    offline: boolean;
    awaySince: number;
    idleSince: number;
}

const Status: React.FC<StatusProps> = (props) => {
  const [stateTime, setStateTime] = useState<string>()
  const tickerStateTime = useRef()
  const updateStateTime = () => {
    if (props.offline) {
      setStateTime('Offline')
    } else if (props.state === UserAvailabilityState.AWAY) {
      setStateTime(timeago.format((new Date(props.awaySince * 1000))))
    } else if (props.isIdle === true) {
      setStateTime(timeago.format((new Date(props.idleSince))))
    } else {
      setStateTime('')
    }
  }
  useEffect(() => {
    if (tickerStateTime.current) {
      clearInterval(tickerStateTime.current)
    }
    // @ts-ignore
    tickerStateTime.current = setInterval(() => {
      updateStateTime()
    }, 10000)
  }, [props.state, props.isIdle])

  useEffect(() => {
    if (
      props.state === UserAvailabilityState.AWAY ||
        props.isIdle === true
    ) {
      updateStateTime()
    }
  }, [props.state, props.isIdle])

  useEffect(() => {
    return () => {
      if (tickerStateTime.current) {
        clearInterval(tickerStateTime.current)
      }
    }
  }, [])
  return (
    <div>
      <StatusOverlay
        isAway={props.state === UserAvailabilityState.AWAY}
        isIdle={props.isIdle}>
        {(props.state === UserAvailabilityState.AWAY || props.isIdle) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              textAlign: 'center'
            }}
          >
            <StyledTime>
              {
                stateTime?.split(' ')[0] !== 'just' ?
                  capitalize(String(stateTime?.split(' ')[0])) :
                  ''
              }
            </StyledTime>
            <StyledTimeSubtitle>
              {
                stateTime?.split(' ')[0] !== 'just' ?
                  stateTime?.split(' ')[0] === 'Offline' ? '' :
                    capitalize(String(stateTime?.split(' ')[1])) :
                  capitalize(stateTime) }
            </StyledTimeSubtitle>
          </div>
        )}
      </StatusOverlay>
    </div>
  )
}

export default Status
