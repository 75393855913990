import styled, { css, CSSProperties } from 'styled-components'
import { Link } from 'react-router-dom'
import { GeneralComponentProps, RadioLabelProps } from '../interfaces/component'
import React from 'react'

export const ButtonStandard = styled.button`
  border: none;
  background-color: #3264FF;
  color: #fff;
  border-radius: 8px;
  height: 80px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  -webkit-transition: background-color 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, color 300ms ease;

  @media screen and (min-width: 768px) {
    margin: 0;
    width: 210px;
    width: -webkit-calc(50% - 5px);
    width: calc(50% - 5px);
  }

  &:disabled,
  &[disabled] {
    &:hover {
      background-color: #fff;
    }
  }
`

export const LinkStandard = styled(Link)`
  border: 1px solid #cecece;
  font-size: 14px;
  height: 42px;
  line-height: 2;
  letter-spacing: 0.16px;
  -webkit-transition: background-color 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, color 300ms ease;

  @media screen and (min-width: 768px) {
    margin: 0;
    width: 210px;
    width: -webkit-calc(50% - 5px);
    width: calc(50% - 5px);
  }
  &:hover {
    background-color: #e7ff35;
  }
`

export const ButtonWithoutBorder =
  // eslint-disable-next-line space-infix-ops
  styled.button<CSSProperties & React.ButtonHTMLAttributes<HTMLButtonElement>>`
    padding: 0;
    border: none;
    background: none;

    ${(props) => {
    if (props.float === 'right') {
      return css`float: right;`
    }
    return css``
  }}
`

export const BlackButton =
  // eslint-disable-next-line space-infix-ops
  styled.button<GeneralComponentProps&React.ButtonHTMLAttributes<HTMLButtonElement>>`
  background-color: #0f1228;
  color: #fff;
  width: 100%;
  max-width: 175px;
  font-size: 16px;
  height: 42px;
  line-height: 38px;
  letter-spacing: 0.16px;
  border-radius: 50px;
  border: 2px solid #000;
  box-shadow: none;
  outline: none;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.25s ease;
  -webkit-appearance: none !important;

  @media screen and (min-width: 500px) {
    max-width: 180px;
  }

  ${(props) => {
    let style = css``

    if (props.size === 'large') {
      style =
        style.concat(css`
          max-width: 100% !important;
          @media screen and (min-width: 500px) {
            max-width: 60% !important;
            width: 100% !important;
          }
        `)
    }

    if (props.inline) {
      style.concat(css`
          display: inline-block;
          position: relative;
      `)
    }

    return style
  }}
`

export const TransparentButton = styled.button < GeneralComponentProps > `
  background-color: transparent;
  border-radius: 50px;
  border: 2px solid #000;
  box-shadow: none;
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  line-height: 38px;
  height: 42px;
  letter-spacing: 0.16px;
  outline: none;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  -webkit-appearance: none !important;
  opacity: 0.6;
  min-width: 100px;
  transition: opacity 0.25s ease;

  @media screen and (min-width: 500px) {
    max-width: 180px;
  }

  ${(props) => {
    if (props.inline) {
      return css`
        display: inline-block;
        position: relative;
        margin-left: 15px;
      `
    }
    return css``
  }}
`

export const RadioButton = styled.input`
  visibility: hidden;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #b4b4b4;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 10px;
  vertical-align: middle;

  @media screen and (min-width: 768px) {
    height: 12px;
    width: 12px;
  }
`

export const RadioLabel = styled.label < GeneralComponentProps & RadioLabelProps > `
  margin: 0 auto;
  -webkit-transition: background-color 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, color 300ms ease;
  width: 165px;
  line-height: 1.5;

  &.checked {
    background-color: #e7ff35;
  }

  &:hover {
    background-color: #e7ff35;
  }

  @media screen and (min-width: 768px) {
    margin: 0;
    width: -webkit-calc(50% - 5px);
    width: calc(50% - 5px);
  }

  ${(props) => {
    let style = css``

    if (props.inline) {
      style.concat(css`
          display: inline-block;
          float: none !important;
          margin-left: 24px;
          position: relative;
          top: 0;
      `)
    }

    if (props.third) {
      style.concat(css`
          width: -webkit-calc(33% - 5px) !important;
          width: calc(33% - 5px) !important;
      `)
    }
    return style
  }}
`
