import { createContext, Dispatch, SetStateAction } from 'react'

export interface DevicesContextPropsMap {
  mics: MediaDeviceInfo[]
  speakers: MediaDeviceInfo[]
  cameras: MediaDeviceInfo[]
}

export interface DevicesContextProps {
  devices: DevicesContextPropsMap
  setDevices: Dispatch<SetStateAction<DevicesContextPropsMap>>
}

export const DevicesContext = createContext<DevicesContextProps>({
  devices: {
    mics: [],
    speakers: [],
    cameras: []
  },
  setDevices: () => {}
})
