import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as sessionActions, SessionState } from '../reducers/session'
import { AccountSidebar } from '../components'
import { GlobalStyles } from '../styles'
import { RootState } from '../store'
import { UserState } from '../reducers/user'

export interface ProtectedComponentProps {
  session: SessionState;
  user: UserState;
}

export default (WrappedComponent: React.FC<ProtectedComponentProps>) => {
  const ProtectComponent: React.FC = (props) => {
    const session = useSelector((state: RootState): SessionState => state.session)
    const user = useSelector((state: RootState): UserState => state.user)
    const dispatch = useDispatch()
    useEffect(() => {
      dispatch(sessionActions.loginSuccess())
      return () => {
        document.title = 'HQ Remote'
      }
    }, [])
    return (
      <div className={`App show`}>
        <GlobalStyles />
        <AccountSidebar />
        <WrappedComponent
          {...props}
          session={session}
          user={user}
        />
      </div>
    )
  }
  // return ProtectComponent
  return ProtectComponent
}
