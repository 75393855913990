import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as userActions, getSelectedTeamId } from '../reducers/user'
import { User } from '../interfaces/user'
import { RootState } from '../store'
import { CALL_STATE, layouts } from '../constants/call'
import {
  actions as callActions,
  getIsActiveSpeakerManual,
  getActiveSpeakerScreen
} from '../reducers/call'
import { TrackDetail } from '../contexts/stream'
import { getSingleUser } from '../reducers/users'

export default (ChatHead: any) => {
  interface OtherChatHeadProps {
    myself: boolean
    user: User | null
    me: User | null
    layout: number
    style: React.CSSProperties
    showPin: boolean
    tracks: Array<TrackDetail>
    userScreenId: number
    isOnPip: boolean
    disableActions: boolean
  }

  const OtherChatHead: React.FC<OtherChatHeadProps> = (props) => {
    const isActiveSpeakerManual = useSelector((state: RootState) =>
      getIsActiveSpeakerManual(state)
    )
    const activeSpeakerScreen = useSelector((state: RootState) =>
      getActiveSpeakerScreen(state)
    )
    const selectedTeamId = useSelector((state: RootState) =>
      getSelectedTeamId(state)
    )
    const userScreen: User = useSelector((state: RootState) => getSingleUser(state, props.userScreenId))

    const dispatch = useDispatch()

    const handleClick = (event: any, userId: number) => {
      event.preventDefault()
      if (Number(activeSpeakerScreen) === userId) {
        // minimize
        dispatch(userActions.changeLayout(selectedTeamId, layouts.CHAT_HEAD))
        return dispatch(callActions.updateActiveSpeaker(userId, false, false))
      }

      if (!activeSpeakerScreen && props.userScreenId !== props.user?.id) {
        dispatch(userActions.changeLayout(selectedTeamId, layouts.FULL_SCREEN))
        return dispatch(callActions.updateActiveSpeaker(userId, true, true))
      }

      return null
    }

    const handlePin = (event: any, userId: number) => {
      event.preventDefault()
      // unpin user manually to fullscreen view
      if (isActiveSpeakerManual) {
        return dispatch(callActions.updateActiveSpeaker(userId, false, true))
      }
      // pin user manually to fullscreen view
      return dispatch(callActions.updateActiveSpeaker(userId, true, true))
    }

    if (!props.user) {
      return null
    }

    const call = {
      isConnected:
        props.user.teamState[selectedTeamId].callState === CALL_STATE.IN_CALL,
      room: props.user.teamState[selectedTeamId].callRoom
    }

    return (
      <ChatHead
        tracks={props.tracks}
        myself={props.myself}
        disableActions={props.disableActions}
        me={props.me}
        onClick={handleClick}
        id={props.user.id}
        call={call}
        name={`${props.userScreenId ? userScreen.name : props.user.name }'s Screen`}
        hideDetails={true}
        style={props.style}
        isScreen={true}
        layout={props.layout}
        handlePin={handlePin}
        pin={isActiveSpeakerManual && props.user.id === activeSpeakerScreen}
        showPin={props.showPin}
        user={props.user}
        isOnPip={props.isOnPip}
      />
    )
  }

  OtherChatHead.defaultProps = {
    user: null,
    layout: layouts.FULL_SCREEN,
    showPin: false
  }

  return OtherChatHead
}
