import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Label, animations } from './Helpers'
import { useDispatch } from 'react-redux'
import {
  actions as notificationActions
} from '../reducers/notification'
import { sounds } from '../reducers/notifications'
import { GIF_SHOT_INTERVAL } from '../constants/gif'
import { COUNTDOWN_STATE } from '../constants/countdown'
interface BackgroundProps {
  countdownState: string;
  flash: boolean;
  countdownDelay: number;
}

const Background = styled.div < BackgroundProps > `
  ${props => {
    if (props.flash === true) {
      return css`
      animation: ${animations.backgroundFlash} 0.5s linear infinite;`
    }

    return ``
  }}

  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${(props) =>
    [COUNTDOWN_STATE.DONE, COUNTDOWN_STATE.STANDBY].includes(props.countdownState) ? -1 : 10 };
`

const Container = styled.div`
  height: 75px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 75px;
`

interface CircleProps {
  state: string;
}

const Circle = styled.svg < CircleProps > `
  backface-visibility: hidden;
  fill: none;
  height: 100%;
  opacity: ${props => props.state === COUNTDOWN_STATE.READY ? `1` : `0`};
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;

  enable-background: new 0 0 80 80;
  stroke-dasharray: 322;
  transform: rotate(-90deg);

  ${props => {
    if (props.state === COUNTDOWN_STATE.READY) {
      return css`
        stroke-dashoffset: 37.5;
        transition: stroke-dashoffset 3.5s linear;
        transition-delay: 500ms;
      `
    }
    return css`
      stroke-dashoffset: 322;
    `
  }}
  circle {
    fill: none;
    stroke: white;
    stroke-miterlimit: 10;
    stroke-width: 5px;
  }
`

interface DotProps {
  delay: number;
}

const Dot = styled.div < DotProps > `
  background: white;
  border-radius: 50%;
  height: 3px;
  opacity: 0;
  position: absolute;
  width: 3px;
  transition: opacity 50ms;
  transition-delay: ${props => props.delay}ms;
`

interface DotsProps {
  state: string;
  dotsCount: number;
}

const Dots = styled.div < DotsProps > `
  height: 100%;
  opacity: ${props => props.state === COUNTDOWN_STATE.READY ? `1` : `0`};
  transform: rotate(105deg);
  width: 100%;

  ${props => {
    const totalCount = props.dotsCount

    let theta = 0
    let step = 2 * (Math.PI / totalCount)

    let h = 72 / 2
    let k = 72 / 2
    let r = 71 / 2

    let output = ``
    let index = 1

    while (theta < 2 * Math.PI) {
      let x = h - (r * Math.cos(theta))
      let y = k - (r * Math.sin(theta))

      output = output + `
        & > div:nth-child(${index}) {
          left: ${x}px;
          top: ${y}px;
        }
      `

      index = index + 1
      theta = theta + step
    }

    return css`${output}`
  }}
  ${Dot} {
    opacity: ${props => props.state === COUNTDOWN_STATE.READY ? `1` : `0`};
  }
`

const Seconds = styled(Label)`
  font-size: 28px;
  font-weight: bold;
  height: 100%;
  left: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;

  div {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`

Seconds.displayName = 'Seconds'
Circle.displayName = 'Circle'
Dots.displayName = 'Dots'

export const initialState = {
  flash: false,
  second: 3
}

interface CountdownProps {
  delay: number;
  dotsCount: number;
  state: string;
  manual: boolean;
  onDone: ((callback: () => void) => void) | null
}

const Countdown: React.FC<CountdownProps> = (props) => {
  const [flash, setFlash] = useState<boolean>(false)
  const [second, setSecond] = useState<number>(3)
  // eslint-disable-next-line no-undef
  const [ticker, setTicker] = useState<NodeJS.Timeout|null>(null)
  // eslint-disable-next-line no-undef
  const [flashLoop, setFlashLoop] = useState<NodeJS.Timeout|null>(null)
  const dots = (Array.from(Array(props.dotsCount).keys())).map((i) => {
    const delay = (i / props.dotsCount) * 500
    return (
      <Dot key={`dot-${i}`} delay={delay} />
    )
  })
  const dispatch = useDispatch()
  const reset = () => {
    setSecond(3)
    setFlash(false)
    if (ticker) {
      clearInterval(ticker)
    }
  }

  const loopFlash = () => {
    setFlashLoop(setInterval(() => {
      if (props.manual) {
        dispatch(notificationActions.updateAudio(sounds.SNAP))
      }
      setFlash(true)
      setTimeout(() => {
        setFlash(false)
      }, 500)
    }, GIF_SHOT_INTERVAL))
  }

  const doFlash = () => {
    if (props.onDone) {
      loopFlash()
      props.onDone(() => {
        reset()
      })
    }
  }

  useEffect(() => {
    if (second === 0) {
      doFlash()
    }
    if (second === 3) {
      if (flashLoop) {
        clearInterval(flashLoop)
      }
    }
  }, [second])
  useEffect(() => {
    if (props.state === COUNTDOWN_STATE.READY) {
      setTimeout(() => {
        setTicker(() => {
          // eslint-disable-next-line max-nested-callbacks
          return setInterval(() => {
            // eslint-disable-next-line max-nested-callbacks
            setSecond((s) => s - 1)
          }, 1000)
        })
      }, 500)
    }
    return () => {
      if (ticker) {
        clearInterval(ticker)
      }
      if (flashLoop) {
        clearInterval(flashLoop)
      }
    }
  }, [props.state])
  return (
    <Background
      countdownState={props.state}
      flash={flash}
      countdownDelay={props.delay}
    >
      <Container>
        {second > 0 &&
          <div
            style={{
              display: 'inline',
              visibility: props.manual ? 'visible' : `hidden`
            }}
          >
            <Circle
              state={props.state}
              version='1.1'
              xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'>
              <circle cx='37.5' cy='37.5' r='35' />
            </Circle>

            <Dots dotsCount={props.dotsCount + 1}
              state={props.state}>
              {dots}
            </Dots>
          </div>
        }
        {props.manual && second > 0 && props.state === COUNTDOWN_STATE.READY &&
          <Seconds>
            <div>{second}</div>
          </Seconds>
        }
      </Container>
    </Background>
  )
}

Countdown.defaultProps = {
  delay: 3500,
  dotsCount: 28,
  onDone: null
}

export default Countdown
