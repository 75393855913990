// <reference types="webrtc" />
import React, { useEffect, useState } from 'react'

type NetworkDetectorProps = {
}

type ComposedComponentProps = {
  connected: boolean,
  webRTCUnsupported: boolean
}

export default function (ComposedComponent: React.FC<ComposedComponentProps>) {
  const NetworkDetector = (props: NetworkDetectorProps) => {
    const [isDisconnected, setConnected] = useState(false)
    const [webRTCUnsupported, setWebRTCUnsupported] = useState(false)
    const handleWebRTC = () => {
      const isWebRTCSupported =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia ||
          window.RTCPeerConnection

      if (window.navigator.userAgent.indexOf('Edge') > -1) {
        setWebRTCUnsupported(false)
      }

      if (!isWebRTCSupported) {
        setWebRTCUnsupported(true)
      }
    }
    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline'
      if (condition === 'online') {
        setConnected(true)
        handleWebRTC()
      } else {
        setConnected(false)
      }
    }
    useEffect(() => {
      handleConnectionChange()
      window.addEventListener('online', handleConnectionChange)
      window.addEventListener('offline', handleConnectionChange)
      handleWebRTC()
      return () => {
        window.removeEventListener('online', handleConnectionChange)
        window.removeEventListener('offline', handleConnectionChange)
      }
    }, [])
    return (
      <>
        <ComposedComponent
          {...props}
          connected={isDisconnected}
          webRTCUnsupported={webRTCUnsupported}
        />
      </>
    )
  }

  return NetworkDetector
}
