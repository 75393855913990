import React, { useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  SocialAuth,
  Home,
  ForgotPassword,
  ResetPassword,
  Account,
  ManageTeam,
  InvitationPage,
  ManageTeamEdit,
  TeamChangeLogs,
  MainBillingPage,
  PaymentMethod,
  BillingHistory,
  UpgradePage,
  AcceptInvitation,
  SignUp,
  Activate,
  NotFound
} from './pages'
import NetworkDetector from './hocs/NetworkDetector'
import { Snackbar } from './modules'
import { routes } from './utils'
import {
  actions as notificationActions, getAudio,
  getSnackbar
} from './reducers/notification'
import { RootState } from './store'

type AppProps = {
  connected: boolean,
  webRTCUnsupported: boolean,
}
import { Snackbar as SnackbarI } from './reducers/notification'
import { isElectron } from './utils/env'
import PaymentSuccess from './pages/PaymentSuccess'

const App = (props: AppProps) => {
  const [connectCount, setConnectCount] = useState<number>(0)
  const snackbar = useSelector((state: RootState) => getSnackbar(state))
  const audio = useSelector((state: RootState) => getAudio(state))
  // const audioRef = useRef<HTMLAudioElement>(null)
  const dispatch = useDispatch()
  useEffect(() => {
    if (connectCount > 1) {
      const payload: SnackbarI = {
        show: true,
        status: props.connected ? 'success' : 'error',
        message: props.connected ? 'You have reconnected' :
          'You have been disconnected. We are trying to reconnect you.'
      }
      if (props.connected) {
        payload.timeout = 3000
      }
      dispatch(
        notificationActions.updateSnackbar(payload)
      )
    }
    setConnectCount(connectCount + 1)
  }, [props.connected])
  useEffect(() =>{
    if (props.webRTCUnsupported) {
      notificationActions.updateSnackbar({
        show: true,
        status: 'warning',
        message:
            'This app use webRTC. Please use browser with supported for WebRTC.'
      })
      return
    }
  }, [props.webRTCUnsupported])
  useEffect(() => {
    if (snackbar.show) {
      if (snackbar.timeout) {
        setTimeout(() => {
          dispatch(
            notificationActions.updateSnackbar({
              show: false,
              status: '',
              message: ''
            })
          )
        }, snackbar.timeout)
      }
    }
  }, [snackbar])
  return (
    <>
      { isElectron() ? (
        <HashRouter>
          <Route
            path={routes.home}
            exact
            render={(homeProps) => <Home {...homeProps} {...props} />}
          />
          <Route path={routes.loginSlack} exact component={SocialAuth} />
          <Route path={routes.loginGoogle} exact component={SocialAuth} />
          <Route path={routes.signUp} component={SignUp} />
          <Route path={routes.invitationAccept} component={AcceptInvitation} />
          <Route path={routes.activate} component={Activate} />
          <Route
            path={routes.forgotPassword}
            exact
            component={ForgotPassword}
          />
          <Route path={routes.resendPassword} exact component={ResetPassword} />
          <Route path={routes.invitation} exact component={InvitationPage} />
          <Route path={routes.teamManage} exact component={ManageTeam} />
          <Route path={routes.teamEdit} exact component={ManageTeamEdit} />
          <Route
            path={routes.teamChangeLogs}
            exact
            component={TeamChangeLogs}
          />
          <Route path={routes.billing} exact component={MainBillingPage} />
          <Route
            path={routes.billingPaymentMethod}
            exact
            component={PaymentMethod}
          />
          <Route
            path={routes.billingHistory}
            exact
            component={BillingHistory}
          />
          <Route
            path={routes.billingUpgrade}
            exact
            component={UpgradePage} />
          <Route path={routes.account} exact component={Account} />
        </HashRouter>
      ) : (
        <Switch>
          <Route
            path={routes.home}
            exact
            render={(homeProps) => <Home {...homeProps} {...props} />}
          />
          <Route path={routes.loginSlack} exact component={SocialAuth} />
          <Route path={routes.loginGoogle} exact component={SocialAuth} />
          <Route path={routes.signUp} component={SignUp} />
          <Route path={routes.invitationAccept} component={AcceptInvitation} />
          <Route path={routes.activate} component={Activate} />
          <Route
            path={routes.forgotPassword}
            exact
            component={ForgotPassword}
          />
          <Route path={routes.resendPassword} exact component={ResetPassword} />
          <Route path={routes.invitation} exact component={InvitationPage} />
          <Route path={routes.teamManage} exact component={ManageTeam} />
          <Route path={routes.teamEdit} exact component={ManageTeamEdit} />
          <Route
            path={routes.teamChangeLogs}
            exact
            component={TeamChangeLogs}
          />
          <Route path={routes.billing} exact component={MainBillingPage} />
          <Route
            path={routes.billingPaymentMethod}
            exact
            component={PaymentMethod}
          />
          <Route
            path={routes.billingHistory}
            exact
            component={BillingHistory}
          />
          <Route
            path={routes.billingUpgrade}
            exact
            component={UpgradePage} />
          <Route path={routes.account} exact component={Account} />
          <Route
            path={routes.paymentSuccess}
            exact
            component={PaymentSuccess} />
          <Route component={NotFound} />
        </Switch>
      )}
      <Snackbar
        type={snackbar?.status}
        message={snackbar?.message}
        zIndex={999999}
        show={snackbar?.show}
        callback={snackbar?.callback}
      />
      { audio !== '' && (
        <audio
          src={audio}
          className='audio-element'
          autoPlay
          onEnded={(): void => {
            dispatch(notificationActions.updateAudio(''))
          }}
        />
      )}
    </>
  )
}

export default NetworkDetector(App)
