import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import { fetchInitialData } from '../helpers'
import { types } from '../../reducers/realtime/user'
import { CallState, actions as callActions, getCall } from '../../reducers/call'
import {
  actions as notificationsActions,
  sounds as notificationSounds,
  bannerTypes as notificationBannerTypes,
  displayTypes as notificationDisplay
} from '../../reducers/notifications'
import { actions as sessionActions } from '../../reducers/session'
import { actions as userActions, getCurrentUser } from '../../reducers/user'
import {
  actions as teamsActions,
  getTeamsAlphabetically,
  getSingleTeam,
  getSingleOffice,
  getDefaultTeamOffice
} from '../../reducers/teams'
import { User } from '../../interfaces/user'
import { Office, Team } from '../../interfaces/team'
import {
  MessageCallLeave,
  MessageCallStart
} from '../../reducers/realtime/interfaces/call'
import { RealtimeAction } from './interface'
import { getSelectedTeamOffices } from '../../reducers/shared'
import { CALL_STATE } from '../../constants/call'
import { sendUpdateCallState } from '../../services/socket/socket'
import { CHANNEL_CLIENT_TEAM } from '../../constants/socket'
import { UserAvailabilityState } from '../../constants/user'

export const USER_JOIN_TEAM_TIMEOUT = 5000
export const USER_JOIN_OFFICE_TIMEOUT = 5000

export function* callStart(action: RealtimeAction<MessageCallStart>) {
  const callConfig: CallState = yield select(getCall)
  const user: User = yield select(getCurrentUser)

  const data = action.payload
  // add call-index data to calls
  const roomIndex = Object.keys(callConfig.rooms).indexOf(String(data.index))
  let existingParticipants: number[] = []
  if (roomIndex >= 0) {
    existingParticipants = callConfig.rooms[data.index].filter(v => [data.calleeId, data.callerId].indexOf(v) === -1)
  }
  yield put(callActions.setCallRooms({
    ...callConfig.rooms,
    [data.index]: [
      ...existingParticipants,
      data.calleeId,
      data.callerId
    ]
  }))
  if (data?.calleeId) {
    if (user.id === data.calleeId) {
      if (
        user.callState !== CALL_STATE.NOT_IN_CALL
      ) {
        return
      }
      if (user.teamState[user.selectedTeam].state !== UserAvailabilityState.AVAILABLE) {
        return
      }
      yield put(callActions.receiveStart(data))
      sendUpdateCallState(
        action.payload.clientChannels[`${CHANNEL_CLIENT_TEAM}:${user.selectedTeam}`],
        {
          teamId: user.selectedTeam,
          userId: Number(data.calleeId),
          callState: CALL_STATE.JOINING_CALL,
          callRoom: callConfig.config.roomFormat.replace('*', String(data.index))
        }
      )
      return
    }
    if (user.id === data.callerId) {
      if (
        user.callState !== CALL_STATE.NOT_IN_CALL
      ) {
        return
      }
      yield put(callActions.receiveStart({
        ...data,
        calleeId: data.callerId,
        callerId: data.calleeId
      }))
      sendUpdateCallState(
        action.payload.clientChannels[`${CHANNEL_CLIENT_TEAM}:${user.selectedTeam}`],
        {
          teamId: user.selectedTeam,
          userId: Number(data.callerId),
          callState: CALL_STATE.JOINING_CALL,
          callRoom: callConfig.config.roomFormat.replace('*', String(data.index))
        }
      )
    }
  }
}

export function* callEnded(action: RealtimeAction<MessageCallLeave>) {
  const { serverChannel, userId, setTracks } = action.payload
  const callConfig: CallState = yield select(getCall)
  let callIndex: number|null = null
  // get the call index the userId is in
  Object.keys(callConfig.rooms).map((key: string) => {
    if (callConfig.rooms[Number(key)].indexOf(userId) >= 0) {
      callIndex = Number(key)
    }
  })
  if (callIndex !== null) {
    const remainingParticipants = callConfig.rooms[callIndex].filter(v => v !== userId)
    if (remainingParticipants.length <= 0) {
      delete callConfig.rooms[callIndex]
    } else {
      callConfig.rooms[callIndex] = remainingParticipants
    }
    yield put(callActions.setCallRooms({
      ...callConfig.rooms
    }))
  }
  yield put(
    callActions.receiveLeave(userId, serverChannel, setTracks)
  )
}

export function* errorWatcher(action: any) {
  yield put(userActions.error(action.payload.error))
}

export function* logoutWatcher() {
  yield put(sessionActions.logout())
}

export function* joinedTeam(action: any) {
  const { teamId } = action.payload

  yield call(fetchInitialData)

  const team: Team = yield select(getSingleTeam, teamId)
  if (!team) {
    return
  }

  const message = 'You have been added to "' + team.name + '"'
  const options = {
    display: notificationDisplay.BOTH,
    type: notificationBannerTypes.ALERT,
    sound: notificationSounds.DEFAULT,
    timeout: USER_JOIN_TEAM_TIMEOUT
  }

  yield put(notificationsActions.create(message, options))

  const defaultOffice: Office = yield select(getDefaultTeamOffice, team.id)
  yield put(userActions.switchOffice(team.id, defaultOffice.id))
}

export function* removedTeam(action: any) {
  yield call(fetchInitialData)

  const teams: Team[] = yield select(getTeamsAlphabetically)

  const [firstTeam] = Object.values(teams).slice(0, 1)
  yield put(userActions.switchTeam(firstTeam.id))

  const { teamId } = action.payload
  yield put(userActions.removeOffice(teamId))

  const defaultOffice: Office = yield select(getDefaultTeamOffice, teamId)
  yield put(userActions.switchOffice(firstTeam.id, defaultOffice.id))
}

export function* joinedOffice(action: any) {
  const { teamId, officeId } = action.payload

  yield call(fetchInitialData)

  const team: Team = yield select(getSingleTeam, teamId)
  if (!team) {
    return
  }

  const office: Office = yield select(getSingleOffice, teamId, officeId)
  if (!office) {
    return
  }

  const message =
    'You have been added to "' + office.name + '" in "' + team.name + '"'
  const options = {
    display: notificationDisplay.BOTH,
    type: notificationBannerTypes.ALERT,
    sound: notificationSounds.DEFAULT,
    timeout: USER_JOIN_OFFICE_TIMEOUT
  }

  yield put(notificationsActions.create(message, options))
}

export function* removedOffice(action: any) {
  const { teamId, officeId } = action.payload

  const defaultOffice: Office = yield select(getDefaultTeamOffice, teamId)
  yield put(userActions.switchOffice(teamId, defaultOffice.id))
  yield put(teamsActions.removeOffice(teamId, officeId))
}

export function* createdOffice(action: any) {
  const { teamId, office } = action.payload
  const currentUser: User = yield select(getCurrentUser)
  if (office.users.indexOf(currentUser.id) > -1) {
    yield put(teamsActions.addOffice(teamId, office))
  }
}

export function* updatedOffice(action: any) {
  const { teamId, office } = action.payload
  const currentUser: User = yield select(getCurrentUser)
  const offices: Office[] = yield select(getSelectedTeamOffices)
  if (offices.find((off) => off.id === office.id)) {
    yield put(teamsActions.updateOffice(teamId, office))
  }
  if (office.users.indexOf(currentUser.id) > -1) {
    yield put(teamsActions.addOffice(teamId, office))
  }
}

// Combine all of our watchers.
export default function* realtimeUserWatcher() {
  yield all([
    takeLatest(types.CALL_ENDED, callEnded),
    takeLatest(types.CALL_START, callStart),
    takeLatest(types.ERROR, errorWatcher),
    takeLatest(types.LOGOUT, logoutWatcher),
    takeEvery(types.JOINED_TEAM, joinedTeam),
    takeEvery(types.REMOVED_TEAM, removedTeam),
    takeEvery(types.JOINED_OFFICE, joinedOffice),
    takeEvery(types.REMOVED_OFFICE, removedOffice),
    takeEvery(types.CREATED_OFFICE, createdOffice),
    takeEvery(types.UPDATED_OFFICE, updatedOffice)
  ])
}
