import React from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  margin: auto 0;
`

const IconImage = styled.img`
  color: white;
  object-fit: fill !important;
  width: 100%;
  height: 100%;
`

const IconLabel = styled.span`
  color: white;
  font-size: 10px;
  position: absolute;
  top: 18.5px;
  right: 18.5px;
  width: 12px;
  height: 12px;
  vertical-align: middle;
`

export default ({ icon, label, iconStyle = {} }) => {
  return (
    <Wrapper>
      <IconImage src={icon} style={iconStyle} />
      <IconLabel style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        borderRadius: '50%'
      }}>{label}</IconLabel>
    </Wrapper>
  )
}
