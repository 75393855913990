// @ts-ignore
import dotProp from 'dot-prop-immutable'
import { createSelector } from 'reselect'
import { PayloadAction } from '@reduxjs/toolkit'
import { Office, Team } from '../interfaces/team'
import { RootState } from '../store'
import { IInterval } from '../interfaces/interval'

export const types = {
  LOADED: 'teams/loaded',
  USER_JOINED: 'teams/user-joined',
  FAILED: 'teams/failed',
  UPDATE_SINGLE: 'teams/update-single',
  ADD_OFFICE: 'teams/add-office',
  UPDATE_OFFICE: 'teams/update-office',
  REMOVE_OFFICE: 'teams/remove-office',
  USER_SWITCH_OFFICE: 'teams/user/switch-office',
  USER_ENTERED: 'teams/user/entered',
  USER_LEFT: 'teams/user/left',
  UPDATE_TEAM_SETTING: 'teams/update/settings',
  UPDATE_LOCAL_TEAM_SETTING: 'teams/update/local-settings',
  ON_UPDATE_ERRORS: 'teams/update/errors'
}

export const initialState = {
  hasLoaded: false,
  byId: {},
  allIds: [],
  errors: null
}

export interface TeamsState {
  hasLoaded: boolean;
  byId: { [key: number]: Team };
  allIds: any[];
  errors: Object | null;
}

export interface TeamsPayloadAction extends PayloadAction<Object> {
  payload: {
    teamId: number;
    officeId: number;
    userId: number;
    teams: Team[];
    // todo: data have a fixed structure, figure this out later
    data: any;
    office?: Office;
  }
}

export default (state: TeamsState = initialState, action: TeamsPayloadAction) => {
  switch (action.type) {
    case types.LOADED:
      return {
        ...state,
        hasLoaded: true,
        byId: action.payload.teams,
        allIds: Object.keys(action.payload.teams)
      }
    case types.USER_JOINED:
      return dotProp.set(state, `byId.${action.payload.teamId}`, (team: Team) => {
        return {
          ...team,
          users: [
            ...team.users,
            action.payload.userId
          ],
          offices: {
            [action.payload.officeId]: {
              ...team.offices[action.payload.officeId],
              users: [
                ...team.offices[action.payload.officeId].users,
                action.payload.userId
              ],
              onlineUsers: [
                ...team.offices[action.payload.officeId].onlineUsers,
                action.payload.userId
              ]
            }
          }
        }
      })
    case types.FAILED:
      return { ...state, hasLoaded: false, byId: {}, allIds: [] }

    case types.UPDATE_SINGLE:
      return dotProp.set(state, `byId.${action.payload.teamId}`, (team: Team) => {
        return {
          ...team,
          ...action.payload.data
        }
      })

    case types.UPDATE_TEAM_SETTING:
      return dotProp.set(state, `byId.${action.payload.teamId}`, (team: Team) => {
        return {
          ...team,
          ...action.payload.data
        }
      })
    case types.UPDATE_LOCAL_TEAM_SETTING:
      return dotProp.set(state, `byId.${action.payload.teamId}`, (team: Team) => {
        return {
          ...team,
          ...action.payload.data
        }
      })

    case types.ON_UPDATE_ERRORS:
      return {
        ...state,
        errors: action.payload
      }

    case types.REMOVE_OFFICE:
      return dotProp.delete(
        state,
        `byId.${action.payload.teamId}.offices.${action.payload.officeId}`
      )

    case types.ADD_OFFICE:
      return dotProp.set(
        state,
        `byId.${action.payload.teamId}.offices`,
        (offices: Office[]) => {
          if (!action.payload.office) {
            return offices
          }
          let result = {
            ...offices
          }
          result[action.payload.office.id] = action.payload.office
          return result
        }
      )

    case types.UPDATE_OFFICE:
      return dotProp.set(
        state,
        `byId.${action.payload.teamId}.offices`,
        (offices: Office[]) => {
          if (!action.payload.office) {
            return offices
          }
          offices[action.payload.office.id] = action.payload.office
          return offices
        }
      )

    case types.USER_SWITCH_OFFICE:
      return dotProp.set(
        state,
        `byId.${action.payload.teamId}.offices`,
        (offices: Office[]) => {
          Object.keys(offices).forEach((id) => {
            let office = offices[Number(id)]
            if (office.id === action.payload.officeId) {
              if (office.onlineUsers.indexOf(action.payload.userId) > -1) {
                return
              }
              office.onlineUsers.push(action.payload.userId)
              offices[Number(id)] = office
              return
            }

            const index = office.onlineUsers.indexOf(action.payload.userId)
            if (index > -1) {
              office.onlineUsers.splice(index, 1)
            }

            offices[Number(id)] = office
            return
          })

          return offices
        }
      )

    case types.USER_ENTERED:
      return dotProp.set(
        state,
        `byId.${action.payload.teamId}.onlineUsers`,
        (onlineUsers: number[]) => [...onlineUsers.filter(
          u => u !== action.payload.userId), action.payload.userId]
      )

    case types.USER_LEFT:
      return dotProp.set(
        state,
        `byId.${action.payload.teamId}.onlineUsers`,
        (onlineUsers: number[]) => [
          ...onlineUsers.filter((id) => id !== action.payload.userId)
        ]
      )

    default:
      return state
  }
}

export const actions = {
  loaded: (teams: Team[]) => ({ type: types.LOADED, payload: { teams } }),
  userJoined: ({
    teamId,
    officeId,
    userId
  }: {
    teamId: number,
    officeId: number,
    userId: number
  }) => ({ type: types.USER_JOINED, payload: { teamId, officeId, userId } }),
  failed: () => ({ type: types.FAILED }),
  updateSingle: (teamId: number, data: any) => ({
    type: types.UPDATE_SINGLE,
    payload: { teamId, data }
  }),
  removeOffice: (teamId: number, officeId: number) => ({
    type: types.REMOVE_OFFICE,
    payload: { teamId, officeId }
  }),
  addOffice: (teamId: number, office: Office) => ({
    type: types.ADD_OFFICE,
    payload: { teamId, office }
  }),
  updateOffice: (teamId: number, office: Office) => ({
    type: types.UPDATE_OFFICE,
    payload: { teamId, office }
  }),
  userSwitchoffice: (userId: number, teamId: number, officeId: number) => ({
    type: types.USER_SWITCH_OFFICE,
    payload: { userId, teamId, officeId }
  }),
  userEntered: (teamId: number, userId: number) => ({
    type: types.USER_ENTERED,
    payload: { teamId, userId }
  }),
  userLeft: (teamId: number, userId: number) => ({
    type: types.USER_LEFT,
    payload: { teamId, userId }
  }),
  updateTeamSetting: (teamId: number, data: any) => ({
    type: types.UPDATE_TEAM_SETTING,
    payload: { teamId, data }
  }),
  updateLocalTeamSetting: (teamId: number, data: any) => ({
    type: types.UPDATE_LOCAL_TEAM_SETTING,
    payload: { teamId, data }
  }),
  onUpdateError: (errors: Object | null) => {
    return {
      type: types.ON_UPDATE_ERRORS,
      payload: errors
    }
  }
}

// Selectors
export const getTeamsById = (state: RootState): { [key: number]: Team } => state.entities.teams.byId
export const isTeamSubscriptionActive = (state: RootState, id: number): boolean => {
  const team = state.entities.teams.byId[id]
  if (!team) {
    return false
  }
  if (process.env.REACT_APP_TEST_ENV) {
    return true
  }
  return team.subscription[0]?.stripe_status === 'active'
}
export const getSingleTeam = (state: RootState, id: number): Team => state.entities.teams.byId[id]
export const getTeamsAlphabetically =
  createSelector([getTeamsById], (teams: { [key: number]: Team }) =>
    Object.values(teams).sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
  )
export const countTeams = (state: RootState): number => state.entities.teams.allIds.length

export const getSingleOffice = (state: RootState, teamId: number, officeId: number): Office =>
  state.entities.teams.byId[teamId].offices[officeId]

export const getDefaultTeamOffice = (state: RootState, teamId: number): Office|boolean => {
  const teams = getTeamsById(state)
  if (teams[teamId]) {
    const keys = Object.keys(teams[teamId].offices)
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keys.length; i++) {
      if (teams[teamId].offices[keys[i]]) {
        return teams[teamId].offices[keys[i]]
      }
    }
  }
  return false
}

export const hasLoaded = (state: RootState): number => state.entities.teams.hasLoaded

// Helpers
export const getNextAllowedInterval =
  (currentInterval: IInterval, allowedIntervals: IInterval[]) => {
    const totalAllowedIntervals = allowedIntervals.length
    if (totalAllowedIntervals === 1) {
      return allowedIntervals[0]
    }

    const position = allowedIntervals.map((allowedInterval) => {
      return allowedInterval.id
    }).indexOf(currentInterval.id)
    if (position === -1) {
      return allowedIntervals[0]
    }

    let nextPosition = position + 1
    if (nextPosition >= totalAllowedIntervals) {
      nextPosition = 0
    }
    return allowedIntervals[nextPosition]
  }
