import React from 'react'

const AccountSettingText: React.FC<React.PropsWithChildren<any>> = (props) => {
  return (<p
    style={{
      color: '#9D9D9D',
      fontSize: '18px',
      fontWeight: 500
    }}
  >
    {props.children}
  </p>)
}

export default AccountSettingText
