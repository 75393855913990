import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface ColProps {
  children: ReactNode
}

const StyledCol = styled.div`
  width: 100%;
`
const Col = (props: ColProps) => {
  return <StyledCol>{props.children}</StyledCol>
}

interface GridProps {
  children: ReactNode
  size?: number
}
const StyledGrid = styled.div<{ size: number }>`
  ${(props) => css`
    grid-template-columns: repeat(auto-fit, minmax(${props.size}rem, 1fr));
  `}
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: row;
  row-gap: 1rem;
  margin-top: 5px;
`

const Grid = ({ children, size = 10 }: GridProps) => {
  return <StyledGrid size={size}>{children}</StyledGrid>
}
Grid.Col = Col
export default Grid
