/* eslint-disable generator-star-spacing */
import { all, call, fork, put, select, take } from 'redux-saga/effects'
import { fetchInitialData } from './helpers'
import { actions } from '../reducers/session'
import { actions as teamsActions } from '../reducers/teams'
import { actions as usersActions } from '../reducers/users'
import { types as sessionTypes } from '../reducers/session'
import { actions as settingAction } from '../reducers/settings'
import { actions as notificationActions } from '../reducers/notification'
import { actions as userActions, getCurrentUser } from '../reducers/user'
import { store } from '../store'

import {
  login as loginApi,
  logout as logoutApi,
  callbackSocial,
  fetchUser
} from '../services/api'
import { removeAuthData, setAuthData } from '../services/auth'
import { MeApiResponse } from '../interfaces/api/userApi'
import axios, { AxiosResponse, HttpStatusCode } from 'axios'
import { User } from '../interfaces/user'
import { callbackAddSlack } from '../services/api/library/auth'
import { isElectron } from '../utils/env'
import { reloadHome } from '../utils/routes'

export function *loginFlow(params: {email: string, password: string, remember_me: boolean}) {
  try {
    const response: AxiosResponse<MeApiResponse> = yield call(loginApi, params)
    // eslint-disable-next-line no-console
    console.log('login-response', response)
    yield call(setAuthData, response.data.user)
    yield put(actions.loginSuccess())
  } catch (e) {
    yield put(actions.loginFailure(e))
  }
}

export function* loginFlowSocial(type: string, query: string) {
  try {
    const response: AxiosResponse<MeApiResponse> = yield call(callbackSocial, type, query)

    yield call(setAuthData, response.data.user)

    yield put(actions.loginSuccess())
  } catch (e) {
    yield put(actions.loginFailure(e))
  }
}

export function* addAppFlowSlack(query: string) {
  try {
    const response: AxiosResponse<any> = yield call(callbackAddSlack, query)
    const { access_token: authToken } = response.data.data

    yield call(setAuthData, authToken)

    yield put(actions.loginSuccess())
  } catch (e) {
    yield put(actions.loginFailure(e))
  }
}

export function* loginSuccessFlow() {
  try {
    yield put(actions.initialData())
    const existingUserData: User|null = yield select(getCurrentUser)
    // Call the API to fetch our user
    const userResponse: AxiosResponse<MeApiResponse> = yield call(fetchUser)

    let { user } = userResponse.data
    if (existingUserData && existingUserData.selectedTeam) {
      // keep existing team-selection
      user = {
        ...user,
        selectedTeam: existingUserData.selectedTeam
      }
    }
    if (isElectron()) {
      // @ts-ignore
      window.ipcRender.getSettings().then(res => {
        // fetch existing setting from config file for desktop-app
        // @ts-ignore
        store.dispatch(settingAction.setElectronSettings(res))
      })
    }
    // Give our user reducer our users' data
    yield put(userActions.loaded(user))
    yield call(fetchInitialData)
    // Mark the session has having loaded the initial data
    yield put(actions.initialDataSuccess())
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (
        [HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden, 419].includes(
          e.response?.status ?? 0
        )
      ) {
        document.cookie = ''
        yield put(actions.logoutSuccess())
        yield call(removeAuthData)
        yield put(actions.initialDataSuccess())
        return
      }
    }
    // eslint-disable-next-line no-console
    console.log('data initialization fail: ', e)
    // Mark the session as having failed the initial data request
    yield all([
      put(notificationActions.updateSnackbar({
        message: `Error initializing data from the server, <span style="color: rgb(50, 100, 255);">click here</span> to reload. If error persists try clearing cookies, or contact support team.`,
        show: true,
        status: 'warning',
        callback: () => {
          reloadHome(false)
        }
      })),
      put(actions.initialDataFailure()),
      put(teamsActions.failed()),
      put(usersActions.failed()),
      put(userActions.failed())
    ])
  }
}

export function* logoutFlow() {
  yield put(actions.logoutSuccess())
  try {
    yield all([
      call(logoutApi),
      put(userActions.resetUserData())
    ])
    reloadHome(false)
  } catch (e) {
    // eslint-disable-next-line
    console.log('error logging out', e)
  }
  yield call(removeAuthData)
}

export function* loginSuccessWatcher() {
  while (true) {
    // When we successfully log in fetch initial data
    yield take(sessionTypes.LOGIN_SUCCESS)
    yield fork(loginSuccessFlow)
  }
}

export function* loginWatcher() {
  while (true) {
    const {
      // eslint-disable-next-line camelcase
      payload: { email, password, remember_me }
    } = yield take(sessionTypes.LOGIN_REQUEST)
    // eslint-disable-next-line camelcase
    yield fork(loginFlow, { email, password, remember_me })
  }
}

export function* loginGoogleWatcher() {
  while (true) {
    const {
      payload: { queryString }
    } = yield take(sessionTypes.LOGIN_REQUEST_GOOGLE)
    yield fork(loginFlowSocial, 'google', queryString)
  }
}

export function* loginSlackWatcher() {
  while (true) {
    const {
      payload: { queryString }
    } = yield take(sessionTypes.LOGIN_REQUEST_SLACK)
    yield fork(loginFlowSocial, 'slack', queryString)
  }
}

export function* addAppSlackWatcher() {
  while (true) {
    const {
      payload: { queryString }
    } = yield take(sessionTypes.ADD_APP_SLACK)
    yield fork(addAppFlowSlack, queryString)
  }
}

export function* logoutWatcher() {
  while (true) {
    yield take(sessionTypes.LOGOUT_REQUEST)
    yield fork(logoutFlow)
  }
}

export default function* sessionWatcher() {
  yield all([
    loginSuccessWatcher(),
    loginWatcher(),
    logoutWatcher(),
    loginGoogleWatcher(),
    loginSlackWatcher(),
    addAppSlackWatcher()
  ])
}
