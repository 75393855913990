export const REALTIME_MESSAGE = {
  CALL: {
    START: 'call:start',
    JOIN: 'call:join',
    LEAVE: 'call:leave',
    // NOTE: (unused)
    SHARE_SCREEN: 'call:share-screen'
  },
  TEAM: {
    BUSY_KNOCK: 'busy:knock',
    TEAM_UPDATED: 'team:updated',
    NEW_USER_JOINED: 'team:user:joined',
    USER: {
      CHANGE_IDLE: 'user:change:idle',
      CHANGE_PHOTO: 'user:change:photo',
      CHANGE_STATE: 'user:change:state',
      CHANGE_CALL: 'user:change:call',
      CHANGE_STATUS: 'user:change:status',
      CHANGE_OFFICE: 'user:change:office',
      DUPLICATE_USER: 'user:presence:duplicate-user',
      CHANGE_PROFILE: 'user:change:profile',
      CHANGE_INTERVAL: 'user:change:interval',
      CHANGE_SNAPSHOT_EFFECT: 'user:change:snapshot-effect',
      NOTIFICATION: 'user:send:notification'
    },
    OFFICE: {
      REMOVED_OFFICE: 'office:removed',
      CREATED_OFFICE: 'office:created',
      UPDATED_OFFICE: 'office:updated'
    }
  },
  USER: {
    ERROR: 'error',
    LOGOUT: 'logout',
    OFFICE_JOIN: 'office:join',
    TEAM_JOIN: 'team:join',
    TEAM_REMOVED: 'team:removed'
  }
}
