import { axios, UPLOAD_POLICY_API_ENDPOINT } from './http'
import base64Binary from '../../helpers/base64Binary'
import { UploadPolicy } from '../../../interfaces/uploadPolicy'
import { AxiosResponse } from 'axios'

export async function fetchUploadPolicy(teamId: number): Promise<AxiosResponse<UploadPolicy>> {
  try {
    return await axios.get(`${UPLOAD_POLICY_API_ENDPOINT}?teamId=${teamId}`)
  } catch (error) {
    throw error
  }
}

export async function uploadPhoto(
  policy: UploadPolicy, imgData: string
): Promise<AxiosResponse> {
  const bArray = base64Binary.decodeArrayBuffer(imgData)
  let blob
  if (Blob) {
    blob = new Blob([bArray])
  } else {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    let Builder = BlobBuilder || WebKitBlob || MozBlobBuilder
    let bb = new Builder()
    bb.append(bArray)
    blob = bb.getBlob()
  }

  let formData = new FormData()
  Object.keys(policy.inputs).forEach((key) => {
    if (key !== 'key') {
      formData.append(key, policy.inputs[key])
    }
  })

  let extension = 'jpg'

  formData.append('key', `${policy.inputs.key}.${extension}`)
  formData.append('file', blob)

  return axios({
    method: 'post',
    url: policy.url,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    responseType: 'text',
    transformRequest: [
      (data) => {
        return data
      }
    ]
  })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}
