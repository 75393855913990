import { Types } from 'ably'
import React from 'react'

interface SocketContextProps {
    serverChannel: Types.RealtimeChannelCallbacks|null;
    clientChannels: {[key: string]: Types.RealtimeChannelCallbacks};
    setServerChannel:((channel: Types.RealtimeChannelCallbacks) => void)|null;
    setClientChannels: React.Dispatch<React.SetStateAction<{[key: string]: Types.RealtimeChannelCallbacks}|null>>;
  }

export const SocketContext = React.createContext<SocketContextProps>(
  {
    serverChannel: null,
    clientChannels: {},
    setServerChannel: null,
    setClientChannels: () => {}
  }
)
