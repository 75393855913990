import {
  ImgHTMLAttributes,
  SyntheticEvent,
  useEffect,
  useRef,
  useState
} from 'react'
import styled, { CSSProperties } from 'styled-components'
import fallbackPhoto from '../assets/img/icon.no_camera.svg'

interface Props extends ImgHTMLAttributes<{}> {
  isMySelf: boolean
  isHidden: boolean
}

const ImageStyled = styled.img<Props>`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 8px;
  ${(props) => {
    return props.isMySelf ? 'transform: scaleX(-1);' : 'transform: scaleX(1);'
  }}
`

const ChatHeadPhoto = (props: Props) => {
  const [style, setStyle] = useState<CSSProperties>()
  const prevSrc = useRef<string | undefined>()
  const onError = (ev: SyntheticEvent<any, Event>) => {
    ev.preventDefault()
    ev.currentTarget.src = fallbackPhoto
    setStyle({
      transform: 'scaleX(1)',
      objectFit: 'scale-down'
    })
  }

  useEffect(() => {
    if (prevSrc.current !== props.src) {
      // eslint-disable-next-line no-undefined
      setStyle(undefined)
    }
    prevSrc.current = props.src
  }, [props.src])

  return (
    <ImageStyled
      {...props}
      onError={onError}
      style={style}
      hidden={props.isHidden}
    />
  )
}

export default ChatHeadPhoto
