import React, { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from 'react'
import '../modules/_styles/video.css'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { calculateElementFit } from '../components/Helpers'
import ChatHead, {
  ContainerStyled as ChatHeadContainer,
  MetadataContainerStyled
} from '../components/ChatHead'
import {
  StyledTime,
  StyledTimeSubtitle
} from '../components/Time'
import { getUserChatHeads } from '../reducers/shared'
import { getActiveSpeaker, getActiveSpeakerScreen } from '../reducers/call'
import { countTeams } from '../reducers/teams'
import { getCurrentUser, getCurrentUserLayout } from '../reducers/user'
import MyChatHead from '../hocs/MyChatHead'
import UserChatHead from '../hocs/UserChatHead'
import ScreenChatHead from '../hocs/ScreenChatHead'
import { RootState } from '../store'
import { CALL_STATE, layouts } from '../constants/call'
import { User } from '../interfaces/user'
import { MessageStyled } from '../components/Metadata'
import { TrackContext, TrackDetail } from '../contexts/stream'
import { getIsPictureInPicture } from '../reducers/ui'

const Myself = MyChatHead(ChatHead)
const OtherUser = UserChatHead(ChatHead)
const ScreenShare = ScreenChatHead(ChatHead)

interface ChatHeadsContainerStyledProps {
  totalTeams: number;
  perRow: number;
  layout: number;
  expanded: boolean;
  isOnPip: boolean;
  isPipActive: boolean
}

const ChatHeadsContainerStyled = styled.div < ChatHeadsContainerStyledProps > `
  background: #1a1a1a;
  min-height: 100%;
  padding: 3px 0 0 3px;
  overflow: hidden;

  ${(props) => {
    if (props.isOnPip) {
      return css `
        position: absolute;
        width: 100%;
        padding: 0;
        left: 0;
        top: 0;
      `
    }
    return css `
      position: relative;
      width: calc(100% - ${
  props.expanded ? '365px' : '60px' } - ${props.totalTeams > 1 ? '80px' : '0px'});
    `
  }}

  ${(props) => {
    const { isOnPip, isPipActive, perRow } = props
    const layout = !isOnPip && isPipActive ? layouts.CHAT_HEAD : props.layout
    if (perRow && layout !== layouts.FULL_SCREEN) {
      // @ts-ignore
      const width = props.containerRef?.current?.offsetWidth
      // 6 is the margin between chatheads
      const chatheadWidth = (width - 3) / perRow - 6
      return css`
        ${ChatHeadContainer} {
          width: ${chatheadWidth}px;
        }
        ${StyledTime} {
          font-weight: 700;
          font-size: ${() => {
    if (chatheadWidth < 300) {
      return '28px'
    } else if (chatheadWidth < 400) {
      return '32px'
    }
    return '36px'
  }}
        }
        ${StyledTimeSubtitle} {
          font-weight: 700;
          font-size: ${() => {
    if (chatheadWidth < 300) {
      return '10px'
    } else if (chatheadWidth < 400) {
      return '14px'
    } else if (chatheadWidth < 500) {
      return '16px'
    }
    return '22px'
  }}
        }
        ${MetadataContainerStyled} {
          padding: ${() => {
    if (chatheadWidth < 300) {
      return '40px 18px 20px 18px'
    }
    return '30px 30px 30px 28px'
  }}
      }
        ${MessageStyled} {
          font-size: ${() => {
    if (chatheadWidth < 360) {
      return 14
    } else if (chatheadWidth >= 360 && chatheadWidth < 720) {
      return 16
    } else if (chatheadWidth >= 720) {
      return 16
    }
    return 12
  }}px;
          width: ${0.8 * chatheadWidth}px;
        }
      `
    }
    return null
  }}
`

interface ChatHeadsContainerProps extends React.PropsWithChildren<ChatHeadsContainerStyledProps> {
  expanded: boolean;
  containerRef: React.RefObject<any>;
}

const ChatHeadsContainer: React.FC<ChatHeadsContainerProps> = (props) => {
  return (
    <ChatHeadsContainerStyled ref={props.containerRef} {...props}>
      {props.children}
    </ChatHeadsContainerStyled>
  )
}

interface ChatheadCSS {
  video: React.CSSProperties|null;
  screen: React.CSSProperties|null;
}

const ChatHeads: React.FC<{isOnPip: boolean}> = ({ isOnPip }) => {
  const chatHeadsRef = useRef<HTMLDivElement>(null)
  const snapShotRef = useRef<HTMLVideoElement>(null)
  const [perRow, setPerRow] = useState<number|null>(null)
  const [sizes, setSizes] = useState<{[key: string]: ChatheadCSS}>({})
  const [screenTrack, setScreenTrack] = useState<TrackDetail>()
  const [screenUId, setScreenUId] = useState(0)
  const chatHeads = useSelector((state: RootState) => getUserChatHeads(state))
  const expanded = useSelector((state: RootState) => state.ui.sideBarExpanded)
  const totalTeams = useSelector((state: RootState) => countTeams(state))
  const activeSpeaker = useSelector((state: RootState) => getActiveSpeaker(state))
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  // const activeSpeakerManual = useSelector((state: RootState) => getIsActiveSpeakerManual(state))
  const activeSpeakerScreen = useSelector((state: RootState) => getActiveSpeakerScreen(state))
  const layout = useSelector((state: RootState) => getCurrentUserLayout(state))
  const currentUserState: User = useSelector((state: RootState) => getCurrentUser(state))
  const isPipActive: boolean = useSelector((state: RootState) => getIsPictureInPicture(state))

  const call = currentUserState.teamState[currentUserState.selectedTeam]
  const { tracks } = useContext(TrackContext)
  const defaultPerRow = 8
  const defaultStyles: CSSProperties = {
    margin: '3px',
    borderRadius: '8px'
  }


  const getDefaultPerRow = () => {
    if (chatHeadsRef.current !== null) {
      const defaultSize = calculateElementFit(
        chatHeadsRef.current.offsetWidth,
        chatHeadsRef.current.offsetHeight,
        chatHeads.length
      )
      return Math.ceil(chatHeadsRef.current.offsetWidth / defaultSize)
    }
    return defaultPerRow
  }
  const isScreenActive = () => {
    return screenTrack?.track && !screenTrack.track.isMuted() && !screenTrack.track.disposed
  }

  const calculateSizes = (): {perRow: number|null, sizes: {[key: string]: ChatheadCSS}} => {
    let sizeProps: {[key: string]: ChatheadCSS} = {}
    if (call.callState === CALL_STATE.IN_CALL && layout === layouts.FULL_SCREEN) {
      const square = isOnPip ? 80 : 100
      let nonActiveSpeakerI = 0
      let right = 0
      let offset = isOnPip ? 10 : 50
      let fullscreenFound = false

      const inCallChatheads = chatHeads.filter((chathead) => {
        if (!chathead.user.myself && chathead.me) {
          const userTeamState = chathead.user.teamState[chathead.me.selectedTeam]
          if (userTeamState.callState === CALL_STATE.IN_CALL) {
            const currentUserTeamState = currentUserState.teamState[currentUserState.selectedTeam]
            if (userTeamState.callRoom !== currentUserTeamState?.callRoom) {
              return false
            }
          } else {
            return false
          }
        }
        return true
      })

      if (isOnPip && (!!activeSpeaker || isScreenActive())) {
        inCallChatheads.filter(c => c.user.myself || [activeSpeaker, activeSpeakerScreen].includes(c.user.id))
          .forEach(c => {
            let top = nonActiveSpeakerI * 100 + offset
            let chatheadCss: ChatheadCSS = {
              video: null,
              screen: null
            }
            if (c.user.id === activeSpeaker && !isScreenActive()) {
              chatheadCss.video = {
                height: '100vh',
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                zIndex: 1,
                padding: 0
              }
            }

            if (isScreenActive()) {
              chatheadCss.screen = {
                height: '100vh',
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                zIndex: 1
              }
            }

            if (c.user.myself) {
              chatheadCss.video = {
                borderRadius: '8px',
                height: `${square}px`,
                right: `${right * 100 + offset}px`,
                position: `absolute`,
                top: `${top + 5 * nonActiveSpeakerI}px`,
                width: `${square}px`,
                zIndex: 2
              }
            }
            sizeProps[String(c.user.id)] = chatheadCss
          })
        return {
          perRow: null,
          sizes: sizeProps
        }
      }

      inCallChatheads.every((chathead, index) => {
        let chatheadCss: ChatheadCSS = {
          video: null,
          screen: null
        }
        let top = nonActiveSpeakerI * 100 + offset
        // @ts-ignore
        if (top + 100 > chatHeadsRef.current.offsetHeight - offset) {
          right = right + 1
          nonActiveSpeakerI = 0
          top = 0
        }
        if (isOnPip && !chathead.user.myself && (!activeSpeaker && !activeSpeakerScreen)) {
          chatheadCss.video = {
            height: '100vh',
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: 1
          }
          return false
        }

        if (
          chathead.user.id === Number(activeSpeaker) && activeSpeakerScreen === null ||
          (!fullscreenFound && index === inCallChatheads.length - 1)
        ) {
          chatheadCss.video = {
            height: '100vh',
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: 1
          }
          fullscreenFound = true
        } else {
          chatheadCss.video = {
            borderRadius: '8px',
            height: `${square}px`,
            right: `${right * 100 + offset}px`,
            position: `absolute`,
            top: `${top + 5 * nonActiveSpeakerI}px`,
            width: `${square}px`,
            zIndex: 2
          }
        }

        if (Number(activeSpeakerScreen) === chathead.user.id && isScreenActive()) {
          chatheadCss.screen = {
            height: '100vh',
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: 1
          }
          fullscreenFound = true
        } else {
          if (chathead.user.myself && isScreenActive()) {
            nonActiveSpeakerI++
            top = nonActiveSpeakerI * 100 + offset
          }
          chatheadCss.screen = {
            borderRadius: '8px',
            height: `${square}px`,
            right: `${right * 100 + offset}px`,
            position: `absolute`,
            top: `${top + 5 * nonActiveSpeakerI}px`,
            width: `${square}px`,
            zIndex: 3
          }
        }
        if (chatheadCss.video.height !== '100vh') {
          nonActiveSpeakerI++
        }
        sizeProps[String(chathead.user.id)] = chatheadCss
        return true
      })

      return {
        perRow: null,
        sizes: sizeProps
      }
    }
    return {
      perRow: getDefaultPerRow(),
      sizes: {}
    }
  }
  const getTracksByUserId = (userId: number, isMySelf: boolean) => {
    const uTracks = (tracks ? tracks[String(userId)] ?? [] : []).filter(t => t.track?.getVideoType() !== 'desktop')
    return isOnPip || isMySelf
      ? uTracks.filter(t => t.track?.getType() !== 'audio')
      : uTracks
  }

  const getScreenTracks = () => {
    if (!tracks) {
      return null
    }

    for (const userKey of Object.keys(tracks)) {
      const sTracks = tracks[userKey].filter(t => t.track?.getVideoType() === 'desktop')
      if (sTracks.length > 0) {
        return { userId: Number(userKey), tracks: sTracks }
      }
    }
    return null
  }

  const updateDimensions = () => {
    if (!chatHeadsRef || chatHeadsRef.current === null) {
      return
    }

    // PIP is active and this container is for non pip
    // To make sure the chat heads are always in the same position
    if (isPipActive && !isOnPip) {
      setPerRow(getDefaultPerRow())
      return
    }
    const { perRow: calculatedPerRow, sizes: calculatedSizes } = calculateSizes()
    if (perRow !== calculatedPerRow || calculatedPerRow === null) {
      setPerRow(calculatedPerRow)
    }
    setSizes(calculatedSizes)
  }

  const onResize = useCallback((e: UIEvent) => {
    e.preventDefault()
    updateDimensions()
  }, [chatHeadsRef, chatHeads, expanded, layout, activeSpeaker, activeSpeakerScreen, screenTrack, isPipActive])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize, window])

  useEffect(() => {
    updateDimensions()
  }, [chatHeadsRef, chatHeads, expanded, layout, activeSpeaker, activeSpeakerScreen, screenTrack, isPipActive])

  useEffect(() => {
    const sc = getScreenTracks()
    if (sc && sc.tracks.length > 0) {
      setScreenTrack(sc.tracks[0])
      setScreenUId(sc.userId)
    } else {
      // eslint-disable-next-line no-undefined
      setScreenTrack(undefined)
      setScreenUId(0)
    }
  }, [getScreenTracks()])

  return (
    <ChatHeadsContainer
      expanded={expanded}
      containerRef={chatHeadsRef}
      totalTeams={totalTeams}
      perRow={Number(perRow)}
      layout={call.callState === CALL_STATE.IN_CALL ? layout : layouts.CHAT_HEAD}
      isOnPip={isOnPip}
      isPipActive={isPipActive}
    >
      <div
        style={{
          display: 'block',
          width: `calc(100% - ${totalTeams > 1 ? '80px' : '0px'})`,
          height: '100%'
        }}
      >
        {chatHeads.map((chathead) => {
          const size = perRow ? null : (sizes[chathead.user.id])
          const key = `${chathead.user.id}`
          if (call.callState === CALL_STATE.IN_CALL && (layout === layouts.CHAT_HEAD_WITHOUT_OTHERS || layout === layouts.FULL_SCREEN)) {
            // skip not in call
            if (!chathead.user.myself && chathead.me) {
              const currentUserTeamstate = currentUserState.teamState[currentUserState.selectedTeam]
              const userTeamState = chathead.user.teamState[chathead.me.selectedTeam]
              if (userTeamState.callState === CALL_STATE.IN_CALL) {
                if (userTeamState.callRoom !== currentUserTeamstate?.callRoom) {
                  return false
                }
              } else {
                return false
              }
            }
          }
          if (chathead.user.myself) {
            return (
              <div key={key}>
                <Myself
                  user={chathead.user}
                  key={key}
                  layout={layout}
                  style={size?.video || defaultStyles}
                  tracks={getTracksByUserId(chathead.user.id, chathead.user.myself)}
                  snapShotRef={snapShotRef}
                  isOnPip={isOnPip}
                />
                <ScreenShare
                  key={`${key}_screen`}
                  me={chathead.me}
                  disableActions={currentUserState.id === screenUId}
                  myself={false}
                  user={chathead.user}
                  layout={layout}
                  style={size?.screen || defaultStyles}
                  showPin={call.callState === CALL_STATE.IN_CALL ? true : false}
                  tracks={screenTrack ? [screenTrack] : []}
                  userScreenId={screenUId}
                  isOnPip={isOnPip}
                />
              </div>
            )
          }
          return (
            <div
              key={`${chathead.user.id}_other`}
            >
              <OtherUser
                key={key}
                user={chathead.user}
                me={chathead.me}
                offline={chathead.offline}
                layout={layout}
                style={size?.video || defaultStyles}
                // todo: add back checks for number of incalls
                // showPin={call.isConnected ? visibleParticipants.length > 2 : false}
                showPin={call.callState === CALL_STATE.IN_CALL ? true : false}
                tracks={getTracksByUserId(chathead.user.id, chathead.user.myself)}
                isOnPip={isOnPip}
              />
            </div>
          )
        })}
      </div>
    </ChatHeadsContainer>
  )
}

export default ChatHeads
