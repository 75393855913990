import * as Ably from 'ably'
import { broadcastingAuth, publicBroadcastingAuth } from '../api/library/auth'
import { REALTIME_MESSAGE } from '../../constants/realtimeMessage'
import { store } from '../../store'
import { actions as callActions } from '../../reducers/call'

let presenceConnected = false

export const initializeSocket = async (
  channelName: string,
  setServerChannel: (channel: Ably.Types.RealtimeChannelCallbacks) => void
) => {
  const options = {
    authCallback: async (tokenParams: any, callback: any) => {
      try {
        const isPublic = channelName.split(':')[0] === 'public'
        const response = isPublic ? await publicBroadcastingAuth(
          `channel_name=${channelName}${presenceConnected ? '&refresh=1' : '&refresh=0'}`
        ) : await broadcastingAuth(
          `channel_name=${channelName}${presenceConnected ? '&refresh=1' : '&refresh=0'}`
        )
        // eslint-disable-next-line no-console
        console.log(`debug:socket:${channelName}`, { response })
        if (response.data.call_token) {
          store.dispatch(callActions.setCallConfig({
            token: response.data.call_token,
            roomFormat: response.data.room_format
          }))
        }
        callback(null, response.data.token)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`socket-auth-fail-${channelName}`, e)
        callback(e)
      }
    }
  }
  const client = new Ably.Realtime(options)
  client.connection.on('failed', (e) => {
    // eslint-disable-next-line no-console
    console.log(`initializeSocket.${channelName} failed`, e)
    presenceConnected = false
  })
  client.connection.on('connected', () => {
    if (channelName.indexOf('presence') > -1) {
      // eslint-disable-next-line
      console.log('set presence true')
      presenceConnected = true
    }
    setServerChannel(client.channels.get(channelName))
  })
}

export const sendStartCall = (
  channel: Ably.Types.RealtimeChannelCallbacks,
  payload: {
    teamId: number,
    officeId: number,
    callerId: number,
    calleeId: number,
    index: number
  }
) => {
  channel.publish(REALTIME_MESSAGE.CALL.START, JSON.stringify({
    type: REALTIME_MESSAGE.CALL.START,
    payload: payload
  }), (err: any) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log('publish result - error: ', err)
    }
  })
}

export const sendEndCall = (
  channel: Ably.Types.RealtimeChannelCallbacks,
  payload: {
    teamId: number,
    userId: number,
  }
) => {
  channel.publish(REALTIME_MESSAGE.CALL.LEAVE, JSON.stringify({
    type: REALTIME_MESSAGE.CALL.LEAVE,
    payload: payload
  }), (err: any) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log('publish result - error: ', err)
    }
  })
}

export const sendUpdateCallState = (
  channel: Ably.Types.RealtimeChannelCallbacks,
  payload: {
    teamId: number;
    userId: number;
    callState: string;
    callRoom: string|null;
  }
) => {
  channel.publish(REALTIME_MESSAGE.CALL.LEAVE, JSON.stringify({
    type: REALTIME_MESSAGE.TEAM.USER.CHANGE_CALL,
    payload: payload
  }), (err: any) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log('publish result - error: ', err)
    }
  })
}

export const sendTeamBusyKnock = (
  channel: Ably.Types.RealtimeChannelCallbacks,
  payload: {
    teamId: number;
    senderId: number;
    recipientId: number;
  }
) => {
  channel.publish(REALTIME_MESSAGE.TEAM.BUSY_KNOCK, JSON.stringify({
    type: REALTIME_MESSAGE.TEAM.BUSY_KNOCK,
    payload: payload
  }), (err: any) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log('publish result - error: ', err)
    }
  })
}

export const sendUserStateChanged = (
  channel: Ably.Types.RealtimeChannelCallbacks,
  payload: {
    userId: number;
    teamId: number;
    state: string;
  }
) => {
  channel.publish(REALTIME_MESSAGE.TEAM.USER.CHANGE_STATE, JSON.stringify({
    type: REALTIME_MESSAGE.TEAM.USER.CHANGE_STATE,
    payload: {
      ...payload,
      timestamp: new Date().getTime()
    }
  }), (err: any) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log('publish result - error: ', err)
    }
  })
}

export const sendUserIdleChanged = (
  channel: Ably.Types.RealtimeChannelCallbacks,
  payload: {
    userId: number;
    teamId: number;
    idle: boolean;
  }
) => {
  channel.publish(REALTIME_MESSAGE.TEAM.USER.CHANGE_IDLE, JSON.stringify({
    type: REALTIME_MESSAGE.TEAM.USER.CHANGE_IDLE,
    payload: payload
  }), (err: any) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log('publish result - error: ', err)
    }
  })
}

export const sendUserPhotoChanged = (
  channel: Ably.Types.RealtimeChannelCallbacks,
  payload: {
    userId: number;
    teamId: number;
    photo: string;
  }
) => {
  channel.publish(REALTIME_MESSAGE.TEAM.USER.CHANGE_PHOTO, JSON.stringify({
    type: REALTIME_MESSAGE.TEAM.USER.CHANGE_PHOTO,
    payload: payload
  }), (err: any) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log('publish result - error: ', err)
    }
  })
}

export const sendUserIntervalChanged = (
  channel: Ably.Types.RealtimeChannelCallbacks,
  payload: {
    userId: number;
    teamId: number;
    timerInterval: number;
  }
) => {
  channel.publish(REALTIME_MESSAGE.TEAM.USER.CHANGE_INTERVAL, JSON.stringify({
    type: REALTIME_MESSAGE.TEAM.USER.CHANGE_INTERVAL,
    payload: {
      ...payload,
      timestamp: new Date().getTime()
    }
  }), (err: any) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log('publish result - error: ', err)
    }
  })
}

export const sendUserSnapshotEffectChanged = (
  channel: Ably.Types.RealtimeChannelCallbacks,
  payload: {
    userId: number;
    teamId: number;
    snapshotEffectId: number;
  }
) => {
  channel.publish(REALTIME_MESSAGE.TEAM.USER.CHANGE_SNAPSHOT_EFFECT, JSON.stringify({
    type: REALTIME_MESSAGE.TEAM.USER.CHANGE_SNAPSHOT_EFFECT,
    payload: {
      ...payload,
      timestamp: new Date().getTime()
    }
  }), (err: any) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log('publish result - error: ', err)
    }
  })
}

export const sendUserStatusMessageChanged = (
  channel: Ably.Types.RealtimeChannelCallbacks,
  payload: {
    userId: number;
    teamId: number;
    statusMessage: string;
  }
) => {
  channel.publish(REALTIME_MESSAGE.TEAM.USER.CHANGE_STATUS, JSON.stringify({
    type: REALTIME_MESSAGE.TEAM.USER.CHANGE_STATUS,
    payload: payload
  }), (err: any) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log('publish result - error: ', err)
    }
  })
}

export const sendUserProfileChanged = (
  channel: Ably.Types.RealtimeChannelCallbacks,
  payload: {
    teamId: number;
    userId: number;
    timezone: string;
    displayName: string;
  }
) => {
  channel.publish(REALTIME_MESSAGE.TEAM.USER.CHANGE_PROFILE, JSON.stringify({
    type: REALTIME_MESSAGE.TEAM.USER.CHANGE_PROFILE,
    payload: payload
  }), (err: any) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log('publish result - error: ', err)
    }
  })
}
