import { all, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../reducers/countdown'
import logger from '../services/logger'
import { DOM_ID } from '../constants/elementIds'
const { error } = logger('saga:countdown')
const jitsi = require('lib-jitsi-meet')

let videoTrack: any = null

// eslint-disable-next-line
function *createLocalTrack(camera: string) {
  // @ts-ignore
  const tracks = yield jitsi.createLocalTracks({
    devices: [ 'video' ]
    // cameraDeviceId: camera
  })
  for (let i = 0; i < tracks.length; i++) {
    if (tracks[i].getType() === 'video') {
      videoTrack = tracks[i]
    }
  }
  const element = document.getElementById(DOM_ID.SNAPSHOT) as HTMLVideoElement
  element.volume = 0
  videoTrack.attach(element).then(() => {
    element.play()
  })
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
function *destroyLocalTrack() {
  // eslint-disable-next-line
  console.log('debug: destroy videotrack', videoTrack)
  if (videoTrack === null) {
    return
  }
  videoTrack.dispose()
  videoTrack = null
}

export function *countdownStart() {
  try {
    // const settings: Setting = yield select(getSettings)
    // yield createLocalTrack(settings.camera)
    yield put(actions.ready())
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error allocating publisher', err)
    yield put(actions.done())
  }
}


export function *countdownStandby() {
  try {
    // const settings: Setting = yield select(getSettings)
    // createLocalTrack(settings.camera)
  } catch (err) {
    yield put(actions.done())
    error(err)
  }
}

export function *countdownDone() {
  // yield destroyLocalTrack()
}

export default function *countdownWatcher() {
  yield all([
    takeLatest(types.COUNTDOWN_START, countdownStart),
    takeLatest(types.COUNTDOWN_DONE, countdownDone),
    takeLatest(types.COUNTDOWN_STANDBY, countdownStandby)
  ])
}
