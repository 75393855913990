/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ProtectedPage from '../../hocs/ProtectedPage'
import {
  SectionLoading,
  ManagementPage,
  ManagementPanel,
  ManagementPanelInner,
  ManagementPanelBlock,
  Snackbar, RadioButton, RadioLabel, ButtonStandard
} from '../../modules'
import {
  actions, getAvailablePlans,
  getSubscriptionNotification,
  getSubscriptionPlan
} from '../../reducers/subscription'
import { SnackbarProps } from '../../modules/Snackbar'
import { RootState } from '../../store'
import { getCurrentUser } from '../../reducers/user'
import { Subscription } from '../../interfaces/subscription'
import { Plan } from '../../interfaces/api/paymentApi'
import { getCurrency } from '../../utils/currency'

const PaymentMethod = () => {
  const title = 'Update your payment information'
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    show: false,
    type: '',
    message: '',
    zIndex: 9999
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [planPeriod, setPlanPeriod] = useState<string>('')
  const [planCurrency, setPlanCurrency] = useState<string>('')
  const [prevPeriod, setPrevPeriod] = useState<string>('')
  const [prevCurrency, setPrevCurrency] = useState<string>('')
  const [selectedPrice, setSelectedPrice] = useState<number>(0)
  const [availablePeriods, setAvailablePeriods] = useState<string[]>([])
  const [availableCurrencies, setAvailableCurrencies] = useState<string[]>([])
  const user = useSelector((state: RootState) => getCurrentUser(state))

  const notification = useSelector((state: RootState) => getSubscriptionNotification(state))
  const plans: Subscription = useSelector((state: RootState) => getSubscriptionPlan(state))
  const availablePlans: Plan[] = useSelector((state: RootState) => getAvailablePlans(state))
  const dispatch = useDispatch()
  const handleSwitchPricePlan = () => {
    dispatch(actions.updatePricePlan(user.selectedTeam, planCurrency,
      planPeriod)
    )
  }
  const getPlanPrice = (period: string, currency: string): number => {
    if (!availablePlans) {
      return 0
    }
    const p = availablePlans.find(plan => {
      return plan.period === period && plan.currency === currency
    })
    if (!p) {
      return 0
    }
    return p.price / 100
  }
  useEffect(() => {
    document.title = 'HQ Remote | ' + title
    if (user.selectedTeam) {
      setLoading(true)
      dispatch(actions.init())
      setLoading(false)
    }
  }, [])
  useEffect(() => {
    if (user.selectedTeam) {
      setLoading(true)
      dispatch(actions.init())
      setLoading(false)
    }
  }, [user.selectedTeam])
  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setSnackbar({
          ...snackbar,
          show: false,
          message: ''
        })
      }, 3000)
    }
  }, [notification])
  useEffect(() => {
    if (plans && plans.type) {
      const planTypes = plans.type.split('_')
      setPlanCurrency(planTypes[1])
      setPlanPeriod(planTypes[0])
      setPrevCurrency(planTypes[1])
      setPrevPeriod(planTypes[0])
      setSelectedPrice(getPlanPrice(planTypes[0], planTypes[1]))
    }
  }, [plans, availablePlans])
  useEffect(() => {
    if (planPeriod && planCurrency) {
      setSelectedPrice(getPlanPrice(planPeriod, planCurrency))
    }
  }, [planPeriod, planCurrency])
  useEffect(() => {
    if (availablePlans && availablePlans.length > 0) {
      let availableP = new Set<string>()
      let availableC = new Set<string>()
      availablePlans.forEach((plan) => {
        availableP.add(plan.period)
        availableC.add(plan.currency)
      })
      setAvailablePeriods([...availableP])
      setAvailableCurrencies([...availableC])
    }
  }, [availablePlans])
  return (
    <ManagementPage>
      {loading && <SectionLoading color={false} />}
      {!loading && (
        <ManagementPanel className='management-panel'>
          <ManagementPanelInner className='management-panel__inner'>
            <ManagementPanelBlock
              className='management-panel__block'
              noMargin>
              <h2>{title}</h2>
              <p className='spaced'>
                Thanks for using HQ Remote, we really appreciate it. You can
                update your payment information below.
              </p>
              <div className='form__row'>
                <label className='form__label'>
                  Period
                </label>
                <div className='form__options-group' style={{ display: 'inline-flex' }}>
                  {availablePeriods.map((p, index) => {
                    return (
                      <React.Fragment key={`${p}_${index}`}>
                        <RadioButton defaultValue={p} />
                        <RadioLabel
                          className={`btn ${
                            p === planPeriod ? 'checked' : ''
                          }`}
                          // @ts-ignore
                          onClick={() => setPlanPeriod(p)}
                          inline
                        >
                          {p.charAt(0).toUpperCase() + p.slice(1)}
                        </RadioLabel>
                        &nbsp;
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
              <div className='form__row'>
                <label className='form__label'>
                  Currency
                </label>
                <div className='form__options-group' style={{ display: 'inline-flex' }}>
                  {availableCurrencies.map((c, index) => (
                    <React.Fragment key={`${c}_${index}`}>
                      <RadioButton defaultValue={c} />
                      <RadioLabel
                        className={`btn ${
                          c === planCurrency ? 'checked' : ''
                        }`}
                        // @ts-ignore
                        onClick={() => setPlanCurrency(c)}
                        inline
                      >
                        {c.charAt(0).toUpperCase() + c.slice(1)}
                      </RadioLabel>
                      &nbsp;
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className='form__row'>
                <p className='form__secondary-text'>
                  <small>
                    <em>
                      {getCurrency(planCurrency)}
                      <span className='js-billing-amount'>{selectedPrice ?? '-'}</span>
                    </em>{' '}
                    per user per{' '}
                    <span className='js-billing-cycle'>
                        year (excluding VAT)
                    </span>
                  </small>
                </p>
              </div>
              <div className='form__row'>
                <ButtonStandard
                  className='btn'
                  disabled={prevPeriod === planPeriod && prevCurrency === planCurrency}
                  onClick={handleSwitchPricePlan}
                >
                  Update
                </ButtonStandard>
              </div>
            </ManagementPanelBlock>
          </ManagementPanelInner>
        </ManagementPanel>
      )}
      <Snackbar
        show={snackbar.show}
        type={snackbar.type}
        message={snackbar.message}
        zIndex={snackbar.zIndex}
      />
    </ManagementPage>
  )
}

export default ProtectedPage(PaymentMethod)
