import React from 'react'
import styled from 'styled-components'

// import LogoIcon from '../../assets/img/logo.svg'
import HQLogoIcon from '../../assets/img/hq_small_logo.svg'

export const LogoWrapper = styled.div`
  background-size: 100% auto;
  display: block;
  width: 38px;
  height: 20px;
`

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
`

export default () => {
  return (
    <LogoWrapper>
      <LogoImage src={HQLogoIcon} />
    </LogoWrapper>
  )
}
