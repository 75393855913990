/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, isTeamSubscriptionActive } from '../reducers/teams'
import ProtectedPage, { ProtectedComponentProps } from '../hocs/ProtectedPage'
import {
  SectionLoading,
  ManagementPage,
  ManagementPanel,
  ManagementPanelInner,
  Snackbar,
  ButtonStandard,
  ManagementPanelBlock,
  Loader,
  FormRowWithBorder
} from '../modules'
import { CALL_INTERVAL } from '../constants/call'
import { SnackbarProps } from '../modules/Snackbar'
import { Interval } from '../interfaces/interval'
import { RootState } from '../store'
import AccountSettingTitle from '../components/AccountSettingTitle'

const ManageTeamEdit: React.FC<ProtectedComponentProps> = (props) => {
  const title = 'Team Settings'
  const team = useSelector((state: RootState) => state.entities.teams.byId[state.user.selectedTeam])
  const isSubscribed = useSelector(
    (state: RootState) => isTeamSubscriptionActive(
      state, team ? team.id : 0))
  const [onSaving, setOnSaving] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [name, setName] = useState<string>('')
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    message: '', show: false, type: '', zIndex: 9999
  })

  const errors = useSelector((state: RootState) => state.entities.teams.errors)
  const [snapshotEffect, setSnapshotEffect] = useState<boolean>(false)
  const [interval, setInterval] = useState<Interval>({
    allow_interval_1_minute: false, allow_interval_5_minutes: false, allow_interval_manual: false
  })
  const dispatch = useDispatch()
  const updateSnackBar = () => {
    const { error, message, response } = errors
    let errorMessage = message

    if (response && response.status === 403) {
      errorMessage = 'You don\'t have permission to edit settings.'
    }
    if (response && response.status === 422) {
      errorMessage = 'Please fill out all required field'
    }
    if (!error) {
      errorMessage = 'Team setting has been updated'
    }

    setSnackbar({
      ...snackbar,
      show: !!message,
      type: error ? 'error' : '',
      message: errorMessage
    })
  }
  const setData = () => {
    if (props.user.selectedTeam) {
      setLoading(false)
      setName(team?.name)
      setInterval({
        ...interval,
        allow_interval_manual: !!team.permissions?.intervals[0],
        allow_interval_1_minute: !!team.permissions?.intervals[1],
        allow_interval_5_minutes: !!team.permissions?.intervals[2]
      })
      setSnapshotEffect(team.permissions.snapshotEffects === 1)
    }
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    setOnSaving(true)
    let intervals = [CALL_INTERVAL.ONE_MINUTE]

    if (interval.allow_interval_manual) {
      intervals.push(CALL_INTERVAL.MANUAL)
    }
    if (interval.allow_interval_5_minutes) {
      intervals.push(CALL_INTERVAL.FIVE_MINUTES)
    }
    dispatch(actions.updateTeamSetting(team.id, {
      name,
      snapshot_effects: snapshotEffect,
      intervals
    }))
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: input } = e
    if (input.type === 'checkbox') {
      if (input.name.startsWith('allow_interval')) {
        setInterval({
          ...interval,
          [input.name]: input.checked
        })
        return
      }
      if (input.name === 'snapshot_effects') {
        setSnapshotEffect(input.checked)
        return
      }
    }
    setName(input.value)
  }
  useEffect(() => {
    if (snackbar.show) {
      setTimeout(() => {
        setSnackbar({
          ...snackbar,
          show: false,
          type: '',
          message: ''
        })
        setOnSaving(false)
      }, 3000)
    }
  }, [snackbar.show])
  useEffect(() => {
    if (props.user.selectedTeam) {
      setData()
    }
  }, [props.user.selectedTeam])
  useEffect(() => {
    if (errors) {
      updateSnackBar()
    }
  }, [errors])
  useEffect(() => {
    if (props.user.selectedTeam) {
      setData()
    }
    return () => {
      document.title = 'HQ Remote'
    }
  }, [])
  return (

    <ManagementPage>
      {loading ? (
        <SectionLoading color={false} />
      ) : (
        <ManagementPanel>
          <ManagementPanelInner>
            <ManagementPanelBlock noMargin>
              <AccountSettingTitle>
                {title}
              </AccountSettingTitle>
            </ManagementPanelBlock>
            <form className='form manage-team' onSubmit={(e) => handleSubmit(e)}>
              <FormRowWithBorder className='form__row'>
                <p style={{
                  fontSize: '18px',
                  color: '#666666',
                  fontWeight: 700
                }} className='spaced'>
                  Note:
                </p>
                <p style={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#9D9D9D'
                }}>
                You currently cannot change the value of the "Team Url" at
                    this time.
                </p>
              </FormRowWithBorder>

              <div className='form__row'>
                <label htmlFor='name' className='form__label'>
                  Team Name
                </label>
                <input
                  type='text'
                  className='form__text-input'
                  name='name'
                  id='name'
                  placeholder='e.g. Team Name'
                  value={name}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className='form__row'>
                <label className='form__label'>Team Url</label>
                <input
                  disabled={true}
                  type='text'
                  className='form__text-input'
                  name='url'
                  id='url'
                  value={'https://analogrepublic.hqremote.io'}
                  onChange={() => {}}
                />
              </div>

              <div className='form__row'>
                <label htmlFor='name' className='form__label__bold' style={{
                  fontWeight: '700 !important'
                }}>
                  Allowed Snapshot Intervals  { !isSubscribed && `(premium account only)` }
                </label>
                <label className='form__checkbox'>
                  <input
                    type='checkbox'
                    name='allow_interval_manual'
                    disabled={!isSubscribed}
                    value={1}
                    checked={
                      interval.allow_interval_manual
                    }
                    onChange={(e) => handleChange(e)}
                  />
                  &nbsp;
                  <span className='form__checkbox__text'>Manual</span>
                </label>
                <label className='form__checkbox'>
                  <input
                    type='checkbox'
                    name='allow_interval_1_minute'
                    value={2}
                    checked={
                      interval.allow_interval_1_minute
                    }
                    disabled={true}
                    onChange={(e) => handleChange(e)}
                  />
                  &nbsp;
                  <span className='form__checkbox__text'>
                      1 Minute (this can't be disabled)
                  </span>
                </label>
                <label className='form__checkbox'>
                  <input
                    type='checkbox'
                    name='allow_interval_5_minutes'
                    value={3}
                    disabled={!isSubscribed}
                    checked={
                      interval.allow_interval_5_minutes
                    }
                    onChange={(e) => handleChange(e)}
                  />
                  &nbsp;
                  <span className='form__checkbox__text'>5 Minutes</span>
                </label>
              </div>
              <div className='form__row'>
                <label htmlFor='name' className='form__label__bold'>
                  Allow Snapshot Pixelation { !isSubscribed && `(premium account only)` }
                </label>
                <label className='form__checkbox'>
                  <input
                    disabled={!isSubscribed}
                    type='checkbox'
                    name='snapshot_effects'
                    defaultChecked={snapshotEffect}
                    onChange={(e) => handleChange(e)}
                  />
                  &nbsp;
                  <span className='form__checkbox__text'>Yes</span>
                </label>
              </div>

              <div
                className='form__action form__row'
                style={{
                  margin: '30px 0 10px'
                }}>
                <ButtonStandard
                  type='submit'
                  className='btn p-relative'
                  disabled={onSaving}>
                  {(onSaving && <Loader white />) || `Save Team`}
                </ButtonStandard>
              </div>
            </form>
          </ManagementPanelInner>
        </ManagementPanel>
      )}
      <Snackbar
        show={snackbar.show}
        type={snackbar.type}
        message={snackbar.message}
        zIndex={snackbar.zIndex}
      />
    </ManagementPage>
  )
}

export default ProtectedPage(ManageTeamEdit)
