import React, { useContext, useEffect } from 'react'
import { Types } from 'ably'
import { initializeSocket } from '../services/socket/socket'
import { SocketContext } from '../contexts/socket'

interface ClientSocketProps {
  presence: boolean;
  channelName: string;
  handler: (message: Types.Message) => void;
  presenceHandler: Types.messageCallback<Types.PresenceMessage>|null;
  ready: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClientSocket = (props: ClientSocketProps) => {
  const { setClientChannels, clientChannels } = useContext(SocketContext)

  useEffect(() => {
    initializeSocket(
      props.channelName,
      (ch) => {
        if (setClientChannels) {
          setClientChannels(
            (prevChannels) => {
              const newChannels: {[key: string]: any} = {}
              Object.assign(newChannels, prevChannels)
              newChannels[props.channelName] = ch
              return newChannels
            })
        }
      }
    )
  }, [])
  useEffect(() => {
    if (
      !clientChannels[props.channelName] ||
      clientChannels[props.channelName] === null ||
      Object.keys(clientChannels).length < 4
    ) {
      return
    }
    props.ready((prev: boolean) => {
      if (prev) {
        return true
      }
      if (props.presence) {
        if (props.presenceHandler) {
          clientChannels[props.channelName].presence.subscribe(props.presenceHandler)
          clientChannels[props.channelName].presence.enter()
        }
      }
      // eslint-disable-next-line no-undefined
      clientChannels[props.channelName].subscribe(props.handler)
      return true
    })
  }, [clientChannels])
  return <></>
}

export default ClientSocket
